import { ProcessType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { LegacyPaperLayoutProps } from "~/components/PaperLayout/LegacyPaperLayout";
import { LegacyPaperLayout } from "~/components/PaperLayout/LegacyPaperLayout";
import { ProjectPageTitleAccessory } from "../../../components/ProjectPageTitleAccessory";
import { RunbooksPaperLayout } from "../../Runbooks/Layouts";
type ProcessPaperLayoutProps = LegacyPaperLayoutProps & {
    processType: ProcessType;
};
export const ProcessPaperLayout: React.FC<ProcessPaperLayoutProps> = ({ children, ...rest }) => {
    if (rest.processType === ProcessType.Runbook) {
        return (<RunbooksPaperLayout fullWidth={true} {...rest}>
                {children}
            </RunbooksPaperLayout>);
    }
    else {
        return (<LegacyPaperLayout titleAccessory={<ProjectPageTitleAccessory />} fullWidth={true} {...rest}>
                {children}
            </LegacyPaperLayout>);
    }
};
ProcessPaperLayout.displayName = "ProcessPaperLayout"
