import type { Dictionary } from "lodash";
import type * as React from "react";
import { ClusterRoleBindingIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/ClusterRoleBindingIcon";
import { ClusterRoleIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/ClusterRoleIcon";
import { ConfigMapIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/ConfigMapIcon";
import { CronJobIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/CronJobIcon";
import { CustomResourceIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/CustomResourceIcon";
import { DemonSetIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/DemonSetIcon";
import { DeploymentIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/DeploymentIcon";
import { EndpointSliceIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/EndpointSliceIcon";
import { GroupIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/GroupIcon";
import { HorizontalAutoScalerIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/HorizontalAutoScalerIcon";
import { IngressIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/IngressIcon";
import { JobIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/JobIcon";
import { LimitsIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/LimitsIcon";
import { NameSpaceIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/NameSpaceIcon";
import { NetworkPolicyIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/NetworkPolicyIcon";
import { PersistentVolumeClaimIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/PersistentVolumeClaimIcon";
import { PersistentVolumeIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/PersistentVolumeIcon";
import { PodIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/PodIcon";
import { PodSecurityPolicyIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/PodSecurityPolicyIcon";
import { QuotaIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/QuotaIcon";
import { ReplicaSetIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/ReplicaSetIcon";
import { RoleBindingIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/RoleBindingIcon";
import { RoleIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/RoleIcon";
import { SecretIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/SecretIcon";
import { ServiceAccountIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/ServiceAccountIcon";
import { ServiceIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/ServiceIcon";
import { StatefulSetIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/StatefulSetIcon";
import { StorageClassIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/StorageClassIcon";
import { UserIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/UserIcon";
import { VolumeIcon } from "~/areas/projects/components/Observability/K8ResourceIcons/VolumeIcon";
export const KnownKubernetesResources: Dictionary<() => React.ReactElement> = {
    ClusterRole: ClusterRoleIcon,
    ConfigMap: ConfigMapIcon,
    ClusterRoleBinding: ClusterRoleBindingIcon,
    CustomResourceDefinition: CustomResourceIcon,
    CronJob: CronJobIcon,
    ControllerRevision: DeploymentIcon,
    Deployment: DeploymentIcon,
    DemonSet: DemonSetIcon,
    Endpoints: EndpointSliceIcon,
    EndpointSlice: EndpointSliceIcon,
    Group: GroupIcon,
    HorizontalAutoScaler: HorizontalAutoScalerIcon,
    Ingress: IngressIcon,
    Job: JobIcon,
    Limits: LimitsIcon,
    NetworkPolicy: NetworkPolicyIcon,
    NameSpace: NameSpaceIcon,
    Pod: PodIcon,
    PodSecurityPolicy: PodSecurityPolicyIcon,
    PersistentVolume: PersistentVolumeIcon,
    PersistentVolumeClaim: PersistentVolumeClaimIcon,
    Quota: QuotaIcon,
    RoleBinding: RoleBindingIcon,
    Role: RoleIcon,
    ReplicaSet: ReplicaSetIcon,
    ServiceAccount: ServiceAccountIcon,
    StorageClass: StorageClassIcon,
    Secret: SecretIcon,
    StatefulSet: StatefulSetIcon,
    Service: ServiceIcon,
    User: UserIcon,
    Volume: VolumeIcon,
};
export function K8sResourceIcon({ resourceType }: {
    resourceType: string;
}): React.ReactElement {
    return KnownKubernetesResources[resourceType] === undefined ? CustomResourceIcon() : KnownKubernetesResources[resourceType]();
}
