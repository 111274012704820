import { css, cx } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
interface ChipIconWrapperProps {
    backgroundColor?: string;
    children?: React.ReactNode;
}
export function ChipIconWrapper({ children, backgroundColor }: ChipIconWrapperProps): React.ReactElement | null {
    const backgroundColorValue = backgroundColor || "var(--avatarBackground)";
    return <div className={cx(chipIconWrapperStyles, chipIconWrapperBackground(backgroundColorValue))}>{children}</div>;
}
const chipIconWrapperStyles = css({
    margin: `${space[4]} 0`,
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
});
const chipIconWrapperBackground = (backgroundColor: string) => css({
    backgroundColor: backgroundColor,
});
interface ChipLegacyIconWrapperProps {
    iconComponent: React.ComponentType<{
        style?: React.CSSProperties;
    }>;
    iconColor?: string;
    backgroundColor?: string;
}
export function ChipLegacyIconWrapper({ iconComponent: Icon, iconColor, backgroundColor }: ChipLegacyIconWrapperProps): React.ReactElement | null {
    return (<ChipIconWrapper backgroundColor={backgroundColor}>
            <Icon 
    // Any icons here are almost definitely MUI SvgIcons, which have their own styles applied via a class name.
    // These inline styles are needed to override these without directly referencing the MUI class names.
    style={{
            fill: iconColor || "var(--chipIcon)",
            width: 14,
            height: 14,
        }}/>
        </ChipIconWrapper>);
}
