/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/init-declarations */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Tooltip } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { EnvironmentResource, GetTaskRunDashboardItemsListArgs, ResourceCollection, RunbookResource, RunbooksDashboardItemResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, HasRunbooksInGit, Permission, Repository, TenantedDeploymentMode, toGitBranch, TriggerActionCategory } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import classNames from "classnames";
import type * as H from "history";
import { isEqual } from "lodash";
import * as React from "react";
import { useHistory } from "react-router";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { AdvancedFilters } from "~/components/AdvancedFilterLayout/AdvancedFilters";
import { EnvironmentChip, TenantChip } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { Feature, FeatureToggle } from "~/components/FeatureToggle";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import RunbookMultiSelect from "~/components/MultiSelect/RunbookMultiSelect";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import { TenantTagMultiSelect } from "~/components/MultiSelect/TenantTagMultiSelect";
import InternalLink from "~/components/Navigation/InternalLink";
import InternalNavLink from "~/components/Navigation/InternalNavLink";
import { PageContent } from "~/components/PageContent/PageContent";
import { PageLoading } from "~/components/PageContent/PageLoading";
import PagingDataTable from "~/components/PagingDataTable";
import { PermissionCheck } from "~/components/PermissionCheck";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { Select } from "~/components/form";
import EnvironmentSelect from "~/components/form/EnvironmentSelect/EnvironmentSelect";
import type { TagIndex } from "~/components/tenantTagsets";
import * as tenantTagsets from "~/components/tenantTagsets";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import { NextScheduleRunsTitle } from "./NextScheduledRuns";
import styles from "./OperationsOverviewPage.module.less";
import RunbookTaskStatusDetails from "./RunbookTaskStatusDetails/RunbookTaskStatusDetails";
import { RunbooksWelcomeOnboarding } from "./RunbooksOnboarding";
import { ScheduledTriggersBar } from "./Triggers/ScheduledTriggersBar";
import { useNewRunbookRunsListPageFeatureToggle } from "./useNewRunbookRunsListPageFeatureToggle";
interface OperationsOverviewLayoutFilter {
    environmentIds: string[];
    runbookIds: string[];
    tenantIds: string[];
    tenantTags: string[];
}
interface OperationsOverviewLayoutQuery extends IQuery {
    environmentIds: string[];
    runbookIds: string[];
    tenantIds: string[];
    tenantTags: string[];
}
const OperationsOverviewQueryStringFilters = QueryStringFilters.For<OperationsOverviewLayoutFilter, OperationsOverviewLayoutQuery>();
class OperationsOverviewTable extends PagingDataTable<RunbooksDashboardItemResource> {
}
interface OperationsOverviewPageState extends DataBaseComponentState {
    hasAtLeastOneRunbook?: boolean;
    runbookRunsDashboardItems: ResourceCollection<RunbooksDashboardItemResource> | null;
    tenants: TenantResource[];
    tagIndex: TagIndex;
    environments: EnvironmentResource[];
    runbooks: RunbookResource[];
    filter: OperationsOverviewLayoutFilter;
    queryFilter?: OperationsOverviewLayoutFilter;
    totalTriggers: number;
    runbookRunsDashboardItemsToTake: number;
}
interface OperationsOverviewPagePropsInternal extends WithProjectContextInjectedProps {
    newRunbookRunsListPageEnabled: boolean;
    history: H.History;
}
const refreshIntervalInMs = 15000;
const initialTakeOfDashboardItems = 30;
class OperationsOverviewPageInternal extends DataBaseComponent<OperationsOverviewPagePropsInternal, OperationsOverviewPageState> {
    constructor(props: OperationsOverviewPagePropsInternal) {
        super(props);
        this.state = {
            hasAtLeastOneRunbook: null!,
            runbookRunsDashboardItems: null,
            tenants: [],
            tagIndex: null!,
            filter: createEmptyFilter(),
            environments: [],
            runbooks: [],
            totalTriggers: 0,
            runbookRunsDashboardItemsToTake: initialTakeOfDashboardItems,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.startRefreshLoop(() => this.getData(), refreshIntervalInMs, false, timeOperationOptions.forRefresh());
        });
    }
    async reloadRuns() {
        await this.doBusyTask(async () => {
            this.setState(await this.getData());
        }, { timeOperationOptions: timeOperationOptions.for("ReloadRuns") });
    }
    getRunbookRunsDashboardItems = async (itemsToTake: number) => {
        const project = this.props.projectContext.state.model;
        return repository.Progression.getTaskRunDashboardItemsForProject(project, {
            projectIds: [project.Id],
            environmentIds: this.state.filter.environmentIds,
            tenantIds: this.state.filter.tenantIds,
            runbookIds: this.state.filter.runbookIds,
            take: itemsToTake,
        });
    };
    getData = async () => {
        const project = this.props.projectContext.state.model;
        if (!project) {
            return null;
        }
        // We use the list of Runbooks to allow the user to filter by specific Runbooks. The default branch Runbooks feels like a safe bet for this one for
        // now. We may want to add a branch selector here or allow free-form entry of a Runbook slug, but for now this will do.
        const defaultBranch = HasGitPersistenceSettings(project.PersistenceSettings) && HasRunbooksInGit(project.PersistenceSettings) ? toGitBranch(project.PersistenceSettings.DefaultBranch) : undefined;
        const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? repository.Tenants.all() : Promise.resolve([]);
        const [tenants, tagIndex, environments, runbooks, runbookRunsDashboardItems, triggersResponse] = await Promise.all([
            tenantsPromise,
            tenantTagsets.getTagIndex(),
            repository.Environments.all(),
            repository.Runbooks.getRunbooks(project, defaultBranch, { skip: 0, take: Repository.takeAll }),
            repository.Progression.getTaskRunDashboardItemsForProject(project, {
                projectIds: [project.Id],
                environmentIds: this.state.filter.environmentIds,
                tenantIds: this.state.filter.tenantIds,
                runbookIds: this.state.filter.runbookIds,
            }),
            repository.Projects.getTriggers(project, 0, 0, undefined, TriggerActionCategory.Runbook),
        ]);
        return {
            runbookRunsDashboardItems,
            runbooks: runbooks.Items,
            hasAtLeastOneRunbook: runbooks.Items.length > 0,
            tenants,
            tagIndex,
            environments,
            totalTriggers: triggersResponse.TotalResults,
        };
    };
    loadMoreDashboardItems = async () => {
        const runbookRunsDashboardItemsToTake = this.state.runbookRunsDashboardItemsToTake + initialTakeOfDashboardItems;
        this.setState({
            runbookRunsDashboardItems: await this.getRunbookRunsDashboardItems(runbookRunsDashboardItemsToTake),
            runbookRunsDashboardItemsToTake,
        });
    };
    render() {
        const pageTitle = "Operations";
        const project = this.props.projectContext.state.model;
        if (!project || !this.state.runbookRunsDashboardItems) {
            return <PageLoading loadingTitle={pageTitle} errors={this.errors}/>;
        }
        if (!this.state.hasAtLeastOneRunbook) {
            const showOnboarding = this.state.hasAtLeastOneRunbook !== null && !this.state.hasAtLeastOneRunbook;
            return (<PageContent header={{ title: pageTitle }} busy={this.state.busy} errors={this.errors}>
                    {showOnboarding && <RunbooksWelcomeOnboarding project={project}/>}
                </PageContent>);
        }
        const selectedEnvironmentId = this.state.filter.environmentIds && this.state.filter.environmentIds.length > 0 ? this.state.filter.environmentIds[0] : null;
        const selectedRunbookId = this.state.filter.runbookIds && this.state.filter.runbookIds.length > 0 ? this.state.filter.runbookIds[0] : null;
        const nextScheduledElement = this.renderNextScheduled();
        const operationsOverviewProps = {
            initialData: this.state.runbookRunsDashboardItems,
            additionalRequestParams: this.getAdditionalRequestParams(),
            onRow: (item: any) => this.buildRunbookRunRow(item),
            onFilter: this.filter,
            headerColumns: ["", "", "", ""], // DO NOT REMOVE, this is needed so the headerColumn classes are correctly populated for our spacing.
            headerColumnClassNames: [styles.headerColumn, styles.headerColumn, styles.headerColumn, styles.headerColumn],
            hideHeader: false,
            onEmpty: this.handleOnEmpty,
            filterSearchEnabled: false, // This component has advanced filtering instead.
            apiSearchParams: ["partialName"],
            filterHintText: "Filter by name...",
            headerRowClassName: styles.resultsTableHeaderRow,
            onLoadMore: this.loadMoreDashboardItems,
        };
        return (<PageContent header={{ title: pageTitle }} filters={{
                advancedFilters: {
                    content: (<AdvancedFilters>
                                <RunbookMultiSelect items={this.state.runbooks} value={this.state.filter.runbookIds} onChange={(x) => {
                            this.setFilterState({ runbookIds: x }, async () => {
                                await this.onFilterChange();
                            });
                        }}/>
                                <EnvironmentMultiSelect environments={this.state.environments} value={this.state.filter.environmentIds} onChange={(x) => {
                            this.setFilterState({ environmentIds: x }, async () => {
                                await this.onFilterChange();
                            });
                        }}/>
                                <FeatureToggle feature={Feature.MultiTenancy}>
                                    <PermissionCheck permission={Permission.TenantView} tenant="*">
                                        <TenantMultiSelect value={this.state.filter.tenantIds} items={this.state.tenants} onChange={(x) => {
                            this.setFilterState({ tenantIds: x }, async () => {
                                await this.onFilterChange();
                            });
                        }}/>
                                        <TenantTagMultiSelect value={this.state.filter.tenantTags} doBusyTask={this.doBusyTask} onChange={(x) => {
                            this.setFilterState({ tenantTags: x }, async () => {
                                await this.onFilterChange();
                            });
                        }}/>
                                    </PermissionCheck>
                                </FeatureToggle>
                            </AdvancedFilters>),
                    hasUserSelectedValues: !isEqual(this.state.filter, createEmptyFilter()),
                    onResetFilter: () => {
                        this.setState({ filter: createEmptyFilter() }, async () => {
                            await this.onFilterChange();
                            const location = { ...this.props.history, search: null as any };
                            this.props.history.replace(location);
                        });
                    },
                },
                inputs: [
                    <FilterControl>
                            <Select label="" placeholder="Filter by Runbook" allowFilter={true} allowClear={true} value={selectedRunbookId!} onChange={(x) => {
                            this.setFilterState({ runbookIds: x ? [x] : null! }, async () => {
                                await this.onFilterChange();
                            });
                        }} items={this.state.runbooks.map((pg) => ({ value: pg.Id, text: pg.Name }))}/>
                        </FilterControl>,
                    <FilterControl>
                            <EnvironmentSelect label="" placeholder="Filter by Environment" autoFocus={true} allowFilter={true} allowClear={true} value={selectedEnvironmentId!} onChange={(x) => {
                            this.setFilterState({ environmentIds: x ? [x] : null! }, async () => {
                                await this.onFilterChange();
                            });
                        }} environments={this.state.environments}/>
                        </FilterControl>,
                ],
            }} busy={this.state.busy} errors={this.errors} sidebar={this.props.newRunbookRunsListPageEnabled ? undefined : nextScheduledElement}>
                {this.props.newRunbookRunsListPageEnabled && <ScheduledTriggersBar project={project} doBusyTask={this.doBusyTask}/>}
                <OperationsOverviewQueryStringFilters filter={this.state.filter} getQuery={this.queryFromFilter} getFilter={this.getFilter} onFilterChange={(filter) => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())}/>
                {this.state.runbookRunsDashboardItems && <OperationsOverviewTable {...operationsOverviewProps}/>}
            </PageContent>);
    }
    private renderNextScheduled() {
        const project = this.props.projectContext.state.model;
        const hasTriggers = this.state.totalTriggers > 0;
        const pluralized = this.state.totalTriggers === 1 ? "" : "s";
        return (<PermissionCheck permission={Permission.TriggerView} project={project.Id}>
                <>
                    <NextScheduleRunsTitle />
                    <div className={styles.nextScheduledRow}>
                        {hasTriggers && (<InternalLink to={links.projectRunbookTriggersPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}>
                                {this.state.totalTriggers} trigger{pluralized} found
                            </InternalLink>)}
                        {!hasTriggers && (<div>
                                No triggers are currently setup for runbooks.{" "}
                                <PermissionCheck permission={Permission.TriggerCreate} project={project.Id}>
                                    <InternalLink to={links.projectRunbookCreateScheduledTriggerPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}>Create a trigger now</InternalLink>.
                                </PermissionCheck>
                            </div>)}
                    </div>
                </>
            </PermissionCheck>);
    }
    private buildRunbookRunRow(runbookRunItem: RunbooksDashboardItemResource) {
        const project = this.props.projectContext.state.model;
        const runbook = this.state.runbooks.find((x) => x.Id === runbookRunItem.RunbookId);
        // If we're in CaC Runbooks, the Runbook might not be available if it was deleted
        // from the repo, so we don't want to error here (and still want to show it). If
        // it's database Runbooks then we'll throw an error because this should never happen.
        if (!HasRunbooksInGit(project.PersistenceSettings) && !runbook) {
            logger.error("Failed to find runbook {runbookId}.", { runbookId: runbookRunItem.RunbookId });
            return [];
        }
        const environment = this.state.environments.find((x) => x.Id === runbookRunItem.EnvironmentId);
        let tenant: TenantResource | null | undefined;
        if (runbook && runbook.MultiTenancyMode !== TenantedDeploymentMode.Untenanted) {
            tenant = this.state.tenants.find((x) => x.Id === runbookRunItem.TenantId);
        }
        const runbookName = runbook?.Name ?? runbookRunItem.RunbookId;
        return [
            <RunbookTaskStatusDetails project={project} item={runbookRunItem}/>,
            <div className={classNames(styles.customListItem)}>
                {runbook ? (<InternalLink to={links.runbookRootRedirect.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook?.Id ?? runbookRunItem.RunbookId })}>{runbookName}</InternalLink>) : (<Tooltip content={"This Runbook could not be found on the default branch"}>{runbookName}</Tooltip>)}
            </div>,
            <>
                {environment && <EnvironmentChip environmentName={environment.Name}/>}
                {tenant && <TenantChip tenantName={tenant.Name} isDisabled={!!tenant.IsDisabled}/>}
            </>,
            <div className={styles.runBy}>Run by {runbookRunItem.RunBy}</div>,
        ];
    }
    private handleOnEmpty = () => {
        const project = this.props.projectContext.state.model;
        return (<div className={styles.emptyCell}>
                No runs found.&nbsp;<InternalNavLink to={links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}>Go to Runbooks</InternalNavLink>
            </div>);
    };
    private getAdditionalRequestParams(): Map<keyof GetTaskRunDashboardItemsListArgs, any> {
        const project = this.props.projectContext.state.model;
        const additionalRequestParams = new Map<keyof GetTaskRunDashboardItemsListArgs, any>();
        additionalRequestParams.set("projectIds", [project.Id]);
        additionalRequestParams.set("environmentIds", this.state.filter.environmentIds);
        additionalRequestParams.set("runbookIds", this.state.filter.runbookIds);
        additionalRequestParams.set("tenantIds", this.state.filter.tenantIds);
        return additionalRequestParams;
    }
    private filter(filter: string, resource: RunbooksDashboardItemResource) {
        return !filter || filter.length === 0 || !resource || resource.RunbookSnapshotName.toLowerCase().includes(filter.toLowerCase());
    }
    private setFilterState<K extends keyof OperationsOverviewLayoutFilter>(state: Pick<OperationsOverviewLayoutFilter, K>, callback?: () => void) {
        this.setState((prev) => ({
            filter: { ...(prev!.filter as object), ...(state as object) },
            runbookRunsDashboardItemsToTake: initialTakeOfDashboardItems,
        }), callback);
    }
    private isFiltering() {
        return !isEqual(this.state.filter, createEmptyFilter());
    }
    private async onFilterChange() {
        await this.reloadRuns();
    }
    private queryFromFilter = (filter: OperationsOverviewLayoutFilter): OperationsOverviewLayoutQuery => {
        const query: OperationsOverviewLayoutQuery = {
            environmentIds: filter.environmentIds,
            runbookIds: filter.runbookIds,
            tenantIds: filter.tenantIds,
            tenantTags: filter.tenantTags,
        };
        return query;
    };
    private getFilter = (query: OperationsOverviewLayoutQuery): OperationsOverviewLayoutFilter => {
        const filter: OperationsOverviewLayoutFilter = {
            ...createEmptyFilter(),
            environmentIds: arrayValueFromQueryString(query.environmentIds) || [],
            runbookIds: arrayValueFromQueryString(query.runbookIds) || [],
            tenantIds: arrayValueFromQueryString(query.tenantIds) || [],
            tenantTags: arrayValueFromQueryString(query.tenantTags) || [], // Expecting canonical tag names
        };
        return filter;
    };
    static displayName = "OperationsOverviewPageInternal";
}
function FilterControl({ children }: React.PropsWithChildren<{}>) {
    return <div className={styles.filterControl}>{children}</div>;
}
function createEmptyFilter(): OperationsOverviewLayoutFilter {
    return {
        environmentIds: [],
        runbookIds: [],
        tenantIds: [],
        tenantTags: [],
    };
}
export function OperationsOverviewPage() {
    const history = useHistory();
    const projectContext = useProjectContext();
    const newRunbookRunsListPageEnabled = useNewRunbookRunsListPageFeatureToggle(projectContext.state.model);
    return <OperationsOverviewPageInternal history={history} projectContext={projectContext} newRunbookRunsListPageEnabled={newRunbookRunsListPageEnabled}/>;
}
