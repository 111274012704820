import { css } from "@emotion/css";
import { borderRadius, colorScales } from "@octopusdeploy/design-system-tokens";
export { DegradedIcon } from "./LiveAppStatusIcons/DegradedIcon";
export { HealthyIcon } from "./LiveAppStatusIcons/HealthyIcon";
export { MissingIcon } from "./LiveAppStatusIcons/MissingIcon";
export { OutOfSyncIcon } from "./LiveAppStatusIcons/OutOfSyncIcon";
export { ProgressingIcon } from "./LiveAppStatusIcons/ProgressingIcon";
export { UnavailableIcon } from "./LiveAppStatusIcons/UnavailableIcon";
export { UnknownIcon } from "./LiveAppStatusIcons/UnknownIcon";
export { WaitingIcon } from "./LiveAppStatusIcons/WaitingIcon";
export type LiveStatusIconSize = 20 | 28 | 48;
export const iconStyle = (size: LiveStatusIconSize) => css({
    borderRadius: borderRadius.small,
    fontSize: 0.6 * size,
    width: `${size}px`,
    height: `${size}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colorScales.white,
});
