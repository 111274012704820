import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { Skeleton } from "~/areas/projects/components/Projects/Skeleton";
import type { KubernetesDetailsStepView } from "~/areas/tasks/components/Task/Kubernetes/KubernetesDetailsLayout";
const manifestStyles = {
    container: css({
        display: "flex",
        flexDirection: "row",
        padding: space[16],
        paddingTop: 0,
        gap: space[16],
    }),
    navigationContainer: css({
        width: `calc(${space["16"]} * 25)`, // 400px
        display: "flex",
        flexDirection: "column",
        gap: space[16],
    }),
    navItem: css({
        display: "flex",
        flexDirection: "row",
        gap: space[4],
    }),
    navItemIcon: css({
        height: "20px",
        width: "20px",
    }),
    navItemTitle: css({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flexGrow: 1,
        "> div": {
            height: "20px",
            marginBottom: space[8],
        },
    }),
    manifestsContainer: css({
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: space[16],
    }),
    manifest: css({
        height: `calc(${space["16"]} * 27)`, // 432px,
        width: "100%",
    }),
    manifestHeader: css({
        height: `calc(${space["16"]} * 3.75)`, // 60px,
        width: "100%",
    }),
};
const snapshotStyles = {
    snapshotGrid: css({
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: space[16],
        paddingTop: 0,
    }),
    snapshotGridRow: css({
        display: "flex",
        flexDirection: "row",
        gap: space[80],
    }),
    snapshotGridCell: css({
        height: "20px",
        width: "100%",
        flexGrow: 1,
        margin: space[8],
    }),
};
const QuickSkeleton = () => <Skeleton shape={"Rounded"} borderRadius={"small"}/>;
type KubernetesStepsLoadingSkeletonProps = {
    activeView: KubernetesDetailsStepView;
};
export const KubernetesStepsLoadingSkeleton = (props: KubernetesStepsLoadingSkeletonProps) => {
    switch (props.activeView) {
        case "Manifests":
            return (<div className={manifestStyles.container}>
                    <div className={manifestStyles.navigationContainer}>
                        {[...new Array(3)].map((x, i) => (<div key={`navItem-${i}`} className={manifestStyles.navItem}>
                                <div className={manifestStyles.navItemIcon}>
                                    <QuickSkeleton />
                                </div>
                                <div className={manifestStyles.navItemTitle}>
                                    <QuickSkeleton />
                                    <QuickSkeleton />
                                </div>
                            </div>))}
                    </div>
                    <div className={manifestStyles.manifestsContainer}>
                        <div className={manifestStyles.manifestHeader}>
                            <QuickSkeleton />
                        </div>
                        <div className={manifestStyles.manifest}>
                            <QuickSkeleton />
                        </div>
                        <div className={manifestStyles.manifestHeader}>
                            <QuickSkeleton />
                        </div>
                    </div>
                </div>);
        case "Snapshot":
            return (<div className={snapshotStyles.snapshotGrid}>
                    {[...new Array(7)].map((x, i) => (<div key={`gridRow-${i}`} className={snapshotStyles.snapshotGridRow}>
                            {[...new Array(5)].map((y, j) => (<div key={`gridCell-${j}`} className={snapshotStyles.snapshotGridCell}>
                                    <QuickSkeleton />
                                </div>))}
                        </div>))}
                </div>);
    }
    return null;
};
