/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import DialogContent from "@material-ui/core/DialogContent";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { useEffect } from "react";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
interface Props {
    open: boolean;
    message: React.ReactNode;
    onContinueNavigating(): void;
    onCancelNavigation(): void;
    onSaveClick(): Promise<void>;
    saveLabel: string;
}
function ConfirmNavigate({ open, message, onContinueNavigating, onCancelNavigation, onSaveClick, saveLabel }: Props) {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onCancelNavigation();
            }
        };
        document.addEventListener("keydown", handleKeyDown, { capture: true });
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, [onCancelNavigation]);
    const actions = [
        <ActionButton key="SaveChanges" label={saveLabel} type={ActionButtonType.Secondary} onClick={onSaveClick}/>,
        <ActionButton key="discard" label="Discard changes" type={ActionButtonType.Secondary} onClick={onContinueNavigating}/>,
        <ActionButton key="cancel" label="Cancel" type={ActionButtonType.Primary} keyboardFocused={true} onClick={onCancelNavigation}/>,
    ];
    return (<Dialog title="Unsaved Changes" actions={actions} open={open}>
            <DialogContent>{message}</DialogContent>
        </Dialog>);
}
export default ConfirmNavigate;
