import { css } from "@emotion/css";
import type { ErrorInfo } from "@octopusdeploy/design-system-components";
import { ErrorPanel } from "@octopusdeploy/design-system-components";
import { ArrowExpandIcon, DuplicateIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { KubernetesResourceManifestSummaryResource, KubernetesResourceStatus } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import cn from "classnames";
import React, { useCallback, useMemo, useRef, useState } from "react";
import type { KubernetesStepBaseProps } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepExpander";
import { ManifestNavigationDetails } from "~/areas/tasks/components/Task/Kubernetes/Manifests/ManifestNavigationDetails";
import { ObjectStatusIcon } from "~/areas/tasks/components/Task/Kubernetes/ObjectStatusIcon";
import { CodeViewerWithLoading } from "~/areas/tasks/components/Task/Kubernetes/components/CodeViewerWithLoading/CodeViewerWithLoading";
import { createErrorInfo } from "~/areas/tasks/components/Task/Kubernetes/createErrorInfo";
import type { CodeComponent } from "~/components/Code/Code";
import StringHelper from "~/utils/StringHelper/index";
import type { ExpandableCodeViewerIconButtonAction } from "../components/ExpandableCodeViewer/ExpandableCodeViewer";
import { ExpandableCodeViewer } from "../components/ExpandableCodeViewer/ExpandableCodeViewer";
import { ExpandableCodeViewerGroup } from "../components/ExpandableCodeViewer/ExpandableCodeViewerGroup";
const styles = {
    mainContainer: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
    }),
    errorPanel: css({
        margin: space[16],
        marginBottom: 0,
    }),
    navigationContainer: css({
        padding: space[16],
    }),
    navigationWidthContainer: css({
        width: `calc(${space["16"]} * 25)`, // 400px
    }),
    manifestsContainer: css({
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        paddingRight: space[16],
    }),
    namespacesTreeSummary: css({
        paddingLeft: space[32],
    }),
    manifestListItemList: css({
        display: "flex",
        flexDirection: "column",
        gap: space[4],
    }),
    manifestListItem: css({
        padding: space[8],
        paddingLeft: space[56],
        paddingRight: space[12],
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        gap: space[8],
        alignItems: "flex-start",
        "&:hover": {
            borderRadius: borderRadius["small"],
            backgroundColor: themeTokens.color.background.primary.hovered,
        },
        "&:focus": {
            backgroundColor: themeTokens.color.background.primary.default,
            borderRadius: borderRadius["small"],
            border: `1px solid ${themeTokens.color.border.primary}`,
        },
    }),
    manifestListItemSelected: css({
        borderRadius: borderRadius["small"],
        backgroundColor: themeTokens.color.background.primary.pressed,
    }),
    manifestListItemIcon: css({
        paddingTop: space[4],
    }),
    manifestListItemHeader: css({
        color: themeTokens.color.text.primary,
        font: text.regular.default.medium,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }),
    manifestListItemSubtitle: css({
        color: themeTokens.color.text.secondary,
        font: text.regular.default.xSmall,
    }),
    manifestHeader: css({
        color: themeTokens.color.text.primary,
        font: text.regular.bold.medium,
    }),
    manifestSubtitle: css({
        color: themeTokens.color.text.primary,
        font: text.regular.default.xSmall,
    }),
    codeViewerContainer: css({
        marginTop: space[16],
        marginBottom: space[16],
    }),
};
type TargetTreeNavItem = {
    machineId: string;
    targetName: string;
    namespaceItems: NamespaceTreeNavItem[];
};
type NamespaceTreeNavItem = {
    namespace: string;
    manifestItems: ManifestTreeNavItem[];
};
type ManifestTreeNavItem = {
    resource: KubernetesResourceManifestSummaryResource;
    name: string;
    kind: string;
    objectStatus: KubernetesResourceStatus | undefined;
};
const expandableCodeViewerAnimationOptions = { unmountOnExit: false };
export type KubernetesStepAppliedManifestsProps = Omit<KubernetesStepBaseProps, "stepObjectStatuses">;
export const KubernetesStepAppliedManifests = (props: KubernetesStepAppliedManifestsProps) => {
    const [selectedManifestId, setSelectedManifestId] = useState<string | undefined>();
    const manifestDivsRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const codeComponentRefs = useRef<Record<string, CodeComponent | null>>({});
    //For the scrollMarginTop to work, this needs to match the height of the page header
    const pageHeaderHeight = useMemo(() => {
        //get the header dom node so we can retrieve its height.
        const stickyHeaderRef = document.querySelector("[class*=\"headerSecondaryStickiedStyle\"]");
        //default to 92 px
        const heightInPx = stickyHeaderRef?.getBoundingClientRect()?.height || 92;
        //the extra 16px is just to clear the drop shadow
        return `calc(${heightInPx}px + ${space[16]})`;
    }, []);
    const manifestsTree: TargetTreeNavItem[] = useMemo(() => {
        if (!props.stepSummaries) {
            return [];
        }
        //get the unique machine ids
        const machineIds = [...new Set(props.stepSummaries.map((krm) => krm.MachineId))];
        //get all the unique namespaces
        const namespaces = [...new Set(props.stepSummaries.map((krm) => krm.KubernetesObjectNamespace))];
        return machineIds
            .map((machineId) => {
            const perMachineResources = (props.stepSummaries || []).filter((krm) => krm.MachineId === machineId);
            return {
                machineId,
                targetName: perMachineResources[0].MachineName, //all the machines will have the same name, so just pick the first one
                namespaceItems: namespaces
                    .map((ns) => {
                    const perNamespaceResources = perMachineResources.filter((krm) => krm.KubernetesObjectNamespace === ns);
                    return {
                        namespace: !ns || StringHelper.isNullOrWhiteSpace(ns) ? "Cluster-scoped" : ns,
                        manifestItems: perNamespaceResources.map((krm) => ({
                            resource: krm,
                            name: krm.KubernetesObjectName,
                            kind: krm.KubernetesResourceKind,
                            objectStatus: krm.ResourceStatus,
                        })),
                    };
                })
                    .filter((t) => t.manifestItems.length > 0),
            };
        })
            .filter((t) => t.namespaceItems.length > 0);
    }, [props.stepSummaries]);
    const summariesHealthLookup = useMemo(() => (props.stepSummaries || []).reduce((acc, t) => {
        acc[t.Id] = t.ResourceStatus;
        return acc;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as Record<string, KubernetesResourceStatus | undefined>), [props.stepSummaries]);
    const manifestsLookup: Record<string, string> = useMemo(() => (props.stepManifestsQueryResult?.result || []).reduce((acc, t) => {
        acc[t.Id] = t.ManifestContent?.trimEnd();
        return acc;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as Record<string, string>), [props.stepManifestsQueryResult]);
    const orderedManifests: KubernetesResourceManifestSummaryResource[] = useMemo(() => {
        if (manifestsTree.length === 0) {
            return [];
        }
        return manifestsTree.flatMap((t1) => t1.namespaceItems.flatMap((t2) => t2.manifestItems)).map((t) => t.resource);
    }, [manifestsTree]);
    const selectManifest = (manifestId: string) => {
        setSelectedManifestId(manifestId);
        const ref = manifestDivsRefs.current[manifestId];
        if (!ref) {
            return;
        }
        //the scroll-margin is used as a sneaky way to offset the scrollIntoView
        ref.style.scrollMarginTop = pageHeaderHeight;
        ref.scrollIntoView(true);
        ref.style.scrollMarginTop = "0";
    };
    const manifestTitle = (krm: KubernetesResourceManifestSummaryResource) => (<div>
            <div className={styles.manifestHeader}>{krm.KubernetesObjectName}</div>
            <div className={styles.manifestSubtitle}>
                {krm.KubernetesResourceKind} in {krm.KubernetesObjectNamespace}
            </div>
        </div>);
    const getActions: (id: string) => ExpandableCodeViewerIconButtonAction[] = useCallback((id: string) => [
        {
            icon: <DuplicateIcon size={20} color={themeTokens.color.icon.primary}/>,
            onClick: async () => await codeComponentRefs.current[id]?.copyToClipboard(),
            accessibleName: "Copy content to clipboard",
            tooltip: "Copy to clipboard",
        },
        {
            icon: <ArrowExpandIcon size={20} color={themeTokens.color.icon.primary}/>,
            onClick: async () => codeComponentRefs.current[id]?.showInFullScreen(),
            accessibleName: "Enter full screen",
            tooltip: "Enter full screen",
        },
    ], []);
    const errorInfo: ErrorInfo | undefined = useMemo(() => {
        //if the step is due to run _or_ has been skipped, we don't need to show an error state for it
        if (props.stepActivity.Status === ActivityStatus.Pending || props.stepActivity.Status === ActivityStatus.Skipped) {
            return;
        }
        return createErrorInfo(props.stepManifestsQueryResult?.error);
    }, [props.stepActivity.Status, props.stepManifestsQueryResult?.error]);
    return (<>
            {errorInfo && (<div className={styles.errorPanel}>
                    <ErrorPanel error={errorInfo}/>
                </div>)}
            <>
                <div className={styles.mainContainer}>
                    <div className={styles.navigationContainer}>
                        <div className={styles.navigationWidthContainer}>
                            {manifestsTree.map((machineTreeItem) => (<ManifestNavigationDetails key={machineTreeItem.machineId} summary={machineTreeItem.targetName}>
                                    {machineTreeItem.namespaceItems.map((namespaceTreeItem) => (<ManifestNavigationDetails key={namespaceTreeItem.namespace} summary={namespaceTreeItem.namespace} summaryClassName={styles.namespacesTreeSummary}>
                                            <div className={styles.manifestListItemList}>
                                                {namespaceTreeItem.manifestItems.map((manifestTreeItem) => (<div key={manifestTreeItem.resource.Id} className={cn(styles.manifestListItem, manifestTreeItem.resource.Id === selectedManifestId ? styles.manifestListItemSelected : null)} onClick={() => selectManifest(manifestTreeItem.resource.Id)}>
                                                        <div className={styles.manifestListItemIcon}>{manifestTreeItem.objectStatus && <ObjectStatusIcon objectStatus={manifestTreeItem.objectStatus} stepStatus={props.stepActivity.Status}/>}</div>
                                                        <div>
                                                            <div className={styles.manifestListItemHeader}>{manifestTreeItem.name}</div>
                                                            <div className={styles.manifestListItemSubtitle}>{manifestTreeItem.kind}</div>
                                                        </div>
                                                    </div>))}
                                            </div>
                                        </ManifestNavigationDetails>))}
                                </ManifestNavigationDetails>))}
                        </div>
                    </div>
                    {!errorInfo && (<div className={styles.manifestsContainer}>
                            <ExpandableCodeViewerGroup showGroupActions={true} isExpandedByDefault={true}>
                                {orderedManifests.map((krm) => {
                const objectStatus = summariesHealthLookup[krm.Id];
                return (<div key={krm.Id} ref={(ref) => (manifestDivsRefs.current[krm.Id] = ref)} className={cn(styles.codeViewerContainer)}>
                                            <ExpandableCodeViewer title={krm.KubernetesObjectName} description={`${krm.KubernetesResourceKind} in ${krm.KubernetesObjectNamespace}`} animation={expandableCodeViewerAnimationOptions} statusIcon={objectStatus && <ObjectStatusIcon objectStatus={objectStatus} stepStatus={props.stepActivity.Status}/>} selected={krm.Id === selectedManifestId} actions={getActions(krm.Id)}>
                                                <CodeViewerWithLoading ref={(ref) => (codeComponentRefs.current[krm.Id] = ref)} value={manifestsLookup[krm.Id]} language={"YAML"} showLineNumbers={true} lineWrapping={true} fullScreenDialogTitle={manifestTitle(krm)} isLoading={props.stepManifestsQueryResult?.isLoading || false}/>
                                            </ExpandableCodeViewer>
                                        </div>);
            })}
                            </ExpandableCodeViewerGroup>
                        </div>)}
                </div>
            </>
        </>);
};
