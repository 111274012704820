import { type SimpleMenuItem, type PrimaryPageAction, Callout, ExternalLink } from "@octopusdeploy/design-system-components";
import type { DeploymentFreezeFilterQueryParameters, DeploymentFreezeOverviewBffResponse, DeploymentFreezeSummary, PagingCollection } from "@octopusdeploy/octopus-server-client";
import { DeploymentFreezeStatus, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import * as React from "react";
import { DeploymentFreezeTableContent } from "~/areas/configuration/components/DeploymentFreezes/DataTable/DeploymentFreezeTableContent";
import { DeploymentFreezeOnboardingPage } from "~/areas/configuration/components/DeploymentFreezes/DeploymentFreezeOnboardingPage";
import { repository } from "~/clientInstance";
import { AdvancedFilters } from "~/components/AdvancedFilterLayout/AdvancedFilters";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/index";
import type { DataBaseComponentState, DoBusyTask } from "~/components/DataBaseComponent/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/index";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import type { DeploymentFreezeStatusResource } from "~/components/MultiSelect/DeploymentFreezeStatusMultiSelect";
import { DeploymentFreezeStatusMultiSelect } from "~/components/MultiSelect/DeploymentFreezeStatusMultiSelect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { ProjectMultiSelect } from "~/components/MultiSelect/ProjectMultiSelect";
import { TenantMultiSelect } from "~/components/MultiSelect/TenantMultiSelect";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { IQuery } from "~/components/QueryStringFilters/QueryStringFilters";
import { QueryStringFilters } from "~/components/QueryStringFilters/QueryStringFilters";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { arrayValueFromQueryString } from "~/utils/ParseHelper/ParseHelper";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner/index";
interface DeploymentFreezeListProps {
    initialData: PagingCollection<DeploymentFreezeSummary>;
}
interface DeploymentFreezesListState extends DataBaseComponentState, DeploymentFreezeOverviewBffResponse {
    QueryFilter?: DeploymentFreezeFilter;
    Query: DeploymentFreezeQuery;
    Filter: DeploymentFreezeFilter;
    PageNumber: number;
    PageSize: number;
}
export interface DeploymentFreezeQuery extends IQuery {
    filterByEnvironments?: string[];
    filterByProjects?: string[];
    filterByTenants?: string[];
    filterByName?: string;
    filterByStatus?: string[];
    pageNumber: string;
    pageSize: string;
}
class FilterLayout extends AdvancedFilterLayout<DeploymentFreezeFilter> {
}
export interface DeploymentFreezeFilter {
    Name: string | undefined;
    Environments: string[];
    Projects: string[];
    Tenants: string[];
    Status: DeploymentFreezeStatus[];
}
const DeploymentFreezeDefaultFilter = {
    Environments: [],
    Projects: [],
    Tenants: [],
    Name: "",
    Status: [],
};
export interface DeploymentFreezeFilterLayoutProps {
    doBusyTask: DoBusyTask;
    queryFilter: DeploymentFreezeFilter | undefined;
    deploymentFreezes: DeploymentFreezeOverviewBffResponse;
}
const initialFilterData = {
    Environments: [],
    Projects: [],
    Tenants: [],
    Status: [],
    Name: "",
};
export const DeploymentFreezesListPage = () => <DeploymentFreezesListPageInner initialData={{ Items: [], ItemsPerPage: 0, TotalResults: 0 }}/>;
export interface DeploymentFreezeQuery extends IQuery {
    environmentIds?: string[];
    projectIds?: string[];
    tenantIds?: string[];
    name?: string;
    status?: string[];
    pageNumber: string;
    pageSize: string;
}
export const DeploymentFreezeQueryStringFilters = QueryStringFilters.For<DeploymentFreezeFilter, DeploymentFreezeQuery>();
class DeploymentFreezesListPageInner extends DataBaseComponent<DeploymentFreezeListProps, DeploymentFreezesListState> {
    constructor(props: DeploymentFreezeListProps) {
        super(props);
        this.state = {
            Filters: initialFilterData,
            DeploymentFreezes: props.initialData.Items,
            TotalDeploymentFreezeCount: 0,
            FilteredDeploymentFreezeCount: 0,
            Filter: DeploymentFreezeDefaultFilter,
            PageNumber: 0,
            PageSize: 30,
            Query: { pageNumber: "0", pageSize: "30" },
        };
    }
    componentDidMount() {
        this.doBusyTask(async () => {
            await this.reload();
        });
    }
    private advancedFilters(): React.ReactNode {
        return (<AdvancedFilters>
                <EnvironmentMultiSelect accessibleName="environmentFilter" key="filterEnvironment" items={this.state.Filters.Environments} value={this.state.Filter.Environments} onChange={(Environments) => this.onFiltersChanged({ ...this.state.Filter, Environments })}/>
                <ProjectMultiSelect accessibleName="projectFilter" key="filterProject" items={this.state.Filters.Projects} value={this.state.Filter.Projects} onChange={(Projects) => this.onFiltersChanged({ ...this.state.Filter, Projects })}/>
                {isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle") && (<>
                        <TenantMultiSelect accessibleName="tenantFilter" key="filterTenant" items={this.state.Filters.Tenants} value={this.state.Filter.Tenants} onChange={(Tenants) => this.onFiltersChanged({ ...this.state.Filter, Tenants })}/>
                    </>)}
                <DeploymentFreezeStatusMultiSelect accessibleName="freezeStatusFilter" key="filterFreezeStatus" items={this.getStatusMultiSelectItems()} value={this.state.Filter.Status} onChange={(Status) => this.onFiltersChanged({ ...this.state.Filter, Status })}/>
            </AdvancedFilters>);
    }
    getStatusMultiSelectItems = (): DeploymentFreezeStatusResource[] => {
        return this.state.Filters.Status.map((s) => ({ Id: s, Name: s.toString() }));
    };
    requestRaceConditioner = new RequestRaceConditioner();
    onFiltersChanged = (filters: DeploymentFreezeFilter) => {
        this.setState({ Filter: filters }, async () => {
            await this.reload();
        });
    };
    onPaginationChanged = (index: number) => {
        this.setState({ ...this.state, PageNumber: index }, async () => {
            await this.reload();
        });
    };
    setQueries = (filters: DeploymentFreezeFilter) => {
        this.setState({
            ...this.state,
            Query: {
                filterByEnvironments: filters.Environments.length > 0 ? filters.Environments : undefined,
                filterByProjects: filters.Projects.length > 0 ? filters.Projects : undefined,
                filterByTenants: filters.Tenants.length > 0 ? filters.Tenants : undefined,
                filterByName: filters.Name,
                filterByStatus: filters.Status.length > 0 ? filters.Status : undefined,
                pageNumber: this.state.PageNumber.toString(),
                pageSize: this.state.PageSize.toString(),
            },
        });
    };
    reload = async () => {
        const filter = this.state.Filter;
        const queryParams: DeploymentFreezeFilterQueryParameters = {
            filterByEnvironments: filter.Environments,
            filterByName: filter.Name,
            filterByProjects: filter.Projects,
            filterByTenants: filter.Tenants,
            filterByStatus: filter.Status,
            pageSize: this.state.PageSize.toString(),
            pageNumber: (this.state.PageNumber + 1).toString(),
        };
        await this.doBusyTask(async () => {
            const freezes = repository.DeploymentFreezes.getOverviewBff(queryParams);
            await this.requestRaceConditioner.avoidStaleResponsesForRequest(freezes, (response) => {
                this.setState({
                    DeploymentFreezes: response.DeploymentFreezes,
                    TotalDeploymentFreezeCount: response.TotalDeploymentFreezeCount,
                    FilteredDeploymentFreezeCount: response.FilteredDeploymentFreezeCount,
                    Filters: response.Filters,
                });
            });
        }, { timeOperationOptions: timeOperationOptions.forRefresh() });
    };
    convertToStatusEnum = (query: string | string[] | undefined): DeploymentFreezeStatus[] => {
        if (query === undefined)
            return [];
        if (typeof query === "string")
            return [this.convertStringToFreezeStatus(query)];
        return query.map((q) => this.convertStringToFreezeStatus(q));
    };
    convertStringToFreezeStatus = (q: string): DeploymentFreezeStatus => {
        switch (q) {
            case "Active":
                return DeploymentFreezeStatus.Active;
            case "Scheduled":
                return DeploymentFreezeStatus.Scheduled;
            default:
                return DeploymentFreezeStatus.Expired;
        }
    };
    getFilterFromQuery = (query: DeploymentFreezeQuery): DeploymentFreezeFilter => {
        return {
            Projects: arrayValueFromQueryString(query.filterByProjects),
            Tenants: arrayValueFromQueryString(query.filterByTenants),
            Environments: arrayValueFromQueryString(query.filterByEnvironments),
            Name: query.filterByName,
            Status: this.convertToStatusEnum(arrayValueFromQueryString(query.filterByStatus)),
        };
    };
    getQueryFromFilters = (filter: DeploymentFreezeFilter): DeploymentFreezeQuery => {
        return {
            filterByProjects: filter.Projects,
            filterByEnvironments: filter.Environments,
            filterByName: filter.Name,
            filterByStatus: filter.Status,
            pageNumber: this.state.PageNumber.toString(),
            pageSize: this.state.PageSize.toString(),
        };
    };
    render() {
        const AddDeploymentFreezeAction = (): PrimaryPageAction => {
            return {
                type: "navigate",
                label: "Add Deployment Freeze",
                hasPermissions: isAllowed({ permission: Permission.DeploymentFreezeAdminister }),
                path: links.deploymentFreezesCreatePage.generateUrl(),
            };
        };
        const overflowActions: SimpleMenuItem[] = [];
        if (this.state.DeploymentFreezes.length > 0 && isAllowed({ permission: Permission.EventView })) {
            overflowActions.push({ type: "internal-link", label: "Audit trail", path: links.auditPage.generateUrl({ documentTypes: ["DeploymentFreezes"], includeSystem: true }) });
        }
        if (!this.state.busy && this.state.TotalDeploymentFreezeCount === 0) {
            return (<PageContent header={{ title: "Deployment Freezes", primaryAction: AddDeploymentFreezeAction(), overflowActions }}>
                    <DeploymentFreezeOnboardingPage />
                </PageContent>);
        }
        const freezeSummaryPagingCollection = {
            Items: this.state.DeploymentFreezes,
            ItemsPerPage: this.state.PageSize,
            TotalResults: this.state.FilteredDeploymentFreezeCount ?? this.state.TotalDeploymentFreezeCount,
        };
        return (<>
                <DeploymentFreezeQueryStringFilters filter={this.state.Filter} getFilter={this.getFilterFromQuery} onFilterChange={(filters) => {
                this.setState({ ...this.state, QueryFilter: filters });
                this.onFiltersChanged(filters);
            }} getQuery={(filter) => this.getQueryFromFilters(filter)}/>
                <PageContent header={{ title: "Deployment Freezes", primaryAction: AddDeploymentFreezeAction(), overflowActions }} busy={this.state.busy} errors={this.errors} filters={{
                inputs: [<FilterSearchBox key="filter-by-name" placeholder={"Filter by name..."} value={this.state.Filter.Name} onChange={(Name) => this.onFiltersChanged({ ...this.state.Filter, Name })} autoFocus={true}/>],
                advancedFilters: {
                    content: this.advancedFilters(),
                    onResetFilter: () => {
                        this.onFiltersChanged(DeploymentFreezeDefaultFilter);
                    },
                    hasUserSelectedValues: !isEqual(DeploymentFreezeDefaultFilter, this.state.Filter),
                },
            }}>
                    <Callout type={"warning"} title="Early Access">
                        Deployment Freezes is currently in early access. We'd love to hear <ExternalLink href={"DeploymentFreezeEnhancements"} label="your feedback"/>.<br />
                        When the feature becomes generally available, only enterprise customers will be able to create Deployment Freezes that apply to multiple projects. <br /> However, all customers will be able to create freezes on a per-project
                        basis. Any freezes created prior to this change will be carried forward.
                    </Callout>
                    <DeploymentFreezeTableContent deploymentFreezes={freezeSummaryPagingCollection} currentPage={this.state.PageNumber} onPageSelected={(currentIndex) => this.onPaginationChanged(currentIndex)}/>
                </PageContent>
            </>);
    }
    static displayName = "DeploymentFreezesListPageInner";
}
export default DeploymentFreezesListPage;
