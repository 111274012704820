import { css } from "@emotion/css";
import { Button, ErrorPanel, type ErrorInfo } from "@octopusdeploy/design-system-components";
import { CodeIcon, KubernetesIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import type { TaskState, ActivityElement, TaskResource, KubernetesObjectStatus, KubernetesResourceManifestSummaryResource } from "@octopusdeploy/octopus-server-client";
import React, { useMemo, useState } from "react";
import type { StepWithKubernetesAction } from "~/areas/tasks/components/Task/K8sStatus/StepWithKubernetesAction";
import { KubernetesStepsLayout } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepsLayout";
import { KubernetesStepsLoadingSkeleton } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepsLoadingSkeleton";
import { createErrorInfo } from "~/areas/tasks/components/Task/Kubernetes/createErrorInfo";
import type { FilterSection } from "~/components/AdvancedFilterLayout/index";
import AdvancedFilterLayout from "~/components/AdvancedFilterLayout/index";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import Select from "~/primitiveComponents/form/Select/Select";
import type { Item } from "~/primitiveComponents/form/Select/Select";
import StringHelper from "~/utils/StringHelper/index";
export type KubernetesDetailsBaseProps = {
    steps: StepWithKubernetesAction[];
    activityLogs: Pick<ActivityElement, "Children">[];
    projectId: string | undefined;
    taskState: TaskState;
};
type KubernetesDetailsProps = Omit<KubernetesDetailsBaseProps, "taskState"> & {
    activeView: KubernetesDetailsStepView;
    setActiveView: (view: KubernetesDetailsStepView) => void;
    task: TaskResource<{
        DeploymentId: string;
        RunbookRunId: string;
    }>;
};
export type KubernetesDetailsFilter = {
    name?: string;
    namespace?: string;
    targetName?: string;
    kind?: string;
    status?: string;
};
export const hasNoFilters = (filter: KubernetesDetailsFilter) => Object.values(filter).every((val) => val === undefined || StringHelper.isNullOrWhiteSpace(val));
export const clusterScopedNamespaceFilter = "Cluster-scoped";
export const unknownStatusFilter = "Unknown";
const styles = {
    toggleButtonContainer: css({
        display: "flex",
        flexDirection: "row",
        padding: space[4],
        background: themeTokens.color.background.secondary.default,
        borderRadius: borderRadius["medium"],
        gap: space[4],
    }),
};
export type KubernetesDetailsStepView = "Manifests" | "Snapshot";
export const KubernetesDetailsLayout = (props: KubernetesDetailsProps) => {
    const { activeView, setActiveView, ...rest } = props;
    const [filter, setFilter] = useState<KubernetesDetailsFilter>({});
    const taskId = props.task.Id;
    const isCompleted = props.task.IsCompleted;
    const deploymentId = props.task.Arguments.DeploymentId;
    const runbookRunId = props.task.Arguments.RunbookRunId;
    const [hasViewedManifests, setHasViewedManifests] = useState<boolean>(false);
    const { result: queryResult, isLoading, error, } = useInlineStatusQuery(async (repo) => {
        const summaries = () => {
            if (deploymentId) {
                return repo.KubernetesManifest.getKubernetesManifestSummariesForDeployment(deploymentId);
            }
            else if (runbookRunId) {
                return repo.KubernetesManifest.getKubernetesManifestSummariesForRunbookRun(runbookRunId);
            }
            return Promise.reject("Either deploymentId or runbookRunId must be set.");
        };
        const resourceStatus = repo.Tasks.retrieveKubernetesStatusUpdate(taskId);
        return {
            summaries: await summaries(),
            resourceStatus: await resourceStatus,
        };
    }, [deploymentId, runbookRunId, taskId], "Kubernetes Object summary and statuses", {
        refetchIntervalInMs: isCompleted ? undefined : 5000, // refetch every 5s (unless we are done)
    });
    if (activeView === "Manifests" && !hasViewedManifests) {
        setHasViewedManifests(true);
    }
    const manifestsQuery = useInlineStatusQuery(async (repo) => {
        if (hasViewedManifests || !isCompleted) {
            if (deploymentId) {
                return repo.KubernetesManifest.getKubernetesManifestsForDeployment(deploymentId);
            }
            else if (runbookRunId) {
                return repo.KubernetesManifest.getKubernetesManifestsForRunbookRun(runbookRunId);
            }
            return Promise.reject("Either deploymentId or runbookRunId must be set.");
        }
        return null;
    }, [deploymentId, isCompleted, runbookRunId, hasViewedManifests], "Kubernetes Object manifests", {
        refetchIntervalInMs: isCompleted ? undefined : 5000, // refetch every 5s (unless we are done)
    });
    const errorInfo: ErrorInfo | undefined = useMemo(() => createErrorInfo(error), [error]);
    const getViewToggleButtons = () => [
        <div className={styles.toggleButtonContainer}>
            <Button label={"Applied Manifests"} leftIcon={<CodeIcon size={20}/>} importance={activeView === "Manifests" ? "loud" : "quiet"} onClick={() => setActiveView("Manifests")} disabled={!!errorInfo}/>
            <Button label={"Object Snapshot"} leftIcon={<KubernetesIcon size={20}/>} importance={activeView === "Snapshot" ? "loud" : "quiet"} onClick={() => setActiveView("Snapshot")} disabled={!!errorInfo}/>
        </div>,
    ];
    const updateFilter = (changedFilters: Partial<KubernetesDetailsFilter>) => {
        setFilter((prev) => ({
            ...prev,
            ...changedFilters,
        }));
    };
    const filterSections: FilterSection[] = useMemo(() => {
        //any error? no filters
        if (errorInfo) {
            return [];
        }
        const filters: React.ReactNode[] = [];
        const statuses = queryResult?.resourceStatus.KubernetesStepsStatus || [];
        const manifestSummaries = queryResult?.summaries.Resources || [];
        const distinctFilters = (kosMap: (kos: KubernetesObjectStatus) => string, manifestSummariesMap: (krms: KubernetesResourceManifestSummaryResource) => string): Item[] => {
            const kosValues = statuses.flatMap((kss) => kss.KubernetesObjects.map(kosMap));
            const summaryValues = manifestSummaries.map(manifestSummariesMap);
            return kosValues
                .concat(summaryValues)
                .filter((value, idx, arr) => arr.findIndex((x) => x === value) === idx)
                .map((x) => ({ value: x, text: x }));
        };
        const targetNames = distinctFilters((kos) => kos.ClusterName, (krms) => krms.MachineName);
        if (targetNames.length > 1) {
            filters.push(<Select key="targetNamesFilter" value={filter.targetName} onChange={(targetName) => updateFilter({ targetName })} items={targetNames} allowClear={true} placeholder="By deployment target"/>);
        }
        const namespaceNames = distinctFilters((kos) => kos.Namespace || clusterScopedNamespaceFilter, (krms) => krms.KubernetesObjectNamespace || clusterScopedNamespaceFilter);
        if (namespaceNames.length > 1) {
            filters.push(<Select key="namespaceFilter" value={filter.namespace} onChange={(namespace) => updateFilter({ namespace })} items={namespaceNames} allowClear={true} placeholder="By namespace"/>);
        }
        const kinds = distinctFilters((kos) => kos.Kind, (krms) => krms.KubernetesResourceKind);
        if (kinds.length > 1) {
            filters.push(<Select key="kindFilter" value={filter.kind} onChange={(kind) => updateFilter({ kind })} items={kinds} allowClear={true} placeholder="By object kind"/>);
        }
        const statusFilterItems = distinctFilters((kos) => kos.Status, (krms) => krms.ResourceStatus || unknownStatusFilter);
        if (statusFilterItems.length > 1) {
            filters.push(<Select key="statusFilter" value={filter.status} onChange={(status) => updateFilter({ status })} items={statusFilterItems} allowClear={true} placeholder="By object status"/>);
        }
        //if we have no advanced filters, don't show anything
        if (filters.length === 0) {
            return [];
        }
        return [
            {
                render: <div>{filters}</div>,
            },
        ];
    }, [errorInfo, filter, queryResult]);
    return (<AdvancedFilterLayout filterSections={filterSections} additionalHeaderFilters={errorInfo ? undefined : [<FilterSearchBox placeholder="Search objects..." value={filter.name} onChange={(name: string) => updateFilter({ name })}/>]} filter={filter} defaultFilter={{}} onFilterReset={() => setFilter({})} additionalHeaderControls={getViewToggleButtons()} renderContent={() => (<>
                    {errorInfo && <ErrorPanel error={errorInfo}/>}
                    {isLoading && <KubernetesStepsLoadingSkeleton activeView={activeView}/>}
                    {!errorInfo && !isLoading && (<KubernetesStepsLayout {...rest} activeView={activeView} filter={filter} manifestsQuery={manifestsQuery} resourceStatusResult={queryResult?.resourceStatus} summariesResult={queryResult?.summaries} taskState={props.task.State}/>)}
                </>)}/>);
};
