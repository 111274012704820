import { css } from "@emotion/css";
import { IconButton } from "@octopusdeploy/design-system-components";
import { XmarkIcon } from "@octopusdeploy/design-system-icons";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { StyledMaterialChip } from "./StyledMaterialChip";
import type { CommonChipProps } from "./types";
export interface DeletableChipProps extends CommonChipProps {
    onRequestDelete: () => void;
    deleteButtonAccessibleName: string;
}
//We require the onClick to be named onClick as Material will assign the onClick handler to the component when it clones it internally
//if we used something like onDelete/onRequestDelete then we would have to duplicate on assigning the click handler, which can cause
//some problems if anything changes in how material handles things.
interface DeleteChipButtonProps {
    onClick?: (event: object) => void;
    accessibleName: string;
}
const deleteButtonStyles = css({
    "&.MuiIconButton-root": {
        color: themeTokens.color.icon.secondary,
        padding: space[4],
        marginLeft: `-${space[4]}`,
    },
});
function DeleteChipButton(props: DeleteChipButtonProps) {
    return <IconButton className={deleteButtonStyles} accessibleName={props.accessibleName} onClick={props.onClick} customIcon={<XmarkIcon size={16}/>}/>;
}
export const DeletableChip: React.FC<DeletableChipProps> = (props) => {
    return (<StyledMaterialChip accessibilityRole={props.accessibilityRole ?? "presentation"} //We override the default role since material-ui will assign a role of button by default to the parent
     fullWidth={props.fullWidth} borderColor={props.borderColor} markAsRemoved={props.markAsRemoved} noMargin={props.noMargin} labelColor={props.labelColor} backgroundColor={props.backgroundColor} tabIndex={props.tabIndex} onDelete={props.onRequestDelete} icon={props.icon} label={props.children} tooltipContent={props.description ?? props.children} variant={props.variant} deleteIcon={<DeleteChipButton accessibleName={props.deleteButtonAccessibleName}/>} opacity={props.opacity}/>);
};
DeletableChip.displayName = "DeletableChip"
export default DeletableChip;
