import { type CalloutType } from "@octopusdeploy/design-system-components";
import { LicenseMessageDisposition } from "@octopusdeploy/octopus-server-client";
import type { LicenseLimitStatus, LicenseUsageResource, Repository } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { PageContent } from "~/components/PageContent/PageContent";
import Section from "~/components/Section";
import { UsageDetailTable } from "./UsageDetailTable";
import { UsageOverviewCard } from "./UsageOverviewCard";
import styles from "./style.module.less";
interface LicenseUsagePageProps {
    loaderData: LicenseUsagePageLoaderData;
}
export interface LicenseUsagePageLoaderData {
    licenseUsage: LicenseUsageResource;
}
export async function licenseUsagePageLoader(repository: Repository): Promise<LicenseUsagePageLoaderData> {
    return {
        licenseUsage: await repository.Licenses.getCurrentUsage(),
    };
}
const displayLimit = ["projects", "tenants", "targets", "machines", "tasks", "task cap"];
const dispositionSeverityOrder = [LicenseMessageDisposition.Error, LicenseMessageDisposition.Warning, LicenseMessageDisposition.Notice];
export const licenseUsagePageTitle = "License Usage Dashboard";
export function LicenseUsagePage({ loaderData }: LicenseUsagePageProps) {
    const licenseUsage = loaderData.licenseUsage;
    const limits = licenseUsage.Limits.filter((l) => displayLimit.includes(l.Name.toLowerCase()));
    const { severity, title } = determineCalloutMessage(limits);
    const limitMessages = limits.filter((l) => dispositionSeverityOrder.some((d) => d == l.Disposition)).map((l) => l && <p key={l.Name}>{l.Message}</p>);
    const filteredAndOrderedUsageOverviews = displayLimit.map((limitName) => licenseUsage.Limits.find((l) => l.Name.toLowerCase() === limitName)).filter((item) => item !== undefined);
    return (<PageContent header={{ title: licenseUsagePageTitle }} callout={licenseUsage && severity
            ? {
                title,
                type: severity,
                content: limitMessages,
            }
            : undefined}>
            <Section>
                <div className="license-overview">
                    <h2>Overall License Usage</h2>
                    <div className={styles.cardList}>
                        {filteredAndOrderedUsageOverviews?.map((limit, index) => (<UsageOverviewCard licenseLimitStatus={limit} key={index}/>))}
                    </div>
                </div>
            </Section>
            <Section>
                <UsageDetailTable spacesUsage={licenseUsage.SpacesUsage ?? []} isPtm={licenseUsage.IsPtm}/>
            </Section>
        </PageContent>);
}
function determineCalloutMessage(limits: LicenseLimitStatus[]): {
    severity: CalloutType | "";
    title: string;
} {
    const hasError = limits.some((l) => l.Disposition === LicenseMessageDisposition.Error);
    if (hasError)
        return { severity: "danger", title: "License Limit Exceeded" };
    const hasWarningOrNotice = limits.some((l) => [LicenseMessageDisposition.Warning, LicenseMessageDisposition.Notice].includes(l.Disposition));
    if (hasWarningOrNotice)
        return { severity: "warning", title: "Approaching License Limit" };
    return { severity: "", title: "" };
}
