/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { TextField } from "@material-ui/core";
import cn from "classnames";
import * as React from "react";
import { generateGuid } from "~/areas/projects/components/Process/generation";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import UseLabelStrategy from "~/components/LabelStrategy/LabelStrategy";
import { withTheme } from "~/components/Theme";
import type FormFieldProps from "../../../components/form/FormFieldProps";
import styles from "./style.module.less";
export interface TextOtherProps {
    autoFocus?: boolean;
    autoComplete?: string;
    type?: string;
    min?: number;
    max?: number;
    placeholder?: string;
    hideUnderline?: boolean;
    id?: string;
    multiline?: boolean;
    minRows?: number;
    maxRows?: number;
    style?: React.CSSProperties;
    applyMaxWidth?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    label?: string | JSX.Element;
    accessibleName?: string;
    error?: string;
    warning?: string | null;
    className?: string;
    showBorder?: boolean;
    monoSpacedFont?: boolean;
    showValueAsTitleAttribute?: boolean;
    name?: string;
    generateUniqueName?: boolean;
    usePlaceholderAsLabel?: boolean;
    customMargins?: string;
    helperText?: string | JSX.Element;
    inputProps?: any;
    margin?: "none" | "dense" | "normal";
    textInputRef?(textInput: TextInput | null): void;
    inputContainerRef?(inputWrapper: HTMLDivElement | null): void;
    validate?(value: string): string;
    onValidate?(value: string): void;
    onKeyPress?(keyEvent: any): void;
    onKeyDown?(keyEvent: any): void;
    onFocus?(event: any): void;
    onBlur?(event: any): void;
    onClick?(event: any): void;
}
export type TextProps = TextOtherProps & FormFieldProps<string>;
interface TextState {
    error?: string;
    showExternalError: boolean;
}
export interface Selection {
    start: number | null;
    end: number | null;
}
export interface TextInput {
    isFocused(): boolean;
    focus(): void;
    blur(): void;
    select(): void;
    getSelection(): Selection;
    insertAtCursor(value: string): void;
    setValueAndSelection(selection: Selection, value: string): void;
}
// eslint-disable-next-line react/no-unsafe
class TextInternal extends React.Component<TextProps, TextState> implements TextInput {
    static defaultProps: Partial<TextProps> = {
        type: "text",
        autoComplete: "off",
        autoFocus: false,
        applyMaxWidth: false,
        multiline: false,
        showBorder: false,
        maxRows: 30,
        textInputRef: (input) => {
            /* Do nothing */
        },
    };
    private textFieldInput: any = undefined!;
    private genericName: string;
    private uniqueId: string;
    constructor(props: TextProps) {
        super(props);
        this.state = {
            showExternalError: true,
        };
        this.genericName = props.generateUniqueName ? `input-${generateGuid()}` : typeof props.label === "string" ? props.label : props.placeholder ? props.placeholder : `input-${generateGuid()}`;
        this.uniqueId = `${props.label}-${generateGuid()}`;
    }
    UNSAFE_componentWillMount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(this as TextInput);
        }
    }
    componentWillUnmount() {
        if (this.props.textInputRef) {
            this.props.textInputRef(null);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: TextProps) {
        const isNewExternalErrorAvailable = nextProps.error !== this.props.error;
        if (isNewExternalErrorAvailable) {
            this.setState({ showExternalError: true });
        }
    }
    getSelection = () => {
        const input = this.textFieldInput;
        return { start: input.selectionStart, end: input.selectionEnd };
    };
    setValueAndSelection = (selection: {
        start: any;
        end: any;
    }, value: string) => {
        const input = this.textFieldInput;
        input.value = value;
        input.selectionStart = selection.start;
        input.selectionEnd = selection.end;
        this.callValidateAndChange(input.value);
    };
    isFocused() {
        return this.textFieldInput && this.textFieldInput === document.activeElement;
    }
    select() {
        if (this.textFieldInput) {
            this.textFieldInput.select();
        }
    }
    focus() {
        if (this.textFieldInput) {
            this.textFieldInput.focus();
        }
    }
    blur() {
        if (this.textFieldInput) {
            this.textFieldInput.blur();
        }
    }
    insertAtCursor(value: string) {
        if (!this.textFieldInput) {
            return;
        }
        const input = this.textFieldInput;
        if (input.selectionStart || input.selectionStart === 0) {
            const startPos = input.selectionStart;
            const endPos = input.selectionEnd;
            if (endPos !== null) {
                input.value = input.value.substring(0, startPos) + value + input.value.substring(endPos, input.value.length);
            }
            input.selectionStart = startPos + value.length;
            input.selectionEnd = startPos + value.length;
        }
        else {
            input.value += value;
        }
        this.callValidateAndChange(input.value);
    }
    handleChange = (event: React.SyntheticEvent<any>) => {
        event.preventDefault();
        event.stopPropagation();
        const value = event.currentTarget.value;
        this.callValidateAndChange(value);
    };
    callValidateAndChange = (value: string) => {
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }
        this.setState({ showExternalError: false });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    handleKeyPress = (event: any) => {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }
    };
    handleKeyDown = (event: any) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };
    render() {
        return withTheme((theme) => {
            const { id, validate, error, onChange, onValidate, value, label, min, max, name, placeholder, hideUnderline, warning, monoSpacedFont, onKeyPress, minRows, multiline, applyMaxWidth, showBorder, textInputRef, inputContainerRef, showValueAsTitleAttribute, autoComplete, type, usePlaceholderAsLabel, accessibleName, customMargins, generateUniqueName, helperText, margin, inputProps, ...otherProps } = this.props;
            const err = this.state.error || (this.state.showExternalError && error);
            const errorText = err || warning;
            const val = value ? value : "";
            // Play carefully here. There's some interesting combinations happening with SensitiveInput label/placeholder etc. We have tests around this.
            const inputLabel = usePlaceholderAsLabel && placeholder && !val ? placeholder : label;
            const commonStyle = { margin: customMargins ? customMargins : !inputLabel ? "1rem 0" : "0 0 1rem 0", pointerEvents: "auto" as any }; // This allows us to match the padding/alignment of our other select components (AutoComplete, Select, MultiSelect), so we get consistent alignments.
            const widthStyle = applyMaxWidth ? { maxWidth: "100%" } : {};
            const inputId = id || this.uniqueId;
            const getHelpText = () => {
                if (errorText)
                    return errorText;
                if (helperText)
                    return helperText;
                return null;
            };
            const typeComplete = type === "password" && autoComplete === "off" ? "new-password" : autoComplete;
            return (<div className={cn(styles.container, { [styles.border]: showBorder })}>
                    <TextField ref={inputContainerRef} id={inputId} className={cn(monoSpacedFont ? [styles.text, styles.monospacedText].join(" ") : styles.text, { [styles.noUnderline]: hideUnderline })} inputRef={(textFieldInput: any) => {
                    this.textFieldInput = textFieldInput;
                }} type={type} autoComplete={typeComplete} autoCorrect={autoComplete} placeholder={placeholder} title={showValueAsTitleAttribute ? val : undefined} name={name || this.genericName} value={val} label={inputLabel} onChange={this.handleChange} error={!!errorText} helperText={getHelpText()} inputProps={{ min: min, max: max, "aria-label": accessibleName }} InputProps={{ ...this.props.inputProps }} minRows={multiline ? minRows || 3 : minRows} multiline={multiline} onKeyPress={this.handleKeyPress} onKeyDown={this.handleKeyDown} style={{ ...widthStyle, ...commonStyle }} margin={margin} {...otherProps}/>
                </div>);
        });
    }
    static displayName = "TextInternal";
}
const Text = UseLabelStrategy(TextInternal, (fieldName) => fieldName);
export const DebounceText = DebounceValue(Text);
export default Text;
