/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { ActiveFreeze, TenantEnvironmentMapping, TenantProjectEnvironment, TenantResource, TenantResourceWithFrozenInfo } from "@octopusdeploy/octopus-server-client";
import type { GetDeploymentFreezes, GetDeploymentFreezesResponse } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import type { Moment } from "moment";
import moment from "moment";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
function isOutsideSchedule(freeze: GetDeploymentFreezes, queueTime: Moment | undefined) {
    const targetDeployTime = queueTime ?? moment();
    const startMoment = moment(freeze.Start);
    const endMoment = moment(freeze.End);
    if ((freeze.RecurringSchedule === undefined || freeze.RecurringSchedule === null) && (targetDeployTime.isBefore(startMoment) || targetDeployTime.isAfter(endMoment))) {
        return true;
    }
    return false;
}
function isActive(freeze: GetDeploymentFreezes, projectId: string, selectedEnvironmentIds: string[], queueTime: Moment | undefined) {
    if (isOutsideSchedule(freeze, queueTime)) {
        return false;
    }
    return freeze.ProjectEnvironmentScope[projectId] && freeze.ProjectEnvironmentScope[projectId].some((environment) => selectedEnvironmentIds.includes(environment));
}
function isActiveForProjectsOrTenants(freeze: GetDeploymentFreezes, projectId: string, selectedEnvironmentIds: string[], tenantIds: string[], queueTime: Moment | undefined) {
    if (isOutsideSchedule(freeze, queueTime)) {
        return false;
    }
    return isActiveForEnvironment(freeze, projectId, selectedEnvironmentIds) || isActiveForTenants(freeze, projectId, tenantIds, selectedEnvironmentIds);
}
function isActiveForEnvironment(freeze: GetDeploymentFreezes, projectId: string, selectedEnvironmentIds: string[]): boolean {
    return freeze.ProjectEnvironmentScope[projectId] && freeze.ProjectEnvironmentScope[projectId].some((environment) => selectedEnvironmentIds.includes(environment));
}
function isActiveForTenants(freeze: GetDeploymentFreezes, projectId: string, selectedTenantIds: string[], selectedEnvironmentIds: string[]): boolean {
    return freeze.TenantProjectEnvironmentScope?.some((tpe) => selectedTenantIds.includes(tpe.TenantId) && selectedEnvironmentIds.includes(tpe.EnvironmentId) && tpe.ProjectId === projectId);
}
function transformToTenantEnvironments(tenantProjectEnvironmentScope: TenantProjectEnvironment[], projectId: string, selectedEnvironmentIds: string[], selectedTenantIds: string[]): TenantEnvironmentMapping[] {
    const tenantProjectMap = new Map<string, Map<string, Set<string>>>();
    tenantProjectEnvironmentScope.forEach((item) => {
        if (item.ProjectId !== projectId)
            return;
        if ((selectedEnvironmentIds.length === 0 || selectedEnvironmentIds.includes(item.EnvironmentId)) && (selectedTenantIds.length === 0 || selectedTenantIds.includes(item.TenantId))) {
            if (!tenantProjectMap.has(item.TenantId)) {
                tenantProjectMap.set(item.TenantId, new Map<string, Set<string>>());
            }
            const projectMap = tenantProjectMap.get(item.TenantId)!;
            if (!projectMap.has(item.ProjectId)) {
                projectMap.set(item.ProjectId, new Set<string>());
            }
            projectMap.get(item.ProjectId)!.add(item.EnvironmentId);
        }
    });
    const result: TenantEnvironmentMapping[] = [];
    tenantProjectMap.forEach((projectMap, tenantId) => {
        projectMap.forEach((environments, projectId) => {
            result.push({
                TenantId: tenantId,
                ProjectId: projectId,
                Environments: Array.from(environments),
            });
        });
    });
    return result;
}
export function getActiveFreezes(freezes: GetDeploymentFreezesResponse | null, projectId: string, selectedEnvironmentIds: string[], selectedTenantIds: string[], queueTime: Moment | undefined): ActiveFreeze[] {
    if (freezes === null) {
        return [];
    }
    if (isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle")) {
        return freezes.DeploymentFreezes.filter((f) => isActiveForProjectsOrTenants(f, projectId, selectedEnvironmentIds, selectedTenantIds, queueTime)).map((freeze) => ({
            Id: freeze.Id,
            Name: freeze.Name,
            Environments: freeze.ProjectEnvironmentScope[projectId] ? freeze.ProjectEnvironmentScope[projectId].filter((environment) => selectedEnvironmentIds.includes(environment)) : [],
            TenantEnvironments: transformToTenantEnvironments(freeze.TenantProjectEnvironmentScope, projectId, selectedEnvironmentIds, selectedTenantIds),
        }));
    }
    return freezes.DeploymentFreezes.filter((f) => isActive(f, projectId, selectedEnvironmentIds, queueTime)).map((freeze) => ({
        Id: freeze.Id,
        Name: freeze.Name,
        Environments: freeze.ProjectEnvironmentScope[projectId] ? freeze.ProjectEnvironmentScope[projectId].filter((environment) => selectedEnvironmentIds.includes(environment)) : [],
        TenantEnvironments: [],
    }));
}
export function isTenantFrozen(freezes: GetDeploymentFreezesResponse | undefined, projectId: string, selectedEnvironmentIds: string[], tenantId: string, queueTime: Moment | undefined): boolean {
    if (!isFeatureToggleEnabled("DeploymentFreezeByTenantFeatureToggle") || freezes === undefined) {
        return false;
    }
    return freezes.DeploymentFreezes.some((freeze) => {
        const targetDeployTime = queueTime ?? moment();
        const startMoment = moment(freeze.Start);
        const endMoment = moment(freeze.End);
        if (targetDeployTime.isBefore(startMoment) || targetDeployTime.isAfter(endMoment)) {
            return false;
        }
        return freeze.TenantProjectEnvironmentScope?.some((scope) => scope.TenantId === tenantId && scope.ProjectId === projectId && selectedEnvironmentIds.includes(scope.EnvironmentId)) ?? false;
    });
}
export function getTenantsWithFrozenInfo(tenants: TenantResource[], deployementFreezes: GetDeploymentFreezesResponse | undefined, projectId: string, selectedEnvironmentIds: string[]): TenantResourceWithFrozenInfo[] {
    return tenants.map((tenant) => ({
        ...tenant,
        IsFrozen: isTenantFrozen(deployementFreezes, projectId, selectedEnvironmentIds, tenant.Id, moment()),
    }));
}
