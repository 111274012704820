import { css } from "@emotion/css";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { borderRadius, fontSize, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import cn from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";
import { useAnalyticFirstDeploymentFeedbackDispatch } from "~/analytics/Analytics";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import CustomDialogContent from "~/components/DialogLayout/Custom/CustomDialogContent";
import { CustomDialogActions, FrameWrapper } from "~/components/DialogLayout/Custom/index";
import ExternalLink from "~/components/Navigation/ExternalLink/index";
import { ThumbsDownIcon, ThumbsUpIcon } from "../../../../../packages/design-system-icons/src";
import { isFeatureToggleEnabled } from "../FeatureToggle/New/FeatureToggleContext";
import successfulDeploymentImage from "./assets/img-successfuldeployment.svg";
import styles from "./styles.module.less";
interface SuccessfulDeploymentDialogProps {
    open: boolean;
    close: () => void;
}
const ExternalLinkIcon: JSX.Element = <em className={cn("fa-solid", "fa-external-link", styles.externalLinkIcon)} aria-hidden="true"/>;
const SuccessfulDeploymentDialog = ({ open, close }: SuccessfulDeploymentDialogProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const [showSurvey, setShowSurvey] = useState(false);
    const [positiveFeedback, setPositiveFeedback] = useState<boolean | null>(null);
    const [surveyClicked, setSurveyClicked] = useState(false);
    const isFirstDeploymentFeedbackFeatureEnabled = isFeatureToggleEnabled("FirstDeploymentFeedbackFeatureToggle");
    const dispatchAction = useAnalyticFirstDeploymentFeedbackDispatch();
    const { result: userOnboarding } = useInlineStatusQuery(async (repo) => repo.UserOnboarding.getForCurrentUser(), [], "Get User Onboarding");
    useEffect(() => {
        // Always close dialog immediately
        if (!open) {
            setShowDialog(false);
            return;
        }
        // Delay opening dialog,
        // to avoid showing it before the deployment screen indicates success
        setTimeout(() => setShowDialog(true), 3000);
    }, [open]);
    const onFeedbackButtonClicked = (positiveFeedback: boolean) => {
        setShowSurvey(true);
        setPositiveFeedback(positiveFeedback);
    };
    const onSurveyButtonClicked = () => {
        setSurveyClicked(true);
    };
    const onCloseDialog = () => {
        if (isFirstDeploymentFeedbackFeatureEnabled && userOnboarding?.UserOnboardingResource?.IsTrialInstance) {
            dispatchAction("First Deployment Experience", { experience: positiveFeedback ? "fantastic" : "notGreat", surveyClicked });
        }
        close();
    };
    const showFirstDeploymentSurvey = () => (<>
            <p>If you have a minute, tell us about your deployment experience and we'll mail you a pack of laptop stickers as a thank you.</p>
            <p>Happy deployments!</p>
            <ExternalLink href="OnboardingFeedbackForm" showIcon={false}>
                <ActionButton label={"Feedback form"} type={ActionButtonType.Secondary} icon={ExternalLinkIcon} iconPosition="right"/>
            </ExternalLink>
        </>);
    const showFirstDeploymentFeedback = () => (<>
            {!showSurvey ? (<div className={dialogStyles.feedbackContainer}>
                    <div className="title center">How is Octopus going?</div>
                    <div className="actions center">
                        <ActionButton label={"Fantastic"} type={ActionButtonType.Secondary} onClick={() => onFeedbackButtonClicked(true)} icon={<ThumbsUpIcon size={16}/>} iconPosition="left"/>
                        <ActionButton label={"Not great"} type={ActionButtonType.Secondary} onClick={() => onFeedbackButtonClicked(false)} icon={<ThumbsDownIcon size={16}/>} iconPosition="left"/>
                    </div>
                </div>) : (<div className={dialogStyles.feedbackContainer}>
                    <div className="title">Thanks for your feedback 💙</div>
                    <div>If you have a moment, tell us about your trial experience, and we’ll send you a $25 voucher for the Octopus merch shop.</div>
                    <div className="actions">
                        <ExternalLink href="OnboardingFeedbackFormTrialsOnly" showIcon={false} onClick={onSurveyButtonClicked}>
                            <ActionButton label={"One minute survey"} type={ActionButtonType.Secondary} icon={ExternalLinkIcon} iconPosition="right"/>
                        </ExternalLink>
                    </div>
                </div>)}
        </>);
    return (<CustomDialog close={close} open={showDialog} render={(renderProps) => (<FrameWrapper>
                    <div className={cn(styles.successfulDeploymentDialog)}>
                        <div className={styles.successfulDeploymentDialogContent}>
                            <p className={styles.successfulDeploymentDialogTitle}>Congratulations</p>
                            <CustomDialogContent className={styles.successfulDeploymentDialogText}>
                                <p>You completed your first successful deployment!</p>
                                {isFirstDeploymentFeedbackFeatureEnabled && userOnboarding?.UserOnboardingResource?.IsTrialInstance ? showFirstDeploymentFeedback() : showFirstDeploymentSurvey()}
                            </CustomDialogContent>
                            <CustomDialogActions actions={<ActionButton key="close" onClick={onCloseDialog} label={"Close"} type={ActionButtonType.Primary}/>}/>
                        </div>

                        <img src={successfulDeploymentImage} className={cn(styles.successfulDeploymentDialogImage)} alt={"Successful Deployment"}/>
                    </div>
                </FrameWrapper>)}/>);
};
export default SuccessfulDeploymentDialog;
const dialogStyles = {
    feedbackContainer: css({
        backgroundColor: themeTokens.color.callout.background.info.default,
        padding: space["16"],
        fontSize: fontSize["medium"],
        borderRadius: borderRadius["small"],
        width: "386px",
        "& .title": {
            fontWeight: fontWeight["700"],
            marginBottom: space["12"],
            "&.center": {
                textAlign: "center",
            },
        },
        "& .actions": {
            display: "flex",
            gap: space["12"],
            marginTop: space["12"],
            "&.center": {
                justifyContent: "center",
            },
        },
    }),
};
