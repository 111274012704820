import { useDebouncedCallback } from "@octopusdeploy/design-system-components";
import * as React from "react";
import { useEffect, useState } from "react";
export function useDebouncedExternalState<T>(externalValue: T, changeExternalValue: (value: T) => void, delay: number): [
    T,
    (val: T) => void
] {
    const [internalValue, setInternalValue] = useState(externalValue);
    const debouncedValueChange = useDebouncedCallback(() => changeExternalValue(internalValue), delay);
    useEffect(() => {
        setInternalValue(externalValue);
    }, [externalValue]);
    const handleChange = React.useCallback((newValue: T) => {
        setInternalValue(newValue);
        debouncedValueChange();
    }, [debouncedValueChange]);
    return [internalValue, handleChange];
}
