import { css } from "@emotion/css";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import React from "react";
const style = (size: number) => css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 0.8 * size,
    width: `${size}px`,
    height: `${size}px`,
});
export const InProgressIcon = (props: {
    size: 20 | 24;
    ariaLabel?: string;
}) => (<span className={style(props.size)} style={{ color: colorScales.blue[500] }} aria-label={props.ariaLabel}>
        <em className="fa-solid fa-circle-notch fa-spin"/>
    </span>);
