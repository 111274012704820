import { themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { GetCommonTemplatesWithValuesBffResponse, LibraryVariableSetInfo, VariableType, ProjectResource, WorkerPoolResource, ActionTemplateParameterResource, CommonTemplateTenantValue, LibraryVariableSetResource, } from "@octopusdeploy/octopus-server-client";
import { VariableSetContentType, Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import CommonTemplateValueInlineCell from "~/areas/projects/components/Variables/TenantVariables/CommonTemplateValueInlineCell";
import CommonTemplatesSidebarContent from "~/areas/projects/components/Variables/TenantVariables/CommonTemplatesSidebarContent";
import { CommonTemplatesFiltersBar } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
import { MissingValuesCallout } from "~/areas/projects/components/Variables/TenantVariables/MissingValuesCallout";
import NoCommonTemplateValuesOnboarding from "~/areas/projects/components/Variables/TenantVariables/NoCommonTemplateValuesOnboarding";
import NoVariableSetsOnboarding from "~/areas/projects/components/Variables/TenantVariables/NoVariableSetsOnboarding";
import { TemplateNameCell } from "~/areas/projects/components/Variables/TenantVariables/TemplateNameCell";
import { getVariableTypeFromDisplaySettings } from "~/areas/projects/components/Variables/TenantVariables/getVariableTypeFromDisplaySettings";
import { pagingBarStyles } from "~/areas/projects/components/Variables/TenantVariables/styles";
import type { CommonTemplatesValueChangeTracker, CommonTemplatesValueKey } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesValueChangeTracker";
import { createCommonTemplatesValueKey } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesValueChangeTracker";
import type { TenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import type { TenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import { TenantNameCellWithLink } from "~/areas/tenants/components/DataTable/Cells/TenantNameCell";
import NumberedPagingBar from "~/areas/tenants/components/Paging/NumberedPagingBar";
import { repository } from "~/clientInstance";
import { type DoBusyTask, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { GroupedDataTable } from "~/components/GroupedDataTable/GroupedDataTable";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SidebarLayout, { SidebarSide } from "~/components/SidebarLayout/index";
interface CommonTemplateTabContentProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    filterState: ProjectTenantVariablesFilterState;
    filterData: TenantVariableFiltersData;
    setFilterState: (filter: ProjectTenantVariablesFilterState) => void;
    pageSize: number;
    setPageState: (newPageNumber: number, newPageSize: number) => void;
    commonTemplatesWithValues: GetCommonTemplatesWithValuesBffResponse;
    onAddTemplateFromOnboarding: (text: string) => void;
    resourceOptions: TenantVariableResourceOptions;
    valueChangeTracker: CommonTemplatesValueChangeTracker;
}
export function CommonTemplatesInlineTabContent({ project, doBusyTask, filterState, filterData, setFilterState, pageSize, setPageState, commonTemplatesWithValues, onAddTemplateFromOnboarding, resourceOptions, valueChangeTracker, }: CommonTemplateTabContentProps) {
    const [variableSets, setVariableSets] = useState<LibraryVariableSetResource[] | null>(null);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const libraryVariableSets = await repository.LibraryVariableSets.all({
            ids: project.IncludedLibraryVariableSetIds,
            contentType: VariableSetContentType.Variables,
        });
        setVariableSets(libraryVariableSets);
    }, [project]);
    if (variableSets === null) {
        return null;
    }
    if (variableSets.length === 0) {
        return <NoVariableSetsOnboarding project={project} doBusyTask={doBusyTask} onIncludeVariableSet={onAddTemplateFromOnboarding}/>;
    }
    const missingValueVariableSetIds = new Set<string>(commonTemplatesWithValues.MissingValueLibraryVariableSetIds);
    if (commonTemplatesWithValues.TotalValuesCount === 0) {
        return (<div style={{ height: "100%" }}>
                <SidebarLayout sideBar={<CommonTemplatesSidebarContent variableSets={variableSets} spaceId={project.SpaceId} pageSize={pageSize} filterState={filterState} setFilterState={setFilterState} missingValueVariableSetIds={missingValueVariableSetIds}/>} side={SidebarSide.Left} extendSidebarToEdges={true} extendContentToEdges={true} reduceWidthForSmallResolution={true}>
                    <NoCommonTemplateValuesOnboarding project={project}/>
                </SidebarLayout>
            </div>);
    }
    const [variableValues, bindableTemplateNames] = buildCommonTemplateRowData(commonTemplatesWithValues, variableSets);
    const unsavedChanges = valueChangeTracker.values().length;
    return (<SidebarLayout sideBar={<CommonTemplatesSidebarContent variableSets={variableSets} spaceId={project.SpaceId} pageSize={pageSize} filterState={filterState} setFilterState={setFilterState} missingValueVariableSetIds={missingValueVariableSetIds}/>} side={SidebarSide.Left} extendSidebarToEdges={true} extendContentToEdges={true} reduceWidthForSmallResolution={true}>
            <div>
                {commonTemplatesWithValues.AnyMissingValues && <MissingValuesCallout filterState={filterState} setFilterState={setFilterState}/>}
                <CommonTemplatesFiltersBar totalVariablesCount={commonTemplatesWithValues.TotalValuesCount} filteredVariablesCount={commonTemplatesWithValues.FilteredValuesCount} filterState={filterState} onFilterChange={setFilterState} filterData={filterData} unsavedChangesCount={unsavedChanges}/>
                <CommonTemplatesDataTable values={variableValues} project={project} accounts={commonTemplatesWithValues.Accounts} certificates={commonTemplatesWithValues.Certificates} workerPools={commonTemplatesWithValues.WorkerPools} resourceOptions={resourceOptions} valueChangeTracker={valueChangeTracker} doBusyTask={doBusyTask} bindableTemplateNames={bindableTemplateNames}/>
                <div className={pagingBarStyles}>
                    <NumberedPagingBar totalItems={commonTemplatesWithValues.FilteredValuesCount} pageNumber={filterState.pageNumber} pageSize={pageSize} pageSizeOptions={[30, 50, 100]} onPagingSelectionChange={setPageState}/>
                </div>
            </div>
        </SidebarLayout>);
}
interface CommonTemplatesDataTableProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    project: ProjectResource;
    values: CommonTemplateRowData[];
    resourceOptions: TenantVariableResourceOptions;
    valueChangeTracker: CommonTemplatesValueChangeTracker;
    doBusyTask: DoBusyTask;
    bindableTemplateNames: string[];
}
export function CommonTemplatesDataTable({ values, project, accounts, certificates, workerPools, resourceOptions, valueChangeTracker, doBusyTask, bindableTemplateNames }: CommonTemplatesDataTableProps) {
    const [focusedValueKey, setFocusedValueKey] = useState<CommonTemplatesValueKey | null>(null);
    const editingPermissions = {
        permission: Permission.VariableEdit,
        project: project.Id,
        wildcard: true,
    };
    const hasEditPermissions = isAllowed(editingPermissions);
    const renderValueCell = (data: CommonTemplateRowData) => {
        const tenantId = data.Variable.TenantId;
        const sourceItems = resourceOptions.get(tenantId);
        const valueKey = createCommonTemplatesValueKey(data.VariableSetId, data.Variable.TemplateId, data.Variable.TenantId, "");
        const hasValueChanged = valueChangeTracker.has(valueKey);
        const value = hasValueChanged ? valueChangeTracker.get(valueKey) : data.Variable.Value;
        const handleOnBlur = () => {
            setFocusedValueKey((previous: CommonTemplatesValueKey | null) => (previous === valueKey ? null : previous));
        };
        return (<CommonTemplateValueInlineCell template={data.Template} accounts={accounts} certificates={certificates} workerPools={workerPools} value={value} valueType={data.VariableType} sourceItems={sourceItems} doBusyTask={doBusyTask} valueTracker={valueChangeTracker} valueKey={valueKey} focused={focusedValueKey === valueKey} onFocus={() => setFocusedValueKey(valueKey)} onBlur={handleOnBlur} bindableTemplateNames={bindableTemplateNames} hasEditPermissions={hasEditPermissions} hasValueChanged={hasValueChanged} initialValue={data.Variable.Value}/>);
    };
    return (<GroupedDataTable data={values} columns={[
            { title: "Variable Set", render: (data) => (data.IsSetChild ? null : data.VariableSet.Name), width: "20%", isChild: (data) => data.IsSetChild },
            { title: "Name", render: (data) => (data.IsTemplateChild ? null : <TemplateNameCell template={data.Template}/>), width: "25%", isChild: (data) => data.IsTemplateChild },
            {
                title: "Tenant",
                render: (data) => (<TenantNameCellWithLink spaceId={project.SpaceId} tenantId={data.Variable.TenantId} tenantName={data.Variable.TenantName} tenantLogoLink={data.Variable.TenantLogoLink} isDisabled={data.Variable.TenantIsDisabled} linkToPage="Variables"/>),
                width: "20%",
            },
            {
                title: "Value",
                render: renderValueCell,
                width: "35%",
                padding: "0",
                backgroundColor: (data) => {
                    const valueKey = createCommonTemplatesValueKey(data.VariableSetId, data.Template.Id, data.Variable.TenantId, "");
                    const hasValueChanged = valueChangeTracker.has(valueKey);
                    return hasValueChanged ? themeTokens.color.callout.background.attention.default : undefined;
                },
            },
        ]} getRowKey={(data) => data.RowKey}/>);
}
interface CommonTemplateRowData {
    VariableSetId: string;
    VariableSet: LibraryVariableSetInfo;
    Variable: CommonTemplateTenantValue;
    Template: ActionTemplateParameterResource;
    VariableType: VariableType;
    RowKey: string;
    IsSetChild: boolean;
    IsTemplateChild: boolean;
}
function buildCommonTemplateRowData(commonTemplatesWithValues: GetCommonTemplatesWithValuesBffResponse, variableSets: LibraryVariableSetResource[]): [
    CommonTemplateRowData[],
    string[]
] {
    const rows: CommonTemplateRowData[] = [];
    const [templatesLookup, bindableNames] = buildTemplatesLookup(variableSets);
    const setsWithChildren = new Set<string>();
    const templatesWithChildren = new Set<string>();
    for (const variableSet of commonTemplatesWithValues.VariableSets) {
        const set = variableSet.LibraryVariableSet;
        for (const templateWithValues of variableSet.Templates) {
            for (const variable of templateWithValues.Values) {
                const templateWithType = templatesLookup.get(variable.TemplateId);
                if (!templateWithType) {
                    logger.warn("Could not find common template with Id {id}", { id: variable.TemplateId });
                    continue;
                }
                const { template, type } = templateWithType;
                const isSetHasChildren = setsWithChildren.has(set.Id);
                if (!isSetHasChildren) {
                    setsWithChildren.add(set.Id);
                }
                const isTemplateHasChildren = templatesWithChildren.has(template.Id);
                if (!isTemplateHasChildren) {
                    templatesWithChildren.add(template.Id);
                }
                const row: CommonTemplateRowData = {
                    VariableSetId: set.Id,
                    VariableSet: set,
                    Variable: variable,
                    Template: template,
                    VariableType: type,
                    RowKey: createCommonTemplatesValueKey(set.Id, template.Id, variable.TenantId, ""),
                    IsSetChild: isSetHasChildren,
                    IsTemplateChild: isTemplateHasChildren,
                };
                rows.push(row);
            }
        }
    }
    // Show enabled tenants first
    rows.sort((a, b) => Number(a.Variable.TenantIsDisabled) - Number(b.Variable.TenantIsDisabled));
    return [rows, bindableNames];
}
interface TemplateWithVariableType {
    template: ActionTemplateParameterResource;
    type: VariableType;
}
function buildTemplatesLookup(variableSets: LibraryVariableSetResource[]): [
    Map<string, TemplateWithVariableType>,
    string[]
] {
    const templates = new Map<string, TemplateWithVariableType>();
    const bindableNames = new Set<string>();
    for (const variableSet of variableSets) {
        for (const template of variableSet.Templates) {
            const type = getVariableTypeFromDisplaySettings(template.DisplaySettings["Octopus.ControlType"]);
            templates.set(template.Id, { template, type });
            bindableNames.add(template.Name);
        }
    }
    return [templates, Array.from(bindableNames)];
}
