import type { InlineQueryResult } from "@octopusdeploy/octopus-react-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import type { KubernetesTaskResourceStatusResource, KubernetesStepStatus, KubernetesObjectStatus, GetKubernetesResourceManifestsResponse, KubernetesResourceManifestResource, GetKubernetesResourceManifestSummariesResponse, KubernetesResourceManifestSummaryResource, } from "@octopusdeploy/octopus-server-client";
import React, { useMemo, useState } from "react";
import { getStepActivity } from "~/areas/tasks/components/Task/K8sStatus/getStepActivity";
import type { KubernetesDetailsBaseProps, KubernetesDetailsFilter, KubernetesDetailsStepView } from "~/areas/tasks/components/Task/Kubernetes/KubernetesDetailsLayout";
import { KubernetesObjectDrawer } from "~/areas/tasks/components/Task/Kubernetes/KubernetesObjectDrawer";
import { KubernetesStepExpander } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepExpander";
import { ExpansionButtons } from "~/components/form/index";
export const KubernetesStepsLayoutContainerKey = "KubernetesStepsLayout";
type KubernetesStepsLayoutProps = KubernetesDetailsBaseProps & {
    activeView: KubernetesDetailsStepView;
    filter: KubernetesDetailsFilter;
    resourceStatusResult: KubernetesTaskResourceStatusResource | undefined;
    summariesResult: GetKubernetesResourceManifestSummariesResponse | undefined;
    manifestsQuery: InlineQueryResult<GetKubernetesResourceManifestsResponse | undefined, null>;
};
export const KubernetesStepsLayout = (props: KubernetesStepsLayoutProps) => {
    const [objectStatusInDrawer, setObjectStatusInDrawer] = useState<KubernetesObjectStatus | undefined>(undefined);
    const stepStatusMap = useMemo(() => {
        const lookup: Record<string, KubernetesStepStatus | undefined> = {};
        for (const step of props.steps) {
            lookup[step.stepName] = (props.resourceStatusResult?.KubernetesStepsStatus || []).find((v) => v.StepName === step.stepName);
        }
        return lookup;
    }, [props.resourceStatusResult, props.steps]);
    const stepSummariesMap = useMemo(() => {
        const lookup: Record<string, KubernetesResourceManifestSummaryResource[] | undefined> = {};
        for (const step of props.steps) {
            lookup[step.actionId] = (props.summariesResult?.Resources || []).filter((v) => v.DeploymentActionId === step.actionId);
        }
        return lookup;
    }, [props.summariesResult, props.steps]);
    const stepManifestsQueryResultMap = useMemo(() => {
        const lookup: Record<string, InlineQueryResult<KubernetesResourceManifestResource[] | undefined, null>> = {};
        for (const step of props.steps) {
            lookup[step.actionId] = {
                ...props.manifestsQuery,
                result: (props.manifestsQuery?.result?.Resources || []).filter((v) => v.DeploymentActionId === step.actionId),
            };
        }
        return lookup;
    }, [props.manifestsQuery, props.steps]);
    return (<>
            <ExpansionButtons containerKey={KubernetesStepsLayoutContainerKey} expandAllOnMount={true}/>
            {props.steps.map(({ stepName, actionId, isResourceStatusEnabled, isAtomicHelmStep }) => {
            const activity = getStepActivity(stepName, props.activityLogs) ?? { Name: stepName, Started: "", Ended: "", Status: ActivityStatus.Pending };
            return (<KubernetesStepExpander key={actionId} stepName={stepName} stepActivity={activity} stepResourceStatus={stepStatusMap[stepName]} stepSummaries={stepSummariesMap[actionId]} stepManifestsQueryResult={stepManifestsQueryResultMap[actionId]} isAtomicHelmStep={isAtomicHelmStep} isResourceStatusEnabled={isResourceStatusEnabled} showObjectDrawer={setObjectStatusInDrawer} {...props}/>);
        })}
            <KubernetesObjectDrawer onClose={() => setObjectStatusInDrawer(undefined)} objectStatus={objectStatusInDrawer}/>
        </>);
};
