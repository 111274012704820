import { css } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ScopedCommonTemplateScopeEdit from "~/areas/projects/components/Variables/TenantVariables/ScopedTenantVariables/ScopedCommonTemplateScopeEdit";
import type { ScopedCommonTemplatesScopeChangeTracker, ScopedCommonTemplatesScopeKey } from "~/areas/projects/components/Variables/TenantVariables/ScopedTenantVariables/useScopedCommonTemplatesScopeChangeTracker";
import { EnvironmentChip } from "~/components/Chips";
import type { PopoverPosition } from "~/components/PopoverWhenFocused/PopoverWhenFocused";
import PopoverWhenFocused from "~/components/PopoverWhenFocused/index";
interface ScopedCommonTemplateScopeCellProps {
    environments: Map<string, EnvironmentResource>;
    scopeTracker: ScopedCommonTemplatesScopeChangeTracker;
    scopeKey: ScopedCommonTemplatesScopeKey;
    focused: boolean;
    onFocus: () => void;
    onBlur: () => void;
    hasEditPermissions: boolean;
    hasScopeChanged: boolean;
    initialEnvironmentScope: string[];
}
export default ({ environments, scopeTracker, scopeKey, focused, onFocus, onBlur, hasEditPermissions, hasScopeChanged, initialEnvironmentScope }: ScopedCommonTemplateScopeCellProps) => (<ScopedCommonTemplateScopeEditableCell environments={environments} scopeTracker={scopeTracker} scopeKey={scopeKey} focused={focused} onFocus={onFocus} onBlur={onBlur} hasEditPermissions={hasEditPermissions} hasScopeChanged={hasScopeChanged} initialEnvironmentScope={initialEnvironmentScope}/>);
interface ScopedCommonTemplateScopeEditableCellProps extends ScopedCommonTemplateScopeCellProps {
    hasEditPermissions: boolean;
}
function ScopedCommonTemplateScopeEditableCell({ environments, scopeTracker, scopeKey, focused, onFocus, onBlur, hasEditPermissions, hasScopeChanged, initialEnvironmentScope }: ScopedCommonTemplateScopeEditableCellProps) {
    const calculateFocusedPopoverPosition = (): PopoverPosition => {
        // Assumes parent's padding, How to retrieve padding values from the parent
        return {
            top: -12,
            left: 0,
            right: 0,
        };
    };
    return (<div onFocus={onFocus} onClick={onFocus} className={cellStyles.container}>
            <PopoverWhenFocused isFocused={focused} onClickOutside={onBlur} dynamicPosition={calculateFocusedPopoverPosition}>
                {focused ? (<ScopedCommonTemplateScopeEdit scopeKey={scopeKey} scopeChangeTracker={scopeTracker} initialEnvironmentScope={initialEnvironmentScope} environments={environments}/>) : (<ScopedCommonTemplateReadonlyCell environments={environments} scopeKey={scopeKey} scopeChangeTracker={scopeTracker} initialEnvironmentScope={initialEnvironmentScope} hasEditPermissions={hasEditPermissions} hasScopeChanged={hasScopeChanged}/>)}
            </PopoverWhenFocused>
        </div>);
}
interface ScopedCommonTemplateScopeReadonlyCellProps {
    environments: Map<string, EnvironmentResource>;
    scopeKey: ScopedCommonTemplatesScopeKey;
    scopeChangeTracker: ScopedCommonTemplatesScopeChangeTracker;
    initialEnvironmentScope: string[];
    hasEditPermissions: boolean;
    hasScopeChanged: boolean;
}
const ScopedCommonTemplateReadonlyCell = (props: ScopedCommonTemplateScopeReadonlyCellProps) => {
    const { environments, scopeKey, scopeChangeTracker, initialEnvironmentScope, hasEditPermissions, hasScopeChanged } = props;
    const scope = scopeChangeTracker.has(scopeKey) ? scopeChangeTracker.get(scopeKey) : initialEnvironmentScope;
    const scopeLength = scope?.length ?? 0;
    const tooManyChips = scopeLength >= 3;
    if (!scope?.length) {
        return (<div className={cellStyles.missingValueContainer}>
                <EnvironmentChip environmentName={"All environments"} key="environmentsChipsList"/>
            </div>);
    }
    return tooManyChips ? (<EnvironmentChip environmentName={`${scopeLength} environments`} key="environmentsChipsList"/>) : (<>
            {scope.map((s) => {
            const environment = environments.get(s);
            return environment ? <EnvironmentChip environmentName={environment.Name} key={environment.Id}/> : null;
        })}
        </>);
};
const cellStyles = {
    container: css({
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "4rem",
    }),
    readonlyContainer: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: space[8],
        width: "100%",
        height: "100%",
        padding: `${space[8]} ${space[12]}`,
    }),
    missingValue: css({
        "&&": { color: themeTokens.color.icon.secondary },
    }),
    missingValueContainer: css({
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: space[8],
    }),
};
