import { Tooltip } from "@octopusdeploy/design-system-components";
import { HeartCrackIcon, HeartIcon } from "@octopusdeploy/design-system-icons";
import { colorScales } from "@octopusdeploy/design-system-tokens";
import type { KubernetesResourceStatus } from "@octopusdeploy/octopus-server-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { InProgressIcon } from "./components/InProgressIcon";
const completedStepStatuses = [ActivityStatus.Failed, ActivityStatus.Success, ActivityStatus.SuccessWithWarning, ActivityStatus.Canceled, "Successful"];
export const ObjectStatusIcon = (props: {
    objectStatus: KubernetesResourceStatus;
    stepStatus: ActivityStatus | string | undefined;
}) => {
    switch (props.objectStatus) {
        case "InProgress":
            //if the step has completed, but the object is InProgress, we claim it's timed out
            if (props.stepStatus && completedStepStatuses.indexOf(props.stepStatus) !== -1) {
                return (<Tooltip content="Timed out while in progress">
                        <em aria-label="Timeout Status" className="fa-solid fa-clock" style={{ color: colorScales.red["500"] }}/>
                    </Tooltip>);
            }
            return <InProgressIcon size={20} ariaLabel={"In-progress Status"}/>;
        case "Successful":
            return <HeartIcon size={20} color={colorScales.green[500]} ariaLabel={"Successful Status"}/>;
        case "Failed":
            return <HeartCrackIcon size={20} color={colorScales.red[500]} ariaLabel={"Failed Status"}/>;
    }
};
