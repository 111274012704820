import { logger } from "@octopusdeploy/logging";
import type { AccountResource, ActionTemplateSearchResource, BlueprintResource, FeedResource, GitCredentialResource, ModifyProcessCommand, PersistenceSettings, ProcessResource } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, HasGitPersistenceSettings, isModifyDeploymentProcessCommand, isModifyRunbookProcessCommand, OctopusError, Permission, Repository } from "@octopusdeploy/octopus-server-client";
import { isEmpty } from "lodash";
import flatMap from "lodash/flatMap";
import uniq from "lodash/uniq";
import React, { useCallback, useEffect, useMemo } from "react";
import { getProcessTemplateCommitFromAction, getProcessTemplateSlugFromAction } from "~/areas/projects/components/Process/Blueprints/processTemplateId";
import { ProcessAccountsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessAccountsContextProvider";
import { ProcessBlueprintsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { ProcessGitCredentialsContextProvider } from "~/areas/projects/components/Process/Contexts/ProcessGitCredentialsContextProvider";
import { repository } from "~/clientInstance";
import type { DoBusyTask, Errors } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { createErrorsFromOctopusError } from "~/components/DataBaseComponent/Errors";
import { DevToolsTab } from "~/components/DevTools/DevToolsContext";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import pluginRegistry from "../../../../../components/Actions/pluginRegistry";
import type { ProcessContextModelState, ProcessIdentifier, ProcessPageSupportedActions } from "../types";
import { isBlueprintProcessIdentifier, isDeploymentOrRunbookProcessIdentifier } from "../types";
import { DevToolbarProcessUpload } from "./DeploymentProcessUpload";
import { ProcessActionTemplatesContextProvider } from "./ProcessActionTemplatesContextProvider";
import type { ProcessContextLookupState, ProcessContextProps, ProcessContextProviderSetupActions } from "./ProcessContext";
import { ProcessContext, useBoundProcessActions } from "./ProcessContext";
import type { ProcessStateSelectors } from "./ProcessContextState";
import { getProcessContextModelInitialState, getSelectors, processContextModelStateReducer } from "./ProcessContextState";
import { ProcessErrorsController } from "./ProcessErrors/ProcessErrorsContext";
import { ProcessFeedsContextProvider } from "./ProcessFeedsContextProvider";
import { ProcessSearchFilterController } from "./ProcessSearchFilter/ProcessSearchFilterContext";
import { ProcessWarningsController } from "./ProcessWarnings/ProcessWarningsContext";
interface ProcessControllerProps {
    doBusyTask: DoBusyTask;
    children: (renderProps: ProcessContextProps) => React.ReactNode;
    layoutActions: ProcessPageSupportedActions;
    errors?: Errors;
    processIdentifier: ProcessIdentifier;
    process: ProcessResource;
    reloadProcess: () => void;
    modifyProcess: (process: ModifyProcessCommand, gitRef: string | undefined) => Promise<void>;
}
export type ProcessTemplateDetails = {
    commit: string;
    processTemplate: BlueprintResource;
};
const useProcessState = () => {
    return React.useState<ProcessContextLookupState>({
        actionTemplates: "NotLoaded",
        feeds: "NotLoaded",
        accounts: [],
        gitCredentials: [],
        blueprints: "NotLoaded",
    });
};
const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProcessContextLookupState>>) => {
    return {
        onActionTemplatesUpdated: (actionTemplates: ActionTemplateSearchResource[]) => setState((current) => ({ ...current, actionTemplates })),
        onFeedsUpdated: (feeds: FeedResource[]) => setState((current) => ({ ...current, feeds })),
        onAccountsUpdated: (accounts: AccountResource[]) => setState((current) => ({ ...current, accounts })),
        onGitCredentialsUpdated: (gitCredentials: GitCredentialResource[]) => setState((current) => ({ ...current, gitCredentials })),
        onBlueprintsUpdated: (blueprints: ProcessTemplateDetails[]) => setState((current) => ({ ...current, blueprints })),
    };
};
const useSelectors = (state: ProcessContextModelState): ProcessStateSelectors => {
    return React.useMemo(() => getSelectors(state), [state]);
};
export const ProcessController: React.FC<ProcessControllerProps> = ({ children, doBusyTask, layoutActions, processIdentifier, process, reloadProcess, modifyProcess }: ProcessControllerProps) => {
    const [state, dispatch] = React.useReducer(processContextModelStateReducer, getProcessContextModelInitialState(processIdentifier));
    const selectors = useSelectors(state);
    const [lookupsState, setState] = useProcessState();
    const { setProcess: setProcessDispatchAction, conflictDetected: conflictDetectedDispatchAction, ...boundDispatchActions } = useBoundProcessActions(dispatch);
    const setProcess = useCallback(async (process: ProcessResource, updateCleanModel: boolean) => {
        const allPlugins = await Promise.all(uniq(flatMap(process.Steps, (step) => step.Actions).map((action) => pluginRegistry.getAction(action.ActionType, action.StepPackageVersion))));
        setProcessDispatchAction(process, updateCleanModel, allPlugins);
    }, [setProcessDispatchAction]);
    const boundActions = {
        ...boundDispatchActions,
        setProcess,
        conflictDetected: async (serverProcess: ProcessResource, stagedProcess: ProcessResource) => {
            const allPlugins = await Promise.all(uniq(flatMap([...serverProcess.Steps, ...stagedProcess.Steps], (step) => step.Actions).map((action) => pluginRegistry.getAction(action.ActionType, action.StepPackageVersion))));
            conflictDetectedDispatchAction(serverProcess, stagedProcess, allPlugins);
        },
    };
    useEffect(() => {
        setProcess(process, true);
    }, [setProcess, process]);
    const stateUpdaters = React.useMemo(() => getStateUpdaters(setState), [setState]);
    const isBlueprintsEnabled = useOctopusFeatureToggle("blueprints", false);
    const refreshAccounts = useDoBusyTaskEffect(doBusyTask, async () => {
        const accounts = await repository.Accounts.all();
        stateUpdaters.onAccountsUpdated(accounts);
    }, []);
    const refreshActionTemplates = useDoBusyTaskEffect(doBusyTask, async () => {
        const templates = await repository.ActionTemplates.search();
        stateUpdaters.onActionTemplatesUpdated(templates);
    }, []);
    const refreshFeeds = useDoBusyTaskEffect(doBusyTask, async () => {
        if (isBlueprintProcessIdentifier(processIdentifier)) {
            stateUpdaters.onFeedsUpdated([]);
            return;
        }
        const feeds = isAllowed({ permission: Permission.FeedView, project: isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined, wildcard: true }) ? await repository.Feeds.all() : [];
        stateUpdaters.onFeedsUpdated(feeds);
    }, []);
    const refreshGitCredentials = useDoBusyTaskEffect(doBusyTask, async () => {
        if (isBlueprintProcessIdentifier(processIdentifier)) {
            const gitCredentials = isAllowed({ permission: Permission.PlatformHubView, wildcard: true }) ? (await repository.PlatformHubRepository.getGitCredentials({ take: Repository.takeAll })).Items : [];
            stateUpdaters.onGitCredentialsUpdated(gitCredentials);
            return;
        }
        const gitCredentials = isAllowed({ permission: Permission.GitCredentialView, project: isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined, wildcard: true })
            ? (await repository.GitCredentials.list({ take: Repository.takeAll })).Items
            : [];
        stateUpdaters.onGitCredentialsUpdated(gitCredentials);
    }, []);
    const blueprintDetails = useMemo(() => {
        if (state.model.process?.Id) {
            return new Set(Object.values(state.model.actions.byId)
                .flatMap((a) => {
                const slug = getProcessTemplateSlugFromAction(a);
                const commit = getProcessTemplateCommitFromAction(a);
                return { slug, commit };
            })
                .filter((details) => !!details.slug)
                .sort());
        }
        return "WaitingForProcess";
    }, [state.model.actions.byId, state.model.process?.Id]);
    const blueprintDetailsString = blueprintDetails === "WaitingForProcess" ? "WaitingForProcess" : JSON.stringify([...blueprintDetails.values()]);
    const refreshBlueprintsForProcess = useDoBusyTaskEffect(doBusyTask, async () => {
        if (isBlueprintsEnabled && blueprintDetails !== "WaitingForProcess") {
            const processTemplates: {
                commit: string;
                processTemplate: BlueprintResource;
            }[] = [];
            for (const blueprintDetail of blueprintDetails) {
                try {
                    const blueprint = await repository.Blueprints.getByGitRefOrLatest(blueprintDetail.slug, blueprintDetail.commit);
                    processTemplates.push({
                        commit: isEmpty(blueprintDetail.commit) ? "latest" : blueprintDetail.commit,
                        processTemplate: blueprint,
                    });
                }
                catch (e) {
                    logger.warn("Couldn't load blueprint {blueprintId}", { blueprintId: blueprintDetail });
                }
            }
            stateUpdaters.onBlueprintsUpdated(processTemplates);
        }
        else if (!isBlueprintsEnabled) {
            stateUpdaters.onBlueprintsUpdated([]);
        }
    }, [isBlueprintsEnabled, blueprintDetailsString]);
    const saveOnServer = async (process: ModifyProcessCommand, onError: (errors: Errors) => void, onSuccess: () => void, gitRef?: string): Promise<boolean> => {
        try {
            await modifyProcess(process, gitRef);
            onSuccess();
            return true;
        }
        catch (e) {
            if (e instanceof OctopusError) {
                const errors = createErrorsFromOctopusError(e);
                onError(errors);
            }
            throw e;
        }
    };
    const cloneToDifferentProcess = async (process: ModifyProcessCommand, targetProjectPersistenceSettings: PersistenceSettings, onError: (errors: Errors) => void, onSuccess: () => void): Promise<void> => {
        await doBusyTask(async () => {
            if (isModifyRunbookProcessCommand(process)) {
                if (HasRunbooksInGit(targetProjectPersistenceSettings)) {
                    throw new Error("Cannot clone steps to a Git runbook.");
                }
                await repository.Runbooks.modifyRunbookProcess(process);
            }
            else if (isModifyDeploymentProcessCommand(process)) {
                if (HasGitPersistenceSettings(targetProjectPersistenceSettings)) {
                    throw new Error("Cannot clone steps to a Git deployment process.");
                }
                await repository.DeploymentProcesses.modify(process, process.ProjectId);
            }
            else {
                throw new Error("Only deployment and runbook processes can be cloned to.");
            }
        }, {
            onError,
            onSuccess,
        });
    };
    const actions: ProcessContextProviderSetupActions = {
        ...boundActions,
        saveOnServer,
        cloneToDifferentProcess,
        refreshFromServer: reloadProcess,
        ...layoutActions,
    };
    const contextValue: ProcessContextProps = {
        state,
        actions: actions,
        selectors,
    };
    return (<ProcessFeedsContextProvider feeds={lookupsState.feeds} refreshFeeds={refreshFeeds}>
            <ProcessActionTemplatesContextProvider templates={lookupsState.actionTemplates} refreshActionTemplates={refreshActionTemplates}>
                <ProcessAccountsContextProvider accounts={lookupsState.accounts} refreshAccounts={refreshAccounts}>
                    <ProcessGitCredentialsContextProvider gitCredentials={lookupsState.gitCredentials} refreshGitCredentials={refreshGitCredentials}>
                        <ProcessBlueprintsContextProvider blueprints={lookupsState.blueprints} refreshBlueprints={refreshBlueprintsForProcess}>
                            <ProcessContext.Provider value={contextValue}>
                                <DevToolsTab name={`Upload ${contextValue.state.processIdentifier.type} Process`}>
                                    <DevToolbarProcessUpload processContext={contextValue}/>
                                </DevToolsTab>
                                <ProcessSearchFilterController processType={contextValue.state.processIdentifier.type} selectors={contextValue.selectors}>
                                    {() => (<ProcessErrorsController>
                                            <ProcessWarningsController>{children(contextValue)}</ProcessWarningsController>
                                        </ProcessErrorsController>)}
                                </ProcessSearchFilterController>
                            </ProcessContext.Provider>
                        </ProcessBlueprintsContextProvider>
                    </ProcessGitCredentialsContextProvider>
                </ProcessAccountsContextProvider>
            </ProcessActionTemplatesContextProvider>
        </ProcessFeedsContextProvider>);
};
ProcessController.displayName = "ProcessController"
