export type StaticEnvironmentSelection = {
    type: "Static";
    environmentId: string;
    name: string;
};
export type EnvironmentSelection = StaticEnvironmentSelection;
export function getStaticEnvironments(environments: EnvironmentSelection[]) {
    return environments.filter<StaticEnvironmentSelection>(isEnvironmentSelectionStatic);
}
export function getEnvironmentIds(environments: EnvironmentSelection[]) {
    const staticEnvironments = getStaticEnvironments(environments);
    return staticEnvironments.map((e) => e.environmentId);
}
export function isEnvironmentSelectionStatic(environment: EnvironmentSelection): environment is StaticEnvironmentSelection {
    return environment.type === "Static";
}
