import { Callout } from "@octopusdeploy/design-system-components";
import { useInlineStatusQuery, useQuery } from "@octopusdeploy/octopus-react-client";
import type { ISnapshotResource } from "@octopusdeploy/octopus-server-client";
import { OctopusError, isReleaseResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import EventListing from "~/components/EventListing";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
interface ListEventsForReleaseOrRunbookSnapshotProps {
    snapshot: ISnapshotResource;
}
export function ListEventsForReleaseOrRunbookSnapshot({ snapshot }: ListEventsForReleaseOrRunbookSnapshotProps) {
    const useOptimization = isFeatureToggleEnabled("PermissionCheckOptimizationFeatureToggle");
    const snapshotType = isReleaseResource(snapshot) ? "release" : "runbook snapshot";
    const { result: events, error } = useInlineStatusQuery(() => (useOptimization ? repository.Events.getUnpaginatedEvents({ regarding: [snapshot.Id] }) : repository.Events.list({ regarding: [snapshot.Id] })), [snapshot, useOptimization], `Load events for ${snapshotType}`);
    const { result: eventCategories } = useQuery(() => repository.Events.categories({}), [], "Load event categories");
    if (error) {
        return (<Callout type={"warning"} title={`There was an error retrieving the history for this ${snapshotType}`}>
                {OctopusError.isOctopusError(error) ? error.ErrorMessage : error.message}
            </Callout>);
    }
    if (!events || !eventCategories) {
        return null;
    }
    return <EventListing data={events} regarding={[snapshot.Id]} eventCategories={eventCategories}/>;
}
