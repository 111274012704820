import { css } from "@emotion/css";
import { Callout, Tooltip } from "@octopusdeploy/design-system-components";
import { fontSize, fontWeight, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { KubernetesObjectStatus } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import React, { useMemo } from "react";
import type { KubernetesStepBaseProps } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepExpander";
import { ObjectStatusIcon } from "~/areas/tasks/components/Task/Kubernetes/ObjectStatusIcon";
import { EmptyState } from "~/areas/tasks/components/Task/Kubernetes/Snapshot/EmptyState";
import { sortObjectStatusByObjectType } from "~/areas/tasks/components/Task/Kubernetes/sortObjectStatusByObjectType";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import SectionHeading from "~/primitiveComponents/dataDisplay/Section/SectionHeading";
const styles = {
    linkStyle: css({
        cursor: "pointer",
    }),
    expandoBodyContainer: css({
        paddingLeft: space[16],
        paddingRight: space[16],
        "@media (min-width @screen-sm)": {
            overflowX: "auto",
        },
    }),
    leftRightContainer: css({
        display: "flex",
        justifyContent: "space-between",
        "> div:last-child": {
            display: "flex",
            justifyContent: "flex-end",
            gap: "10/16rem",
            width: "100%",
        },
        "> div:first-child": {
            display: "flex",
            justifyContent: "flex-start",
            gap: "10/16rem",
            width: "100%",
        },
    }),
    kindContainer: css({
        marginTop: space[16],
        marginBottom: space[16],
        borderLeftWidth: space["1"],
        borderLeftStyle: "solid",
        borderLeftColor: themeTokens.color.border.primary,
        borderRightWidth: space["1"],
        borderRightStyle: "solid",
        borderRightColor: themeTokens.color.border.primary,
        "@media (min-width: @screen-sm)": {
            minWidth: "800px",
        },
    }),
    sectionHeadingTitle: css({
        fontSize: fontSize["medium"],
        fontWeight: fontWeight["700"],
    }),
};
type KubernetesStepSnapshotStatusProps = Omit<KubernetesStepBaseProps, "stepSummaries" | "stepManifestsQueryResult"> & {
    showObjectDrawer: (kos: KubernetesObjectStatus) => void;
};
export const KubernetesStepSnapshotStatus = (props: KubernetesStepSnapshotStatusProps) => {
    const data = useMemo(() => {
        if (props.stepObjectStatuses) {
            const dict = Object.entries(props.stepObjectStatuses.reduce<Record<string, KubernetesObjectStatus[]>>((prev, current) => {
                if (prev[current.Kind] === undefined) {
                    prev[current.Kind] = [];
                }
                prev[current.Kind].push(current);
                return prev;
            }, {}));
            sortObjectStatusByObjectType(dict);
            return dict;
        }
        return [];
    }, [props.stepObjectStatuses]);
    const renderData = (data: object) => {
        if (Array.isArray(data)) {
            return data.join(", ");
        }
        return data.toString();
    };
    const isUnsuccessfulTask = props.taskState === TaskState.Failed || props.taskState === TaskState.Canceled || props.taskState === TaskState.TimedOut;
    const showResourceStatus = !(props.isAtomicHelmStep && isUnsuccessfulTask);
    return (<div className={styles.expandoBodyContainer}>
            <EmptyState status={props.stepActivity.Status} isResourceStatusEnabled={props.isResourceStatusEnabled} hasData={(props.stepObjectStatuses?.length || 0) > 0}/>
            {!showResourceStatus && (<Callout type={"warning"} title={"Object status unavailable"}>
                    The Helm <code>--atomic</code> flag on this step rolled back to a previous revision. We can’t determine the status accurately because objects from different revisions might share the same names.
                </Callout>)}
            {data.map(([kind, objects]) => {
            const anyHasNamespace = objects.some((kos) => !!kos.Namespace);
            return (<div className={styles.kindContainer} key={kind}>
                        <SectionHeading title={<div className={styles.sectionHeadingTitle}>{kind}</div>}/>
                        <DataTable title={kind}>
                            <DataTableHeader>
                                <DataTableRow>
                                    {showResourceStatus && (<DataTableHeaderColumn aria-label="Status Column" width="10px">
                                            &nbsp;
                                        </DataTableHeaderColumn>)}
                                    <DataTableHeaderColumn width={"20%"}>Name</DataTableHeaderColumn>
                                    {Object.keys(objects[0].Data).map((k) => (<DataTableHeaderColumn key={k} width={"10%"}>
                                            {k}
                                        </DataTableHeaderColumn>))}
                                    <DataTableHeaderColumn>{/* Empty column used for auto alignment */}</DataTableHeaderColumn>
                                    <DataTableHeaderColumn width={anyHasNamespace ? "15%" : "30%"}>Target Name</DataTableHeaderColumn>
                                    {anyHasNamespace && <DataTableHeaderColumn width={"15%"}>Namespace</DataTableHeaderColumn>}
                                </DataTableRow>
                            </DataTableHeader>
                            <DataTableBody>
                                {objects.map((obj) => (<DataTableRow key={`${obj.Name}-${obj.ClusterName}=${obj.Namespace}`}>
                                        {showResourceStatus && (<DataTableRowColumn>
                                                <ObjectStatusIcon objectStatus={obj.Status} stepStatus={props.stepActivity.Status}/>
                                            </DataTableRowColumn>)}
                                        <DataTableRowColumn>
                                            {obj.ManifestId ? (<Tooltip content="View object details">
                                                    <a className={styles.linkStyle} onClick={() => props.showObjectDrawer(obj)}>
                                                        {obj.Name}
                                                    </a>
                                                </Tooltip>) : (<span>{obj.Name}</span>)}
                                        </DataTableRowColumn>
                                        {Object.values(obj.Data).map((v, i) => (<DataTableRowColumn key={i}>{renderData(v)}</DataTableRowColumn>))}
                                        <DataTableRowColumn>{/* Empty column used for auto alignment */}</DataTableRowColumn>
                                        <DataTableRowColumn>{obj.ClusterName}</DataTableRowColumn>
                                        {obj.Namespace ? <DataTableRowColumn>{obj.Namespace}</DataTableRowColumn> : undefined}
                                    </DataTableRow>))}
                            </DataTableBody>
                        </DataTable>
                    </div>);
        })}
        </div>);
};
