import { css } from "@emotion/css";
import { ActionButton, ActionButtonType, Callout, Drawer, IconButton, Tooltip } from "@octopusdeploy/design-system-components";
import { ArrowExpandIcon, DuplicateIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import type { KubernetesObjectStatus } from "@octopusdeploy/octopus-server-client";
import { OctopusError } from "@octopusdeploy/octopus-server-client";
import React, { useMemo, useRef } from "react";
import { SnapshotTaken } from "~/areas/tasks/components/Task/Kubernetes/SnapshotTaken";
import { CodeViewerWithLoading } from "~/areas/tasks/components/Task/Kubernetes/components/CodeViewerWithLoading/CodeViewerWithLoading";
import type { CodeComponent } from "~/components/Code/Code";
type KubernetesObjectDrawerProps = {
    onClose: () => void;
    objectStatus: KubernetesObjectStatus | undefined;
};
const styles = {
    mainContainer: css({
        padding: space[16],
        paddingBottom: space[32],
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        position: "relative",
    }),
    contentHeading: css({
        marginBottom: space[16],
    }),
    heading: css({
        color: themeTokens.color.text.primary,
        font: text.regular.bold.large,
        marginBottom: space[4],
    }),
    codeViewerHeader: css({
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: space[16],
        border: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
        padding: `${space[8]} ${space[12]}`,
        borderTopRightRadius: borderRadius["small"],
        borderTopLeftRadius: borderRadius["small"],
    }),
    codeViewerContainer: css({
        position: "relative",
        border: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
        borderTop: 0,
        borderBottomRightRadius: borderRadius["small"],
        borderBottomLeftRadius: borderRadius["small"],
        //this is required for the outer border radius to properly clip content
        overflow: "auto",
    }),
    bulletStyle: css({
        listStyleType: "disc",
    }),
};
export const KubernetesObjectDrawer = (props: KubernetesObjectDrawerProps) => {
    const { onClose, objectStatus } = props;
    const { result, isLoading, error } = useInlineStatusQuery(async (repo) => (objectStatus?.ManifestId ? repo.KubernetesManifest.getKubernetesManifest(objectStatus.ManifestId) : null), [objectStatus], "Kubernetes Object Manifest by Id");
    const codeRef = useRef<CodeComponent>(null);
    const errorCallout = useMemo(() => {
        if (!error) {
            return null;
        }
        if (OctopusError.isOctopusError(error)) {
            return (<Callout type={"danger"} title={error.ErrorMessage}>
                    <ul className={styles.bulletStyle}>
                        {error.Errors?.map((err) => (<li key={err}>{err}</li>))}
                    </ul>
                </Callout>);
        }
        else {
            return (<Callout type={"danger"} title="Error">
                    {error.message}
                </Callout>);
        }
    }, [error]);
    return (<Drawer variant={"customActions"} isOpen={!!objectStatus} onClose={() => onClose} customActions={[<ActionButton key={`Cancel ${objectStatus?.Name}`} type={ActionButtonType.Secondary} label="Close" accessibleName={`Close ${objectStatus?.Name}}`} onClick={onClose}/>]} title={objectStatus?.Name || ""} subtitle={`${objectStatus?.Kind}${objectStatus?.Namespace ? ` in ${objectStatus.Namespace}` : ""}`}>
            {objectStatus && (<div className={styles.mainContainer}>
                    <div className={styles.contentHeading}>
                        <div className={styles.heading}>Object YAML snapshot</div>
                        <SnapshotTaken date={objectStatus.LastModified}/>
                    </div>
                    {errorCallout || (<>
                            <div className={styles.codeViewerHeader}>
                                <Tooltip content={"Copy to clipboard"}>
                                    <IconButton customIcon={<DuplicateIcon size={20} color={themeTokens.color.icon.primary}/>} onClick={async () => await codeRef.current?.copyToClipboard()}/>
                                </Tooltip>
                                <Tooltip content={"Enter full screen"}>
                                    <IconButton customIcon={<ArrowExpandIcon size={20} color={themeTokens.color.icon.primary}/>} onClick={() => codeRef.current?.showInFullScreen()}/>
                                </Tooltip>
                            </div>
                            <div className={styles.codeViewerContainer}>
                                <CodeViewerWithLoading ref={codeRef} language={"YAML"} value={result?.Resource?.ManifestContent.trimEnd()} showLineNumbers={true} lineWrapping={true} isLoading={isLoading}/>
                            </div>
                        </>)}
                </div>)}
        </Drawer>);
};
