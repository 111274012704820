import { Callout, Tooltip } from "@octopusdeploy/design-system-components";
import { NoteIcon } from "@octopusdeploy/design-system-icons";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ActionProperties, ActionTemplateResource, ActionTemplateSearchResource, DeploymentActionContainer, FeedResource, GitRefResource, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { Permission, HasManualInterventionResponsibleTeams, RunConditionForAction } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import { Section } from "app/components/Section/Section";
import { difference, intersection, isFunction, keyBy, uniq } from "lodash";
import * as React from "react";
import { useMemo, useState } from "react";
import { ContainersFeedbackCallout } from "~/areas/ContainersFeedbackCallout";
import { getProcessTemplateCommitFromAction, getProcessTemplateSlugFromAction } from "~/areas/projects/components/Process/Blueprints/processTemplateId";
import { isMatchingProcessTemplate, isRunOnServerOrWorkerPool, runsOnServer, whereConfiguredToRun } from "~/areas/projects/components/Process/Common/CommonProcessHelpers";
import Environments from "~/areas/projects/components/Process/Common/Environments";
import { ErrorsForAction } from "~/areas/projects/components/Process/Common/ErrorsForAction";
import ExecutionPlan from "~/areas/projects/components/Process/Common/ExecutionPlan";
import { getRollingDeploymentSummary } from "~/areas/projects/components/Process/Common/RollingDeploymentExpander";
import { getRunConditionText } from "~/areas/projects/components/Process/Common/RunTriggerForChildActionExpander";
import { StartConditionText } from "~/areas/projects/components/Process/Common/StartTriggerExpander";
import StepName from "~/areas/projects/components/Process/Common/StepName";
import { roleSummary } from "~/areas/projects/components/Process/Common/TargetRolesFormSection";
import { useProcessBlueprintsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessBlueprintsContextProvider";
import { useProcessContext } from "~/areas/projects/components/Process/Contexts/ProcessContext";
import { useProcessErrorSelectors } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { useFeedsFromContext, useRefreshFeedsFromContext } from "~/areas/projects/components/Process/Contexts/ProcessFeedsContextProvider";
import type { EnvironmentSelection } from "~/areas/projects/components/Process/ProcessActionDetails";
import type { FailedPermissionCheck, ProcessStepActionState, ProcessStepLookupState } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import { ProcessSubPageLayout } from "~/areas/projects/components/Process/ProcessSubPageLayout";
import styles from "~/areas/projects/components/Process/style.module.less";
import type { RunOn, StoredAction } from "~/areas/projects/components/Process/types";
import { EnvironmentOption, ExecutionLocation, isDeploymentOrRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import getActionLogoUrl from "~/areas/projects/components/getActionLogoUrl";
import { usePersistenceSettingsContext } from "~/areas/projects/context/PersistenceSettingsContext";
import { ProjectActionPropertiesEditor } from "~/components/ActionPropertiesEditor/ProjectActionPropertiesEditor";
import { HelmV2DeprecationCallout } from "~/components/Actions/helmChartUpgrade/HelmV2DeprecationCallout";
import pluginRegistry, { type ActionEditProps, type AdditionalActions, type MixedExecutionLocationsConfig } from "~/components/Actions/pluginRegistry";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { ExpandableContainer } from "~/components/Expandable/index";
import { Feature, FeatureToggle } from "~/components/FeatureToggle/index";
import Logo from "~/components/Logo/index";
import Markdown from "~/components/Markdown/index";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TenantTagsList from "~/components/TenantTagsList/TenantTagsList";
import convertPropertyValueResourceToString, { convertPropertyValueResourceToStringOrNull } from "~/components/convertPropertyValueResourceToString";
import { ExpansionButtons, FormSection, FormSectionHeading, UnstructuredFormSection } from "~/components/form/index";
import NameSummaryWithSlug from "~/primitiveComponents/form/Slugs/NameSummaryWithSlug";
import CommonSummaryHelper from "~/utils/CommonSummaryHelper/index";
import ParseHelper from "~/utils/ParseHelper/index";
import { PackageRequirementText } from "./Common/PackageRequirementExpander";
export type ProcessBlueprintActionDetailsProps = {
    blueprintSlug: string;
    blueprintCommit: string;
    blueprintActionId: string;
    containingProcessTemplateActionId: string;
    doBusyTask: DoBusyTask;
    busy: boolean;
    stepLookups: ProcessStepLookupState;
    stepOther: ProcessStepActionState;
    actionTemplates: ActionTemplateSearchResource[];
    gitRefResource: GitRefResource | undefined;
    isTenanted: boolean;
    processVariableSet: VariableSetResource | FailedPermissionCheck;
    libraryVariableSets: LoadedLibraryVariableSets[] | FailedPermissionCheck;
};
function ProcessBlueprintActionDetails({ blueprintSlug, blueprintCommit, blueprintActionId, containingProcessTemplateActionId, doBusyTask, busy, stepLookups, stepOther, actionTemplates, gitRefResource, isTenanted, processVariableSet, libraryVariableSets, }: ProcessBlueprintActionDetailsProps) {
    const processContext = useProcessContext();
    const processIdentifier = processContext.state.processIdentifier;
    const feeds = useFeedsFromContext();
    const refreshFeeds = useRefreshFeedsFromContext();
    const blueprints = useProcessBlueprintsFromContext();
    const [action, setAction] = useState<StoredAction | null>(null);
    const persistenceSettings = usePersistenceSettingsContext();
    const processErrorSelectors = useProcessErrorSelectors();
    const containingProcessTemplateStep = processContext.selectors.getParentStep(containingProcessTemplateActionId);
    const blueprint = blueprints.find((b) => isMatchingProcessTemplate(b, blueprintSlug, blueprintCommit))?.processTemplate;
    const step = blueprint?.Steps.find((s) => s.Actions.some((a) => a.Id === blueprintActionId));
    const containingProcessTemplateAction = processContext.selectors.getActionById(containingProcessTemplateActionId);
    const [environmentOption, setEnvironmentOption] = useState<EnvironmentOption | undefined>(undefined);
    const prefix = useMemo(() => `Octopus.Step[${step?.Name}].Action[${action?.Name}].`, [action?.Name, step?.Name]);
    const existingContainerProperty = containingProcessTemplateAction.Properties[`${prefix}Container`];
    // Memoizing this as ExecutionPlan has a custom state update method that checks for differences in runOn, so we don't want a new reference with the same values
    const runOn = useMemo(() => {
        if (!step || !action) {
            return null;
        }
        const runOn = whereConfiguredToRun(!!step.Properties["Octopus.Action.TargetRoles"], action, stepLookups.availableWorkerPools, action.plugin);
        if (isRunOnServerOrWorkerPool(runOn)) {
            const overriddenContainerProperty = convertPropertyValueResourceToStringOrNull(existingContainerProperty);
            if (overriddenContainerProperty) {
                const overriddenContainer: DeploymentActionContainer = JSON.parse(overriddenContainerProperty);
                return { ...runOn, container: overriddenContainer, runningInContainer: !!overriddenContainer.Image || !!overriddenContainer.GitUrl || !!overriddenContainer.Dockerfile };
            }
        }
        return runOn;
    }, [action, existingContainerProperty, step, stepLookups.availableWorkerPools]);
    const onRunOnChanged = (runOn: RunOn) => {
        // This function currently only handles container image changes
        if (isRunOnServerOrWorkerPool(runOn)) {
            const prefixedProperties: ActionProperties = {
                [`${prefix}Container`]: runOn.container ? JSON.stringify(runOn.container) : null,
            };
            processContext.actions.setActionProperties(containingProcessTemplateActionId, prefixedProperties);
        }
    };
    const mergeEnvironmentPropertiesFromContainingProcessTemplate = (): {
        mergedEnvironments: string[];
        mergedExcludedEnvironments: string[];
        mergedEnvironmentOption: EnvironmentOption;
    } => {
        const overriddenEnvironmentsString = convertPropertyValueResourceToString(containingProcessTemplateAction.Properties[`${prefix}Environments`]);
        const overriddenExcludedEnvironmentsString = convertPropertyValueResourceToString(containingProcessTemplateAction.Properties[`${prefix}ExcludedEnvironments`]);
        const isOverridden = overriddenEnvironmentsString.length > 0 || overriddenExcludedEnvironmentsString.length > 0;
        if (isOverridden) {
            const mergedEnvironmentOption = overriddenEnvironmentsString.length > 0 ? EnvironmentOption.Include : overriddenExcludedEnvironmentsString.length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All;
            const mergedEnvironments = overriddenEnvironmentsString.split(",");
            const mergedExcludedEnvironments = overriddenExcludedEnvironmentsString.split(",");
            return { mergedEnvironments, mergedExcludedEnvironments, mergedEnvironmentOption };
        }
        else {
            const mergedEnvironments = action?.Environments || [];
            const mergedExcludedEnvironments = action?.ExcludedEnvironments || [];
            const mergedEnvironmentOption = mergedEnvironments.length > 0 ? EnvironmentOption.Include : mergedExcludedEnvironments.length > 0 ? EnvironmentOption.Exclude : EnvironmentOption.All;
            return { mergedEnvironments, mergedExcludedEnvironments, mergedEnvironmentOption };
        }
    };
    const { mergedEnvironments, mergedExcludedEnvironments, mergedEnvironmentOption } = mergeEnvironmentPropertiesFromContainingProcessTemplate();
    useDoBusyTaskEffect(doBusyTask, async () => {
        const blueprintAction = step?.Actions.find((a) => a.Id === blueprintActionId);
        if (!step || !blueprintAction) {
            return;
        }
        const plugin = await pluginRegistry.getAction(blueprintAction.ActionType);
        setAction({ ...blueprintAction, ParentId: step?.Id, plugin });
    }, [step, blueprintActionId]);
    const blueprintStepNumberInProcess = useMemo(() => {
        const actionIdInProcess = Object.values(processContext.state.model.actions.byId).find((a) => getProcessTemplateSlugFromAction(a) === blueprintSlug && getProcessTemplateCommitFromAction(a) === blueprintCommit)?.Id;
        const stepIdInProcess = Object.values(processContext.state.model.steps.byId).find((s) => s.ActionIds.some((a) => a === actionIdInProcess))?.Id;
        if (!stepIdInProcess) {
            return null;
        }
        return processContext.state.model.steps.allIds.indexOf(stepIdInProcess) + 1;
    }, [processContext.state.model.actions.byId, processContext.state.model.steps, blueprintSlug, blueprintCommit]);
    if (!blueprint || !step || !blueprintStepNumberInProcess) {
        return (<Section>
                <Callout type={"information"} title={"Blueprint could not be found"}>
                    {<span>The requested blueprint could not be found. Please select from the available steps.</span>}
                </Callout>
            </Section>);
    }
    if (!action || !runOn) {
        return null;
    }
    const setPropertyOnContainingProcessTemplate = (properties: Partial<ActionProperties>, callback?: () => void) => {
        const prefixedProperties: ActionProperties = Object.entries(properties).reduce((acc, [key, value]) => ({ ...acc, [`${prefix}Properties[${key}]`]: value }), {});
        processContext.actions.setActionProperties(containingProcessTemplateActionId, prefixedProperties);
        if (callback) {
            callback();
        }
    };
    const combinePropertiesFromContainingProcessTemplate = (): ActionProperties => {
        const propertiesRegex = /Properties\[(.*)\]$/;
        const unprefixedProperties: ActionProperties = Object.entries(containingProcessTemplateAction.Properties)
            .filter(([key]) => key.startsWith(prefix))
            .reduce((acc, [key, value]) => {
            const match = key.match(propertiesRegex);
            if (match) {
                return { ...acc, [match[1]]: value };
            }
            return acc;
        }, {});
        return { ...action.Properties, ...unprefixedProperties };
    };
    const setWorkerPoolProperties = (workerPoolId: string | null, workerPoolVariable: string | null) => {
        const prefixedProperties: ActionProperties = {
            [`${prefix}WorkerPoolId`]: workerPoolId,
            [`${prefix}WorkerPoolVariable`]: workerPoolVariable,
        };
        processContext.actions.setActionProperties(containingProcessTemplateActionId, prefixedProperties);
    };
    const getWorkerPoolId = (): string | null => convertPropertyValueResourceToStringOrNull(containingProcessTemplateAction.Properties[`${prefix}WorkerPoolId`]);
    const getWorkerPoolVariable = (): string | null => convertPropertyValueResourceToStringOrNull(containingProcessTemplateAction.Properties[`${prefix}WorkerPoolVariable`]);
    const isChildAction = step.Actions.length > 1;
    const stepNumberInBlueprint = blueprint.Steps.map((s) => s.Id).indexOf(step.Id) + 1;
    const actionNumberInBlueprintStep = step.Actions.map((a) => a.Id).indexOf(action.Id) + 1;
    const errors = processErrorSelectors.getProcessTemplateActionErrors(containingProcessTemplateStep.Name, stepNumberInBlueprint - 1, actionNumberInBlueprintStep - 1);
    const topEditSection = action.plugin.editSections.top;
    const defaultEditSection: React.ComponentType<ActionEditProps> = action.plugin.editSections.default;
    const actionEditorAdditionalActions: AdditionalActions = {
        packageAcquisition: {
            stepPackageRequirement: step.PackageRequirement,
            onStepPackageRequirementChanged: noOp,
        },
        stepTargetRoles: convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"]),
        actionType: action.plugin.actionType,
    };
    const runsOnServerOrWorkerPoolCopy = isRunOnServerOrWorkerPool(runOn) ? runOn : null;
    const allAvailableRoles = uniq([...stepLookups.machineRoles, ...ParseHelper.parseCSV(convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"]))]);
    const mixedExecutionLocationsConfig: MixedExecutionLocationsConfig = (action.plugin.mixedExecutionLocations && action.plugin.mixedExecutionLocations()) || { enabled: false };
    const disableInlineExecutionContainers: boolean = action.plugin.disableInlineExecutionContainers || false;
    const shouldRunOnOrOnBehalfOfTargets = runOn.executionLocation === ExecutionLocation.DeploymentTarget || runOn.executionLocation === ExecutionLocation.OctopusServerForRoles || runOn.executionLocation === ExecutionLocation.WorkerPoolForRoles;
    const shouldRenderTargetRolesAndRollingDeploymentExpanders = !mixedExecutionLocationsConfig.enabled && !isChildAction && shouldRunOnOrOnBehalfOfTargets;
    const isKubernetesStep = actionTemplates.filter((template) => template.Categories.includes("Kubernetes")).some((template: ActionTemplateSearchResource) => template.Name === stepOther.actionTypeName);
    const hasManualInterventionResponsibleTeams = HasManualInterventionResponsibleTeams(action);
    const maximumRetries = Number(action.Properties["Octopus.Action.AutoRetry.MaximumCount"] ?? 0);
    const actionExecutionTimeOut = Number(action.Properties["Octopus.Action.ExecutionTimeout.Minutes"] ?? 0);
    const canRunOnWorker = action.plugin.canRunOnWorker ?? true;
    const canBeRetried = action.plugin.canBeRetried ?? true;
    const canRunInContainer = action.plugin.canRunInContainer ?? true;
    const maxParallelism = convertPropertyValueResourceToString(step.Properties["Octopus.Action.MaxParallelism"]);
    let canUseExecutionTimeouts = action.plugin.canUseExecutionTimeouts ?? true;
    if (isFunction(canUseExecutionTimeouts)) {
        canUseExecutionTimeouts = canUseExecutionTimeouts();
    }
    const loadFeeds = async (callback?: (feeds: FeedResource[]) => void) => {
        await doBusyTask(async () => {
            await refreshFeeds();
            if (callback) {
                callback(feeds);
            }
        });
    };
    const getStepTemplateName = (): string => {
        if (isStepTemplate(stepLookups.actionTemplate)) {
            return stepLookups.actionTemplate.Name;
        }
        else if (action.StepPackageVersion) {
            return action.plugin.stepPackage?.name ?? action.ActionType;
        }
        return actionTemplates.find((x) => x.Type === action.ActionType)?.Name ?? "";
    };
    const environmentSelection = (): EnvironmentSelection => {
        const environmentsByKey = keyBy(stepLookups.environments, "Id");
        const knownEnvironments = Object.keys(environmentsByKey);
        const unavailableEnvironments = difference(action.Environments || [], knownEnvironments);
        const unavailableExcludedEnvironments = difference(action.ExcludedEnvironments || [], knownEnvironments);
        const inclusiveEnvironments = intersection(action.Environments || [], knownEnvironments);
        const exclusiveEnvironments = intersection(action.ExcludedEnvironments || [], knownEnvironments);
        return {
            unavailable: unavailableEnvironments,
            unavailableExclusive: unavailableExcludedEnvironments,
            inclusive: inclusiveEnvironments,
            exclusive: exclusiveEnvironments,
        };
    };
    const { unavailableExclusive, unavailable } = environmentSelection();
    const hasHiddenEnvironments = unavailableExclusive.length > 0 || unavailable.length > 0;
    function overrideEnvironments(selection: EnvironmentSelection | null, environmentOption: EnvironmentOption | null) {
        const environments = selection ? uniq((selection.inclusive || []).concat(selection.unavailable)) : [];
        const excludedEnvironments = selection ? uniq((selection.exclusive || []).concat(selection.unavailableExclusive)) : [];
        if (environmentOption && environmentOption !== EnvironmentOption.Include) {
            environments.splice(0);
        }
        if (environmentOption && environmentOption !== EnvironmentOption.Exclude) {
            excludedEnvironments.splice(0);
        }
        const properties: ActionProperties = {
            [`${prefix}Environments`]: environments.toLocaleString(),
            [`${prefix}ExcludedEnvironments`]: excludedEnvironments.toLocaleString(),
        };
        processContext.actions.setActionProperties(containingProcessTemplateActionId, properties);
    }
    function updateEnvironmentOption(selectedEnvironmentOption: EnvironmentOption) {
        overrideEnvironments(environmentSelection(), selectedEnvironmentOption);
        setEnvironmentOption(selectedEnvironmentOption);
    }
    function updateEnvironmentSelection(environmentSelection: EnvironmentSelection) {
        overrideEnvironments(environmentSelection, environmentOption || null);
        setEnvironmentOption(environmentOption);
    }
    if (!environmentOption && action) {
        setEnvironmentOption(mergedEnvironmentOption);
    }
    return (<ExpandableContainer containerKey={blueprintActionId}>
            <ProcessSubPageLayout title={<StepName name={action.Name} number={`${blueprintStepNumberInProcess}.${stepNumberInBlueprint}${isChildAction ? `.${actionNumberInBlueprintStep}` : ""}`} stepType={action.ActionType}/>} titleLogo={action && <Logo url={getActionLogoUrl(action)}/>}>
                <ExpansionButtons containerKey={action.Id} errors={undefined} expandAllOnMount={true}/>
                <ErrorsForAction actionErrors={errors}/>
                {errors.length === 0 ? (<>
                        <ContainersFeedbackCallout onTaskPage={false} actionTypes={[action.ActionType]} action={action}/>
                        <HelmV2DeprecationCallout action={action}/>
                    </>) : null}
                {action.IsDisabled && (<UnstructuredFormSection stretchContent={true}>
                        <Callout type={"warning"} title={"This step is currently disabled"}/>
                    </UnstructuredFormSection>)}
                <FormSection title="Step Name" help={nameSummary(action.Name, action.Slug ?? null, action.Notes)} includeBorder={true}/>
                {mixedExecutionLocationsConfig.enabled && (<>
                        <FormSection help={roleSummary(convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"])).node} title="Target Tags"/>
                        {mixedExecutionLocationsConfig.allLocationsRequireActionContainer && isRunOnServerOrWorkerPool(runOn) && (<FormSection title="Container Image" help={CommonSummaryHelper.actionContainerSummary(runOn.container, feeds, runOn, !isAllowed({ permission: Permission.FeedView, project: isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined, wildcard: true }), processIdentifier.type).node}/>)}
                    </>)}
                {!stepLookups.actionTemplate && topEditSection && (<ProjectActionPropertiesEditor editor={topEditSection} plugin={action.plugin} deploymentActionSlug={action.Slug || ""} isNew={false} doBusyTask={doBusyTask} busy={busy} inputs={action.Inputs} properties={combinePropertiesFromContainingProcessTemplate()} packages={action.Packages} gitDependencies={action.GitDependencies} runOn={runOn} setInputs={noOp} setProperties={(p, i, c) => {
                if (i)
                    return;
                setPropertyOnContainingProcessTemplate(p, c);
            }} setPackages={noOp} setGitDependencies={noOp} additionalActions={actionEditorAdditionalActions} getFieldError={emptyFieldError} errors={undefined} expandedByDefault={true} refreshRunOn={noOp} getProcessResource={processContext.selectors.getProcessResource} projectTriggers={stepLookups.projectTriggers} gitRefResource={gitRefResource} isStepFromProcessTemplate={true}/>)}
                <ExecutionPlan projectId={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined} projectSlug={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectSlug : processIdentifier.blueprintId} gitRef={gitRefResource} expandedByDefault={true} doBusyTask={doBusyTask} executionLocation={action.plugin.executionLocation} runOnServerOrWorkerPoolCopy={runsOnServerOrWorkerPoolCopy} runOn={runOn} onRunOnChanged={onRunOnChanged} targetRoleOption={action.plugin.targetRoleOption(action)} targetRoles={convertPropertyValueResourceToString(step.Properties["Octopus.Action.TargetRoles"])} onTargetRolesChanged={noOp} targetRolesError={""} isChildStep={isChildAction} maxParallelism={maxParallelism} onMaxParallelismChanged={noOp} availableRoles={allAvailableRoles} availableWorkerPools={stepLookups.availableWorkerPools} canRunOnWorker={canRunOnWorker} isBuiltInWorkerEnabled={stepOther.isBuiltInWorkerEnabled} targetWorkerPool={getWorkerPoolId() ?? action.WorkerPoolId} targetWorkerPoolVariable={getWorkerPoolVariable() ?? action.WorkerPoolVariable} onTargetWorkerPoolChanged={(workerPoolId, workerPoolVariable) => setWorkerPoolProperties(workerPoolId, workerPoolVariable)} runsOnServer={runsOnServer(action, action.plugin.executionLocation)} getFieldError={emptyFieldError} feeds={feeds} loadFeeds={loadFeeds} canRunInContainer={canRunInContainer} containerImageRecommendation={undefined} imageNameError={""} analyticsStepEditorDispatch={noOp} mixedExecutionLocationsConfig={mixedExecutionLocationsConfig} disableInlineExecutionContainers={disableInlineExecutionContainers} isKubernetesStep={isKubernetesStep} userOnboarding={null} shouldRenderTargetRolesExpander={shouldRenderTargetRolesAndRollingDeploymentExpanders} stepTemplateName={getStepTemplateName()} processType={processContext.selectors.getProcessType()} isStepFromProcessTemplate={true}/>
                {/*Blueprints don't allow configuring cloud target discovery since they're reliant on project variables. It's also a bit tricky to prevent this from appearing configurable*/}
                {/*{action.plugin.targetDiscoveryCloudConnectionProviders && action.plugin.targetDiscoveryCloudConnectionProviders().length > 0 && stepLookups.projectVariables && (*/}
                {/*    <CloudConnections*/}
                {/*        connectionType={CloudConnectionUsageType.TargetDiscovery}*/}
                {/*        isexpandedByDefault={true}*/}
                {/*        providers={action.plugin.targetDiscoveryCloudConnectionProviders()}*/}
                {/*        sectionErrorKey="Octopus.TargetDiscovery.CloudConnections"*/}
                {/*        sectionHelpText="Configure a cloud connection to enable cloud target discovery"*/}
                {/*        projectVariables={stepLookups.projectVariables}*/}
                {/*        libraryVariableSets={stepLookups.libraryVariableSets}*/}
                {/*        refreshVariables={noOp}*/}
                {/*        saveVariables={(variables) => new Promise(() => variables)} // What would be done here?*/}
                {/*        project={projectContext.state.model}*/}
                {/*        isDefaultBranch={projectContext.state.isDefaultBranch}*/}
                {/*        gitRef={gitRefResource?.CanonicalName}*/}
                {/*        haveVariablesChanged={() => new Promise(() => false)} // Same here*/}
                {/*    />*/}
                {/*)}*/}
                {!stepLookups.actionTemplate && environmentOption && (<div>
                        <ProjectActionPropertiesEditor editor={defaultEditSection} plugin={action.plugin} deploymentActionSlug={action.Slug || ""} isNew={false} doBusyTask={doBusyTask} busy={busy} inputs={action.Inputs} properties={combinePropertiesFromContainingProcessTemplate()} packages={action.Packages} gitDependencies={action.GitDependencies} runOn={runOn} setInputs={noOp} setProperties={(p, i, c) => {
                if (i)
                    return;
                setPropertyOnContainingProcessTemplate(p, c);
            }} setPackages={noOp} setGitDependencies={noOp} additionalActions={actionEditorAdditionalActions} getFieldError={emptyFieldError} errors={undefined} expandedByDefault={true} refreshRunOn={noOp} getProcessResource={processContext.selectors.getProcessResource} projectTriggers={stepLookups.projectTriggers} gitRefResource={gitRefResource} isStepFromProcessTemplate={true}/>

                        {/* TODO: Features aren't editable but making them readonly will take a bit more effort. Hiding them completely on the consumer side. */}
                        {/*{pluginRegistry.hasFeaturesForAction(action.ActionType) && (*/}
                        {/*    <FeatureEditor*/}
                        {/*        plugin={action.plugin}*/}
                        {/*        projectId={isDeploymentOrRunbookProcessIdentifier(processIdentifier) ? processIdentifier.projectId : undefined}*/}
                        {/*        gitRef={gitRefResource}*/}
                        {/*        isNew={false}*/}
                        {/*        doBusyTask={doBusyTask}*/}
                        {/*        busy={busy}*/}
                        {/*        properties={action.Properties}*/}
                        {/*        gitDependencies={action.GitDependencies}*/}
                        {/*        packages={action.Packages}*/}
                        {/*        runOn={runOn}*/}
                        {/*        setProperties={noOp}*/}
                        {/*        setPackages={noOp}*/}
                        {/*        setGitDependencies={noOp}*/}
                        {/*        enabledFeatures={convertPropertyValueResourceToString(action.Properties["Octopus.Action.EnabledFeatures"])}*/}
                        {/*        getFieldError={emptyFieldError}*/}
                        {/*        errors={undefined}*/}
                        {/*        expandedByDefault={true}*/}
                        {/*        openFeaturesElement={undefined}*/}
                        {/*        refreshRunOn={noOp}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {/* TODO: Step templates not supported in process templates yet*/}
                        {/*{stepLookups.actionTemplate && (*/}
                        {/*    <ActionTemplateEditor*/}
                        {/*        actionTemplate={stepLookups.actionTemplate}*/}
                        {/*        process={processContext.state.model}*/}
                        {/*        processIdentifier={processContext.state.processIdentifier}*/}
                        {/*        processType={processContext.selectors.getProcessType()}*/}
                        {/*        actionId={action.Id}*/}
                        {/*        properties={action.Properties}*/}
                        {/*        setProperties={noOp}*/}
                        {/*        setPackages={noOp}*/}
                        {/*        setGitDependencies={noOp}*/}
                        {/*        doBusyTask={doBusyTask}*/}
                        {/*        onActionTemplateUpdate={processContext.actions.refreshFromServer}*/}
                        {/*        gitRefResource={gitRefResource}*/}
                        {/*        processVariableSet={processVariableSet}*/}
                        {/*        libraryVariableSets={libraryVariableSets}*/}
                        {/*        refreshVariables={undefined}*/}
                        {/*    />*/}
                        {/*)}*/}
                        <FormSectionHeading title="Conditions"/>
                        <Environments environmentOption={environmentOption} hasHiddenEnvironments={hasHiddenEnvironments} environments={stepLookups.environments} inclusiveEnvironments={mergedEnvironments} exclusiveEnvironments={mergedExcludedEnvironments} onEnvironmentOptionChanged={(environmentOption) => updateEnvironmentOption(environmentOption)} onInclusiveEnvironmentsChanged={(val) => updateEnvironmentSelection({ ...environmentSelection(), inclusive: val })} onExclusiveEnvironmentsChanged={(val) => updateEnvironmentSelection({ ...environmentSelection(), exclusive: val })} onInclusiveEnvironmentRemoved={noOp} onExclusiveEnvironmentRemoved={noOp} persistenceSettings={persistenceSettings} processType={processIdentifier.type}/>
                        {(mixedExecutionLocationsConfig.enabled || shouldRenderTargetRolesAndRollingDeploymentExpanders) && (<FormSection title="Rolling Deployment" help={getRollingDeploymentSummary(maxParallelism ? maxParallelism.length > 0 : false, maxParallelism).node}/>)}
                        {/*Excluding channel selector*/}
                        <FeatureToggle feature={Feature.MultiTenancy}>
                            {(isTenanted || action.TenantTags.length > 0) && (<FormSection title="Tenants" help={action.TenantTags.length > 0 ? (<span>
                                                This step will only run for tenants tagged with <TenantTagsList tags={action.TenantTags}/>
                                            </span>) : (<span>This step will run for all tenants</span>)}/>)}
                        </FeatureToggle>
                        {!isChildAction && step && <FormSection title="Run Condition" help={getRunConditionText(blueprintStepNumberInProcess === 1 && stepNumberInBlueprint === 1, action.Condition || RunConditionForAction.Success)}/>}
                        {isChildAction && action && <FormSection title="Run Condition" help={getRunConditionText(actionNumberInBlueprintStep === 1, action.Condition || RunConditionForAction.Success)}/>}
                        {stepNumberInBlueprint > 1 && !isChildAction && <FormSection title="Start Trigger" help={StartConditionText[step.StartTrigger]}/>}

                        {/*Originally PackageRequirementExpander - The logic for hiding PackageRequirementExpander is complicated - see processModelSelectors.shouldShowPackageRequirementOptionForAction*/}
                        {/*Always showing it for now since it's not configurable anyway*/}
                        <FormSection title="Package Requirement" help={PackageRequirementText[step.PackageRequirement]}/>

                        <FormSection title="Required" help={action.IsRequired || hasManualInterventionResponsibleTeams ? (<span>
                                        This step is <strong>required</strong> and cannot be skipped
                                    </span>) : (<span>
                                        This step is <strong>not required</strong> and can be skipped
                                    </span>)}/>
                        {canBeRetried && <FormSection title="Retries" help={maximumRetries > 0 ? <span>Upon failure, this step will automatically retry</span> : <span>No</span>}/>}
                        {canUseExecutionTimeouts && <FormSection title="Time out" help={actionExecutionTimeOut > 0 ? <span>This step will timeout after {actionExecutionTimeOut} minutes.</span> : <span>Never</span>}/>}
                    </div>)}
            </ProcessSubPageLayout>
        </ExpandableContainer>);
}
const isStepTemplate = (stepTemplate: ActionTemplateResource | null | FailedPermissionCheck): stepTemplate is ActionTemplateResource => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return stepTemplate !== null && !(stepTemplate as FailedPermissionCheck).type;
};
// Since these steps are mostly readonly there shouldn't be any errors
const emptyFieldError = () => "";
const nameSummary = (stepName: string, stepSlug: string | null, notes: string | null) => (<div className={styles.nameSummaryContainer}>
        <NameSummaryWithSlug name={stepName} slug={stepSlug}/>
        {!!notes && (<Tooltip content={<Markdown markup={notes}/>} position="right">
                <NoteIcon size={24} color={themeTokens.color.icon.secondary}/>
            </Tooltip>)}
    </div>);
export default ProcessBlueprintActionDetails;
