import { css, cx } from "@emotion/css";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { Collapse } from "@material-ui/core";
import { Badge, CircularProgress, IconButton, tableMobileStyles, tableStyles } from "@octopusdeploy/design-system-components";
import { CheckCircleIcon, XmarkCircleIcon } from "@octopusdeploy/design-system-icons";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { TenantsDetailProject, TenantsOverviewTenant, TenantTagSummary } from "@octopusdeploy/octopus-server-client";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Action, useAnalyticDataTableExpansionDispatch } from "~/analytics/Analytics";
import { TenantDataTableAllEnvironmentsCell, TenantDataTableEnvironmentsSummaryCell, TenantDataTableMultipleProjectsEnvironmentsCell } from "~/areas/tenants/components/DataTable/Cells/TenantDataTableEnvironmentsCell";
import { TenantNameCellWithLink } from "~/areas/tenants/components/DataTable/Cells/TenantNameCell";
import { TenantsDataTableProjectCell, TenantsDataTableProjectsCell } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableProjectsCell";
import { TenantsDataTableTagsCell } from "~/areas/tenants/components/DataTable/Cells/TenantsDataTableTagsCell";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
interface TenantsDataTableProps {
    spaceId: string;
    tenants: TenantsOverviewTenant[];
    includeMissingVariables: boolean;
    doBusyTask: DoBusyTask;
    busy: boolean;
}
export default function TenantsDataTable({ doBusyTask, includeMissingVariables, spaceId, tenants, busy }: TenantsDataTableProps) {
    const isLargerThanIpad = useMediaQuery({ query: `(min-width: 811px)` });
    const [expandedTenantId, setExpandedTenantId] = useState<string | null>(null);
    return (<table className={cx(tableStyles, { [tableMobileStyles]: !isLargerThanIpad })}>
            <thead>
                <tr>
                    <th>Tenants ({tenants.length})</th>
                    <th>Tenant tag</th>
                    <th>Project</th>
                    <th>Environment</th>
                </tr>
                {tenants.filter((t) => !t.IsDisabled).length > 0 && (<tr className={statusRowHeadingStyles}>
                        <td colSpan={999}>
                            <div className={statusRowHeadingContainerStyles}>
                                <CheckCircleIcon size={24} color={themeTokens.color.icon.success}/>
                                Enabled&nbsp;
                                <Badge count={tenants.filter((t) => !t.IsDisabled).length} size="small" variant="neutral"/>
                            </div>
                        </td>
                    </tr>)}
            </thead>
            <tbody>
                {tenants
            .filter((t) => !t.IsDisabled)
            .map((tenant) => (<TenantsDataTableRow key={tenant.Id} spaceId={spaceId} tenant={tenant} includeMissingVariables={includeMissingVariables} doBusyTask={doBusyTask} setExpandedTenantId={(tenantId) => setExpandedTenantId(tenantId)} isExpanded={expandedTenantId === tenant.Id} busy={busy}/>))}
                {tenants.filter((t) => t.IsDisabled).length > 0 && (<tr className={statusRowHeadingStyles}>
                        <td colSpan={999}>
                            <div className={statusRowHeadingContainerStyles}>
                                <XmarkCircleIcon size={24} color={themeTokens.color.icon.danger}/>
                                Disabled&nbsp;
                                <Badge count={tenants.filter((t) => t.IsDisabled).length} size="small" variant="neutral"/>
                            </div>
                        </td>
                    </tr>)}
                {tenants
            .filter((t) => t.IsDisabled)
            .map((tenant) => (<TenantsDataTableRow key={tenant.Id} spaceId={spaceId} tenant={tenant} includeMissingVariables={includeMissingVariables} doBusyTask={doBusyTask} setExpandedTenantId={(tenantId) => setExpandedTenantId(tenantId)} isExpanded={expandedTenantId === tenant.Id} busy={busy}/>))}
            </tbody>
        </table>);
}
interface TenantsDataTableRowProps {
    spaceId: string;
    tenant: TenantsOverviewTenant;
    includeMissingVariables: boolean;
    doBusyTask: DoBusyTask;
    setExpandedTenantId: (tenantId: string | null) => void;
    isExpanded: boolean;
    busy: boolean;
}
function TenantsDataTableRow({ spaceId, tenant, includeMissingVariables, doBusyTask, setExpandedTenantId, isExpanded, busy }: TenantsDataTableRowProps) {
    const [details, setDetails] = useState<TenantDetails | null>(null);
    const dispatchAction = useAnalyticDataTableExpansionDispatch();
    function dispatchViewMoreLessAction(category: string) {
        dispatchAction("View More/Less", { resource: "Content", action: Action.View, category });
    }
    async function loadTenantDetails() {
        if (!details) {
            await doBusyTask(async () => {
                const tenantDetail = await repository.Tenants.tenantDetail(tenant.Id, includeMissingVariables);
                setDetails({ allTags: tenantDetail.Tenant.Tags, allProjects: tenantDetail.Tenant.Projects });
            });
        }
    }
    async function onRowExpandCollapse() {
        if (isExpanded) {
            setExpandedTenantId(null);
        }
        else {
            setExpandedTenantId(tenant.Id);
        }
        await loadTenantDetails();
        dispatchViewMoreLessAction("Tenant");
    }
    async function onShowAll(category: string) {
        await loadTenantDetails();
        dispatchViewMoreLessAction(category);
    }
    return <TenantRowInternal spaceId={spaceId} isTenantExpanded={isExpanded} tenant={tenant} details={details} onRowExpandCollapse={onRowExpandCollapse} onShowAll={onShowAll} busy={busy}/>;
}
interface TenantDetails {
    allTags: TenantTagSummary[];
    allProjects: TenantsDetailProject[];
}
interface TenantRowInternalProps {
    spaceId: string;
    tenant: TenantsOverviewTenant;
    details: TenantDetails | null;
    onRowExpandCollapse: () => Promise<void>;
    onShowAll: (category: string) => Promise<void>;
    isTenantExpanded: boolean;
    busy: boolean;
}
function TenantRowInternal({ spaceId, tenant, details, onRowExpandCollapse, onShowAll, isTenantExpanded, busy }: TenantRowInternalProps) {
    const hasMultipleProjects = tenant.Projects.Items.length > 1;
    if (hasMultipleProjects) {
        return (<React.Fragment>
                <MultiProjectTenantParentRow spaceId={spaceId} tenant={tenant} details={details} onRowExpandCollapse={onRowExpandCollapse} isTenantExpanded={isTenantExpanded}/>
                <tr>
                    <td colSpan={4} style={{ padding: 0, borderTop: "none" }}>
                        <Collapse in={isTenantExpanded} timeout={200}>
                            {details ? (<table className={cx(tableStyles, expandedRow)}>
                                    <tbody>
                                        {details.allProjects.slice(1).map((p) => (<MultiProjectTenantChildRow key={p.Id} project={p}/>))}
                                    </tbody>
                                </table>) : busy ? (<div className={cx(loading, expandedRow)}>
                                    <CircularProgress size={"small"}/>
                                </div>) : null}
                        </Collapse>
                    </td>
                </tr>
            </React.Fragment>);
    }
    return <NonExpandableTenantRow spaceId={spaceId} tenant={tenant} details={details} onShowAll={onShowAll}/>;
}
interface NonExpandableTenantRowTenantRowProps {
    spaceId: string;
    tenant: TenantsOverviewTenant;
    details: TenantDetails | null;
    onShowAll: (category: string) => Promise<void>;
}
function NonExpandableTenantRow({ spaceId, tenant, details, onShowAll }: NonExpandableTenantRowTenantRowProps) {
    const project = tenant.Projects.Items[0];
    return (<tr>
            <td className={tenantColumnStyle}>
                <TenantNameCellWithLink spaceId={spaceId} tenantId={tenant.Id} tenantName={tenant.Name} tenantLogoLink={tenant.LogoLink} isDisabled={tenant.IsDisabled}/>
            </td>
            <td className={tagColumnStyle}>
                <TenantsDataTableTagsCell tenantId={tenant.Id} tags={tenant.Tags} allTags={details?.allTags} onShowAll={onShowAll}/>
            </td>
            <td className={projectColumnStyle}>
                <TenantsDataTableProjectCell project={project}/>
            </td>
            <td className={environmentColumnStyle}>
                <TenantDataTableEnvironmentsSummaryCell environments={tenant.Projects.Items[0]?.Environments} allEnvironments={details?.allProjects && details?.allProjects.length > 0 ? details?.allProjects[0].Environments : undefined} hasMissingVariables={tenant.HasMissingVariables} onShowAll={onShowAll}/>
            </td>
        </tr>);
}
interface MultiProjectTenantParentRowProps {
    spaceId: string;
    tenant: TenantsOverviewTenant;
    details: TenantDetails | null;
    project?: TenantsDetailProject;
    onRowExpandCollapse: () => void;
    isTenantExpanded: boolean;
}
function MultiProjectTenantParentRow({ spaceId, tenant, details, onRowExpandCollapse, isTenantExpanded }: MultiProjectTenantParentRowProps) {
    const uniqueEnvironments = new Set(tenant.Projects.Items.flatMap((p) => p.Environments.Items));
    const environments = Array.from(uniqueEnvironments);
    const project = details?.allProjects[0];
    return (<tr className={cx(isTenantExpanded ? expandedRow : undefined)}>
            <td className={tenantColumnStyle}>
                <TenantNameCellWithLink spaceId={spaceId} tenantId={tenant.Id} tenantName={tenant.Name} tenantLogoLink={tenant.LogoLink} isDisabled={tenant.IsDisabled}/>
            </td>
            <td className={tagColumnStyle}>
                <TenantsDataTableTagsCell tenantId={tenant.Id} allTags={details?.allTags} isTenantExpanded={isTenantExpanded} tags={tenant.Tags}/>
            </td>
            <td className={projectColumnStyle}>{project && isTenantExpanded ? <TenantsDataTableProjectCell project={project}/> : <TenantsDataTableProjectsCell projects={tenant.Projects}/>}</td>
            <td className={cx(environmentColumnStyle)}>
                <div className={environmentsCellContainer}>
                    {project && isTenantExpanded ? (<TenantDataTableAllEnvironmentsCell environments={project.Environments} hasMissingVariables={project.HasMissingVariables}/>) : (<TenantDataTableMultipleProjectsEnvironmentsCell hasMissingVariables={tenant.HasMissingVariables} environments={environments}/>)}
                    <IconButton accessibleName={`Toggle expansion for ${tenant.Name}`} icon={isTenantExpanded ? "ChevronUpIcon" : "ChevronDownIcon"} onClick={onRowExpandCollapse}/>
                </div>
            </td>
        </tr>);
}
interface MultiProjectTenantChildRowProps {
    project: TenantsDetailProject;
}
function MultiProjectTenantChildRow({ project }: MultiProjectTenantChildRowProps) {
    return (<tr>
            <td className={cx(noBorderTop, tenantColumnStyle)}></td>
            <td className={cx(noBorderTop, tagColumnStyle)}></td>
            <td className={projectColumnStyle}>
                <TenantsDataTableProjectCell project={project}/>
            </td>
            <td className={environmentColumnStyle}>
                <TenantDataTableAllEnvironmentsCell environments={project.Environments} hasMissingVariables={project.HasMissingVariables}/>
            </td>
        </tr>);
}
const tenantColumnStyle = css({ width: "20%" });
const tagColumnStyle = css({ width: "30%" });
const projectColumnStyle = css({ width: "20%" });
const environmentColumnStyle = css({ width: "30%" });
const loading = css({
    display: "flex",
    justifyContent: "center",
    padding: space[16],
});
const environmentsCellContainer = css({
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
});
const noBorderTop = css({
    borderTop: "0 !important",
});
const expandedRow = css({
    backgroundColor: themeTokens.color.background.secondary.default,
});
const statusRowHeadingStyles = css({
    backgroundColor: themeTokens.color.background.secondary.default,
    color: themeTokens.color.text.secondary,
    "& td": {
        font: text.regular.bold.small,
        textTransform: "uppercase",
        verticalAlign: "middle",
    },
});
const statusRowHeadingContainerStyles = css({
    display: "flex",
    alignItems: "center",
    gap: space["8"],
    marginLeft: space["12"],
});
