/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css } from "@emotion/css";
import type { DesignSystemLinkHref, ExternalLinkMenuItem, InternalLinkMenuItem, MenuTargetAriaAttributes, ShowLinkAsActive, SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { dividerMenuItem, SideNavBarLinkTab, SimpleMenu, useMenuState } from "@octopusdeploy/design-system-components";
import { DesktopIcon, MoonIcon, SunIcon } from "@octopusdeploy/design-system-icons";
import { space } from "@octopusdeploy/design-system-tokens";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import type { LinkEvent } from "@octopusdeploy/portal-routes";
import { links, nameForLink, useDispatchLinkClicked } from "@octopusdeploy/portal-routes";
import { OctoLink } from "@octopusdeploy/utilities";
import type { MouseEventHandler } from "react";
import { useCallback, useMemo } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHiddenFeatures } from "~/areas/configuration/components/FeaturesLayout/useHiddenFeatures";
import { repository, session } from "~/clientInstance";
import { authRoutes } from "~/components/AuthenticationRoutes/authRoutes";
import { useSetThemeType, useThemeType } from "~/components/Theme/useThemePaletteType";
import { Avatar } from "~/primitiveComponents/dataDisplay/Avatar/Avatar";
import type { GlobalState } from "~/store/reducers";
interface ExposedUserAccountMenuProps {
    currentUser: UserResource;
}
export function UserAccountMenu({ currentUser }: ExposedUserAccountMenuProps) {
    const [openMenu, menuState, buttonAriaAttributes] = useMenuState();
    const dispatchLinkClicked = useDispatchLinkClicked();
    const userAccountMenuItems = useUserAccountMenuItems(currentUser, dispatchLinkClicked);
    return (<>
            <OpenUserAccountMenuButton currentUser={currentUser} onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            openMenu(e);
        }} ariaAttributes={buttonAriaAttributes} isMenuOpen={menuState.isOpen}/>
            <SimpleMenu accessibleName={"User account"} items={userAccountMenuItems} menuState={menuState} anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
        }}/>
        </>);
}
interface OpenUserAccountMenuButtonProps {
    onClick: MouseEventHandler;
    currentUser: UserResource;
    ariaAttributes: MenuTargetAriaAttributes;
    isMenuOpen: boolean;
}
function OpenUserAccountMenuButton({ onClick, currentUser, ariaAttributes, isMenuOpen }: OpenUserAccountMenuButtonProps) {
    return (<SideNavBarLinkTab icon={<UserAvatar user={currentUser} size={32}/>} href={links.currentUserRootRedirect.generateUrl()} accessibleName={isMenuOpen ? "Hide user account menu" : "Show user account menu"} showLinkAsActive={"if path partially matches"} onClick={onClick} {...ariaAttributes}/>);
}
function UserAvatar({ user, size }: {
    user: UserResource;
    size: number;
}) {
    return <Avatar avatarLink={user.Links?.Avatar} isService={user?.IsService} size={size}/>;
}
function useUserAccountMenuItems(currentUser: UserResource, dispatchLinkClicked: (name: string, event: LinkEvent) => void): SimpleMenuItem[] {
    const userDisplayNameMenuItem = createUserDisplayNameMenuItem(currentUser);
    const themeMenuItem = useThemeMenuItem();
    const featureFlags = useFeatureFlags();
    const downloadLink = createDownloadLinkMenuItem(dispatchLinkClicked);
    const profileLink = createInternalLinkMenuItem(dispatchLinkClicked, "My profile", links.currentUserRootRedirect.generateUrl(), "never");
    const signOutLink = createInternalLinkMenuItem(dispatchLinkClicked, "Sign out", authRoutes.signOut, "never");
    const recentChangesMenuItem = useRecentChangesMenuItem(dispatchLinkClicked);
    const helpItems = useHelpLinkItems(dispatchLinkClicked);
    return [userDisplayNameMenuItem, dividerMenuItem, downloadLink, recentChangesMenuItem, dividerMenuItem, ...featureFlags, dividerMenuItem, themeMenuItem, dividerMenuItem, profileLink, dividerMenuItem, signOutLink, ...helpItems];
}
function createInternalLinkMenuItem(dispatchLinkClicked: (name: string, event: LinkEvent) => void, label: string, path: DesignSystemLinkHref, showAsActive: ShowLinkAsActive): InternalLinkMenuItem {
    return {
        type: "internal-link",
        label,
        path,
        showAsActive,
        disableImplicitEventTracking: true,
        onClick: () => {
            dispatchLinkClicked(nameForLink(), {
                linkLabel: label,
                documentUrl: typeof path === "string" ? path : path.resolveWithSpaceId(null),
                linkLocation: "Main Navigation",
            });
        },
    };
}
function createExternalLinkMenuItem(dispatchLinkClicked: (name: string, event: LinkEvent) => void, label: string, href: string): ExternalLinkMenuItem {
    return {
        type: "external-link",
        label,
        href,
        onClick: () => {
            dispatchLinkClicked(nameForLink(), {
                linkLabel: label,
                documentUrl: href,
                linkLocation: "Main Navigation",
            });
        },
    };
}
function createUserDisplayNameMenuItem(user: UserResource): SimpleMenuItem {
    return {
        type: "information",
        content: (<div className={userDisplayNameContainerStyles}>
                <UserAvatar user={user} size={40}/>
                {user.DisplayName}
            </div>),
    };
}
const userDisplayNameContainerStyles = css({
    display: "flex",
    gap: space[16],
    alignItems: "center",
});
function createDownloadLinkMenuItem(dispatchLinkClicked: (name: string, event: LinkEvent) => void): SimpleMenuItem {
    const version = repository.getServerInformation().version;
    const [major, minor, ...rest] = version.split(".");
    const build = rest.join(".");
    return createExternalLinkMenuItem(dispatchLinkClicked, `v${major}.${minor} (Build ${build})`, `https://octopus.com/downloads/${version}`);
}
function useThemeMenuItem(): SimpleMenuItem {
    const themeType = useThemeType();
    const setThemeType = useSetThemeType();
    const setPalette = useCallback((theme) => setThemeType(theme), [setThemeType]);
    const isItemSelected = useCallback((theme) => themeType === theme, [themeType]);
    return useMemo(() => ({
        type: "nested-menu",
        label: "Theme",
        children: [
            { type: "icon-button", label: "Light", icon: <SunIcon size={24}/>, autoFocus: false, isSelected: isItemSelected("light"), onClick: () => setPalette("light"), disableClose: true },
            { type: "icon-button", label: "Dark", icon: <MoonIcon size={24}/>, autoFocus: false, isSelected: isItemSelected("dark"), onClick: () => setPalette("dark"), disableClose: true },
            { type: "icon-button", label: "System", icon: <DesktopIcon size={24}/>, autoFocus: false, isSelected: isItemSelected("unassigned"), onClick: () => setPalette("unassigned"), disableClose: true },
        ],
    }), [setPalette, isItemSelected]);
}
function useFeatureFlags(): SimpleMenuItem[] {
    const hiddenFeaturesEnabled = useHiddenFeatures();
    return useMemo(() => {
        if (!hiddenFeaturesEnabled)
            return [];
        const features: SimpleMenuItem[] = [];
        return features;
    }, [hiddenFeaturesEnabled]);
}
function useRecentChangesMenuItem(dispatchLinkClicked: (name: string, event: LinkEvent) => void): SimpleMenuItem {
    const host = "http://octopus.com/releasenotes";
    const installationHistory = session.installationHistory;
    const queryParams = new URLSearchParams();
    const installationsToSend = Math.min(4, installationHistory.length);
    installationHistory.slice(0, installationsToSend).forEach((i) => {
        queryParams.append("versions", i.Version);
        queryParams.append("installedAt", i.InstalledAt);
    });
    return createExternalLinkMenuItem(dispatchLinkClicked, "Release notes", `${host}?${queryParams.toString()}`);
}
function useHelpLinkItems(dispatchLinkClicked: (name: string, event: LinkEvent) => void): SimpleMenuItem[] {
    const isHelpSidebarEnabled = useSelector((state: GlobalState) => state.configurationArea.features.isHelpSidebarEnabled);
    const isNarrowScreen = useMediaQuery({ query: `(max-width: 811px)` });
    const customHelpSidebarSupportLink = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLink);
    const customHelpSidebarSupportLinkLabel = useSelector((state: GlobalState) => state.configurationArea.features && state.configurationArea.features.helpSidebarSupportLinkLabel);
    const helpSupportLink = customHelpSidebarSupportLink || OctoLink.Create("HelpGeneral");
    let helpSupportLinkLabel = "Octopus support";
    // customHelpSidebarSupportLinkLabel requires customHelpSidebarSupportLink to exist otherwise it will use the default Octopus support
    if (customHelpSidebarSupportLink) {
        helpSupportLinkLabel = customHelpSidebarSupportLinkLabel || "Your organization's internal support";
    }
    if (!isHelpSidebarEnabled || isNarrowScreen) {
        return [dividerMenuItem, createExternalLinkMenuItem(dispatchLinkClicked, helpSupportLinkLabel, helpSupportLink)];
    }
    return [];
}
