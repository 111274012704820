/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { ActionButton, ActionButtonType, Checkbox, RadioButtonGroup, RadioButton, Callout } from "@octopusdeploy/design-system-components";
import { UserCircleIcon } from "@octopusdeploy/design-system-icons";
import type { ProjectResource, ChannelResource, DeploymentResource, TagSetResource, DeploymentPromotionTarget, DeploymentPromotionTenant, DeploymentTemplateResource, EnvironmentResource, LatestReleaseResource, ReleaseResource, TagTestResult, TenantResource, } from "@octopusdeploy/octopus-server-client";
import { ProcessType, Permission, TaskState, TenantedDeploymentMode, DeploymentMode } from "@octopusdeploy/octopus-server-client";
import type { GetDeploymentFreezesResponse } from "@octopusdeploy/octopus-server-client/src/resources/deploymentFreezes/getDeploymentFreezesResponse";
import { links } from "@octopusdeploy/portal-routes";
import * as _ from "lodash";
import { compact } from "lodash";
import moment from "moment";
import * as React from "react";
import { repository } from "~/clientInstance";
import { AdvancedTenantsAndTenantTagsSelector } from "~/components/AdvancedTenantSelector/AdvancedTenantSelector";
import { ChipIconWrapper } from "~/components/Chips/ChipIconWrapper";
import { ChipIcon, EnvironmentChip, LookupTenantChip, TenantChip } from "~/components/Chips/index";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import type { DataBaseComponentState, DoBusyTaskOptions } from "~/components/DataBaseComponent/DataBaseComponent";
import FrozenDashboardIcon from "~/components/DeploymentFreezes/FrozenDashboardIcon";
import { FrozenResourceType } from "~/components/DeploymentFreezes/FrozenIcon";
import { LookupResourceChipComponent } from "~/components/LookupResourceChip/LookupResourceChip";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import TenantTagsList from "~/components/TenantTagsList/TenantTagsList";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import UnstructuredFormSection from "~/components/form/Sections/UnstructuredFormSection";
import { expanderActions } from "~/components/form/Sections/reducers/expanders";
import * as tenantTagsets from "~/components/tenantTagsets";
import Select from "~/primitiveComponents/form/Select/Select";
import store from "~/store";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import type { EnvironmentType } from "../EnvironmentType";
import { getTenantsWithFrozenInfo, isTenantFrozen } from "../getActiveFreezes";
import type { EnvironmentSelection, StaticEnvironmentSelection } from "./EnvironmentSelection";
import HelpText from "./HelpText";
import SkipTenantsPanel from "./SkipTenantsPanel";
import styles from "./style.module.less";
type RetryDeploymentGoal = {
    previousDeployment: DeploymentResource;
};
type NewDeploymentGoal = {
    environmentIds?: string[];
    tenantIds?: string[];
    tenantTags?: string[];
};
export type DeploymentGoal = RetryDeploymentGoal | NewDeploymentGoal;
function isNewRun(goal: DeploymentGoal): goal is NewDeploymentGoal {
    return !("previousDeployment" in goal && Boolean(goal.previousDeployment));
}
interface EnvironmentAndTenantSelectorProps {
    project: ProjectResource;
    template: DeploymentTemplateResource;
    channel: ChannelResource;
    tenantedDeploymentMode: TenantedDeploymentMode;
    release: ReleaseResource;
    latestReleases: LatestReleaseResource[];
    allTenants: TenantResource[];
    allEnvironments: EnvironmentResource[];
    goal: DeploymentGoal;
    onSelectionUpdated(environments: EnvironmentSelection[], tenantIds: string[], tenantTagsUsed: boolean, tenantsWithMissingVariables: string[], deploymentMode: DeploymentMode): void;
    onDoingBusyTask(action: () => Promise<any>, options: DoBusyTaskOptions): Promise<boolean>;
}
interface EnvironmentAndTenantSelectorState extends DataBaseComponentState {
    showEnvironmentsInitiallyExpanded: boolean;
    showTenantsInitiallyExpanded: boolean;
    selectedTenantIds: string[];
    selectedTenantTagIds: string[];
    selectedEnvironmentIds: string[];
    availableEnvironmentsForTenantPromotion: DeploymentPromotionTarget[];
    doAnyTenantsMatchProject: boolean;
    doAnyTenantsMatchChannel: boolean;
    availableTenants: TenantResource[];
    resultantTenants: TenantResource[];
    tagSets: TagSetResource[];
    tenantsWhoGotThisReleaseAsCurrent: TenantResource[];
    skipTenantsWhoHaveTheReleaseAsCurrent: boolean;
    showTenantsWhoGotThisReleaseAsCurrent: boolean;
    showSkipPanel: boolean;
    deployToAllAvailableTenants: boolean;
    deployToUnfrozenTenants: boolean;
    deploymentMode: DeploymentMode;
    isTryingAgain: boolean;
    isShowingAdvancedTenantsOptions: boolean;
    environmentType: EnvironmentType;
    tenantsWithMissingVariables: string[];
    deploymentFreezes: GetDeploymentFreezesResponse | undefined;
}
type InitialEnvironmentSelection = {
    environmentType: EnvironmentType;
    environmentIds: string[];
};
class EnvironmentAndTenantSelector extends DataBaseComponent<EnvironmentAndTenantSelectorProps, EnvironmentAndTenantSelectorState> {
    private deployToAllAvailableTenantsWarningThreshold: number = 10;
    private tenantTagTestRaceConditioner = new RequestRaceConditioner();
    constructor(props: EnvironmentAndTenantSelectorProps) {
        super(props);
        const initialEnvironments = this.getInitialEnvironments();
        this.state = {
            showEnvironmentsInitiallyExpanded: initialEnvironments.environmentIds.length === 0,
            showTenantsInitiallyExpanded: false,
            selectedTenantTagIds: [],
            selectedTenantIds: [],
            selectedEnvironmentIds: initialEnvironments.environmentType === "Static" ? initialEnvironments.environmentIds : [],
            availableEnvironmentsForTenantPromotion: [],
            doAnyTenantsMatchProject: false,
            doAnyTenantsMatchChannel: false,
            availableTenants: [],
            resultantTenants: [],
            tagSets: [],
            tenantsWhoGotThisReleaseAsCurrent: null!,
            skipTenantsWhoHaveTheReleaseAsCurrent: true,
            showTenantsWhoGotThisReleaseAsCurrent: false,
            showSkipPanel: true,
            deployToAllAvailableTenants: false,
            deployToUnfrozenTenants: false,
            deploymentMode: DeploymentMode.Untenanted,
            isTryingAgain: false,
            isShowingAdvancedTenantsOptions: false,
            environmentType: initialEnvironments.environmentType,
            tenantsWithMissingVariables: [],
            deploymentFreezes: undefined,
        };
    }
    private async loadMissingTenantVariables(project: ProjectResource, tenantIds: string[], environmentId?: string): Promise<string[]> {
        if (project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return Promise.resolve([]);
        }
        const { TenantsMissingVariables } = await repository.Tenants.missingVariablesBff([project.Id], tenantIds, environmentId ? [environmentId] : []);
        return TenantsMissingVariables.map((t) => t.TenantId);
    }
    getInitialEnvironments(): InitialEnvironmentSelection {
        let environmentIds: string[] = [];
        if (!isNewRun(this.props.goal)) {
            environmentIds = [this.props.goal.previousDeployment.EnvironmentId];
        }
        else {
            environmentIds = this.props.goal.environmentIds ?? [];
        }
        return {
            environmentType: "Static",
            environmentIds,
        };
    }
    async componentDidMount() {
        if (this.props.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            this.notifyParentForSelectionChangedFromState();
            return;
        }
        const [initialTenantIds, initialTenantTags, isTryingAgain] = isNewRun(this.props.goal) ? [this.props.goal.tenantIds ?? [], this.props.goal.tenantTags ?? [], false] : [[this.props.goal.previousDeployment.TenantId!], [], true];
        const availableEnvironmentsForTenantPromotion = this.loadAvailableEnvironmentsForTenant();
        const tagSetsPromise = tenantTagsets.getAll();
        const tenantsWhoMatchProject = this.props.allTenants.filter((tenant) => Object.keys(tenant.ProjectEnvironments).includes(this.props.project.Id)).map((tenant) => tenant.Id);
        const tenantsWhoMatchChannel = this.props.channel.TenantTags.length > 0
            ? this.determineTagsMatchedTenantIds(await repository.Tenants.tagTest([], this.props.channel.TenantTags)).filter((tenantId) => tenantsWhoMatchProject.includes(tenantId))
            : tenantsWhoMatchProject.slice();
        const availableTenants = this.getTenantsWhoCanBePromoted(this.state.selectedEnvironmentIds, this.props.allTenants);
        const selectedTenantIds = compact(initialTenantIds.filter((tenantId) => availableTenants.some((t) => t.Id === tenantId)));
        const canOnlyDeployTenanted = this.canOnlyDeployTenanted();
        // Auto-select the tenant for them if they only have one choice.
        if (selectedTenantIds.length === 0 && canOnlyDeployTenanted && availableTenants.length === 1) {
            selectedTenantIds.push(availableTenants[0].Id);
        }
        const selectedTenantTagIds = canOnlyDeployTenanted && this.props.channel.TenantTags.length > 0 ? (initialTenantTags.length === 0 && initialTenantIds.length === 0 ? this.props.channel.TenantTags : initialTenantTags) : initialTenantTags;
        const tagsMatchedTenants = await this.loadTagMatched(availableTenants, selectedTenantIds, selectedTenantTagIds);
        const tenantsWhoGotThisReleaseAsCurrent = this.getTenantsWhoHaveThisReleaseAsCurrent(tagsMatchedTenants, this.props.latestReleases, this.state.selectedEnvironmentIds, this.props.release.Id);
        // By default, only deploy to those tenants who haven't got this release as their current release,
        // dashboard status will be ignored for try again deployment
        const resultantTenants = isTryingAgain ? tagsMatchedTenants : tagsMatchedTenants.filter((t) => this.getTenantIdsWhoDoNotHaveThisRelease(tagsMatchedTenants, tenantsWhoGotThisReleaseAsCurrent).some((tt) => tt === t.Id));
        const showTenantsInitiallyExpanded = this.recommendedTenantDeploymentOption(selectedTenantTagIds) === DeploymentMode.Tenanted ? true : (initialTenantIds.length > 0 || initialTenantTags.length > 0) && resultantTenants.length === 0;
        if (showTenantsInitiallyExpanded) {
            this.expandTheTenantExpander();
        }
        const deploymentMode = this.recommendedTenantDeploymentOption(selectedTenantTagIds, selectedTenantIds);
        const getDeploymentFreezes = await repository.DeploymentFreezes.list(this.props.project);
        this.setState({
            availableEnvironmentsForTenantPromotion,
            selectedTenantTagIds,
            selectedTenantIds,
            doAnyTenantsMatchChannel: tenantsWhoMatchChannel.length > 0,
            doAnyTenantsMatchProject: tenantsWhoMatchProject.length > 0,
            availableTenants,
            resultantTenants,
            showTenantsInitiallyExpanded,
            tagSets: await tagSetsPromise,
            tenantsWhoGotThisReleaseAsCurrent,
            deploymentMode,
            isTryingAgain,
            deploymentFreezes: getDeploymentFreezes,
        }, () => this.notifyParentForSelectionChangedFromState());
    }
    render() {
        return (<div className={styles.envAndTenantSelectorContainer}>
                {this.props.template && this.props.project && (<div>
                        <ExpandableFormSection title="Environments" errorKey="environments" help={this.areTenantsRequiredForThisProject() ? "Select an environment" : "Select one or many environments"} isExpandedByDefault={this.state.showEnvironmentsInitiallyExpanded} summary={this.buildEnvironmentSummary()}>
                            {this.areTenantsRequiredForThisProject() ? (<Select label="environment" value={this.state.selectedEnvironmentIds[0]} items={this.props.template.PromoteTo.map((e: DeploymentPromotionTarget) => {
                        return { text: e.Name, value: e.Id };
                    })} onChange={(id) => this.onStaticEnvironmentsSelected([id!])}/>) : (this.getStaticEnvironmentMultiSelect())}
                        </ExpandableFormSection>
                        {this.isMultiTenancyEnabledForThisProject() &&
                    (!this.canDeploymentBeTenanted() ? (this.explainLackOfTenantSelection()) : (<ExpandableFormSection title="Tenants" errorKey="tenants" help="Select one or many tenants" summary={this.buildTenantSummary()}>
                                    {!this.canOnlyDeployTenanted() && this.buildTenantedOrUntenantedRadioGroup()}
                                    {this.state.deploymentMode === DeploymentMode.Tenanted && (<div>
                                            <HelpText channel={this.props.channel} allTenants={this.props.allTenants} availableTenants={this.state.availableTenants} tenantsWithMissingVariables={this.state.tenantsWithMissingVariables} tenantedDeploymentMode={this.props.tenantedDeploymentMode} availableEnvironmentsForTenantPromotion={this.state.availableEnvironmentsForTenantPromotion} doAnyTenantsMatchChannel={this.state.doAnyTenantsMatchChannel} doAnyTenantsMatchProject={this.state.doAnyTenantsMatchProject} processType={ProcessType.Deployment}/>
                                            {this.state.availableTenants && this.state.availableTenants.length > 0 && (<div>
                                                    {this.props.channel.TenantTags.length > 0 && (<div className={styles.channelCallout}>
                                                            <Callout type={"information"} title={"Tenant selection limited"}>
                                                                Only the tenants that match the{" "}
                                                                <InternalLink to={links.editChannelPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug, channelId: this.props.channel.Id })}>
                                                                    {this.props.channel.Name}
                                                                </InternalLink>{" "}
                                                                channel settings are available for selection.
                                                            </Callout>
                                                        </div>)}
                                                    {!this.state.isShowingAdvancedTenantsOptions && (<ActionButton type={ActionButtonType.Secondary} label={"Advanced selection options"} onClick={() => this.setState({ isShowingAdvancedTenantsOptions: true })}/>)}
                                                    {this.state.isShowingAdvancedTenantsOptions && (<React.Fragment>
                                                            <Checkbox label={`Include all connected tenants (${this.state.availableTenants.length})`} value={this.state.deployToAllAvailableTenants} onChange={this.toggleAllApplicableTenants}/>
                                                            {(() => {
                                        const unfrozenTenants = this.state.availableTenants.filter((t) => !isTenantFrozen(this.state.deploymentFreezes, this.props.project.Id, this.state.selectedEnvironmentIds, t.Id, moment()));
                                        return (unfrozenTenants.length > 0 &&
                                            unfrozenTenants.length < this.state.availableTenants.length && (<Checkbox label={`Include all unfrozen connected tenants (${unfrozenTenants.length})`} value={this.state.deployToUnfrozenTenants} onChange={this.toggleUnfrozenTenants}/>));
                                    })()}
                                                            {(this.state.deployToAllAvailableTenants || this.state.deployToUnfrozenTenants) && (<Callout type={this.state.availableTenants.length > this.deployToAllAvailableTenantsWarningThreshold ? "warning" : "information"} title={`${this.state.deployToUnfrozenTenants
                                            ? this.state.availableTenants.filter((t) => !isTenantFrozen(this.state.deploymentFreezes, this.props.project.Id, this.state.selectedEnvironmentIds, t.Id, moment())).length
                                            : this.state.availableTenants.length} connected tenants`}>
                                                                    This will deploy to{" "}
                                                                    {this.state.deployToUnfrozenTenants ? (<strong>
                                                                            all unfrozen tenants (
                                                                            {this.state.availableTenants.filter((t) => !isTenantFrozen(this.state.deploymentFreezes, this.props.project.Id, this.state.selectedEnvironmentIds, t.Id, moment())).length})
                                                                        </strong>) : this.state.availableTenants.length > 1 ? (<strong>all {this.state.availableTenants.length} tenants</strong>) : (<strong>1 tenant</strong>)}
                                                                    . Please make sure you review the Preview section below before deploying.
                                                                </Callout>)}
                                                        </React.Fragment>)}
                                                    {!this.state.deployToAllAvailableTenants && !this.state.deployToUnfrozenTenants && (<AdvancedTenantsAndTenantTagsSelector tenants={getTenantsWithFrozenInfo(this.state.availableTenants, this.state.deploymentFreezes, this.props.project.Id, this.state.selectedEnvironmentIds)} selectedTenantIds={this.state.selectedTenantIds} selectedTenantTags={this.state.selectedTenantTagIds} doBusyTask={this.doBusyTask} onChange={this.onTenantsOrTenantTagsSelected} showPreviewButton={true}/>)}
                                                    <SkipTenantsPanel release={this.props.release} selectedEnvironmentIds={this.state.selectedEnvironmentIds} showSkipPanel={this.state.showSkipPanel} tenantsWhoGotThisReleaseAsCurrent={this.state.tenantsWhoGotThisReleaseAsCurrent} showTenantsWhoGotThisReleaseAsCurrent={this.state.showTenantsWhoGotThisReleaseAsCurrent} skipTenantsWhoHaveTheReleaseAsCurrent={this.state.skipTenantsWhoHaveTheReleaseAsCurrent} allEnvironments={this.props.allEnvironments} onTenantFilterRuleChange={this.onTenantFilterRuleChange} onTenantsToggled={() => this.setState((prev) => ({
                                    showTenantsWhoGotThisReleaseAsCurrent: !prev.showTenantsWhoGotThisReleaseAsCurrent,
                                }))}/>
                                                </div>)}
                                        </div>)}
                                </ExpandableFormSection>))}
                    </div>)}
            </div>);
    }
    private getStaticEnvironmentMultiSelect() {
        return <EnvironmentMultiSelect accessibleName="Environments that this release will be deployed to" onChange={this.onStaticEnvironmentsSelected} value={this.state.selectedEnvironmentIds} items={this.props.template.PromoteTo}/>;
    }
    private notifyParentForSelectionChangedFromState(resultantTenantIds?: string[], tenantsWithMissingVariables?: string[]) {
        const environments: EnvironmentSelection[] = [];
        if (this.state.environmentType === "Static") {
            const staticEnvironments = this.state.selectedEnvironmentIds.map<StaticEnvironmentSelection>((environmentId) => {
                return { type: "Static", environmentId: environmentId, name: this.props.allEnvironments.find((e) => e.Id === environmentId)?.Name || "(unknown environment)" };
            });
            environments.push(...staticEnvironments);
        }
        this.notifyParentForSelectionChanged(environments, resultantTenantIds ?? this.state.resultantTenants.map((t) => t.Id), this.state.selectedTenantTagIds.length > 0, tenantsWithMissingVariables ?? []);
    }
    private async setMatchedTenants() {
        await this.props.onDoingBusyTask(async () => {
            await this.tenantTagTestRaceConditioner.avoidStaleResponsesForRequest(this.loadTagMatched(this.state.availableTenants, this.state.selectedTenantIds, this.state.selectedTenantTagIds), async (resultantTenants) => {
                const tenantsWhoGotThisReleaseAsCurrent = this.getTenantsWhoHaveThisReleaseAsCurrent(resultantTenants, this.props.latestReleases, this.state.selectedEnvironmentIds, this.props.release.Id);
                const explicitSelectedTenantsHaveGotThisRelease = resultantTenants.length > 0 ? tenantsWhoGotThisReleaseAsCurrent.some((t) => resultantTenants.some((rt) => rt.Id === t.Id)) : false;
                const selectedTenantIds = this.state.skipTenantsWhoHaveTheReleaseAsCurrent && explicitSelectedTenantsHaveGotThisRelease ? this.getTenantIdsWhoDoNotHaveThisRelease(resultantTenants, tenantsWhoGotThisReleaseAsCurrent) : resultantTenants.map((x) => x.Id);
                let tenantsWithMissingVariables: string[] = [];
                if (selectedTenantIds.length === 0)
                    tenantsWithMissingVariables = [];
                else {
                    tenantsWithMissingVariables =
                        this.state.selectedEnvironmentIds.length === 1
                            ? await this.loadMissingTenantVariables(this.props.project, selectedTenantIds, this.state.selectedEnvironmentIds[0])
                            : await this.loadMissingTenantVariables(this.props.project, selectedTenantIds);
                }
                this.setState({
                    resultantTenants,
                    tenantsWhoGotThisReleaseAsCurrent,
                    skipTenantsWhoHaveTheReleaseAsCurrent: this.state.skipTenantsWhoHaveTheReleaseAsCurrent && explicitSelectedTenantsHaveGotThisRelease,
                    showSkipPanel: explicitSelectedTenantsHaveGotThisRelease,
                }, () => {
                    this.notifyParentForSelectionChangedFromState(selectedTenantIds, tenantsWithMissingVariables);
                });
            });
        }, { preserveCurrentErrors: true });
    }
    private determineTagsMatchedTenantIds(testResult: TagTestResult): string[] {
        return Object.keys(testResult).filter((tenantId) => testResult[tenantId].IsMatched);
    }
    private getTenantsWhoCanBePromoted(selectedEnvironmentIds: string[], allTenants: TenantResource[]) {
        if (selectedEnvironmentIds.length !== 1) {
            return [];
        }
        // Check whether the tenants can be promote to the selected environment
        const matchedTenants = this.props.template.TenantPromotions.filter((tenantPromotion: DeploymentPromotionTenant) => tenantPromotion.PromoteTo.some((p) => p.Id === selectedEnvironmentIds[0]));
        return allTenants.filter((t) => !t.IsDisabled).filter((t) => matchedTenants.some((dt) => dt.Id === t.Id));
    }
    private loadAvailableEnvironmentsForTenant() {
        // the connected environments for tenant this release can be deployed to
        const availableEnvironmentsForTenantPromotion = _.uniqBy(_.flatten(this.props.template.TenantPromotions.map((tenantPromotion: DeploymentPromotionTenant) => tenantPromotion.PromoteTo)), (promoteTo: DeploymentPromotionTarget) => promoteTo.Id);
        return availableEnvironmentsForTenantPromotion;
    }
    private onStaticEnvironmentsSelected = (environmentIds: string[]) => {
        if (_.isEqual(this.state.selectedEnvironmentIds, environmentIds)) {
            return;
        }
        const tenantsWhoCanDeploy = this.getTenantsWhoCanBePromoted(environmentIds, this.props.allTenants);
        const availableTenants = this.props.allTenants.filter((t) => tenantsWhoCanDeploy.some((dt) => dt.Id === t.Id));
        const deploymentMode = environmentIds.length === 0 || environmentIds.length > 1 ? this.recommendedTenantDeploymentOption() : this.state.deploymentMode;
        const canOnlyDeployTenanted = this.canOnlyDeployTenanted();
        const tenantTagsIds = canOnlyDeployTenanted ? (this.props.channel.TenantTags.length > 0 ? this.props.channel.TenantTags : []) : [];
        this.setState({
            selectedEnvironmentIds: environmentIds,
            selectedTenantIds: [],
            selectedTenantTagIds: tenantTagsIds,
            resultantTenants: [],
            availableTenants,
            deploymentMode,
            deployToAllAvailableTenants: false,
            deployToUnfrozenTenants: false,
        }, () => {
            canOnlyDeployTenanted ? this.setMatchedTenants() : this.notifyParentForSelectionChangedFromState();
        });
    };
    private onTenantsOrTenantTagsSelected = (tenantIds: string[], tenantTags: string[]) => {
        const tenantsGotThisRelease = this.getTenantsWhoHaveThisReleaseAsCurrent(this.state.availableTenants, this.props.latestReleases, this.state.selectedEnvironmentIds, this.props.release.Id);
        const explicitSelectedTenantsHaveGotThisRelease = tenantIds.length > 0 ? tenantsGotThisRelease.some((t) => tenantIds.includes(t.Id)) : false;
        this.setState({
            selectedTenantTagIds: tenantTags,
            selectedTenantIds: tenantIds,
            skipTenantsWhoHaveTheReleaseAsCurrent: explicitSelectedTenantsHaveGotThisRelease || tenantTags.length > 0,
            showSkipPanel: explicitSelectedTenantsHaveGotThisRelease || tenantTags.length > 0,
        }, this.setMatchedTenants);
    };
    private async loadTagMatched(availableTenants: TenantResource[], selectedTenantIds: string[], selectedTenantTagIds: string[]): Promise<TenantResource[]> {
        if (selectedTenantIds.length === 0 && selectedTenantTagIds.length === 0) {
            return [];
        }
        const matchedTenantIds = selectedTenantTagIds.length === 0 ? selectedTenantIds : this.determineTagsMatchedTenantIds(await repository.Tenants.tagTest(selectedTenantIds || [], selectedTenantTagIds));
        return availableTenants.filter((t) => matchedTenantIds.includes(t.Id));
    }
    private onTenantFilterRuleChange = (value: boolean) => {
        this.setState({ skipTenantsWhoHaveTheReleaseAsCurrent: value }, this.setMatchedTenants);
    };
    private buildTenantedOrUntenantedRadioGroup = () => {
        return (<RadioButtonGroup value={this.state.deploymentMode} onChange={this.onDeploymentModeChanged} autoFocus>
                <RadioButton value={DeploymentMode.Untenanted} label="Untenanted"/>
                <RadioButton value={DeploymentMode.Tenanted} label="Tenanted"/>
            </RadioButtonGroup>);
    };
    private onDeploymentModeChanged = (deploymentMode: DeploymentMode) => {
        this.setState((prev) => ({
            deploymentMode,
            deployToAllAvailableTenants: deploymentMode === DeploymentMode.Untenanted ? false : prev.deployToAllAvailableTenants,
            deployToUnfrozenTenants: deploymentMode === DeploymentMode.Untenanted ? false : prev.deployToUnfrozenTenants,
        }), () => {
            if (deploymentMode === DeploymentMode.Untenanted) {
                this.onTenantsOrTenantTagsSelected([], []);
            }
            else {
                const tagIds = this.props.channel.TenantTags && this.props.channel.TenantTags.length > 0 && this.state.selectedTenantTagIds.length === 0 ? this.props.channel.TenantTags : this.state.selectedTenantTagIds;
                this.onTenantsOrTenantTagsSelected(this.state.selectedTenantIds, tagIds);
            }
        });
    };
    private toggleUnfrozenTenants = (selectUnfrozen: boolean) => {
        if (selectUnfrozen) {
            const unfrozenTenants = this.state.availableTenants.filter((t) => !isTenantFrozen(this.state.deploymentFreezes, this.props.project.Id, this.state.selectedEnvironmentIds, t.Id, moment()));
            this.setState({
                selectedTenantIds: unfrozenTenants.map((x) => x.Id),
                deployToUnfrozenTenants: selectUnfrozen,
                deployToAllAvailableTenants: false,
                skipTenantsWhoHaveTheReleaseAsCurrent: true,
                showSkipPanel: true,
            }, this.setMatchedTenants);
        }
        else {
            this.setState({
                selectedTenantIds: [],
                selectedTenantTagIds: [],
                deployToUnfrozenTenants: selectUnfrozen,
            }, this.setMatchedTenants);
        }
    };
    private toggleAllApplicableTenants = (selectAll: boolean) => {
        selectAll
            ? this.setState({
                selectedTenantIds: this.state.availableTenants.map((x) => x.Id),
                deployToAllAvailableTenants: selectAll,
                deployToUnfrozenTenants: false,
                skipTenantsWhoHaveTheReleaseAsCurrent: true,
                showSkipPanel: true,
            }, this.setMatchedTenants)
            : this.setState({
                selectedTenantIds: [],
                selectedTenantTagIds: [],
                deployToAllAvailableTenants: selectAll,
            }, this.setMatchedTenants);
    };
    private getTenantIdsWhoDoNotHaveThisRelease = (tenantsToFilter: TenantResource[], tenantsWhoGotThisReleaseAsCurrent: TenantResource[]) => {
        return tenantsToFilter.filter((t) => !tenantsWhoGotThisReleaseAsCurrent.some((tt) => tt.Id === t.Id)).map((x) => x.Id);
    };
    private getTenantsWhoHaveThisReleaseAsCurrent(tenantsToBeFilter: TenantResource[], latestReleases: LatestReleaseResource[], selectedEnvironmentIds: string[], releaseId: string) {
        return selectedEnvironmentIds.length === 1
            ? tenantsToBeFilter.filter((t) => {
                return latestReleases.some((d) => d.TenantId === t.Id && d.EnvironmentId === selectedEnvironmentIds[0] && d.ReleaseId === releaseId && d.ReleaseVersion === this.props.release.Version && d.State === TaskState.Success);
            })
            : [];
    }
    private expandTheTenantExpander = () => {
        store.dispatch(expanderActions.onExpanderStateChanged({ containerKey: "", key: "tenants", expanded: true }));
    };
    private notifyParentForSelectionChanged = (environments: EnvironmentSelection[], tenantIds: string[], tenantTagsUsed: boolean, tenantsWithMissingVariables: string[]) => {
        if (this.state.deploymentMode === DeploymentMode.Tenanted && tenantIds.length === 0) {
            // Tenanted deployment but no tenants, should not fall back to environment
            this.props.onSelectionUpdated([], [], tenantTagsUsed, tenantsWithMissingVariables, this.state.deploymentMode);
        }
        else {
            this.props.onSelectionUpdated(environments, tenantIds, tenantTagsUsed, tenantsWithMissingVariables, this.state.deploymentMode);
        }
    };
    private canDeploymentBeTenanted = (): boolean => {
        return this.state.selectedEnvironmentIds.length === 1;
    };
    private canOnlyDeployTenanted = (): boolean => {
        const userCanNotPerformUntenantedDeployments = !isAllowed({ permission: Permission.DeploymentCreate, environment: "*", project: this.props.project.Id });
        return this.props.project.TenantedDeploymentMode === TenantedDeploymentMode.Tenanted || userCanNotPerformUntenantedDeployments;
    };
    private recommendedTenantDeploymentOption = (selectedTenantTagIds: string[] = [], selectedTenantIds: string[] = []): DeploymentMode => {
        return selectedTenantTagIds.length > 0 || selectedTenantIds.length > 0 || this.canOnlyDeployTenanted() ? DeploymentMode.Tenanted : DeploymentMode.Untenanted;
    };
    private isMultiTenancyEnabledForThisProject = (): boolean => {
        return this.props.tenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted || this.props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted;
    };
    private buildTenantSummary = () => {
        const tagChips = <TenantTagsList tags={this.state.selectedTenantTagIds}/>;
        const onlyTenantsSelected = () => {
            const tenantChips = this.state.resultantTenants && this.state.resultantTenants.length > 0
                ? this.state.resultantTenants.map((tenant) => (<TenantChip onRequestDelete={() => { }} deleteButtonAccessibleName={`Delete ${tenant.Name}`} tenantName={tenant.Name} key={tenant.Id} isDisabled={!!tenant.IsDisabled} icon={isTenantFrozen(this.state.deploymentFreezes, this.props.project.Id, this.state.selectedEnvironmentIds, tenant.Id, moment()) ? (<FrozenDashboardIcon for={FrozenResourceType.Tenant}/>) : (<ChipIconWrapper>
                                          <UserCircleIcon size={16}/>
                                      </ChipIconWrapper>)}/>))
                : [];
            return Summary.summary(<div className={styles.envAndTenantSummary}>{tenantChips}</div>);
        };
        const onlyTenantTagsSelected = () => {
            return Summary.summary(<div className={styles.envAndTenantSummary}>Tenants tagged with {tagChips}</div>);
        };
        const bothTenantsAndTagsSelected = () => {
            const tenantChips = this.state.selectedTenantIds.map((id) => <LookupTenantChip lookupTenants={this.props.allTenants} id={id}/>);
            return Summary.summary(<div className={styles.envAndTenantSummary}>
                    {tenantChips} and tenants tagged with {tagChips}
                </div>);
        };
        if (this.state.selectedTenantTagIds.length === 0 && this.state.selectedTenantIds.length > 0) {
            return onlyTenantsSelected();
        }
        if (this.state.selectedTenantTagIds.length > 0 && this.state.selectedTenantIds.length === 0) {
            return onlyTenantTagsSelected();
        }
        if (this.state.selectedTenantTagIds.length > 0 && this.state.selectedTenantIds.length > 0) {
            return bothTenantsAndTagsSelected();
        }
        return Summary.placeholder("No tenants are selected");
    };
    private buildEnvironmentSummary = () => {
        const LookupStaticEnvironmentChip = LookupResourceChipComponent<DeploymentPromotionTarget>();
        const staticEnvironmentChips = this.state.selectedEnvironmentIds
            ? this.state.selectedEnvironmentIds.map((envId) => (<LookupStaticEnvironmentChip lookupCollection={this.props.template.PromoteTo} key={envId} lookupId={envId} type={ChipIcon.Environment} chipRender={(item) => <EnvironmentChip environmentName={item.Name}/>}/>))
            : [];
        if (staticEnvironmentChips.length > 0) {
            return Summary.summary(<div className={styles.envAndTenantSummary}>{staticEnvironmentChips}</div>);
        }
        return Summary.placeholder("No environments are selected");
    };
    private explainLackOfTenantSelection() {
        const selectedEnvironmentCount = this.state.selectedEnvironmentIds.length;
        return (<UnstructuredFormSection>
                <Callout type={"information"} title={<span>
                            {selectedEnvironmentCount > 1 && "Tenant selection is only available for deployments targeting a single environment."}
                            {selectedEnvironmentCount === 0 && "Tenant selection requires an environment."}
                        </span>}/>
            </UnstructuredFormSection>);
    }
    private areTenantsRequiredForThisProject() {
        return this.props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted;
    }
    static displayName = "EnvironmentAndTenantSelector";
}
export default EnvironmentAndTenantSelector;
