import { css } from "@emotion/css";
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { ListItemLink, ListItemContent, Pagination, SearchInput, List, NoSearchResults } from "@octopusdeploy/design-system-components";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useQuery } from "@octopusdeploy/octopus-react-client";
import type { TagSetResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links, type QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { sortBy } from "lodash";
import { useCallback } from "react";
import * as React from "react";
import useIsMultiTenancyEnabledFeatureFlag from "~/areas/configuration/hooks/useIsMultiTenancyEnabledFeatureFlag";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import MarkdownDescription from "~/components/MarkdownDescription";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Tag from "~/components/Tag/index";
import { useDebouncedExternalState } from "~/hooks/useDebouncedExternalState";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle";
import Onboarding from "./Onboarding";
import TagSetsSorter from "./TagSetsSorter";
import styles from "./style.module.less";
interface TagSetsPageProps {
    spaceId: string;
    queryParams: TagSetsPageQueryParams;
    setQueryParams: QueryParamValuesSetter<TagSetsPageQueryParams>;
}
const pageTitle = "Tenant Tag Sets";
export interface TagSetsPageQueryParams {
    tagSetName: string;
    page: number | undefined;
    pageSize: number | undefined;
}
export function TagSetsPage({ spaceId, queryParams, setQueryParams }: TagSetsPageProps) {
    const isMultiTenancyEnabled = useIsMultiTenancyEnabledFeatureFlag();
    const sortDialogControls = useDialogTrigger();
    const [debouncedVariableSetName, setDebouncedVariableSetName] = useDebouncedExternalState(queryParams.tagSetName, (value) => setQueryParams({ tagSetName: value }), 200);
    const { result: tagSetsResponse, refetch: reloadTagSets } = usePagedTagSetsQuery(queryParams);
    const onPageChange = useCallback((page: number) => {
        setQueryParams({ ...queryParams, page });
    }, [queryParams, setQueryParams]);
    const onPageSizeChange = useCallback((pageSize: number) => {
        setQueryParams({ ...queryParams, pageSize: pageSize, page: 1 });
    }, [queryParams, setQueryParams]);
    if (!isMultiTenancyEnabled) {
        return <InternalRedirect to={links.tenantsPage.generateUrl({ spaceId })}/>;
    }
    if (!tagSetsResponse) {
        return <PageContent header={{ title: pageTitle }}>{null}</PageContent>;
    }
    const addTagSetPageAction: PrimaryPageAction = {
        type: "navigate",
        hasPermissions: isAllowed({ permission: Permission.TagSetCreate }),
        label: "Add Tag Set",
        path: links.createTagSetPage.generateUrl({ spaceId }),
    };
    if (tagSetsResponse.TotalResults === 0 && !queryParams.tagSetName) {
        return (<PageContent header={{ title: pageTitle, primaryAction: addTagSetPageAction }}>
                <Onboarding />
            </PageContent>);
    }
    const hideBottomPagination = tagSetsResponse.Items.length < 20;
    return (<PageContent pagination={{
            ui: (<Pagination label="Tag Sets" totalResults={tagSetsResponse.TotalResults} itemsPerPageOptions={[30, 50, 100]} selectedItemsPerPage={tagSetsResponse.ItemsPerPage} currentPage={queryParams.page ?? 1} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}/>),
            placement: hideBottomPagination ? "top" : "topAndBottom",
        }} header={{ title: pageTitle, primaryAction: addTagSetPageAction, pageActions: getPageActions(sortDialogControls.openDialog) }} filters={{ inputs: [<SearchInput value={debouncedVariableSetName} onChange={setDebouncedVariableSetName} accessibleName={"Search by tag set name"} placeholder={"Filter by name..."}/>] }}>
            <ContentBorder>
                <Dialog open={sortDialogControls.isOpen}>
                    <TagSetsSorter onSaved={reloadTagSets}/>
                </Dialog>
                <List items={tagSetsResponse.Items} renderRow={({ item }) => <TagSetListItem spaceId={spaceId} tagSet={item}/>} rowKey={(item) => item.Id} empty={<NoSearchResults />}/>
            </ContentBorder>
        </PageContent>);
}
function usePagedTagSetsQuery(queryParams: TagSetsPageQueryParams) {
    return useQuery((repository) => {
        const pageSize = queryParams.pageSize ?? 30;
        return repository.TagSets.list({
            skip: ((queryParams.page ?? 1) - 1) * pageSize,
            take: pageSize,
            partialName: queryParams.tagSetName,
        });
    }, [queryParams.page, queryParams.pageSize, queryParams.tagSetName], "Get paginated tag sets");
}
function getPageActions(openReorderDialogClick: () => void): PageAction[] {
    const sortDialogPageAction: PageAction = {
        type: "button",
        buttonType: "secondary",
        hasPermissions: isAllowed({ permission: Permission.TagSetCreate }),
        label: "Reorder",
        onClick: openReorderDialogClick,
    };
    return [sortDialogPageAction];
}
function TagSetListItem({ tagSet, spaceId }: {
    tagSet: TagSetResource;
    spaceId: string;
}) {
    return (<ListItemLink href={links.editTagSetPage.generateUrl({ spaceId, tagSetId: tagSet.Id })}>
            <ListItemContent>
                <ListTitle>{tagSet.Name}</ListTitle>
                <div className={styles.helpText}>
                    <MarkdownDescription markup={tagSet.Description}/>
                </div>
                <div>
                    {sortBy(tagSet.Tags, (t) => t.SortOrder).map((t) => (<Tag tagName={t.Name} tagColor={t.Color} key={t.Id} description={t.Description} borderColor={themeTokens.color.border.primary}/>))}
                </div>
            </ListItemContent>
        </ListItemLink>);
}
function ContentBorder({ children }: {
    children: React.ReactNode;
}) {
    return <div className={contentBorderStyles}>{children}</div>;
}
const contentBorderStyles = css({
    border: `1px solid ${themeTokens.color.border.primary}`,
    borderRadius: borderRadius.medium,
});
