import { css } from "@emotion/css";
import { text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { LiveStatusConnectionIcon } from "~/areas/projects/components/Observability/LiveStatusConnectionIcon";
export type LiveStatusDescriptionProps = {
    status: string;
};
const DescriptionTextStyle = css({
    color: themeTokens.color.text.secondary,
    font: text.regular.default.xSmall,
    lineHeight: "1.25rem",
    display: "flex",
    flexDirection: "row",
    gap: "2px",
    alignItems: "center",
});
export const LiveStatusDescription = (props: LiveStatusDescriptionProps) => {
    if (props.status === "NotSupported") {
        return <></>;
    }
    return (<span className={DescriptionTextStyle}>
            <LiveStatusConnectionIcon />
            {props.status}
        </span>);
};
