import { css, cx } from "@emotion/css";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Callout, EmptyStateDefaultIllustration, NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { EnvironmentResource, EventCategoryResource, EventGroupResource, MachineFilterResource, ProjectResource, Repository, ResourceCollection, RunbookResource, RunRunbookActionResource, ScopedDeploymentActionResource, TriggerFilterResource, TriggerResource, TriggerScheduleResource, } from "@octopusdeploy/octopus-server-client";
import { Permission, TriggerActionCategory, TriggerFilterType, triggerIdIs } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type * as H from "history";
import * as React from "react";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router";
import RunbooksOnboarding from "~/areas/projects/components/Runbooks/RunbooksOnboarding";
import { TriggerNameAndLink } from "~/areas/projects/components/Triggers/Feed/FeedTriggerDescriptionHelper";
import ScheduledTrigger from "~/areas/projects/components/Triggers/Scheduled/ScheduledTrigger";
import TriggerDescription from "~/areas/projects/components/Triggers/TriggerDescription";
import { TriggerType } from "~/areas/projects/components/Triggers/TriggerType";
import { loadAllTriggerRunbooks } from "~/areas/projects/components/Triggers/loadAllTriggerRunbooks";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { EmptyPage } from "~/components/EmptyPage/EmptyPage";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FilterSearchBox from "~/components/FilterSearchBox";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { PageContent } from "~/components/PageContent/PageContent";
import PagingDataTable from "~/components/PagingDataTable";
import List from "~/components/PagingList";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import Select from "~/primitiveComponents/form/Select/Select";
import type { ProjectPageLoaderContext } from "~/routing/pageRegistrations/ProjectPageRegistration";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ScheduledTriggerDescriptionHelper from "~/utils/ScheduledTriggerDescriptionHelper";
import Trigger from "../../Triggers/Trigger";
export async function runbookTriggersPageLoader(repository: Repository, context: ProjectPageLoaderContext): Promise<RunbookTriggersPageLoaderData> {
    const projectContext = await context.projectContext;
    const { model: project } = projectContext.state;
    const environmentsPromise = repository.Environments.all();
    const categoriesPromise = repository.Events.categories({ appliesTo: "Machine" });
    const groupsPromise = repository.Events.groups({ appliesTo: "Machine" });
    const triggersPromise = repository.Projects.getTriggers(project, 0, 30, undefined, TriggerActionCategory.Runbook);
    const runbooksPromise = loadAllTriggerRunbooks(project);
    return {
        triggersResponse: await triggersPromise,
        environments: await environmentsPromise,
        categories: await categoriesPromise,
        groups: await groupsPromise,
        project,
        runbooksInProject: await runbooksPromise,
    };
}
interface RunbookTriggersPageLoaderData {
    project: ProjectResource;
    triggersResponse: ResourceCollection<TriggerResource>;
    environments: EnvironmentResource[];
    categories: EventCategoryResource[];
    groups: EventGroupResource[];
    runbooksInProject: RunbookResource[];
}
interface RunbookTriggersState extends DataBaseComponentState {
    triggersResponse: ResourceCollection<TriggerResource>;
    open: boolean;
    filter: RunbookTriggersFilter;
    isSearching: boolean;
}
interface RunbookTriggersFilter {
    searchText: string;
    runbookId: string | undefined;
}
interface GlobalConnectedProps {
    isMultiTenancyEnabled?: boolean;
}
type RunbookTriggersInternalProps = {
    history: H.History;
} & RunbookTriggersPageProps & GlobalConnectedProps;
class RunbookTriggersInternal extends DataBaseComponent<RunbookTriggersInternalProps, RunbookTriggersState> {
    constructor(props: RunbookTriggersInternalProps) {
        super(props);
        this.state = {
            triggersResponse: this.props.loaderData.triggersResponse,
            open: false,
            filter: createDefaultFilter(),
            isSearching: false,
        };
    }
    filterTriggers = (_: string, trigger: TriggerResource) => {
        const filter = this.state.filter;
        return ((filter.searchText === "" || (filter.searchText !== "" && trigger.Name.toLowerCase().includes(filter.searchText.toLowerCase()))) &&
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            (!filter.runbookId || (!!filter.runbookId && (trigger.Action as RunRunbookActionResource).RunbookId === filter.runbookId)));
    };
    render() {
        const content = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") ? (<MediaQuery maxWidth={600}>
                {(small) => (<MediaQuery minWidth={901}>
                        {(large) => (<PagingDataTable<TriggerResource> tableClassName={small ? "" : emotionStyles.tableCellHeightHack} headerColumns={this.buildDataTableColumnHeaders(getBreakpoint(small, large))} initialData={this.state.triggersResponse} additionalRequestParams={new Map([["triggerActionCategory", [TriggerActionCategory.Runbook]]])} onRow={(item) => this.buildDataTableColumns(item, getBreakpoint(small, large))} onFilter={this.filterTriggers} onEmpty={() => (<EmptyPage image={<div className={emotionStyles.emptyStateIllustration}>
                                                <EmptyStateDefaultIllustration />
                                            </div>} title={"You have no triggers available yet"} description={"Add a trigger to get started"}/>)}></PagingDataTable>)}
                    </MediaQuery>)}
            </MediaQuery>) : (<List<TriggerResource> initialData={this.state.triggersResponse} additionalRequestParams={new Map([["triggerActionCategory", [TriggerActionCategory.Runbook]]])} onRow={(item) => this.buildTriggerRow(item)} onRowRedirectUrl={(trigger) => links.projectRunbookEditScheduledTriggerPage.generateUrl({ spaceId: trigger.SpaceId, projectSlug: this.props.projectSlug, triggerId: trigger.Id })} onFilter={this.filterTriggers} empty={<NoResults />}/>);
        const hasRunbook = this.props.loaderData.runbooksInProject.length !== 0;
        const showOnboarding = this.props.loaderData.runbooksInProject.length === 0 || this.state.triggersResponse.TotalResults === 0;
        const project = this.props.loaderData.project;
        let primaryAction: PrimaryPageAction | undefined = undefined;
        let primaryButton: React.ReactNode | undefined = undefined;
        if (project) {
            const addScheduledTriggerPageAction: PrimaryPageAction = {
                type: "navigate",
                label: "Add Scheduled Trigger",
                path: links.projectRunbookCreateScheduledTriggerPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
                hasPermissions: isAllowed({
                    permission: Permission.TriggerCreate,
                    project: project.Id,
                }),
            };
            const addScheduledTriggerButton = (<PermissionCheck permission={Permission.TriggerCreate} project={project.Id} tenant="*">
                    <NavigationButton type={NavigationButtonType.Primary} label="Add Scheduled Trigger" href={links.projectRunbookCreateScheduledTriggerPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}/>
                </PermissionCheck>);
            const goToRunbooksButton = (<PermissionCheck permission={Permission.RunbookEdit} project={project.Id} tenant="*">
                    <NavigationButton type={NavigationButtonType.Secondary} label="Go to Runbooks" href={links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug })}/>
                </PermissionCheck>);
            primaryButton = !hasRunbook ? goToRunbooksButton : addScheduledTriggerButton;
            primaryAction = addScheduledTriggerPageAction;
        }
        const showAsFullWidth = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle");
        if (showOnboarding) {
            return (<PageContent header={{ title: runbookTriggersPageTitle }} busy={this.state.busy} errors={this.errors} overrideFullWidth={showAsFullWidth}>
                    <RunbooksOnboarding actionButtons={primaryButton} notification={!hasRunbook ? (<Callout title="Runbook required." type={"information"}>
                                    <PermissionCheck permission={Permission.RunbookEdit} project={project.Id} tenant="*">
                                        Before you can schedule a runbook trigger, you will need to create your runbook.
                                    </PermissionCheck>
                                </Callout>) : undefined}/>
                </PageContent>);
        }
        return (<PageContent header={{ title: runbookTriggersPageTitle, primaryAction }} filters={{
                inputs: [
                    <FilterSearchBox placeholder="Filter by name..." value={this.state.filter.searchText} autoFocus={true} onChange={(searchText) => this.setFilterState({ searchText }, async () => await this.onFilterChange())}/>,
                    <ActionList actions={[
                            <div className={emotionStyles.filterControl}>
                                    <Select label="" placeholder="Filter by Runbook" allowFilter={true} allowClear={true} value={this.state.filter.runbookId} onChange={(x) => this.setFilterState({ runbookId: x }, async () => await this.onFilterChange())} items={this.props.loaderData.runbooksInProject.map((r) => ({ value: r.Id, text: r.Name }))}/>
                                </div>,
                        ]}/>,
                ],
            }} busy={this.state.busy} errors={this.errors} overrideFullWidth={showAsFullWidth}>
                {content}
            </PageContent>);
    }
    async reloadTriggers() {
        const project = this.props.loaderData.project;
        await this.doBusyTask(async () => this.setState({
            triggersResponse: await repository.Projects.getTriggers(project, 0, 30, undefined, TriggerActionCategory.Runbook, this.state.filter.runbookId ? [this.state.filter.runbookId] : undefined, this.state.filter.searchText),
        }));
    }
    private async onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.reloadTriggers();
            this.setState({ isSearching: false });
        });
    }
    private setFilterState<K extends keyof RunbookTriggersFilter>(state: Pick<RunbookTriggersFilter, K>, callback?: () => void) {
        this.setState((prev) => ({
            filter: { ...prev.filter, ...state },
        }), callback);
    }
    private buildTriggerRow(trigger: TriggerResource) {
        const overflowMenuItems = this.getOverflowMenuItems(trigger);
        if (trigger.Filter.FilterType === TriggerFilterType.MachineFilter) {
            return <Trigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} environments={this.props.loaderData.environments} categories={this.props.loaderData.categories} groups={this.props.loaderData.groups}/>;
        }
        return <ScheduledTrigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} runbooks={this.props.loaderData.runbooksInProject} environments={this.props.loaderData.environments} channels={[]}/>;
    }
    private getOverflowMenuItems(trigger: TriggerResource) {
        const triggerEditPermission = { permission: Permission.TriggerEdit, project: this.props.loaderData.project.Id };
        const menuItems = [!triggerIdIs(trigger, "built-in-feed") && OverflowMenuItems.item(trigger.IsDisabled ? "Enable" : "Disable", () => (trigger.IsDisabled ? this.enable(trigger) : this.disable(trigger)), triggerEditPermission)] as const;
        return menuItems.filter((val): val is NonNullable<Exclude<typeof val, false>> => !!val);
    }
    private async enable(trigger: TriggerResource) {
        trigger.IsDisabled = false;
        await this.saveTrigger(trigger);
    }
    private async disable(trigger: TriggerResource) {
        trigger.IsDisabled = true;
        await this.saveTrigger(trigger);
    }
    private async saveTrigger(trigger: TriggerResource) {
        const isSuccess = await this.doBusyTask(async () => {
            await repository.ProjectTriggers.modify(trigger);
            const triggersResponse = await repository.Projects.getTriggers(this.props.loaderData.project, 0, 30, undefined, TriggerActionCategory.Runbook);
            this.setState({
                triggersResponse,
            });
        });
        if (!isSuccess) {
            await this.doBusyTask(async () => this.setState({
                triggersResponse: await repository.Projects.getTriggers(this.props.loaderData.project, 0, 30, undefined, TriggerActionCategory.Runbook),
            }), { preserveCurrentErrors: true, timeOperationOptions: timeOperationOptions.forInitialLoad() });
        }
    }
    private buildDataTableColumnHeaders(screenSize: "sm" | "md" | "lg") {
        const columnHeaders = ["Name"];
        if (screenSize !== "sm") {
            columnHeaders.push("Type");
        }
        if (screenSize === "lg") {
            columnHeaders.push("Trigger action");
        }
        if (screenSize !== "sm") {
            columnHeaders.push("");
        }
        return columnHeaders;
    }
    private buildDataTableColumns(trigger: TriggerResource, screenSize: "sm" | "md" | "lg") {
        const columns = [<TriggerNameAndLink trigger={trigger} projectSlug={this.props.projectSlug}/>];
        if (screenSize !== "sm") {
            columns.push(<TriggerType trigger={trigger}/>);
        }
        if (screenSize === "lg") {
            columns.push(this.buildTriggerDescription(trigger));
        }
        if (screenSize !== "sm") {
            columns.push(this.getOverflowMenu(trigger));
        }
        return columns.map((column) => this.wrapWithStyles(column, trigger));
    }
    private wrapWithStyles(children: React.ReactNode, trigger: TriggerResource) {
        let cellStyle = emotionStyles.verticallyCenter;
        if (trigger.IsDisabled) {
            cellStyle = cx(cellStyle, emotionStyles.disabled);
        }
        return <div className={cellStyle}>{children}</div>;
    }
    private buildTriggerDescription(trigger: TriggerResource) {
        if (isMachineFilterResource(trigger.Filter)) {
            const filter = trigger.Filter;
            const description = new TriggerDescription(filter, this.props.loaderData.environments, this.props.loaderData.categories, this.props.loaderData.groups);
            return description.buildDescription();
        }
        const scheduleDescription = isScheduleTriggerResource(trigger.Filter) ? ScheduledTriggerDescriptionHelper.getScheduleDescription(trigger.Filter) : undefined;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const actionDescription = ScheduledTriggerDescriptionHelper.getActionDescription(trigger.Action as ScopedDeploymentActionResource, this.props.loaderData.environments, this.props.loaderData.runbooksInProject, []);
        return (<span>
                {actionDescription}
                {scheduleDescription}.
            </span>);
    }
    private getOverflowMenu(trigger: TriggerResource) {
        return <OverflowMenu menuItems={this.getOverflowMenuItems(trigger)}/>;
    }
    static displayName = "RunbookTriggersInternal";
}
function isMachineFilterResource(triggerFilter: TriggerFilterResource): triggerFilter is MachineFilterResource {
    return triggerFilter.FilterType === TriggerFilterType.MachineFilter;
}
function isScheduleTriggerResource(triggerFilter: TriggerFilterResource): triggerFilter is TriggerScheduleResource {
    switch (triggerFilter.FilterType) {
        case TriggerFilterType.OnceDailySchedule:
        case TriggerFilterType.ContinuousDailySchedule:
        case TriggerFilterType.DaysPerMonthSchedule:
        case TriggerFilterType.CronExpressionSchedule:
            return true;
    }
    return false;
}
function createDefaultFilter(): RunbookTriggersFilter {
    return {
        searchText: "",
        runbookId: "",
    };
}
interface RunbookTriggersPageProps {
    spaceId: string;
    projectSlug: string;
    loaderData: RunbookTriggersPageLoaderData;
}
export const RunbookTriggersPage: React.FC<RunbookTriggersPageProps> = (props) => {
    const isMultiTenancyEnabled = useSelector(isMultiTenancyEnabledSelector);
    const history = useHistory();
    return <RunbookTriggersInternal history={history} isMultiTenancyEnabled={isMultiTenancyEnabled} {...props}/>;
};
RunbookTriggersPage.displayName = "RunbookTriggersPage"
const isMultiTenancyEnabledSelector = (state: GlobalState) => state.configurationArea.currentSpace.isMultiTenancyEnabled;
const getBreakpoint = (small: boolean, large: boolean) => {
    if (small)
        return "sm";
    if (large)
        return "lg";
    return "md";
};
const emotionStyles = {
    // This is required so we can set the height of a cell to 100% so we can center the content
    tableCellHeightHack: css({ height: "1px" }),
    verticallyCenter: css({ height: "100%", display: "flex", alignItems: "center" }),
    disabled: css({ color: themeTokens.color.text.disabled }),
    emptyStateIllustration: css({ height: 300 }),
    filterControl: css({ width: `${240 / 16}rem!important` }),
};
export const runbookTriggersPageTitle = "Runbook Triggers";
