import { css } from "@emotion/css";
import { Callout, Tooltip } from "@octopusdeploy/design-system-components";
import type { ActivityElement, KubernetesObjectStatus, KubernetesStepStatus } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { LastUpdateMessage } from "~/areas/tasks/components/Task/K8sStatus/LastUpdateMessage";
import type { StepWithKubernetesAction } from "~/areas/tasks/components/Task/K8sStatus/StepWithKubernetesAction";
import { KubernetesObjectDrawer } from "~/areas/tasks/components/Task/Kubernetes/KubernetesObjectDrawer";
import { ObjectStatusIcon } from "~/areas/tasks/components/Task/Kubernetes/ObjectStatusIcon";
import { TaskStatusIcon } from "~/areas/tasks/components/Task/Kubernetes/TaskStatusIcon";
import { sortObjectStatusByObjectType } from "~/areas/tasks/components/Task/Kubernetes/sortObjectStatusByObjectType";
import { useKubernetesObjectManifestInspection } from "~/areas/tasks/components/Task/Kubernetes/useKubernetesObjectManifestInspection";
import SimpleExpander from "~/components/SimpleExpander/index";
import { Note } from "~/components/form/index";
import { DataTable, DataTableBody, DataTableHeader, DataTableHeaderColumn, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/index";
import SectionHeading from "~/primitiveComponents/dataDisplay/Section/SectionHeading";
import { EmptyState } from "../Kubernetes/Snapshot/EmptyState";
import type { StepFilter } from "./KubernetesDeploymentStatus";
import { clusterScopedNamespaceFilter } from "./KubernetesDeploymentStatus";
import styles from "./style.module.less";
type KubernetesStepStatusExpanderProps = StepWithKubernetesAction & {
    status: KubernetesStepStatus | undefined;
    stepActivity: Pick<ActivityElement, "Status" | "Ended" | "Started">;
    filter: StepFilter;
    taskState?: TaskState;
};
type DataDictionary = {
    [key: string]: KubernetesObjectStatus[];
};
const linkStyle = css({
    cursor: "pointer",
});
function KubernetesStepStatusExpander(props: KubernetesStepStatusExpanderProps) {
    const [objectStatusInDrawer, setObjectStatusInDrawer] = useState<KubernetesObjectStatus | undefined>(undefined);
    const manifestInspectionEnabled = useKubernetesObjectManifestInspection();
    function applyFilter(objects: KubernetesObjectStatus[], filter: StepFilter) {
        return objects.filter((o) => {
            let showTargetNameFilter = true;
            let showNamespaceFilter = true;
            let nameFilter = true;
            if (filter.targetName && filter.targetName !== o.ClusterName) {
                showTargetNameFilter = false;
            }
            if (filter.namespace) {
                showNamespaceFilter = filter.namespace === (o.Namespace || clusterScopedNamespaceFilter);
            }
            if (filter.name && !o.Name.toLocaleLowerCase().includes(filter.name.toLocaleLowerCase())) {
                nameFilter = false;
            }
            return showTargetNameFilter && showNamespaceFilter && nameFilter;
        });
    }
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    function renderData(data: any) {
        if (Array.isArray(data)) {
            return data.join(", ");
        }
        return data.toString();
    }
    let data: [
        string,
        KubernetesObjectStatus[]
    ][] = [];
    const totalResults = props.status?.KubernetesObjects.length || 0;
    let totalFilteredResult = totalResults;
    if (props.status) {
        const filtererResults = applyFilter(props.status.KubernetesObjects, props.filter);
        totalFilteredResult = filtererResults.length;
        data = Object.entries(filtererResults.reduce<DataDictionary>((prev, current) => {
            if (prev[current.Kind] === undefined) {
                prev[current.Kind] = [];
            }
            prev[current.Kind].push(current);
            return prev;
        }, {}));
        sortObjectStatusByObjectType(data);
    }
    const isUnsuccessfulTask = props.taskState === TaskState.Failed || props.taskState === TaskState.Canceled || props.taskState === TaskState.TimedOut;
    const showResourceStatus = !(props.isAtomicHelmStep && isUnsuccessfulTask);
    return (<>
            <SimpleExpander key={props.stepName} accessibleName={props.stepName} title={<div className={styles.stepNameContainer}>
                        <TaskStatusIcon status={props.stepActivity.Status}/> <span className={styles.stepName}>{props.stepName}</span>
                    </div>} errorKey={props.stepName}>
                <div className={styles.expandoBodyContainer}>
                    {props.status && (<div className={styles.leftRightContainer}>
                            <div aria-label="Last Updated">
                                <Note>
                                    <LastUpdateMessage activityElement={props.stepActivity} lastObjectStatusUpdate={props.status.LastUpdated}/>
                                </Note>
                            </div>
                            {totalResults > totalFilteredResult && (<div aria-label="Filter Counter">
                                    <Note>
                                        {totalFilteredResult} of {totalResults} objects match your filters
                                    </Note>
                                </div>)}
                        </div>)}
                    <EmptyState status={props.stepActivity.Status} isResourceStatusEnabled={props.isResourceStatusEnabled} hasData={totalResults > 0}/>
                    {!showResourceStatus && (<Callout type={"warning"} title={"Object status unavailable"}>
                            The Helm <code>--atomic</code> flag on this step rolled back to a previous revision. We can’t determine the status accurately because objects from different revisions might share the same names.
                        </Callout>)}
                    {data.map(([kind, objects]) => {
            const anyHasNamespace = objects.some((kos) => !!kos.Namespace);
            return (<div className={styles.kindContainer} key={kind}>
                                <SectionHeading title={<div className={styles.sectionHeadingTitle}>{kind}</div>}/>
                                <DataTable title={kind}>
                                    <DataTableHeader>
                                        <DataTableRow>
                                            {showResourceStatus && (<DataTableHeaderColumn aria-label="Status Column" width="10px">
                                                    &nbsp;
                                                </DataTableHeaderColumn>)}
                                            <DataTableHeaderColumn width={"20%"}>Name</DataTableHeaderColumn>
                                            {Object.keys(objects[0].Data).map((k) => (<DataTableHeaderColumn key={k} width={"10%"}>
                                                    {k}
                                                </DataTableHeaderColumn>))}
                                            <DataTableHeaderColumn>{/* Empty column used for auto alignment */}</DataTableHeaderColumn>
                                            <DataTableHeaderColumn width={anyHasNamespace ? "15%" : "30%"}>Target Name</DataTableHeaderColumn>
                                            {anyHasNamespace && <DataTableHeaderColumn width={"15%"}>Namespace</DataTableHeaderColumn>}
                                        </DataTableRow>
                                    </DataTableHeader>
                                    <DataTableBody>
                                        {objects.map((obj) => (<DataTableRow key={`${obj.Name}-${obj.ClusterName}=${obj.Namespace}`}>
                                                {showResourceStatus && (<DataTableRowColumn>
                                                        <ObjectStatusIcon objectStatus={obj.Status} stepStatus={props.stepActivity.Status}/>
                                                    </DataTableRowColumn>)}
                                                <DataTableRowColumn>
                                                    {manifestInspectionEnabled && obj.ManifestId ? (<Tooltip content="View object details">
                                                            <a className={linkStyle} onClick={() => setObjectStatusInDrawer(obj)}>
                                                                {obj.Name}
                                                            </a>
                                                        </Tooltip>) : (<span>{obj.Name}</span>)}
                                                </DataTableRowColumn>
                                                {Object.values(obj.Data).map((v, i) => (<DataTableRowColumn key={i}>{renderData(v)}</DataTableRowColumn>))}
                                                <DataTableRowColumn>{/* Empty column used for auto alignment */}</DataTableRowColumn>
                                                <DataTableRowColumn>{obj.ClusterName}</DataTableRowColumn>
                                                {obj.Namespace ? <DataTableRowColumn>{obj.Namespace}</DataTableRowColumn> : undefined}
                                            </DataTableRow>))}
                                    </DataTableBody>
                                </DataTable>
                            </div>);
        })}
                </div>
            </SimpleExpander>
            {manifestInspectionEnabled && <KubernetesObjectDrawer onClose={() => setObjectStatusInDrawer(undefined)} objectStatus={objectStatusInDrawer}/>}
        </>);
}
export default KubernetesStepStatusExpander;
