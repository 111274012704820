import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import type { ScopedCommonTemplatesScopeChangeTracker, ScopedCommonTemplatesScopeKey } from "./useScopedCommonTemplatesScopeChangeTracker";
interface ScopedCommonTemplateScopeEditProps {
    environments: Map<string, EnvironmentResource>;
    scopeChangeTracker: ScopedCommonTemplatesScopeChangeTracker;
    scopeKey: ScopedCommonTemplatesScopeKey;
    initialEnvironmentScope: string[];
}
export default ({ environments, scopeChangeTracker, scopeKey, initialEnvironmentScope }: ScopedCommonTemplateScopeEditProps) => {
    const isScopePropertyValueUnchanged = (initialEnvironmentScope: string[], scope: string[] | undefined): boolean => {
        if (!initialEnvironmentScope || !scope) {
            return initialEnvironmentScope === scope;
        }
        if (initialEnvironmentScope.length !== scope.length) {
            return false;
        }
        return initialEnvironmentScope.every((s, i) => s === scope[i]);
    };
    const handleChange = (newScope: string[] | undefined) => {
        if (isScopePropertyValueUnchanged(initialEnvironmentScope, newScope)) {
            scopeChangeTracker.remove(scopeKey);
        }
        else {
            scopeChangeTracker.set(scopeKey, newScope);
        }
    };
    return (<EnvironmentMultiSelect environments={Array.from(environments?.values() || [])} onChange={(environmentIds) => {
            handleChange(environmentIds);
        }} value={scopeChangeTracker.has(scopeKey) ? scopeChangeTracker.get(scopeKey) || [] : initialEnvironmentScope}/>);
};
