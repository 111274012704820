import { css } from "@emotion/css";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { Collapse } from "@material-ui/core";
import { IconButton, Tooltip } from "@octopusdeploy/design-system-components";
import { ChevronDownIcon, ChevronUpIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, borderWidth, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import type { PropsWithChildren } from "react";
import React, { useMemo, useCallback, useRef, useState } from "react";
import { v4 } from "uuid";
import { useExpandableCodeViewerGroup } from "./ExpandableCodeViewerGroup";
const useExpandableCodeViewerState = (isExpandedByDefault: boolean): [
    boolean,
    React.Dispatch<boolean>,
    string
] => {
    const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedByDefault);
    const instanceId = useRef<string>(`expandablecodeviewer-${v4()}`);
    const onGroupAction = useCallback((groupAction) => {
        if (groupAction === "Expand") {
            setIsExpanded(true);
        }
        else if (groupAction === "Collapse") {
            setIsExpanded(false);
        }
    }, []);
    const onExpansionChange = useExpandableCodeViewerGroup(instanceId.current, isExpandedByDefault, onGroupAction);
    const setIsExpandedWithReporting = (value: boolean) => setIsExpanded((prev) => {
        if (value !== prev) {
            if (onExpansionChange) {
                onExpansionChange(instanceId.current, value);
            }
        }
        return value;
    });
    return [isExpanded, setIsExpandedWithReporting, instanceId.current];
};
const styles = {
    container: css({
        border: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
        borderRadius: borderRadius["small"],
        //this is required for the outer border radius to properly clip content
        overflow: "auto",
    }),
    containerSelected: css({
        borderColor: themeTokens.color.border.tertiary,
    }),
    headerContainer: css({
        position: "relative",
    }),
    button: css({
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
        padding: 0,
        border: 0,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: themeTokens.color.background.primary.hovered,
        },
        "&:active": {
            backgroundColor: themeTokens.color.background.primary.pressed,
        },
    }),
    buttonSelected: css({
        backgroundColor: themeTokens.color.background.primary.pressed,
    }),
    header: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: `${space[8]} ${space[12]}`,
        gap: space[8],
        position: "relative",
        pointerEvents: "none",
    }),
    titleDesc: css({
        flexGrow: 1,
    }),
    title: css({
        font: text.regular.bold.large,
        color: themeTokens.color.text.primary,
        margin: 0,
    }),
    description: css({
        font: text.regular.default.xSmall,
        color: themeTokens.color.text.primary,
        margin: 0,
    }),
    actions: css({
        pointerEvents: "auto",
        display: "flex",
        flexDirection: "row",
        gap: space[16],
    }),
    chevrons: css({
        marginLeft: space[16],
    }),
    contentContainer: css({
        borderTop: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
    }),
};
export type ExpandableCodeViewerIconButtonAction = {
    icon: React.ReactElement;
    onClick: ((event: React.MouseEvent) => void) | undefined;
    accessibleName: string;
    tooltip?: string;
};
export type ExpandableCodeViewerAction = ExpandableCodeViewerIconButtonAction;
type AnimationOptions = {
    /*
     * By default the child component stays mounted after it reaches the 'exited' state. Set unmountOnExit if you'd prefer to unmount the component after it finishes exiting.
     */
    unmountOnExit?: boolean;
    animate?: boolean;
};
type ExpandableCodeViewerProps = PropsWithChildren<{
    title: string;
    description?: string;
    statusIcon?: React.ReactNode;
    isExpandedByDefault?: boolean;
    actions?: ExpandableCodeViewerAction[];
    selected?: boolean;
    animation?: AnimationOptions;
}>;
export const ExpandableCodeViewer = (props: ExpandableCodeViewerProps) => {
    const [isExpanded, setIsExpanded, instanceId] = useExpandableCodeViewerState(props.isExpandedByDefault || false);
    const buttonAccessibleName = useMemo(() => {
        const action = isExpanded ? "Collapse" : "Expand";
        return `${action} ${props.title}`;
    }, [isExpanded, props.title]);
    const actions = useMemo(() => {
        if (!props.actions || props.actions.length === 0) {
            return undefined;
        }
        return props.actions.map((action) => {
            if (isIconButtonAction(action)) {
                const accessibleLabel = `${action.accessibleName} for ${props.title}`;
                const iconButton = <IconButton customIcon={action.icon} onClick={action.onClick} accessibleName={accessibleLabel}/>;
                return <div key={action.accessibleName}>{action.tooltip ? <Tooltip content={action.tooltip}>{iconButton}</Tooltip> : iconButton}</div>;
            }
        });
    }, [props.actions, props.title]);
    return (<div aria-labelledby={`${instanceId}-title`} className={cn(styles.container, props.selected ? styles.containerSelected : null)}>
            <div className={styles.headerContainer}>
                <button className={cn(styles.button, props.selected ? styles.buttonSelected : null)} onClick={() => setIsExpanded(!isExpanded)} aria-expanded={isExpanded} aria-controls={`${instanceId}-content`} aria-label={buttonAccessibleName}/>
                <div className={styles.header}>
                    {props.statusIcon && <div>{props.statusIcon}</div>}
                    <div className={styles.titleDesc} id={`${instanceId}-title`}>
                        <p className={styles.title}>{props.title}</p>
                        {props.description && <p className={styles.description}>{props.description}</p>}
                    </div>
                    {actions && (<div className={styles.actions} onClick={(e) => e.stopPropagation()}>
                            {actions}
                        </div>)}
                    <div className={styles.chevrons}>{isExpanded ? <ChevronUpIcon size={20} color={themeTokens.color.icon.primary}/> : <ChevronDownIcon size={20} color={themeTokens.color.icon.primary}/>}</div>
                </div>
            </div>
            <Collapse in={isExpanded} unmountOnExit={props.animation?.unmountOnExit === undefined ? true : props.animation.unmountOnExit} timeout={props.animation?.animate ? "auto" : 0}>
                <div role={"region"} id={`${instanceId}-content`} className={styles.contentContainer}>
                    {props.children}
                </div>
            </Collapse>
        </div>);
};
function isIconButtonAction(action: ExpandableCodeViewerAction): action is ExpandableCodeViewerIconButtonAction {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (action as ExpandableCodeViewerIconButtonAction)?.icon !== undefined;
}
