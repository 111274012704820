import { Snackbar } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { ScopedCommonTemplatesInlineTabContent } from "~/areas/projects/components/Variables/TenantVariables/ScopedTenantVariables/ScopedCommonTemplatesInlineTabContent";
import type { ScopedCommonTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/ScopedTenantVariables/useScopedCommonTemplatesState";
import type { TenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import type { TenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import type { CommonTemplatesValueChangeTracker } from "../useCommonTemplatesValueChangeTracker";
import type { ScopedCommonTemplatesScopeChangeTracker } from "./useScopedCommonTemplatesScopeChangeTracker";
interface ScopedCommonTemplatesInlineContentProps {
    project: ProjectResource;
    doBusyTask: DoBusyTask;
    contentState: ScopedCommonTemplatesState;
    filterData: TenantVariableFiltersData;
    variableResourceOptions: TenantVariableResourceOptions;
    valueChangeTracker: CommonTemplatesValueChangeTracker;
    scopeChangeTracker: ScopedCommonTemplatesScopeChangeTracker;
}
export default function ScopedCommonTemplatesInlineContent({ project, doBusyTask, contentState, filterData, variableResourceOptions, valueChangeTracker, scopeChangeTracker }: ScopedCommonTemplatesInlineContentProps) {
    const { data, environments, pageSize, filterState, actions } = contentState;
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState("");
    const onIncludeVariableSetFromOnboarding = (text: string) => {
        setIsSnackbarOpen(true);
        setSnackbarText(text);
    };
    return (<>
            {data && (<ScopedCommonTemplatesInlineTabContent project={project} environments={environments} doBusyTask={doBusyTask} filterState={filterState} filterData={filterData} setFilterState={actions.setFilter} pageSize={pageSize} setPageState={actions.setPageState} commonTemplatesWithValues={data} onAddTemplateFromOnboarding={onIncludeVariableSetFromOnboarding} resourceOptions={variableResourceOptions} valueChangeTracker={valueChangeTracker} scopeChangeTracker={scopeChangeTracker} addRow={actions.addRow} deleteRow={actions.deleteRow}/>)}
            <Snackbar open={isSnackbarOpen} content={snackbarText} autoHideDuration={3500} onClose={() => setIsSnackbarOpen(false)} textAlign={"center"}/>
        </>);
}
