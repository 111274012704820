import type { ResourcesById, RunbookRunResource, ProjectResource, EnvironmentResource, RunbookResource } from "@octopusdeploy/octopus-server-client";
import { isEqual } from "lodash";
import * as React from "react";
import { useProjectContext } from "~/areas/projects/context";
import Task from "~/areas/tasks/components/Task/Task";
import { repository } from "~/clientInstance";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { useErrors } from "~/components/ErrorContext/ErrorContext";
import { PageContent } from "~/components/PageContent/PageContent";
import { useRunbookContext } from "./RunbookContext";
interface RunbookRunDetailsState extends DataBaseComponentState {
    runbookRun?: RunbookRunResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
}
interface RunbookRunDetailsInternalProps extends RunbookRunDetailsProps {
    runbook: RunbookResource;
    project: ProjectResource;
}
class RunbookRunDetailsInternal extends DataBaseComponent<RunbookRunDetailsInternalProps, RunbookRunDetailsState> {
    constructor(props: RunbookRunDetailsInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.reload();
    }
    async componentDidUpdate(prevProps: RunbookRunDetailsInternalProps) {
        const nextRunbook = this.props.runbook;
        const currentRunbook = prevProps.runbook;
        if (!isEqual(currentRunbook, nextRunbook)) {
            await this.reload();
        }
    }
    async reload() {
        await this.doBusyTask(async () => {
            const runbookRun = await repository.RunbookRuns.get(this.props.runbookRunId);
            this.setState({ runbookRun });
        });
    }
    render() {
        const runbookRun = this.state.runbookRun;
        if (runbookRun) {
            return <Task taskId={runbookRun.TaskId} delayRender={() => false} projectId={this.props.project.Id} environmentId={runbookRun.EnvironmentId} tenantId={runbookRun.TenantId ?? undefined}/>;
        }
        return <RunbookRunDetailsWaitingForData busy={this.state.busy}/>;
    }
    static displayName = "RunbookRunDetailsInternal";
}
interface RunbookRunDetailsWaitingForDataProps {
    busy: Promise<void> | undefined | boolean;
}
function RunbookRunDetailsWaitingForData({ busy }: RunbookRunDetailsWaitingForDataProps) {
    const errors = useErrors();
    return (<PageContent header={{ title: "Task" }} busy={busy} errors={errors}>
            {null}
        </PageContent>);
}
interface RunbookRunDetailsProps {
    runbookRunId: string;
}
export function RunbookRunDetailsPage(props: RunbookRunDetailsProps) {
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const runbook = runbookContext.state.runbook;
    if (!project || !runbook) {
        return <RunbookRunDetailsWaitingForData busy={true}/>;
    }
    return <RunbookRunDetailsInternal project={project} runbook={runbook} {...props}/>;
}
