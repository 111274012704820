/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { css } from "@emotion/css";
import { ListItem } from "@material-ui/core";
import type { TooltipPosition } from "@octopusdeploy/design-system-components";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import * as React from "react";
import MediaQuery from "react-responsive";
import { useProcessErrorSelectors } from "~/areas/projects/components/Process/Contexts/ProcessErrors/ProcessErrorsContext";
import { ProcessActionErrorIndicator } from "~/areas/projects/components/Process/ErrorIndicators/ProcessActionErrorIndicator";
import styles from "~/areas/projects/components/Process/ListItems/ProcessListItemForSidebar.module.less";
import Logo from "~/components/Logo";
type ListItemLinkProps = React.PropsWithChildren<{
    className?: string;
    onClick: () => void;
}>;
const ListItemLinkInternal: React.FC<ListItemLinkProps> = ({ onClick, ...rest }) => {
    const clickHandler = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        ev.stopPropagation();
        ev.preventDefault();
        onClick();
    };
    return <ListItem button component="a" disableGutters={true} style={{ paddingTop: 0, paddingBottom: 0 }} {...rest} onClick={clickHandler}/>;
};
ListItemLinkInternal.displayName = "ListItemLinkInternal"
const ListItemLink = React.memo(ListItemLinkInternal);
interface BlueprintSidebarLinkProps {
    className?: string;
    isCurrentAction: boolean;
    onClick: () => void;
    name: string;
    actionType: string | JSX.Element;
    logoUrl?: string;
    icon?: JSX.Element;
    sidebarListing: string;
    children?: React.ReactNode;
    isDisabled: boolean;
    isRunInParallelWithLast?: boolean;
    stepIndex?: number;
    actionIndex?: number;
    containingProcessTemplateStepName: string;
}
export const toolTipBreakpoint = "1279px";
const renderLogoOrIcon = (actionType: string | JSX.Element, logoUrl: string | undefined, isDisabled: boolean, icon: JSX.Element | undefined, toolTipPosition: TooltipPosition | undefined) => {
    return (<Tooltip content={actionType} position={toolTipPosition}>
            <div className={styles.stepLogo}>{logoUrl ? <Logo url={logoUrl} isDisabled={isDisabled} size={"2rem"} className={styles.logo}/> : icon}</div>
        </Tooltip>);
};
const BlueprintSidebarLink: React.FC<BlueprintSidebarLinkProps> = ({ containingProcessTemplateStepName, name, sidebarListing, stepIndex, actionIndex, isCurrentAction, isDisabled, isRunInParallelWithLast, actionType, logoUrl, icon, onClick }) => {
    const processErrorSelectors = useProcessErrorSelectors();
    const errors = processErrorSelectors.getProcessTemplateActionErrors(containingProcessTemplateStepName, stepIndex ?? -1, actionIndex ?? -1);
    return (<StepLink isDisabled={isDisabled} isCurrentAction={isCurrentAction} onClick={onClick}>
            {isRunInParallelWithLast && (<MediaQuery minWidth={toolTipBreakpoint}>
                    {(matches: boolean) => {
                const toolTipPosition = matches ? "left" : undefined;
                return (<div className={styles.stepWrapper}>
                                <Tooltip content="Runs in parallel" position={toolTipPosition}>
                                    <div className={styles.parallelContainer}>
                                        <div className={styles.parallelIcon}>
                                            <div className={styles.content}>&nbsp;</div>
                                        </div>
                                    </div>
                                </Tooltip>
                                {renderLogoOrIcon(actionType, logoUrl, isDisabled, icon, toolTipPosition)}
                            </div>);
            }}
                </MediaQuery>)}
            {!isRunInParallelWithLast && (<MediaQuery minWidth={toolTipBreakpoint}>
                    {(matches: boolean) => {
                const toolTipPosition = matches ? "left" : undefined;
                return renderLogoOrIcon(actionType, logoUrl, isDisabled, icon, toolTipPosition);
            }}
                </MediaQuery>)}
            {withDisabledTooltipWrapper(<div className={styles.stepContainer}>
                    <div className={styles.stepNumber}>{sidebarListing}</div>
                    <div className={styles.stepName}>{name}</div>
                </div>, isDisabled)}
            {errors.length > 0 && (<div className={errorContainer}>
                    <ProcessActionErrorIndicator actionErrors={errors}/>
                </div>)}
        </StepLink>);
};
BlueprintSidebarLink.displayName = "BlueprintSidebarLink"
function withDisabledTooltipWrapper(children: React.ReactNode, isDisabled: boolean) {
    if (isDisabled) {
        return (<Tooltip content="Disabled" style={{ width: "100%" }}>
                {children}
            </Tooltip>);
    }
    return children;
}
type StepLinkProps = React.PropsWithChildren<{
    isDisabled: boolean;
    isCurrentAction: boolean;
    onClick: () => void;
}>;
const StepLinkInternal: React.FC<StepLinkProps> = ({ children, isDisabled, isCurrentAction, onClick }) => {
    return (<ListItemLink className={cn(styles.listItem, isCurrentAction ? styles.isActive : null, isDisabled ? styles.isDisabled : null)} onClick={onClick}>
            <div className={styles.listItemLine}/>
            {children}
        </ListItemLink>);
};
StepLinkInternal.displayName = "StepLinkInternal"
const errorContainer = css({
    paddingRight: space[12],
    display: "flex",
    alignItems: "center",
});
const StepLink = React.memo(StepLinkInternal);
export default React.memo(BlueprintSidebarLink);
