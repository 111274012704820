import { APIOperationStatusProvider, useLoaderQuery } from "@octopusdeploy/octopus-react-client";
import type { Repository, RequestCorrelationContext } from "@octopusdeploy/octopus-server-client";
import { useUpdateGlobalPageDetails, type QueryParamValues, type QueryParamValuesSetter, type UnknownQueryParam } from "@octopusdeploy/portal-routes";
import type { ReactNode } from "react";
import React, { useEffect, useMemo } from "react";
import { v4 } from "uuid";
import ErrorContextProvider from "~/components/ErrorContext/ErrorContext";
import { PageLoading } from "~/components/PageContent/PageLoading";
import type { LoadingContentRender, PageRegistration, UnknownPageRegistration } from "~/routing/pageRegistrations/PageRegistration";
import { PageRegistrationProvider } from "~/routing/pageRegistrations/PageRegistrationContext";
import StringHelper from "~/utils/StringHelper/index";
interface PageProps<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData, LoaderContext, PageContext> {
    pageRegistration: PageRegistration<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext>;
    loaderContext: LoaderContext;
    pageContext: PageContext;
    routeParams: RouteParams;
    queryParams: QueryParamValues<QueryParams>;
    setQueryParams: QueryParamValuesSetter<QueryParamValues<QueryParams>>;
}
export function Page<RouteParams, QueryParams extends UnknownQueryParam[], LoaderData, LoaderContext, PageContext>({ pageRegistration, loaderContext, pageContext, queryParams, setQueryParams, routeParams, }: PageProps<RouteParams, QueryParams, LoaderData, LoaderContext, PageContext>) {
    useUpdateGlobalPageDetailsFromPageRegistrationEffect(pageRegistration);
    const pageInstanceId = useMemo(() => v4(), []);
    const pageCorrelationContext = useMemo(() => ({
        pageid: pageRegistration.pageIdentity.pageId,
        pageinstanceid: pageInstanceId,
    } satisfies RequestCorrelationContext), [pageInstanceId, pageRegistration.pageIdentity.pageId]);
    const loader = pageRegistration.loader;
    if (loader === undefined) {
        // In future, all of our pages will probably have loaders.
        // While we migrate to that future state, many pages won't have loaders yet.
        // Instead of trying to accommodate for this scenario in a more sophisticated way,
        // this option keeps things very simple and isolates most of the ugliness to this component and the type assertion below.
        // Other options tend to add a lot of unnecessary complexity.
        // The only real downside is that a `loaderData` property is available during rendering, even if you haven't yet supplied a loader.
        // (fortunately its type will be `unknown`)
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const loaderData = undefined as unknown as LoaderData;
        return (<PageRegistrationProvider pageRegistration={pageRegistration}>
                <PageWithoutLoader pageCorrelationContext={pageCorrelationContext}>{pageRegistration.render({ context: pageContext, loaderData, routeParams, queryParams, setQueryParams })}</PageWithoutLoader>
            </PageRegistrationProvider>);
    }
    return (<PageRegistrationProvider pageRegistration={pageRegistration}>
            <PageWithLoader<LoaderData> loader={(repository) => loader(repository, { routeParams, queryParams, context: loaderContext })} loadingTitleConfiguration={pageRegistration.loadingTitle} loadingContent={pageRegistration.loadingContent} renderContentWhenLoaded={(loaderData) => pageRegistration.render({ context: pageContext, loaderData, routeParams, queryParams, setQueryParams })} pageCorrelationContext={pageCorrelationContext} isFullWidth={pageRegistration.isFullWidth ?? getDefaultFullWidthForLevel(pageRegistration.level)}/>
        </PageRegistrationProvider>);
}
function getDefaultFullWidthForLevel(level: 1 | 2): boolean {
    return level === 1;
}
function PageWithoutLoader({ children, pageCorrelationContext }: {
    children: ReactNode;
    pageCorrelationContext: RequestCorrelationContext;
}) {
    return (<ErrorContextProvider>
            <APIOperationStatusProvider correlationContext={pageCorrelationContext}>{children}</APIOperationStatusProvider>
        </ErrorContextProvider>);
}
interface PageWithLoaderProps<LoaderData> {
    pageCorrelationContext: RequestCorrelationContext;
    loader: (repository: Repository) => Promise<LoaderData>;
    loadingContent: LoadingContentRender | undefined;
    loadingTitleConfiguration: string | undefined;
    renderContentWhenLoaded: (loaderData: LoaderData) => ReactNode;
    isFullWidth: boolean;
}
function PageWithLoader<LoaderData>({ loader, renderContentWhenLoaded, loadingTitleConfiguration, pageCorrelationContext, loadingContent, isFullWidth }: PageWithLoaderProps<LoaderData>) {
    const queryState = useLoaderQuery(loader, {
        ...pageCorrelationContext,
        operation: "Page load",
    });
    function getContent() {
        if (queryState.isLoading) {
            return renderLoadingContent(loadingTitleConfiguration, loadingContent, "is-loading");
        }
        if (queryState.error !== null) {
            return renderLoadingContent(loadingTitleConfiguration, loadingContent, queryState.error);
        }
        return renderContentWhenLoaded(queryState.result);
    }
    return (<ErrorContextProvider>
            <APIOperationStatusProvider correlationContext={pageCorrelationContext}>{getContent()}</APIOperationStatusProvider>
        </ErrorContextProvider>);
}
function renderLoadingContent(loadingTitleConfiguration: string | undefined, customLoadingContentRenderer: LoadingContentRender | undefined, state: Error | "is-loading") {
    if (customLoadingContentRenderer !== undefined) {
        return customLoadingContentRenderer(state);
    }
    return <DefaultPageLoadingContent title={loadingTitleConfiguration ?? StringHelper.ellipsis} state={state}/>;
}
function DefaultPageLoadingContent({ state, title }: {
    title: string;
    state: Error | "is-loading";
}) {
    if (state === "is-loading") {
        return <PageLoading loadingTitle={title}/>;
    }
    return <PageLoading loadingTitle={title} errors={[state]}/>;
}
function useUpdateGlobalPageDetailsFromPageRegistrationEffect(pageRegistration: UnknownPageRegistration) {
    const updateGlobalPageDetails = useUpdateGlobalPageDetails();
    const { pageIdentity: { pageId, pageName, pageArea }, verticalNavigationPageArea, } = pageRegistration;
    useEffect(() => {
        updateGlobalPageDetails({
            pageId,
            pageName,
            pageArea,
            verticalNavigationPageArea,
        });
    }, [pageArea, pageId, pageName, updateGlobalPageDetails, verticalNavigationPageArea]);
}
