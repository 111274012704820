import { css } from "@emotion/css";
import { Level2OuterPageLayout, Tooltip } from "@octopusdeploy/design-system-components";
import { ExclamationTriangleIcon } from "@octopusdeploy/design-system-icons";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { SkeletonLoadingLayout } from "~/components/SkeletonLoadingLayout/SkeletonLoadingLayout";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
interface ActionTemplateLayoutProps {
    spaceId: string;
    templateId: string;
    children: (context: {
        onActionTemplateUpdated: () => void;
    }) => React.ReactNode;
}
export default function ActionTemplateLayout({ spaceId, templateId, children }: ActionTemplateLayoutProps) {
    const { result, error, refetch } = useInlineStatusQuery(async (repository) => {
        const actionTemplate = await repository.ActionTemplates.get(templateId);
        const actionTemplateUsages = await repository.ActionTemplates.getUsage(actionTemplate);
        const totalPendingUpdates = actionTemplateUsages.filter((u) => u.Version.toString() !== actionTemplate.Version.toString()).length;
        return { actionTemplate, totalPendingUpdates };
    }, [templateId], "ActionTemplateLayout resource and usages");
    const createPageSideNavLink = useCreatePageSideNavLink();
    const context = React.useMemo(() => ({ onActionTemplateUpdated: refetch }), [refetch]);
    if (!result) {
        return (<main>
                <SkeletonLoadingLayout errors={error ? [error] : undefined}/>
            </main>);
    }
    const { actionTemplate, totalPendingUpdates } = result;
    const navLinks = [
        createPageSideNavLink("Details", links.editStepTemplatePage.generateUrl({ spaceId, templateId }), true),
        createPageSideNavLink(<UsageNavbarItemLabel totalPendingUpdates={totalPendingUpdates}/>, links.stepTemplateUsagePage.generateUrl({ spaceId, templateId })),
    ];
    return (<main>
            <Level2OuterPageLayout navItems={navLinks} header={{
            title: actionTemplate.Name,
            breadcrumbs: [{ label: "Step Templates", pageUrl: links.stepTemplatesPage.generateUrl({ spaceId }) }],
            logo: actionTemplate.Links["Logo"] ? { href: actionTemplate.Links["Logo"], accessibleName: "Logo" } : undefined,
        }}>
                {children(context)}
            </Level2OuterPageLayout>
        </main>);
}
interface UsageWithPendingUpdatesLabelProps {
    totalPendingUpdates: number;
}
function UsageNavbarItemLabel({ totalPendingUpdates = 0 }: UsageWithPendingUpdatesLabelProps) {
    return (<div className={usageWithPendingUpdatesLabelStyles}>
            Usage
            {totalPendingUpdates > 0 && <PendingUsages totalPendingUpdates={totalPendingUpdates}/>}
        </div>);
}
function PendingUsages({ totalPendingUpdates }: React.PropsWithChildren<{
    totalPendingUpdates: number;
}>) {
    const tooltipContent = `${totalPendingUpdates} action ${pluralize(totalPendingUpdates, "template usage has", "template usages have")} pending changes.`;
    return (<Tooltip content={tooltipContent}>
            <ExclamationTriangleIcon size={24} color={themeTokens.color.icon.attention}/>
        </Tooltip>);
}
function pluralize(count: number, singular: string, plural: string) {
    return count > 1 ? plural : singular;
}
const usageWithPendingUpdatesLabelStyles = css({
    display: "flex",
    flexDirection: "row",
    gap: space[8],
    alignItems: "center",
});
