/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import { Tooltip } from "@octopusdeploy/design-system-components";
import { PenLineIcon, UnavailableIcon } from "@octopusdeploy/design-system-icons";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { DashboardItemResource, TagSetResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import { sortBy } from "lodash";
import * as React from "react";
import { forwardRef, useRef } from "react";
import type { DeploymentOverviewFilters } from "~/areas/projects/components/DeploymentsOverview/hooks/useDeploymentsOverviewFilters";
import { repository } from "~/clientInstance";
import FrozenDashboardIcon from "~/components/DeploymentFreezes/FrozenDashboardIcon";
import { FrozenResourceType } from "~/components/DeploymentFreezes/FrozenIcon";
import Logo from "../../../../components/Logo/Logo";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import Tag from "../../../../components/Tag/Tag";
import type { DataCube } from "./DataCube";
import { DimensionGetters, DimensionTypes } from "./DataCube";
import TenantsMissingVariablesNotifier from "./TenantsMissingVariablesNotifier";
import styles from "./style.module.less";
import untenantedDeploymentLogo from "./un-tenanted-deployment-logo.svg";
interface DataSet {
    matrix: Matrix;
    columnDimension: DimensionTypes;
    groupDimension: DimensionTypes;
    rowDimension: DimensionTypes;
    getGroups(): string[];
    groupTitle(groupId: string, showEditLink?: boolean): React.ReactNode;
    getRowsForGroup(groupId: string, take?: number): string[];
    rowTitle(rowId: string | null): React.ReactNode;
    getColumnsForGroup(groupId: string): string[];
    columnTitle(columnId: string): string;
    rowLabel(): string;
}
interface Matrix {
    [groupId: string]: {
        [rowId: string]: {
            [columnId: string]: DashboardItemResource[];
        };
    };
}
export type Group = {
    groupId: string;
    rowsInGroup: string[];
};
function getDataSet(filter: Omit<DeploymentOverviewFilters, "page" | "pageSize">, self: DataCube): DataSet {
    const rowDimension = filter.rowDimension || DimensionTypes.None;
    const columnDimension = filter.columnDimension || DimensionTypes.None;
    const groupDimension = filter.groupBy || DimensionTypes.None;
    let deployments = self.deployments;
    if (filter[DimensionTypes.Release]) {
        //To make the rest of the process quicker, filter releases (probably could filter any & all)
        deployments = deployments.filter((i) => {
            return filter[DimensionTypes.Release][DimensionGetters[DimensionTypes.Release](i)];
        });
    }
    const groupedTagSet: TagSetResource = groupDimension === DimensionTypes.TagSet ? self.tagSetIndex[filter.groupByExtra!] : null!;
    const matrix = enterTheMatrix(deployments, getGroupGetter(groupDimension, self), rowDimension, columnDimension);
    return {
        matrix,
        getGroups: () => {
            let groups: Array<string | null> = [null];
            if (groupDimension === DimensionTypes.TagSet) {
                groups = ([null] as Array<string | null>).concat(sortBy(groupedTagSet.Tags, (t) => t.SortOrder).map((t) => t.CanonicalTagName));
            }
            else if (groupDimension === DimensionTypes.ProjectGroup) {
                groups = sortBy(self.projectGroupIndex, (g) => g.Name).map((g) => g.Id);
            }
            else if (filter.groupBy === DimensionTypes.Channel) {
                groups = Object.keys(self.channelIndex)
                    .map((c) => self.channelIndex[c])
                    .sort((channelA, channelB) => {
                    return (
                    // Sort by default channel first
                    // Then sort by name alphabetically
                    (channelA.IsDefault === channelB.IsDefault ? 0 : channelA.IsDefault ? -1 : 1) || (channelA.Name.toLowerCase() === channelB.Name.toLowerCase() ? 0 : channelA.Name.toLowerCase() < channelB.Name.toLowerCase() ? -1 : 1));
                })
                    .map((channel) => channel.Id);
            }
            if (filter.groupBy === DimensionTypes.TagSet) {
                // If we are filtering by the same tag set that we are grouping by,
                // then all tenants within an excluded group will be filtered out in `getRowsForGroup`
                // But we still want to exclude tenants that don't have any tags from the tag set we are grouping by (i.e. the `null` group)
                return groups.filter((g) => g !== null) as string[];
            }
            else if (filter[filter.groupBy!]) {
                return groups.filter((groupId) => filter[filter.groupBy!][groupId!]) as string[];
            }
            return groups as string[];
        },
        groupTitle: (groupId, showEditLink) => {
            if (!groupDimension || !groupId) {
                return null;
            }
            if (groupDimension === DimensionTypes.Channel) {
                if (Object.keys(self.channelIndex).length < 2) {
                    return null;
                }
                return <div>Channel: {self.channelIndex[groupId].Name}</div>;
            }
            else if (groupDimension === DimensionTypes.TagSet) {
                const tag = groupedTagSet.Tags.find((t) => t.CanonicalTagName === groupId);
                return (<div>
                        {groupedTagSet.Name}: <Tag tagName={tag!.Name} description={tag!.Description} tagColor={tag!.Color} showTooltip={false}/>
                    </div>);
            }
            else if (groupDimension === DimensionTypes.ProjectGroup) {
                const groupName = self.projectGroupIndex[groupId].Name;
                return showEditLink ? (<div className={styles.projectGroupHeader}>
                        {groupName}
                        <InternalLink to={links.editProjectGroupPage.generateUrl({ spaceId: repository.spaceId!, projectGroupId: groupId })}>
                            <PenLineIcon size={24}/>
                        </InternalLink>
                    </div>) : (groupName);
            }
            else if (filter.groupBy === DimensionTypes.Channel) {
                if (Object.keys(self.channelIndex).length < 2) {
                    return null;
                }
                return <div>Channel: {self.channelIndex[groupId].Name}</div>;
            }
            throw new Error("Only Channel Grouping Supported");
        },
        getRowsForGroup: (groupId: string, take?: number) => {
            let rows: Array<string | null> = [];
            if (rowDimension === DimensionTypes.Tenant) {
                rows = replaceUntenantedIdWithNull(self, Object.keys(self.tenantIndex));
                if (groupDimension === DimensionTypes.TagSet) {
                    rows = rows.filter((tenantId) => {
                        const tenant = self.tenantIndex[tenantId!];
                        if (groupId === null) {
                            return !tenant || !groupedTagSet.Tags.find((t) => tenant.TenantTags.indexOf(t.CanonicalTagName) !== -1);
                        }
                        else {
                            return tenant && tenant.TenantTags.indexOf(groupId) !== -1;
                        }
                    });
                }
                // Show enabled tenants first
                rows.sort((a, b) => {
                    const aDisabled = a === null ? false : self.tenantIndex[a].IsDisabled;
                    const bDisabled = b === null ? false : self.tenantIndex[b].IsDisabled;
                    return Number(aDisabled) - Number(bDisabled);
                });
            }
            else if (rowDimension === DimensionTypes.Project) {
                rows = Object.keys(self.projectIndex);
                if (groupDimension === DimensionTypes.ProjectGroup) {
                    rows = rows.filter((projectId) => self.projectIndex[projectId!].ProjectGroupId === groupId);
                    if (filter[DimensionTypes.ProjectName]) {
                        const filterName = Object.keys(filter[DimensionTypes.ProjectName])[0];
                        rows = rows.filter((projectId) => self.projectIndex[projectId!].Name.toLocaleUpperCase().includes(filterName.toLocaleUpperCase()));
                    }
                    if (take) {
                        rows = limitProjects(rows, take);
                    }
                }
            }
            else if (rowDimension === DimensionTypes.Release) {
                rows = filter.groupBy === DimensionTypes.Channel ? Object.keys(self.releaseIndex).filter((r) => self.releaseIndex[r].ChannelId === groupId) : Object.keys(self.releaseIndex);
            }
            return rows as string[];
        },
        rowTitle: (rowId: string | null) => {
            if (rowDimension === DimensionTypes.Tenant) {
                if (rowId === null) {
                    return <RowTitleCell logoUrl={untenantedDeploymentLogo} name="Untenanted"/>;
                }
                else {
                    const tenant = self.tenantIndex[rowId];
                    return (<div className={styles.rowHeader}>
                            <RowTitleCell logoUrl={tenant.Links.Logo} name={tenant.Name} toUrl={links.tenantOverviewPage.generateUrl({ spaceId: repository.spaceId!, tenantId: rowId })} isDisabled={tenant.IsDisabled}/>
                            <TenantsMissingVariablesNotifier rowId={rowId} missingVariableTenantsPromise={self.missingVariableTenantsPromise}/>
                            {self.tenantIndex[rowId]?.IsFrozen ? <FrozenDashboardIcon for={FrozenResourceType.Tenant}/> : <></>}
                        </div>);
                }
            }
            if (rowId === null) {
                throw Error("A project or release has a null ID which should never happen");
            }
            if (rowDimension === DimensionTypes.Project) {
                const project = self.projectIndex[rowId];
                return (<ProjectRowTitleCell logoUrl={project.Links.Logo} name={project.Name} toUrl={links.projectRootRedirect.generateUrl({ spaceId: repository.spaceId!, projectSlug: project.Slug })} isDisabled={project.IsDisabled} isFrozen={project.IsFrozen}/>);
            }
            else if (rowDimension === DimensionTypes.Release) {
                const release = self.releaseIndex[rowId];
                return (<div className={styles.rowCell}>
                        <div className={styles.rowHeader}>
                            <InternalLink to={links.releasePage.generateUrl({ spaceId: release.SpaceId, projectSlug: self.projectIndex[release.ProjectId].Slug, releaseVersion: release.Version })}>{release.Version}</InternalLink>
                        </div>
                        {self.blockedReleases.indexOf(rowId) !== -1 && (<div className={styles.blockAlert}>
                                <Tooltip content="This release has been blocked from future deployments. View the release details for more information.">
                                    <em className={cn("fa-solid fa-exclamation-triangle", styles.blockAlertIcon)}/>
                                </Tooltip>
                            </div>)}
                    </div>);
            }
        },
        getColumnsForGroup: (groupId: string) => {
            let colms = Object.keys(self.environmentIndex);
            if (groupDimension === DimensionTypes.Channel) {
                colms = self.channelEnvironments[groupId].filter((environmentId) => colms.includes(environmentId));
            }
            else if (groupDimension === DimensionTypes.ProjectGroup) {
                const projectEnvironments = Object.keys(self.projectIndex)
                    .map((p) => self.projectIndex[p])
                    .filter((p) => p.ProjectGroupId === groupId)
                    .reduce<string[]>((arr, p) => arr.concat(p.EnvironmentIds), []);
                colms = colms.filter((environmentId) => projectEnvironments.indexOf(environmentId) !== -1);
            }
            if (filter[columnDimension]) {
                return colms.filter((rowId) => filter[columnDimension][rowId]);
            }
            return colms;
        },
        columnTitle: (columnId) => {
            //Currently only supports environments for column
            return self.environmentIndex[columnId].Name;
        },
        rowLabel: () => {
            switch (rowDimension) {
                case DimensionTypes.Tenant:
                    return "Tenant";
                case DimensionTypes.Release:
                    return "Release";
                default:
                    return "";
            }
        },
        groupDimension,
        rowDimension,
        columnDimension,
    };
}
// The untenanted dashboard isn't paged on the server, so we need to do some manual paging here.
// It's a bit ugly because the cube comes in a dictionary form so we need to traverse each group and track the count.
function getDataSetRowsByPage(dataSet: DataSet, page: number, pageSize: number): Group[] {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize - 1;
    let allRowsSoFar = 0;
    const groups = dataSet.getGroups().reduce<Group[]>((acc, groupId) => {
        // We've already passed the end of the page.
        if (allRowsSoFar > endIndex) {
            return acc;
        }
        const rowsInGroup = dataSet.getRowsForGroup(groupId);
        const values = [];
        for (const row of rowsInGroup) {
            if (allRowsSoFar >= startIndex && allRowsSoFar <= endIndex) {
                values.push(row);
            }
            allRowsSoFar++;
        }
        // Channels with no releases don't affect the number of items on the page as we page by releases.
        const shouldIncludeEmptyChannel = rowsInGroup.length === 0 && allRowsSoFar >= startIndex && allRowsSoFar <= endIndex;
        if (values.length > 0 || shouldIncludeEmptyChannel) {
            const value = {
                groupId,
                rowsInGroup: values,
            };
            acc.push(value);
        }
        return acc;
    }, []);
    return groups;
}
// The tenanted dashboard is server-side paged, so we can always retrieve the entire dataset.
function getDataSetRows(dataSet: DataSet): Group[] {
    return dataSet.getGroups().reduce<Group[]>((acc, groupId) => {
        const value = {
            groupId,
            rowsInGroup: dataSet.getRowsForGroup(groupId),
        };
        acc.push(value);
        return acc;
    }, []);
}
function getGroupGetter(groupDimension: DimensionTypes, cube: DataCube) {
    if (groupDimension === DimensionTypes.TagSet) {
        // Tag sets need special getter since they aren't stored on the deployment item itself
        return (item: DashboardItemResource) => (item.TenantId ? cube.tenantTagIndex[item.TenantId] || [] : []);
        //groupedTagSet = cube.tagSetIndex[filter.groupByExtra];
    }
    else if (groupDimension === DimensionTypes.ProjectGroup) {
        // Project groups need special getter since they aren't stored on the deployment item itself
        return (item: DashboardItemResource) => {
            const project = cube.projectIndex[item.ProjectId]; //the project won't be here if we've filterd due to project limit
            return project ? project.ProjectGroupId : null;
        };
    }
    return DimensionGetters[groupDimension];
}
function replaceUntenantedIdWithNull(self: DataCube, rows: (string | null)[]) {
    // The Untenanted tenant starts with an undefined id as null ids are not returned as part of the response, so the expected null value is re-instated here
    return self.tenantIndex["undefined"] ? rows.map((id) => (id === "undefined" ? null : id)) : rows;
}
interface RowTitleContainerProps {
    children: React.ReactNode;
}
const RowTitleContainer = forwardRef<HTMLDivElement, RowTitleContainerProps>(({ children }, ref) => {
    return (<div ref={ref} className={rowTitleContainerStyles}>
            {children}
        </div>);
});
const rowTitleContainerStyles = css({
    display: "flex",
});
interface RowTitleCellProps {
    logoUrl: string;
    name: string;
    toUrl?: LinkHref;
    isDisabled?: boolean;
}
function RowTitleCell({ logoUrl, name, toUrl, isDisabled }: RowTitleCellProps) {
    return (<RowTitleContainer>
            <RowTitleLink toUrl={toUrl} isDisabled={isDisabled}>
                <div className={logoWrapperStyles}>
                    <div className={cx({ [logoWrapperDisabledStyles]: isDisabled })}>
                        <Logo url={logoUrl} size="2.25rem" isDisabled={isDisabled}/>
                    </div>
                    {isDisabled && (<div className={unavailableIconContainerStyles}>
                            <Tooltip content="Disabled" display="block">
                                <UnavailableIcon size={24}/>
                            </Tooltip>
                        </div>)}
                </div>
                <span title={isDisabled ? "Disabled" : ""}>{name}</span>
            </RowTitleLink>
        </RowTitleContainer>);
}
interface ProjectRowTitleCellProps extends RowTitleCellProps {
    isFrozen?: boolean;
}
function ProjectRowTitleCell({ logoUrl, name, toUrl, isDisabled, isFrozen }: ProjectRowTitleCellProps) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    return (<RowTitleContainer ref={containerRef}>
            <RowTitleLink toUrl={toUrl} isDisabled={isDisabled}>
                <Logo url={logoUrl} size="2.25rem" isDisabled={isDisabled}/>
                <span className={rowTitleStyles.titleText} title={isDisabled ? "Disabled" : ""}>
                    {name}
                </span>
                {isFrozen && <FrozenDashboardIcon for={FrozenResourceType.Project}/>}
            </RowTitleLink>
        </RowTitleContainer>);
}
const rowTitleStyles = {
    titleText: css({
        flex: 1,
    }),
};
interface RowTitleLinkProps {
    toUrl?: LinkHref;
    isDisabled?: boolean;
    children: React.ReactNode;
}
function RowTitleLink({ toUrl, isDisabled, children }: RowTitleLinkProps) {
    if (toUrl) {
        return (<InternalLink to={toUrl} className={cx(rowTitleLinkStyles, isDisabled ? styles.disabled : null)}>
                {children}
            </InternalLink>);
    }
    else {
        return <div className={cx(rowTitleLinkStyles, isDisabled ? styles.disabled : null)}>{children}</div>;
    }
}
const rowTitleLinkStyles = css({
    display: "flex",
    alignItems: "center",
    height: "100%",
    gap: space[8],
});
const logoWrapperStyles = css({
    position: "relative",
    alignSelf: "center",
});
const unavailableIconContainerStyles = css({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: themeTokens.color.icon.primary,
});
const logoWrapperDisabledStyles = css({
    WebkitFilter: "grayscale(1)",
    filter: "grayscale(1)",
    opacity: 0.4,
});
type GroupingFunction = ((item: DashboardItemResource) => string[]) | ((item: DashboardItemResource) => string | null);
function enterTheMatrix(deployments: DashboardItemResource[], groupByFunction: GroupingFunction, rowDimension: DimensionTypes, columnDimension: DimensionTypes): Matrix {
    const rowFromTask = DimensionGetters[rowDimension];
    const columnFromTask = DimensionGetters[columnDimension];
    const matrix: Matrix = {};
    deployments.forEach((deploymentTask: DashboardItemResource) => {
        let groupingIds = groupByFunction(deploymentTask);
        if (!Array.isArray(groupingIds)) {
            groupingIds = [groupingIds!];
        }
        if (groupingIds.length === 0) {
            groupingIds = [null!];
        }
        groupingIds.forEach((groupingId) => {
            let group = matrix[groupingId];
            if (!group) {
                group = matrix[groupingId] = {};
            }
            const rowId = rowFromTask(deploymentTask);
            let row = group[rowId];
            if (!row) {
                row = group[rowId] = {};
            }
            const columnId = columnFromTask(deploymentTask);
            if (!row[columnId]) {
                row[columnId] = [];
            }
            row[columnId].push(deploymentTask);
        });
    });
    Object.keys(matrix).forEach((groupId) => Object.keys(matrix[groupId]).forEach((rowId) => Object.keys(matrix[groupId][rowId]).forEach((columnId) => {
        const latestByContext = getLatestDeploymentPerContext(matrix[groupId][rowId][columnId]);
        matrix[groupId][rowId][columnId] = sortBy(latestByContext, [(t: DashboardItemResource) => t.ReleaseVersion, (t: DashboardItemResource) => new Date(t.CompletedTime || t.Created)]);
    })));
    return matrix;
}
function getLatestDeploymentPerContext(deployments: DashboardItemResource[]) {
    const latestPerDeploymentContext = deployments.reduce<{
        [key: string]: DashboardItemResource;
    }>((idx: {
        [index: string]: DashboardItemResource;
    }, item: DashboardItemResource) => {
        const key = item.EnvironmentId + item.ReleaseId + item.TenantId + item.ProjectId;
        const current = idx[key] || null;
        if (!current || new Date(current.CompletedTime || current.Created) < new Date(item.CompletedTime || current.Created)) {
            idx[key] = item;
        }
        return idx;
    }, {});
    return Object.keys(latestPerDeploymentContext).map((e) => latestPerDeploymentContext[e]);
}
function limitProjects(data: Array<string | null>, limit: number): Array<string | null> {
    if (data.length <= limit) {
        return data;
    }
    return limit > 0 ? data.slice(0, limit) : [];
}
export { DataSet, Matrix, getDataSet, getDataSetRowsByPage, getDataSetRows };
