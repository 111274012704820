import { css } from "@emotion/css";
import type { SimpleMenuItem, PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { ProjectResource, RunbookProcessResource, RunbookResource, EnvironmentResource, ResourceCollection, RunbooksDashboardItemResource, TenantResource, RunbookRunTemplateResource, RunbookSnapshotResource, GitRefResource, TaskListBffArgs, } from "@octopusdeploy/octopus-server-client";
import { HasRunbooksInGit, Permission, TaskState } from "@octopusdeploy/octopus-server-client";
import type { TaskFilterState } from "@octopusdeploy/portal-routes";
import { links, TaskDateFilterTypeValues, TaskFilterStateValues } from "@octopusdeploy/portal-routes";
import { isEqual } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import RunbooksNavigationTabs from "~/areas/projects/components/Runbooks/RunbooksNavigationTabs";
import { useProjectContext } from "~/areas/projects/context";
import { repository } from "~/clientInstance";
import { AdvancedFilters } from "~/components/AdvancedFilterLayout/AdvancedFilters";
import type { DoBusyTask } from "~/components/DataBaseComponent";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { NoResults } from "~/components/NoResults/NoResults";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed, isAllowedToRunGitRunbook } from "~/components/PermissionCheck/PermissionCheck";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { PageHeaderBranchSelectorVNext } from "../../BranchSelector/PageHeaderBranchSelector";
import { useProjectRunbooksGitRef, type GitRefQueryParamsProps } from "../../BranchSelector/useProjectGitRef";
import { LastPublishedChip } from "../LastPublishedChip";
import PublishButton from "../PublishButton";
import { getPermissionDeniedTooltipText, RunNowButton } from "../RunNowButton";
import { useRunbookContext } from "../RunbookContext";
import RunbookOnboarding from "../RunbookOnboarding";
import { ScheduledTriggersBar } from "../Triggers/ScheduledTriggersBar";
import { RunbookRunsStateFilter, RunbooksAdvancedFilterSection } from "./NewRunbookRunsPageFilters";
import type { RunbookTaskFilter } from "./NewRunbookRunsPageFilters";
import { RunbookRunList } from "./RunbookRunList";
interface NewRunbookRunsListPageProps extends GitRefQueryParamsProps {
    spaceId: string;
}
const pageSize = 30;
export function NewRunbookRunsListPage(props: NewRunbookRunsListPageProps) {
    const { doBusyTask, status } = useLegacyDoBusyTask();
    const runbookContext = useRunbookContext();
    const projectContext = useProjectContext();
    const [runbookProcess, setRunbookProcess] = useState<RunbookProcessResource>();
    const [publishedRunbookSnapshot, setPublishedRunbookSnapshot] = useState<RunbookSnapshotResource>();
    const [runbookRunTemplate, setRunbookRunTemplate] = useState<RunbookRunTemplateResource>();
    const [filter, setFilter] = useState<RunbookTaskFilter>(getDefaultFilter);
    const project = projectContext.state && projectContext.state.model;
    const runbook = runbookContext.state && runbookContext.state.runbook;
    const [gitRef, setGitRef] = useProjectRunbooksGitRef(project, props.queryParams, props.setQueryParams);
    const [environments, setEnvironments] = useState<EnvironmentResource[]>([]);
    const [tenants, setTenants] = useState<TenantResource[]>([]);
    const [take, setTake] = useState<number>(pageSize);
    useDoBusyTaskEffect(doBusyTask, async () => {
        const environmentsPromise = repository.Environments.all();
        if (isAllowed({ permission: Permission.TenantView, tenant: "*" })) {
            const tenants = await repository.Tenants.all();
            setTenants(tenants);
        }
        setEnvironments(await environmentsPromise);
    }, []);
    function onSetFilter(callback: (prev: RunbookTaskFilter) => RunbookTaskFilter) {
        setTake(pageSize);
        setFilter(callback);
    }
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    const getData = async () => {
        const publishedRunbookSnapshot = runbook && runbook.PublishedRunbookSnapshotId ? await repository.RunbookSnapshots.get(runbook.PublishedRunbookSnapshotId) : undefined;
        const runbookRunTemplate = publishedRunbookSnapshot ? await repository.RunbookSnapshots.getRunbookRunTemplate(publishedRunbookSnapshot) : undefined;
        return { publishedRunbookSnapshot, runbookRunTemplate };
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        if (!project || !runbook) {
            return;
        }
        const process = hasGitRunbooks
            ? await repository.Runbooks.getRunbookProcess(project.Id, project.SpaceId, runbook.RunbookProcessId, projectContext.state.gitRef?.CanonicalName)
            : await repository.Runbooks.getRunbookProcess(project.Id, project.SpaceId, runbook.RunbookProcessId);
        setRunbookProcess(process);
        const { publishedRunbookSnapshot, runbookRunTemplate } = await getData();
        setPublishedRunbookSnapshot(publishedRunbookSnapshot);
        setRunbookRunTemplate(runbookRunTemplate);
    }, [project, runbook]);
    const primaryAction = runbook && runbookProcess ? getPrimaryAction(props.spaceId, project, runbook, runbookProcess, gitRef) : undefined;
    const overflowActions = runbook ? getOverflowActions(project, runbook) : undefined;
    const pageActions = runbookProcess ? getPageActions(project, runbookProcess, runbookRunTemplate, publishedRunbookSnapshot) : undefined;
    const titleChip = publishedRunbookSnapshot && <LastPublishedChip project={project} publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified(runbookRunTemplate)}/>;
    return (<PageContent header={{
            title: runbook?.Name ?? "",
            chip: titleChip,
            breadcrumbs: [{ label: "Runbooks", pageUrl: links.projectRunbooksPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }) }],
            primaryAction: primaryAction,
            pageActions: pageActions,
            overflowActions: overflowActions,
            contextSelector: <PageHeaderBranchSelectorVNext project={project} gitRef={gitRef ?? props.queryParams.gitRef} setGitRef={setGitRef}/>,
        }} filters={{
            inputs: [<RunbookRunsStateFilter filter={filter} setFilter={setFilter}/>],
            advancedFilters: {
                onResetFilter: () => getDefaultFilter(),
                hasUserSelectedValues: !isEqual(getDefaultFilter(), filter),
                content: (<AdvancedFilters>
                            <RunbooksAdvancedFilterSection filter={filter} setFilter={setFilter}/>
                        </AdvancedFilters>),
            },
        }} legacyStatus={status} legacyTabs={<RunbooksNavigationTabs />}>
            {runbook && <ScheduledTriggersBar project={project} doBusyTask={doBusyTask} runbook={runbook}/>}
            {runbook && <NewRunbookRunsListPageInternal environments={environments} tenants={tenants} doBusyTask={doBusyTask} project={project} runbook={runbook} gitRef={projectContext.state.gitRef} setTake={setTake} take={take} filter={filter}/>}
        </PageContent>);
}
function isRunbookRunTemplateModified(runbookRunTemplate: RunbookRunTemplateResource | undefined) {
    return runbookRunTemplate && (runbookRunTemplate.IsRunbookProcessModified || runbookRunTemplate.IsVariableSetModified || runbookRunTemplate.IsLibraryVariableSetModified);
}
function getPageActions(project: ProjectResource, runbookProcess: RunbookProcessResource, runbookRunTemplate: RunbookRunTemplateResource | undefined, publishedRunbookSnapshot: RunbookSnapshotResource | undefined): PageAction[] {
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    if (hasGitRunbooks) {
        return [];
    }
    if (runbookProcess.Steps.length === 0) {
        return [];
    }
    return [
        {
            type: "custom",
            key: "Publish",
            content: <PublishButton publishedRunbookSnapshot={publishedRunbookSnapshot} isRunbookRunTemplateModified={isRunbookRunTemplateModified(runbookRunTemplate)}/>,
            hasPermissions: isAllowed({
                permission: Permission.RunbookEdit,
                project: project.Id,
                wildcard: true,
            }),
        },
    ];
}
function getPrimaryAction(spaceId: string, project: ProjectResource, runbook: RunbookResource, runbookProcess: RunbookProcessResource, gitRef: string | undefined): PrimaryPageAction {
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    if (runbookProcess.Steps.length === 0) {
        return {
            type: "navigate",
            label: "Define your Runbook Process",
            path: links.projectRunbookProcessListPage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id, processId: runbook.RunbookProcessId }),
            hasPermissions: isAllowed({ permission: Permission.RunbookEdit, project: project.Id, wildcard: true }),
        };
    }
    const allowedToRunOnCurrentBranch = isAllowedToRunGitRunbook(project, gitRef);
    return {
        type: "custom",
        key: "Run now",
        content: (<RunNowButton spaceId={spaceId} isDisabled={hasGitRunbooks && !allowedToRunOnCurrentBranch} tooltip={hasGitRunbooks && !allowedToRunOnCurrentBranch ? getPermissionDeniedTooltipText(gitRef) : undefined} projectSlug={project.Slug} projectId={project.Id} runbookId={runbook.Id}/>),
        hasPermissions: isAllowed({
            permission: Permission.RunbookRunCreate,
            project: project.Id,
            wildcard: true,
        }),
    };
}
function getOverflowActions(project: ProjectResource, runbook: RunbookResource): SimpleMenuItem[] {
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    const overflowActions: SimpleMenuItem[] = [];
    const isAllowedToViewAudit = isAllowed({
        permission: Permission.EventView,
        wildcard: true,
    });
    if (!hasGitRunbooks) {
        overflowActions.push({
            type: "internal-link",
            label: "View Snapshots",
            path: links.projectRunbookSnapshotsPage.generateUrl({ spaceId: runbook.SpaceId, projectSlug: project.Slug, runbookId: runbook.Id }),
        });
    }
    if (isAllowedToViewAudit) {
        overflowActions.push({
            type: "internal-link",
            label: "Audit Trail",
            path: links.auditPage.generateUrl({ projects: [project.Id], documentTypes: ["Runbooks"], regardingAny: [runbook.Id] }),
        });
    }
    return overflowActions;
}
interface NewRunbookRunsListPageInternalProps {
    project: ProjectResource;
    runbook: RunbookResource;
    doBusyTask: DoBusyTask;
    gitRef?: GitRefResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    filter: RunbookTaskFilter;
    take: number;
    setTake: (updater: (prev: number) => number) => void;
}
function getTaskStatesFromFilterState(taskFilterState?: TaskFilterState) {
    switch (taskFilterState) {
        case TaskFilterStateValues.Incomplete:
            return [TaskState.Queued, TaskState.Executing, TaskState.Cancelling].join(",");
        case TaskFilterStateValues.Completed:
            return [TaskState.Canceled, TaskState.Success, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterStateValues.Unsuccessful:
            return [TaskState.Canceled, TaskState.Failed, TaskState.TimedOut].join(",");
        case TaskFilterStateValues.Queued:
            return TaskState.Queued;
        case TaskFilterStateValues.Executing:
            return TaskState.Executing;
        case TaskFilterStateValues.Cancelling:
            return TaskState.Cancelling;
        case TaskFilterStateValues.Success:
            return TaskState.Success;
        case TaskFilterStateValues.Canceled:
            return TaskState.Canceled;
        case TaskFilterStateValues.TimedOut:
            return TaskState.TimedOut;
        case TaskFilterStateValues.Failed:
            return TaskState.Failed;
        case TaskFilterStateValues.Running:
            return [TaskState.Executing, TaskState.Cancelling].join(",");
        default:
            return undefined;
    }
}
function composeDateFilter(filter: RunbookTaskFilter) {
    // We add one second to 'toDate' because moment considers the end of day to be 11:59:59.
    // We convert it to UTC because that's how we store dates in the backend.
    switch (filter.dateFilterType) {
        case TaskDateFilterTypeValues.CompletedTime:
            return {
                fromCompletedDate: moment.utc(filter.fromDate).format(),
                toCompletedDate: moment.utc(filter.toDate).add(1, "second").format(),
            };
        case TaskDateFilterTypeValues.QueueTime:
            return {
                fromQueueDate: moment.utc(filter.fromDate).format(),
                toQueueDate: moment.utc(filter.toDate).add(1, "second").format(),
            };
        case TaskDateFilterTypeValues.StartTime:
            return {
                fromStartDate: moment.utc(filter.fromDate).format(),
                toStartDate: moment.utc(filter.toDate).add(1, "second").format(),
            };
        default:
            return {};
    }
}
function getDefaultFilter(): RunbookTaskFilter {
    return {
        hasPendingInterruptions: false,
        hasWarningsOrErrors: false,
        fromDate: moment().subtract(1, "month").startOf("day").toDate(),
        toDate: moment().endOf("day").toDate(),
    };
}
function NewRunbookRunsListPageInternal({ project, runbook, filter, doBusyTask, gitRef, tenants, environments, take, setTake }: NewRunbookRunsListPageInternalProps) {
    const hasGitRunbooks = HasRunbooksInGit(project.PersistenceSettings);
    const [runbookProcess, setRunbookProcess] = useState<RunbookProcessResource>();
    const [hasMoreTasks, setHasMoreTasks] = useState<boolean>();
    const [runbookRuns, setRunbookRuns] = useState<ResourceCollection<RunbooksDashboardItemResource>>();
    const refresh = useDoBusyTaskEffect(doBusyTask, async () => {
        const processPromise = hasGitRunbooks
            ? repository.Runbooks.getRunbookProcess(project.Id, project.SpaceId, runbook.RunbookProcessId, gitRef?.CanonicalName)
            : repository.Runbooks.getRunbookProcess(project.Id, project.SpaceId, runbook.RunbookProcessId);
        const searchFilter: TaskListBffArgs = {
            states: getTaskStatesFromFilterState(filter.state),
            project: runbook.ProjectId,
            runbook: runbook.Id,
            environment: filter.environment,
            tenant: filter.tenant,
            spaces: [runbook.SpaceId],
            hasPendingInterruptions: filter.hasPendingInterruptions ? true : undefined, // There is a bug in the endpoint that will not return any results if this is false, need to not supply it at all.
            hasWarningsOrErrors: filter.hasWarningsOrErrors ? true : undefined,
            includeSystem: false,
            ...composeDateFilter(filter),
            take: take,
        };
        const tasks = await repository.Tasks.getBffList(searchFilter);
        const taskIds = tasks.Items.map((task) => task.Id);
        setHasMoreTasks(tasks.Items.length < tasks.TotalResults);
        const runs = await repository.Runbooks.getTaskList(project, runbook.Id, { take: taskIds.length, taskIds });
        setRunbookRuns(runs);
        setRunbookProcess(await processPromise);
    }, [project, runbook, filter, take]);
    useRefreshLoop(refresh, 6000);
    const styles = {
        noRunsContainer: css({
            marginTop: space[24],
            marginBottom: space[24],
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }),
    };
    // If we're still loading, don't render anything
    if (!runbookProcess || !runbookRuns || !environments || !tenants) {
        return null;
    }
    if (runbookProcess.Steps.length === 0 && runbookRuns.Items.length === 0) {
        // Show onboarding if there are no steps and no runs. We don't want to show onboarding if there are
        // runs but they've just deleted the process.
        return <RunbookOnboarding />;
    }
    if (runbookRuns.TotalResults === 0) {
        return (<div className={styles.noRunsContainer}>
                There are no runs for this Runbook yet.
                <NoResults />
            </div>);
    }
    return (<>
            {runbookRuns.Items.length > 0 ? (<RunbookRunList project={project} runs={runbookRuns.Items} environments={environments} tenants={tenants} canLoadMore={!!hasMoreTasks} onLoadMore={() => {
                setTake((currentTake) => currentTake + pageSize);
            }}/>) : (<div className={styles.noRunsContainer}>
                    There are no matching runs.
                    <NoResults />
                </div>)}
        </>);
}
