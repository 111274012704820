import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { ProjectSummaryResource, SensitiveValue, SpaceResource } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { getImportExportMenuItems } from "~/areas/projects/components/ImportExport/ImportExportMenu";
import { repository, session } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { projectChipList } from "~/components/Chips/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import DataBaseComponent from "~/components/DataBaseComponent";
import { ProjectMultiSelect } from "~/components/MultiSelect/ProjectMultiSelect";
import InternalRedirect from "~/components/Navigation/InternalRedirect";
import { PageContent } from "~/components/PageContent/PageContent";
import type { SummaryNode } from "~/components/form";
import { FormSection, Note, Sensitive, Summary } from "~/components/form";
interface ExportProjectsState extends DataBaseComponentState {
    projects: ProjectSummaryResource[];
    space?: SpaceResource;
    includedProjectIds: string[];
    password: SensitiveValue;
    redirectPath?: LinkHref;
}
interface ExportProjectsPageProps {
    spaceId: string;
}
export class ExportProjectsPage extends DataBaseComponent<ExportProjectsPageProps, ExportProjectsState> {
    constructor(props: ExportProjectsPageProps) {
        super(props);
        this.state = {
            projects: [],
            includedProjectIds: [],
            password: { HasValue: false },
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [projects, space] = await Promise.all([repository.Projects.summaries(), repository.Spaces.get(repository.spaceId)]);
            this.setState({
                projects,
                space,
            });
        });
    }
    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }
    doExport = async () => await this.doBusyTask(async () => {
        const projectExportResource = await repository.ImportExport.export({ IncludedProjectIds: this.state.includedProjectIds, Password: this.state.password });
        this.setState({
            redirectPath: links.taskPage.generateUrl({ taskId: projectExportResource.TaskId }),
        });
    });
    projectsChanged = (includedProjectIds: string[]) => {
        if (!this.state.projects)
            return;
        this.setState({ includedProjectIds });
    };
    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true}/>;
        }
        const isSpaceManager = this.isSpaceManager();
        const exportAction: PrimaryPageAction = {
            type: "button",
            label: "Export",
            onClick: this.doExport,
            disabled: this.state.busy || !this.state.password || !this.state.password.NewValue || this.state.password.NewValue === "" || this.state.includedProjectIds.length === 0,
        };
        const selectAllProjects = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            this.setState({ includedProjectIds: this.state.projects.map((x) => x.Id) });
        };
        const selectNoneProjects = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            this.setState({ includedProjectIds: [] });
        };
        return (<PageContent header={{
                title: "Export projects",
                breadcrumbs: [{ label: "Projects", pageUrl: links.projectsPage.generateUrl({ spaceId: this.props.spaceId }) }],
                overflowActions: getImportExportMenuItems(this.props.spaceId),
                primaryAction: exportAction,
            }} busy={this.state.busy} errors={this.errors} callout={this.state.space && !this.isSpaceManager() ? { type: "information", title: "Permission Required", content: "The Space Manager permission is required to import/export projects from this space." } : undefined}>
                {this.state.space && isSpaceManager && (<>
                        <FormSection title="Projects" help="Which projects would you like to include in the exported file." includeBorder={false}>
                            <ProjectMultiSelect onChange={(includedProjectIds) => this.projectsChanged(includedProjectIds)} value={this.state.includedProjectIds} items={this.state.projects} accessibleName="Projects"/>
                            <Note>
                                <ActionList actions={[
                    <a href="#" onClick={selectAllProjects}>
                                            Select all
                                        </a>,
                    <a href="#" onClick={selectNoneProjects}>
                                            Select none
                                        </a>,
                ]}/>
                            </Note>
                        </FormSection>
                        <FormSection title="Password" help="Create a password to protect sensitive values in the exported data." includeBorder={true}>
                            <Sensitive label="Password" value={this.state.password} onChange={(password) => this.setState({ password })} disabled={this.state.includedProjectIds.length === 0 ? true : false}/>
                            <Note>Keep this password safe. You will need this password when importing this export file.</Note>
                        </FormSection>
                    </>)}
            </PageContent>);
    }
    projectsSummary(): SummaryNode {
        return this.state.includedProjectIds && this.state.includedProjectIds.length ? Summary.summary(<div>Export project(s) {projectChipList(this.state.projects, this.state.includedProjectIds)}</div>) : Summary.default("No projects selected");
    }
    passwordSummary(): SummaryNode {
        return this.state.password && this.state.password.NewValue && this.state.password.NewValue !== "" ? Summary.summary(<div>Password has been provided</div>) : Summary.default("No password has been set");
    }
    static displayName = "ExportProjectsPage";
}
