import type { PropertyValueResource, TenantCommonVariableValueResource, TenantProjectVariableValueResource, TenantResource, TenantVariableResource } from "@octopusdeploy/octopus-server-client";
import { repository } from "~/clientInstance";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
export type PageModel = {
    variables: TenantVariableResource;
    commonVariables: TenantCommonVariableValueResource[];
    projectVariables: TenantProjectVariableValueResource[];
};
async function getModelFromApi(tenant: TenantResource): Promise<PageModel> {
    if (!isFeatureToggleEnabled("CommonVariableScopingFeatureToggle")) {
        return {
            variables: await repository.Tenants.getVariables(tenant),
            commonVariables: [],
            projectVariables: [],
        };
    }
    // This is a temporary implementation to maintain the existing model structure
    const variables: TenantVariableResource = {
        Id: "",
        TenantId: tenant.Id,
        TenantName: tenant.Name,
        SpaceId: tenant.SpaceId,
        Links: {},
        LibraryVariables: {},
        ProjectVariables: {},
    };
    const projectVariables = (await repository.Tenants.getProjectVariablesByTenantId(tenant.Id)).ProjectVariables;
    const projectIds = Object.keys(tenant.ProjectEnvironments);
    const projects = await Promise.all(projectIds.map((projectId) => repository.Projects.get(projectId)));
    variables.ProjectVariables = projects.reduce((result, project) => ({
        ...result,
        [project.Id]: {
            ProjectId: project.Id,
            ProjectName: project.Name,
            Templates: project.Templates,
            Variables: tenant.ProjectEnvironments[project.Id].reduce((variables, envId) => ({ ...variables, [envId]: {} }), {}),
            TenantVariables: {},
            Links: project.Links,
        },
    }), {});
    projectVariables.forEach((projectVariable) => {
        const projectId = projectVariable.ProjectId;
        const environmentId = projectVariable.Scope.EnvironmentIds[0];
        const templateId = projectVariable.TemplateId;
        variables.ProjectVariables[projectId].Variables[environmentId] = {
            ...variables.ProjectVariables[projectId].Variables[environmentId],
            [templateId]: projectVariable.Value,
        };
    });
    const commonVariables = (await repository.Tenants.getCommonVariablesByTenantId(tenant.Id)).CommonVariables;
    const libraryVariableSetIds = Array.from(new Set(projects.flatMap((project) => project.IncludedLibraryVariableSetIds)));
    const libraryVariableSets = await Promise.all(libraryVariableSetIds.map((libraryVariableSetId) => repository.LibraryVariableSets.get(libraryVariableSetId)));
    variables.LibraryVariables = libraryVariableSets.reduce((result, libraryVariableSet) => ({
        ...result,
        [libraryVariableSet.Id]: {
            LibraryVariableSetId: libraryVariableSet.Id,
            LibraryVariableSetName: libraryVariableSet.Name,
            Templates: libraryVariableSet.Templates,
            Variables: {},
            TenantVariables: {},
            Links: libraryVariableSet.Links,
        },
    }), {});
    commonVariables.forEach((commonVariable) => {
        const libraryVariableSetId = commonVariable.LibraryVariableSetId;
        const templateId = commonVariable.TemplateId;
        variables.LibraryVariables[libraryVariableSetId].Variables[templateId] = commonVariable.Value;
    });
    return { variables, commonVariables, projectVariables };
}
async function sendModelToApi(tenant: TenantResource, model: PageModel): Promise<void> {
    if (!isFeatureToggleEnabled("CommonVariableScopingFeatureToggle")) {
        await repository.Tenants.setVariables(tenant, model.variables);
        return;
    }
    await repository.Tenants.setProjectVariablesByTenantId(tenant.Id, {
        TenantId: tenant.Id,
        Variables: model.projectVariables,
    });
    await repository.Tenants.setCommonVariablesByTenantId(tenant.Id, {
        TenantId: tenant.Id,
        Variables: model.commonVariables,
    });
}
function updateModelWithCommonVariable(model: PageModel, libraryVariableSetId: string, templateId: string, value: PropertyValueResource): PageModel {
    const newModel = {
        ...model,
        variables: {
            ...model.variables,
            LibraryVariables: {
                ...model.variables.LibraryVariables,
                [libraryVariableSetId]: {
                    ...model.variables.LibraryVariables[libraryVariableSetId],
                    Variables: {
                        ...model.variables.LibraryVariables[libraryVariableSetId].Variables,
                        [templateId]: value,
                    },
                },
            },
        },
    };
    if (!isFeatureToggleEnabled("CommonVariableScopingFeatureToggle"))
        return newModel;
    const index = newModel.commonVariables.findIndex((c) => c.LibraryVariableSetId === libraryVariableSetId && c.TemplateId === templateId && c.Scope.EnvironmentIds.length === 0);
    if (index !== -1) {
        return {
            ...newModel,
            commonVariables: newModel.commonVariables.map((c, i) => (i === index ? { ...c, Value: value ?? "" } : c)),
        };
    }
    return {
        ...newModel,
        commonVariables: [
            ...newModel.commonVariables,
            {
                Id: undefined,
                LibraryVariableSetId: libraryVariableSetId,
                TemplateId: templateId,
                Template: undefined,
                Value: value ?? "",
                Scope: {
                    EnvironmentIds: [],
                },
            },
        ],
    };
}
function updateModelWithProjectVariable(model: PageModel, projectId: string, environmentId: string, templateId: string, value: PropertyValueResource): PageModel {
    const newModel = {
        ...model,
        variables: {
            ...model.variables,
            ProjectVariables: {
                ...model.variables.ProjectVariables,
                [projectId]: {
                    ...model.variables.ProjectVariables[projectId],
                    Variables: {
                        ...model.variables.ProjectVariables[projectId].Variables,
                        [environmentId]: {
                            ...model.variables.ProjectVariables[projectId].Variables[environmentId],
                            [templateId]: value,
                        },
                    },
                },
            },
        },
    };
    if (!isFeatureToggleEnabled("CommonVariableScopingFeatureToggle"))
        return newModel;
    const index = newModel.projectVariables.findIndex((p) => p.ProjectId === projectId && p.Scope.EnvironmentIds.length === 1 && p.Scope.EnvironmentIds[0] === environmentId && p.TemplateId === templateId);
    if (index !== -1) {
        return {
            ...newModel,
            projectVariables: newModel.projectVariables.map((p, i) => (i === index ? { ...p, Value: value ?? "" } : p)),
        };
    }
    return {
        ...newModel,
        projectVariables: [
            ...newModel.projectVariables,
            {
                Id: undefined,
                ProjectId: projectId,
                TemplateId: templateId,
                Template: undefined,
                Value: value ?? "",
                Scope: {
                    EnvironmentIds: [environmentId],
                },
            },
        ],
    };
}
export { getModelFromApi, sendModelToApi, updateModelWithCommonVariable, updateModelWithProjectVariable };
