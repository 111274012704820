/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { FeaturesConfigurationResource, TaskResource, DynamicExtensionsFeaturesMetadataResource, DynamicExtensionsFeaturesValuesResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { cloneDeep, last, sortBy } from "lodash";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import type { AnalyticActionDispatcher, AnalyticTrackedActionDispatcher } from "~/analytics/Analytics";
import { useAnalyticActionDispatch, useAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { ProjectsPageOptimization } from "~/areas/configuration/components/FeaturesLayout/ProjectsPageOptimizationFeature";
import { repository } from "~/clientInstance";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import { LegacyForm } from "~/components/FormPaperLayout/LegacyForm";
import { PageContent } from "~/components/PageContent/PageContent";
import TransitionAnimation from "~/components/TransitionAnimation/TransitionAnimation";
import { FormSectionHeading } from "~/components/form";
import type { GlobalState } from "~/store/reducers";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import type { ConfigurationFeaturesState } from "../../reducers/configurationArea";
import { configurationActions } from "../../reducers/configurationArea";
import { BuiltInWorkerFeature } from "./BuiltInWorkerFeature";
import { BulkDeployment } from "./BulkDeployment";
import { CommunityActionTemplatesFeature } from "./CommunityActionTemplatesFeature";
import { CompositeDockerHubClientFeature } from "./CompositeDockerHubClientFeature";
import { CreateFeedsWithLocalOrSmbPathsFeature } from "./CreateFeedsWithLocalOrSmbPathsFeature";
import { DynamicExtensionsFeature } from "./DynamicExtensionsFeature";
import { ExperimentalFeaturesGroup } from "./ExperimentalFeaturesGroup";
import { ExperimentalUI } from "./ExperimentalUI";
import { GitHubStatusCheckPublishingFeature } from "./GitHubStatusCheckPublishingFeature";
import { HelpSidebarFeature } from "./HelpSidebarFeature";
import { HelpSidebarSupportLink } from "./HelpSidebarSupportLink";
import { StepTemplateUpdates } from "./StepTemplateUpdates";
interface FeaturesPageProps {
    dirtyTrackingDisabled?: boolean;
}
interface FeaturesPageInternalProps extends FeaturesPageProps {
    dispatchAction: AnalyticActionDispatcher;
    trackAction: AnalyticTrackedActionDispatcher;
    features: ConfigurationFeaturesState;
    onFeaturesFetched: (features: FeaturesConfigurationResource) => void;
}
interface ApiResults {
    featuresConfiguration: FeaturesConfigurationResource;
    dynamicFeaturesMetadata: DynamicExtensionsFeaturesMetadataResource;
    dynamicFeaturesValues: DynamicExtensionsFeaturesValuesResource;
}
interface FeaturesState extends OptionalFormBaseComponentState<ApiResults> {
    lastSyncedCommunityStepsTask?: TaskResource<{}>;
    lastSyncedStepPackageFeedTask?: TaskResource<{}>;
    redirectToTaskId?: string;
}
class FeaturesPageInternal extends FormBaseComponent<FeaturesPageInternalProps, FeaturesState, ApiResults> {
    constructor(props: FeaturesPageInternalProps) {
        super(props);
        this.state = {};
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const featuresConfiguration = await repository.FeaturesConfiguration.get();
            const dynamicFeaturesMetadata = await repository.DynamicExtensions.getFeaturesMetadata();
            const dynamicFeaturesValues = await repository.DynamicExtensions.getFeaturesValues();
            this.props.onFeaturesFetched(featuresConfiguration);
            if (this.props.features.isCommunityActionTemplatesEnabled) {
                await this.loadLastCommunityStepsTask();
            }
            if (this.props.features.isAutomaticStepUpdatesEnabled) {
                await this.loadLastSyncedPackageFeedTask();
            }
            const configurationData: ApiResults = {
                featuresConfiguration,
                dynamicFeaturesMetadata,
                dynamicFeaturesValues,
            };
            this.setState({
                model: configurationData,
                cleanModel: cloneDeep(configurationData),
            });
        }, { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    async componentDidUpdate(prevProps: FeaturesPageInternalProps) {
        if (!prevProps.features.isCommunityActionTemplatesEnabled && this.props.features.isCommunityActionTemplatesEnabled) {
            await this.loadLastCommunityStepsTask();
        }
        if (!prevProps.features.isAutomaticStepUpdatesEnabled && this.props.features.isAutomaticStepUpdatesEnabled) {
            await this.loadLastSyncedPackageFeedTask();
        }
    }
    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={links.taskPage.generateUrl({ taskId: this.state.redirectToTaskId })} push={true}/>;
        }
        return (<LegacyForm model={this.state.model} cleanModel={this.state.cleanModel} onSaveClick={this.handleSaveClick} saveText={"Saved"} savePermission={{ permission: Permission.ConfigureServer }} devToolsDirtyTrackingDisabled={this.props.dirtyTrackingDisabled}>
                {({ FormContent, createSaveAction }) => (<PageContent header={{ primaryAction: createSaveAction({}), title: "Features" }} busy={this.state.busy} errors={this.errors}>
                        <FormContent expandAllOnMount={false}>
                            {this.state.model && (<TransitionAnimation>
                                    <ExperimentalFeaturesGroup>
                                        <ExperimentalUI isEnabled={this.state.model!.featuresConfiguration.IsExperimentalUIFeatureEnabled} onChange={(IsExperimentalUIFeatureEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsExperimentalUIFeatureEnabled } })}/>
                                        <GitHubStatusCheckPublishingFeature isEnabled={this.state.model!.featuresConfiguration.IsGitHubStatusCheckPublishingEnabled} onChange={(IsGitHubStatusCheckPublishingEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsGitHubStatusCheckPublishingEnabled } })}/>
                                        <ProjectsPageOptimization isEnabled={this.state.model!.featuresConfiguration.IsProjectsPageOptimizationEnabled} onChange={(IsProjectsPageOptimizationEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsProjectsPageOptimizationEnabled } })}/>
                                    </ExperimentalFeaturesGroup>
                                    <FormSectionHeading title="Deployments"/>
                                    <BulkDeployment isEnabled={this.state.model!.featuresConfiguration.IsBulkDeploymentCreationEnabled} onChange={(IsBulkDeploymentCreationEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsBulkDeploymentCreationEnabled } })}/>
                                    <FormSectionHeading title="Feeds"/>
                                    <CompositeDockerHubClientFeature isEnabled={this.state.model!.featuresConfiguration.IsCompositeDockerHubRegistryFeedEnabled} onChange={(IsCompositeDockerHubRegistryFeedEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsCompositeDockerHubRegistryFeedEnabled } })}/>
                                    <CreateFeedsWithLocalOrSmbPathsFeature isEnabled={this.state.model!.featuresConfiguration.IsConfigureFeedsWithLocalOrSmbPathsEnabled} onChange={(IsConfigureFeedsWithLocalOrSmbPathsEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsConfigureFeedsWithLocalOrSmbPathsEnabled } })}/>
                                    <FormSectionHeading title="Steps"/>
                                    <StepTemplateUpdates isEnabled={this.state.model!.featuresConfiguration.IsAutomaticStepUpdatesEnabled} onChange={(IsAutomaticStepUpdatesEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsAutomaticStepUpdatesEnabled } })} isSyncEnabled={this.state.cleanModel?.featuresConfiguration?.IsAutomaticStepUpdatesEnabled === true} onSyncClick={() => this.synchronizeStepsWithPackageFeed()} lastSyncedTask={this.state.lastSyncedStepPackageFeedTask} busy={this.state.busy}/>
                                    <CommunityActionTemplatesFeature isEnabled={this.state.model!.featuresConfiguration.IsCommunityActionTemplatesEnabled} lastSyncedTask={this.state.lastSyncedCommunityStepsTask} isSyncEnabled={this.state.cleanModel?.featuresConfiguration?.IsCommunityActionTemplatesEnabled === true} busy={this.state.busy} onSyncClick={() => this.synchronizeLibrarySteps()} onChange={(IsCommunityActionTemplatesEnabled: boolean) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsCommunityActionTemplatesEnabled } })}/>
                                    <BuiltInWorkerFeature isEnabled={this.state.model!.featuresConfiguration.IsBuiltInWorkerEnabled} onChange={(newValue: boolean) => {
                        this.setModelState({
                            featuresConfiguration: {
                                ...this.state.model!.featuresConfiguration,
                                IsBuiltInWorkerEnabled: newValue,
                            },
                        });
                    }}/>
                                    <FormSectionHeading title="Help Sidebar"/>
                                    <HelpSidebarSupportLink link={this.state.model!.featuresConfiguration.HelpSidebarSupportLink!} label={this.state.model!.featuresConfiguration.HelpSidebarSupportLinkLabel} onChangeLink={(x) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, HelpSidebarSupportLink: x } })} onChangeLinkLabel={(x) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, HelpSidebarSupportLinkLabel: x } })} linkError={this.getFieldError("HelpSidebarSupportLink")} labelError={this.getFieldError("HelpSidebarSupportLinkLabel")}/>
                                    <HelpSidebarFeature isEnabled={this.state.model!.featuresConfiguration.IsHelpSidebarEnabled} onChange={(IsHelpSidebarEnabled) => this.setModelState({ featuresConfiguration: { ...this.state.model!.featuresConfiguration, IsHelpSidebarEnabled } })}/>
                                    {this.state.model.dynamicFeaturesMetadata.Features.length > 0 && (<>
                                            <FormSectionHeading title="Dynamic Extensions"/>
                                            <DynamicExtensionsFeature dynamicFeaturesMetadata={this.state.model.dynamicFeaturesMetadata} dynamicFeaturesValues={this.state.model.dynamicFeaturesValues} onChange={this.updateDynamicFeaturesValues}/>
                                        </>)}
                                </TransitionAnimation>)}
                        </FormContent>
                    </PageContent>)}
            </LegacyForm>);
    }
    private updateDynamicFeaturesValues(key: string, value: string) {
        this.setModelState({ dynamicFeaturesValues: { Values: { ...this.state.model!.dynamicFeaturesValues.Values, [key]: value } } });
    }
    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const featuresSaveResult = await repository.FeaturesConfiguration.modify(this.state.model!.featuresConfiguration);
            const valuesSaveResult = await repository.DynamicExtensions.putFeaturesValues(this.state.model!.dynamicFeaturesValues);
            this.props.onFeaturesFetched(featuresSaveResult);
            const configurationData: ApiResults = {
                featuresConfiguration: featuresSaveResult,
                dynamicFeaturesMetadata: this.state.model!.dynamicFeaturesMetadata,
                dynamicFeaturesValues: valuesSaveResult,
            };
            this.setState({
                model: configurationData,
                cleanModel: cloneDeep(configurationData),
            });
        });
    };
    private async synchronizeLibrarySteps() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createSynchronizeCommunityStepTemplatesTask();
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private async synchronizeStepsWithPackageFeed() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.createSynchronizeStepsPackageFeedTask();
            this.setState({ redirectToTaskId: task.Id });
        });
    }
    private async loadLastCommunityStepsTask() {
        const tasks = await repository.Tasks.filter({ name: "SyncCommunityActionTemplates", take: 1 });
        if (tasks.Items.length > 0) {
            const tasksByCompleted = sortBy(tasks.Items, "CompletedTime");
            const lastSyncedTask = last(tasksByCompleted);
            this.setState({ lastSyncedCommunityStepsTask: lastSyncedTask });
        }
    }
    private async loadLastSyncedPackageFeedTask() {
        const tasks = await repository.Tasks.filter({ name: "AcquireStepPackages", take: 1 });
        if (tasks.Items.length > 0) {
            const tasksByCompleted = sortBy(tasks.Items, "CompletedTime");
            const lastSyncedTask = last(tasksByCompleted);
            this.setState({ lastSyncedStepPackageFeedTask: lastSyncedTask });
        }
    }
    static displayName = "FeaturesPageInternal";
}
export function FeaturesPage(props: FeaturesPageProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const trackAction = useAnalyticTrackedActionDispatch();
    const features = useSelector((state: GlobalState) => state.configurationArea.features);
    const dispatch = useDispatch();
    const onFeaturesFetched = React.useCallback((features: FeaturesConfigurationResource) => {
        dispatch(configurationActions.featuresFetched(features));
    }, [dispatch]);
    return <FeaturesPageInternal {...props} dispatchAction={dispatchAction} trackAction={trackAction} features={features} onFeaturesFetched={onFeaturesFetched}/>;
}
