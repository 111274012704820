import { Level1PageLayout, Level2InnerPageLayout, useIsBusy } from "@octopusdeploy/design-system-components";
import type { BreadcrumbItem, CalloutType, PageAction, PrimaryPageAction, SimpleMenuItem } from "@octopusdeploy/design-system-components";
import type { ApiOperationStatus } from "@octopusdeploy/octopus-react-client";
import { useAggregateAPIOperationStatus } from "@octopusdeploy/octopus-react-client";
import type { ReactElement, ReactNode } from "react";
import React from "react";
import { usePageRegistration } from "~/routing/pageRegistrations/PageRegistrationContext";
import { type Errors } from "../DataBaseComponent/Errors";
import { createErrorInfoFromError, createErrorInfoFromErrors } from "./createErrorInfo";
interface PageContentBaseProps {
    header: {
        title: string;
        titleComplementaryText?: string;
        logo?: ReactElement;
        chip?: ReactElement;
        contextSelector?: ReactElement;
        breadcrumbs?: BreadcrumbItem[];
        showBreadcrumbBackIcon?: boolean;
        primaryAction?: PrimaryPageAction;
        pageActions?: PageAction[];
        overflowActions?: SimpleMenuItem[];
        pageLayoutV2?: boolean;
    };
    /**
     * @deprecated Pages should use the new data access hooks. Busy state will be handled automatically.
     */
    busy?: Promise<unknown> | boolean;
    /**
     * @deprecated Pages should use the new data access hooks. Busy state will be handled automatically.
     */
    legacyStatus?: ApiOperationStatus;
    description?: ReactNode;
    callout?: PageContentCalloutType;
    /**
     * @deprecated Pages should use the new data access hooks. Errors will be handled automatically.
     */
    errors?: Errors | Error[];
    /**
     * Temporary Prop to handle changes to Level1PageLayout modifications by FFT while we update the pages
     * prop prevents breaking pages that are yet to be migrated to the new Page Layouts design
     */
    pageLayoutV2?: boolean;
    /**
     * @deprecated Overriding the full width on the page content is not recommended. Please configure this via
     * the page registration as this flows through to page loaders as well as page content implicitly.
     */
    overrideFullWidth?: boolean;
}
export type PageContentCalloutType = {
    type: "custom";
    content: React.ReactElement;
} | {
    title?: ReactNode;
    content: ReactNode;
    type: CalloutType;
    onClose?: () => void;
};
interface ContentProps {
    filters?: {
        inputs: ReactNode[];
        filtersSummary?: ReactNode;
        advancedFilters?: {
            content: ReactNode;
            onResetFilter: () => void;
            hasUserSelectedValues: boolean;
        };
    };
    pagination?: {
        ui: ReactNode;
        placement: "top" | "bottom" | "topAndBottom";
    };
    /**
     * @deprecated Avoid using sidebars for new pages; Consult your designer or Frontend Foundations Team for alternative solutions.
     */
    sidebar?: ReactNode;
}
type PageContentPropsWithoutTabs = PageContentBaseProps & ContentProps & {
    children: ReactNode;
    /**
     * @deprecated Legacy tabs have been included to support using tabs for navigation as the third level.
     * This is not a pattern we want to support going forward, so please prefer using built in tab support
     * or by finding an alternate approach.
     */
    legacyTabs?: ReactNode;
};
type PageContentPropsWithTabs<TabValue extends string> = PageContentBaseProps & {
    tabs: PageContentTab<TabValue>[];
    onTabChanged: (value: TabValue) => void;
    currentTab: TabValue;
};
export interface PageContentTab<TabValue extends string> extends ContentProps {
    value: TabValue;
    label: string;
    content: ReactNode;
    warning?: string;
}
export type PageContentProps<TabValue extends string = never> = PageContentPropsWithTabs<TabValue> | PageContentPropsWithoutTabs;
export function PageContent<TabValue extends string>({ header, busy, errors: legacyErrors, legacyStatus, description, callout, overrideFullWidth, ...props }: PageContentProps<TabValue>) {
    const pageRegistration = usePageRegistration();
    const isBusy = useIsBusy(busy);
    const { errors, isInProgress } = useAggregateAPIOperationStatus(legacyStatus);
    const legacyErrorInfo = legacyErrors ? createErrorInfoFromErrors(legacyErrors) : undefined;
    const combinedErrorInfos = errors.map(createErrorInfoFromError);
    if (legacyErrorInfo && legacyErrorInfo.length > 0) {
        combinedErrorInfos.push(...legacyErrorInfo);
    }
    const combinedIsInProgress = isInProgress || isBusy;
    if (pageRegistration === null) {
        throw new Error("A page registration is required to use PageContent. If you are running a test, specify a pageRegistration in the configuration object for renderWithTestAppContext");
    }
    if (pageRegistration.level === 1) {
        if ("tabs" in props) {
            // TODO: Tabs not yet supported in Level1PageLayout
            return null;
        }
        return (<Level1PageLayout isFullWidth={overrideFullWidth ?? pageRegistration.isFullWidth} header={header} callout={callout} errors={combinedErrorInfos} busy={combinedIsInProgress} filters={props.filters} pagination={props.pagination} pageLayoutV2={props.pageLayoutV2}>
                {props.children}
            </Level1PageLayout>);
    }
    if ("tabs" in props) {
        return (<Level2InnerPageLayout header={header} callout={callout} errors={combinedErrorInfos} busy={combinedIsInProgress} description={description} fullWidth={overrideFullWidth ?? pageRegistration.isFullWidth} tabs={props.tabs} currentTab={props.currentTab} onTabChanged={props.onTabChanged}/>);
    }
    return (<Level2InnerPageLayout header={header} callout={callout} errors={combinedErrorInfos} busy={combinedIsInProgress} description={description} fullWidth={overrideFullWidth ?? pageRegistration.isFullWidth} filters={props.filters} pagination={props.pagination} sidebar={props.sidebar} legacyTabs={props.legacyTabs}>
            {props.children}
        </Level2InnerPageLayout>);
}
