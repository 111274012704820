import type { ProjectResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { DataCube } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import ReleaseFilter from "~/areas/projects/components/DeploymentsOverview/ReleaseFilter";
import type { DeploymentOverviewFilters } from "~/areas/projects/components/DeploymentsOverview/hooks/useDeploymentsOverviewFilters";
import styles from "~/areas/projects/components/DeploymentsOverview/style.module.less";
import { AdvancedTenantsAndTenantTagsSelector } from "~/components/AdvancedTenantSelector/index";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
import { SelectField } from "~/primitiveComponents/form/Select/SelectField";
interface DeploymentsOverviewFilterOptionsProps {
    cube: DataCube;
    filters: DeploymentOverviewFilters;
    onFiltersUpdated: (filters: DeploymentOverviewFilters) => void;
}
interface DeploymentsOverviewFilterOptionsAndProjectProps extends DeploymentsOverviewFilterOptionsProps {
    project: ProjectResource;
}
interface DeploymentsOverviewFilterOptionsAndProjectTenantProps extends DeploymentsOverviewFilterOptionsAndProjectProps {
    tenants: TenantResource[];
    doBusyTask: DoBusyTask;
}
export function DeploymentsOverviewReleasesFilter({ cube, project, filters, onFiltersUpdated }: DeploymentsOverviewFilterOptionsAndProjectProps) {
    const releases = Object.values(cube.releaseIndex);
    const shouldShowReleaseFilter = !(filters.rowDimension === DimensionTypes.Release) && releases.length > 0;
    const onReleaseFilterUpdated = (releaseId: string) => {
        const filt = { ...filters };
        if (!releaseId) {
            delete filt[DimensionTypes.Release];
        }
        else {
            filt[DimensionTypes.Release] = { [releaseId]: true };
        }
        onFiltersUpdated(filt);
    };
    const filteredReleaseVersion = filters[DimensionTypes.Release] ? Object.keys(filters[DimensionTypes.Release])[0] : "";
    if (!shouldShowReleaseFilter) {
        return null;
    }
    return <ReleaseFilter filterUpdated={onReleaseFilterUpdated} project={project} releases={releases} channelIndex={cube.channelIndex} value={filteredReleaseVersion}/>;
}
export function DeploymentsOverviewGroupingOptions({ cube, filters, onFiltersUpdated }: DeploymentsOverviewFilterOptionsProps) {
    const selectedGroup = filters.groupBy === DimensionTypes.None || !filters.groupBy ? undefined : `${filters.groupBy}-${filters.groupByExtra || ""}`;
    const menuItems: DropdownMenuOption[] = [{ value: "", text: "No Grouping", onChange: () => onFiltersUpdated({ ...filters, groupBy: DimensionTypes.None }) }];
    if (Object.keys(cube.channelIndex).length > 1) {
        menuItems.push({ value: `${DimensionTypes.Channel}-`, text: "Channel", onChange: () => onFiltersUpdated({ ...filters, groupBy: DimensionTypes.Channel }) });
    }
    if (Object.keys(cube.tagSetIndex).length > 0) {
        Object.keys(cube.tagSetIndex).forEach((tagsetId) => menuItems.push({ value: `${DimensionTypes.TagSet}-${tagsetId}`, text: cube.tagSetIndex[tagsetId].Name, onChange: () => onFiltersUpdated({ ...filters, groupBy: DimensionTypes.TagSet, groupByExtra: tagsetId }) }));
    }
    if (menuItems.length === 0) {
        return null;
    }
    return <SelectField placeholder="Group by" value={selectedGroup} className={styles.selectFilter} items={menuItems}/>;
}
export function DeploymentsOverviewEnvironmentFilter({ cube, filters, onFiltersUpdated }: DeploymentsOverviewFilterOptionsProps) {
    const environments = Object.values(cube.environmentIndex);
    const handleEnvironmentFilterUpdated = (environmentIds: string[]) => {
        const filt = { ...filters };
        if (environmentIds.length === 0) {
            delete filt[DimensionTypes.Environment];
        }
        else {
            filt[DimensionTypes.Environment] = environmentIds.reduce((f: {
                [key: string]: boolean;
            }, environmentId) => {
                f[environmentId] = true;
                return f;
            }, {});
        }
        onFiltersUpdated(filt);
    };
    if (environments.length === 0) {
        return null;
    }
    return <EnvironmentMultiSelect items={environments} value={Object.keys(filters[DimensionTypes.Environment] || {})} onChange={handleEnvironmentFilterUpdated}/>;
}
export function DeploymentsOverviewTenantFilter({ cube, doBusyTask, filters, onFiltersUpdated, project, tenants }: DeploymentsOverviewFilterOptionsAndProjectTenantProps) {
    if (!project || project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
        return null;
    }
    const selectedTags = Object.keys(filters[DimensionTypes.TagSet] || {});
    const selectedTenants = Object.keys(filters[DimensionTypes.Tenant] || {});
    const onTenantsUpdate = (selectedTenants: string[], selectedTags: string[]) => {
        const filt = { ...filters };
        if (selectedTenants.length === 0) {
            delete filt[DimensionTypes.Tenant];
        }
        else {
            filt[DimensionTypes.Tenant] = selectedTenants.reduce((f: {
                [key: string]: boolean;
            }, tenantId) => {
                f[tenantId] = true;
                return f;
            }, {});
        }
        if (selectedTags.length === 0) {
            delete filt[DimensionTypes.TagSet];
        }
        else {
            filt[DimensionTypes.TagSet] = selectedTags.reduce((f: {
                [key: string]: boolean;
            }, tagId) => {
                f[tagId] = true;
                return f;
            }, {});
        }
        onFiltersUpdated(filt);
    };
    return (<AdvancedTenantsAndTenantTagsSelector selectedTenantIds={selectedTenants} selectedTenantTags={selectedTags} tenants={tenants} doBusyTask={doBusyTask} onChange={(tenantIds, tenantTags) => onTenantsUpdate(tenantIds, tenantTags)} showPreviewButton={true}/>);
}
