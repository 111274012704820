import { css } from "@emotion/css";
import { CircularProgress } from "@octopusdeploy/design-system-components";
import { borderRadius, colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import React from "react";
import BusyFromPromise from "~/components/BusyFromPromise";
import DataBaseComponent from "~/components/DataBaseComponent";
import type { OctopusTheme } from "~/components/Theme";
import { useOctopusTheme } from "~/components/Theme";
import { ErrorPanel } from "~/components/form";
import { OverviewCardTitle } from "./CardTitle";
import styles from "./style.module.less";
export interface InfraOverviewCardProps<TResource> {
    dataPromise: Promise<TResource>;
    title: string;
    link: LinkHref | undefined;
    hasContent(resource: TResource): boolean;
    getCount(resource: TResource): number;
    renderContent: (summaryResource: TResource) => React.ReactElement;
}
type InfraOverviewCardPropsWithTheme<TResource> = InfraOverviewCardProps<TResource> & {
    theme: OctopusTheme;
};
interface InfraOverviewCardState<TResource> {
    summaryResource: TResource | null;
}
class InfraOverviewCardWithTheme<TResource> extends DataBaseComponent<InfraOverviewCardPropsWithTheme<TResource>, InfraOverviewCardState<TResource>> {
    constructor(props: InfraOverviewCardPropsWithTheme<TResource>) {
        super(props);
        this.state = {
            summaryResource: null,
        };
    }
    async componentDidMount() {
        return this.doBusyTask(async () => {
            const resource = await this.props.dataPromise;
            this.setState({
                summaryResource: resource,
            });
        });
    }
    render() {
        const count = this.state.summaryResource !== null ? this.props.getCount(this.state.summaryResource) : null;
        const shouldRenderCardContent = this.state.summaryResource !== null ? this.props.hasContent(this.state.summaryResource) : true;
        return (<div className={card}>
                <div className={cardTitleContainer}>
                    <div className={styles.cardTitle}>
                        <OverviewCardTitle link={this.props.link} theme={this.props.theme} count={count} title={this.props.title}/>
                    </div>
                </div>
                <div className={cardContentContainer}>
                    {shouldRenderCardContent && (<>
                            {this.errors && <ErrorPanel message={this.errors.message} errors={this.errors.errors} scrollToPanel={false}/>}
                            <BusyFromPromise promise={this.state.summaryResource === null}>{(busy: boolean) => busy && <CircularProgress size="small"/>}</BusyFromPromise>
                            {this.state.summaryResource !== null && this.props.renderContent(this.state.summaryResource)}
                        </>)}
                </div>
            </div>);
    }
    static displayName = "InfraOverviewCardWithTheme";
}
export function InfraOverviewCard<TResource>(props: InfraOverviewCardProps<TResource>) {
    const theme = useOctopusTheme();
    return <InfraOverviewCardWithTheme<TResource> {...props} theme={theme}/>;
}
const card = css({
    boxShadow: themeTokens.shadow.small,
    borderRadius: borderRadius.small,
    overflow: "hidden",
    color: themeTokens.color.text.primary,
    textDecoration: "none",
    transition: "background-color 0.1s ease-in",
    display: "flex",
    flexDirection: "column",
    width: "17.13rem",
    height: "21.875rem",
    minHeight: "21.875rem",
    position: "relative",
    background: "var(--paper2)",
});
const cardTitleContainer = css({
    color: colorScales.white,
    backgroundColor: "var(--mainBackground)",
    font: text.heading.small,
    height: "3rem",
    minHeight: "3rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0 ${space[16]}`,
});
const cardContentContainer = css({
    minHeight: "2rem",
    display: "flex",
    flexDirection: "column",
    color: themeTokens.color.text.secondary,
    font: text.regular.default.small,
    // No bottom padding allows for overflow to be visible
    // No right padding ensures scrollbar does not overflow the content
    padding: `${space[16]} 0 0 ${space[16]}`,
});
