/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { RadioButtonGroup, RadioButton, Tooltip, Callout } from "@octopusdeploy/design-system-components";
import { UserCircleIcon } from "@octopusdeploy/design-system-icons";
import type { IProcessResource, DeploymentResource, IExecutionResource, Form, TenantResource } from "@octopusdeploy/octopus-server-client";
import { MachineModelHealthStatus, OctopusError, isTenantWithFrozenInfo } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as _ from "lodash";
import * as React from "react";
import { HealthyMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/HealthyMachineIcon";
import { UnavailableMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/UnavailableMachineIcon";
import { UnhealthyMachineIcon } from "~/areas/infrastructure/components/MachineHealthStatusIcons/UnhealthyMachineIcon";
import { DeploymentModelType } from "~/areas/projects/components/Runbooks/RunbookRunNowLayout";
import { ChipIconWrapper } from "~/components/Chips/ChipIconWrapper";
import { EnvironmentChip, TenantChip } from "~/components/Chips/index";
import type { DataBaseComponentState } from "~/components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent/DataBaseComponent";
import { createErrorsFromOctopusError } from "~/components/DataBaseComponent/Errors";
import FrozenDashboardIcon from "~/components/DeploymentFreezes/FrozenDashboardIcon";
import { FrozenResourceType } from "~/components/DeploymentFreezes/FrozenIcon";
import ErrorPanel from "~/components/ErrorPanel/ErrorPanel";
import { MachineMultiSelect } from "~/components/MultiSelect/MachineMultiSelect";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import WarningIcon from "~/components/WarningIcon/WarningIcon";
import CardExpandable from "~/components/form/Sections/CardExpandable";
import ExpandableFormSection from "~/components/form/Sections/ExpandableFormSection";
import Summary from "~/components/form/Sections/Summary";
import { DataTableBody } from "~/primitiveComponents/dataDisplay/DataTable/DataTableBody";
import { DataTableRow } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRow";
import { DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable/DataTableRowColumn";
import ReleaseChangesDetail from "../../ReleaseChanges/ReleaseChangesDetail";
import type { DeploymentMachineInfo } from "../Preview";
import { DeploymentTargetType, DeploymentType } from "../Preview";
import type { DeploymentRequestModel } from "../deploymentRequestModel";
import type { ActionToggleInfo, MachineDeploymentPreview } from "../deploymentStepsWorker";
import ActionPreview from "./ActionPreview";
import styles from "./style.module.less";
interface DeploymentResultItemProps {
    deployment: DeploymentRequestModel;
    stepActionIdsToSkip: string[];
    isMissingVariable: boolean;
    tenant?: TenantResource;
    promptVariableForm: Form;
    stepsForSelectedDeployment: ActionToggleInfo[];
    actions: ActionToggleInfo[];
    process: IProcessResource;
    releaseCount: number;
    workItemsCount: number;
    changesMarkdown: string;
    modelType: DeploymentModelType;
    isExpandedByDefault?: boolean;
    onIncludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onExcludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onAllTargetsSelected(deployment: DeploymentMachineInfo): void;
}
interface DeploymentResultItemState extends DataBaseComponentState {
    deploymentTargetType: DeploymentTargetType;
    expanded: boolean;
    machinesApplicableToThisDeployment: MachineDeploymentPreview[];
    numberOfSteps: number;
}
export default class DeploymentResultItem extends DataBaseComponent<DeploymentResultItemProps, DeploymentResultItemState> {
    constructor(props: DeploymentResultItemProps) {
        super(props);
        this.state = {
            deploymentTargetType: this.determineTargetType(props.deployment),
            expanded: this.props.isExpandedByDefault!,
            machinesApplicableToThisDeployment: [],
            numberOfSteps: 0,
        };
    }
    determineTargetType = (deployment: DeploymentRequestModel) => {
        if (deployment && deployment.request.SpecificMachineIds.length > 0) {
            return DeploymentTargetType.IncludeSpecific;
        }
        if (deployment && deployment.request.ExcludedMachineIds.length > 0) {
            return DeploymentTargetType.ExcludeSpecific;
        }
        return DeploymentTargetType.AllApplicable;
    };
    async componentDidMount() {
        await this.loadData(this.props);
    }
    async UNSAFE_componentWillReceiveProps(nextProps: DeploymentResultItemProps) {
        if (_.isEqual(nextProps.deployment, this.props.deployment) &&
            _.isEqual(nextProps.stepActionIdsToSkip, this.props.stepActionIdsToSkip) &&
            _.isEqual(nextProps.deployment, this.props.deployment) &&
            _.isEqual(nextProps.stepActionIdsToSkip, this.props.stepActionIdsToSkip) &&
            _.isEqual(nextProps.stepsForSelectedDeployment, this.props.stepsForSelectedDeployment)) {
            return;
        }
        await this.loadData(nextProps);
    }
    render() {
        return this.buildDeploymentSummary();
    }
    private async loadData(props: DeploymentResultItemProps) {
        if (props.stepsForSelectedDeployment) {
            const allMachines = _.uniqBy(_.flatMap(props.stepsForSelectedDeployment, (s) => s.details.Machines), (m) => m.Id);
            const numberOfSteps = props.stepsForSelectedDeployment.length - props.stepActionIdsToSkip.length - props.stepsForSelectedDeployment.filter((s) => s.details.IsDisabled).length;
            this.setState({ machinesApplicableToThisDeployment: allMachines, numberOfSteps });
        }
    }
    private makeDeploymentMachinesInfo(machineIds: string[], deploymentType: any) {
        return {
            id: this.props.deployment.tenantId ? this.props.deployment.tenantId : this.props.deployment.request.EnvironmentId,
            machineIds,
            deploymentType,
        };
    }
    private getDeploymentType() {
        let deploymentType = DeploymentType.Environment;
        if (this.props.deployment.tenantId) {
            deploymentType = DeploymentType.Tenant;
        }
        return deploymentType;
    }
    private buildDeploymentSummary() {
        const deployment = this.props.deployment;
        const deploymentType = this.getDeploymentType();
        const variableInfo = this.getDeploymentMissingVariableInfo(deploymentType);
        let chip = undefined;
        if (this.props.tenant) {
            const tenant = this.props.tenant;
            chip = (<TenantChip tenantName={tenant.Name} isDisabled={!!tenant.IsDisabled} icon={isTenantWithFrozenInfo(tenant) && tenant.IsFrozen ? (<FrozenDashboardIcon for={FrozenResourceType.Tenant}/>) : (<ChipIconWrapper>
                                <UserCircleIcon size={16}/>
                            </ChipIconWrapper>)}/>);
        }
        else
            chip = <EnvironmentChip environmentName={deployment.environment.name}/>;
        return (<DataTableBody className={styles.deploymentResultItem}>
                <DataTableRow className={styles.deploymentsTableRow} onClick={() => this.setState((prev) => {
                return { expanded: !prev.expanded };
            })}>
                    <DataTableRowColumn>
                        <div className={styles.targetColumn}>
                            {this.props.deployment && this.successOrErrorIcon()}
                            {deployment.environment && <div className={styles.environmentOrTenantIcon}>{chip}</div>}
                        </div>
                    </DataTableRowColumn>
                    <DataTableRowColumn>
                        <div>{deployment.currentVersion}</div>
                    </DataTableRowColumn>
                    <DataTableRowColumn>
                        <div>
                            {this.state.numberOfSteps} {this.state.numberOfSteps === 1 ? "step" : "steps"}
                        </div>
                    </DataTableRowColumn>
                    <DataTableRowColumn>
                        <div>{this.getIncludeExcludeTargetsInfo()}</div>
                    </DataTableRowColumn>
                    <DataTableRowColumn>
                        <div>
                            {this.getTargetsInfo()}
                            {variableInfo}
                        </div>
                    </DataTableRowColumn>
                    <DataTableRowColumn fullWidth={true}>
                        <div className={styles.expandCollapse}>
                            <CardExpandable expanded={this.state.expanded}/>
                        </div>
                    </DataTableRowColumn>
                </DataTableRow>
                {this.state.expanded && this.buildDetailsChildRow(deploymentType)}
            </DataTableBody>);
    }
    private getIncludeExcludeTargetsInfo() {
        let includeExcludeMachinesInfo = "All included";
        if (this.props.deployment.request.SpecificMachineIds.length > 0) {
            includeExcludeMachinesInfo = `${this.props.deployment.request.SpecificMachineIds.length} included`;
        }
        if (this.props.deployment.request.ExcludedMachineIds.length > 0) {
            includeExcludeMachinesInfo = `${this.props.deployment.request.ExcludedMachineIds.length} excluded`;
        }
        return includeExcludeMachinesInfo;
    }
    private buildDetailsChildRow(deploymentType: DeploymentType) {
        const deployment = this.props.deployment;
        return (<DataTableRow>
                <DataTableRowColumn colSpan={6}>
                    {this.state.expanded && (<div>
                            {deployment && (<div>
                                    <div className={styles.section}>
                                        {this.successOrErrorDetails(this.props.deployment)}
                                        <ExpandableFormSection isExpandedByDefault={this.props.isExpandedByDefault} title="Deployment Targets" help="Include/Exclude specific deployment targets." summary={Summary.summary(<div>{this.getIncludeExcludeTargetsInfo()}</div>)} errorKey="deploymentTargets">
                                            <RadioButtonGroup value={this.state.deploymentTargetType} onChange={(val: DeploymentTargetType) => {
                        this.setState({ deploymentTargetType: val });
                        if (val === DeploymentTargetType.AllApplicable) {
                            const deploymentInfo: DeploymentMachineInfo = this.makeDeploymentMachinesInfo([], deploymentType);
                            this.props.onAllTargetsSelected(deploymentInfo);
                        }
                    }}>
                                                <RadioButton value={DeploymentTargetType.AllApplicable} label="Include all applicable deployment targets" isDefault={true}/>
                                                <RadioButton value={DeploymentTargetType.IncludeSpecific} label="Include specific deployment targets"/>
                                                <div>
                                                    {this.state.deploymentTargetType === DeploymentTargetType.IncludeSpecific && (<div>
                                                            <MachineMultiSelect value={this.props.deployment.request.SpecificMachineIds} items={this.state.machinesApplicableToThisDeployment} onChange={(machineIds) => {
                            const deploymentInfo = this.makeDeploymentMachinesInfo(machineIds, deploymentType);
                            this.props.onIncludeSpecificMachinesSelected(deploymentInfo);
                        }}/>
                                                        </div>)}
                                                </div>
                                                <RadioButton value={DeploymentTargetType.ExcludeSpecific} label="Exclude specific deployment targets"/>
                                                <div>
                                                    {this.state.deploymentTargetType === DeploymentTargetType.ExcludeSpecific && (<div>
                                                            <MachineMultiSelect value={this.props.deployment.request.ExcludedMachineIds} items={this.state.machinesApplicableToThisDeployment} onChange={(machineIds) => {
                            const deploymentInfo = this.makeDeploymentMachinesInfo(machineIds, deploymentType);
                            this.props.onExcludeSpecificMachinesSelected(deploymentInfo);
                        }}/>
                                                        </div>)}
                                                </div>
                                            </RadioButtonGroup>
                                        </ExpandableFormSection>
                                        {this.props.modelType === DeploymentModelType.Deployment && this.props.changesMarkdown && (<ExpandableFormSection title="Changes" summary={this.getReleaseChangesSummary()} help={this.getReleaseChangesText()} errorKey="releaseNotes">
                                                <ReleaseChangesDetail changesMarkdown={this.props.changesMarkdown}/>
                                            </ExpandableFormSection>)}
                                    </div>
                                    <ActionPreview deploymentInfo={this.props.deployment ? this.props.deployment.request : null!} stepActionIdsToSkip={this.props.stepActionIdsToSkip} actions={this.props.actions} process={this.props.process}/>
                                </div>)}
                        </div>)}
                </DataTableRowColumn>
            </DataTableRow>);
    }
    private getReleaseChangesSummary() {
        return Summary.summary(<div>{this.getReleaseChangesText()}</div>);
    }
    private getReleaseChangesText() {
        const releaseCount = this.props.releaseCount;
        const workItemCount = this.props.workItemsCount;
        return releaseCount + " release(s), containing " + workItemCount + " work item(s)";
    }
    private getTargetsInfo() {
        if (this.state.machinesApplicableToThisDeployment) {
            let healthyTargets = 0;
            let unavailableTargets = 0;
            let unhealthyTargets = 0;
            this.state.machinesApplicableToThisDeployment.forEach((m) => {
                if (m.isUnavailable || m.HealthStatus === MachineModelHealthStatus.Unavailable) {
                    unavailableTargets++;
                }
                if (m.HealthStatus === MachineModelHealthStatus.Unhealthy) {
                    unhealthyTargets++;
                }
                if (m.HealthStatus === MachineModelHealthStatus.Healthy || m.HealthStatus === MachineModelHealthStatus.HasWarnings) {
                    healthyTargets++;
                }
            });
            return (<div className={styles.summaryCountContainer}>
                    {healthyTargets > 0 && (<div className={styles.summaryCount}>
                            <div className={styles.healthStatusIconContainer}>
                                <HealthyMachineIcon />
                            </div>
                            {healthyTargets + " HEALTHY"}
                        </div>)}
                    {unavailableTargets > 0 && (<div className={styles.summaryCount}>
                            <div className={styles.healthStatusIconContainer}>
                                <UnavailableMachineIcon />
                            </div>
                            {unavailableTargets + " UNAVAILABLE"}
                        </div>)}
                    {unhealthyTargets > 0 && (<div className={styles.summaryCount}>
                            <div className={styles.healthStatusIconContainer}>
                                <UnhealthyMachineIcon />
                            </div>
                            {unhealthyTargets + " UNHEALTHY"}
                        </div>)}
                </div>);
        }
    }
    private getDeploymentMissingVariableInfo(deploymentType: DeploymentType) {
        if (deploymentType === DeploymentType.Tenant) {
            if (this.props.isMissingVariable && this.props.deployment) {
                return (<div>
                        <WarningIcon />
                        Tenant missing variables
                    </div>);
            }
        }
        return null;
    }
    private successOrErrorIcon() {
        let deploymentResultMessage = "";
        const response = this.props.deployment.response;
        if (response) {
            if (response instanceof OctopusError) {
                // a class of `OctopusError` e.g. 403 errors don't have the 'Errors' array
                if (response.Errors!.length === 0) {
                    deploymentResultMessage += response.ErrorMessage;
                }
                else {
                    response.Errors!.forEach((err) => (deploymentResultMessage += err));
                }
                return (<div className={styles.iconContainer}>
                        <Tooltip content={deploymentResultMessage}>
                            <span className={cn(styles.icon, styles.error)}>
                                <em className="fa-solid fa-exclamation-triangle"/>
                            </span>
                        </Tooltip>
                    </div>);
            }
            return (<div className={styles.iconContainer}>
                    <Tooltip content={this.props.modelType === DeploymentModelType.Deployment ? "Deployment created" : "Runbook Run created"}>
                        <span className={cn(styles.icon, styles.success)}>
                            <em className="fa-solid fa-check"/>
                        </span>
                    </Tooltip>
                </div>);
        }
    }
    private successOrErrorDetails(result: DeploymentRequestModel) {
        if (!result || !result.response) {
            return null;
        }
        if (this.isError(result.response)) {
            const convertedError = createErrorsFromOctopusError(result.response);
            return (<ErrorPanel message={convertedError.message} errors={convertedError.errors} parsedHelpLinks={convertedError.parsedHelpLinks} helpLink={convertedError.helpLink} helpText={convertedError.helpText} statusCode={convertedError.statusCode}/>);
        }
        const deployment = result.response as DeploymentResource;
        return (<Callout type={"success"} title={this.props.modelType === DeploymentModelType.Deployment ? `Deployment created` : `Runbook Run created`}>
                <InternalLink to={links.taskPage.generateUrl({ taskId: deployment.TaskId })} openInSelf={false}>
                    View task
                </InternalLink>
            </Callout>);
    }
    private isError(response: IExecutionResource | OctopusError): response is OctopusError {
        return (response as OctopusError).ErrorMessage !== undefined;
    }
    static displayName = "DeploymentResultItem";
}
