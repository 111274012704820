import { css } from "@emotion/css";
import { fontSize, fontWeight, text, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { logger } from "@octopusdeploy/logging";
import type { InlineQueryResult } from "@octopusdeploy/octopus-react-client";
import { ActivityStatus } from "@octopusdeploy/octopus-server-client";
import type { ActivityElement, KubernetesObjectStatus, KubernetesStepStatus, TaskState, KubernetesResourceManifestResource, KubernetesResourceManifestSummaryResource } from "@octopusdeploy/octopus-server-client";
import { useMemo } from "react";
import * as React from "react";
import type { StepWithKubernetesAction } from "~/areas/tasks/components/Task/K8sStatus/StepWithKubernetesAction";
import type { KubernetesDetailsFilter, KubernetesDetailsStepView } from "~/areas/tasks/components/Task/Kubernetes/KubernetesDetailsLayout";
import { unknownStatusFilter, hasNoFilters, clusterScopedNamespaceFilter } from "~/areas/tasks/components/Task/Kubernetes/KubernetesDetailsLayout";
import { KubernetesStepsLayoutContainerKey } from "~/areas/tasks/components/Task/Kubernetes/KubernetesStepsLayout";
import { KubernetesStepAppliedManifests } from "~/areas/tasks/components/Task/Kubernetes/Manifests/KubernetesStepAppliedManifests";
import { KubernetesStepSnapshotStatus } from "~/areas/tasks/components/Task/Kubernetes/Snapshot/KubernetesStepSnapshotStatus";
import { SnapshotTaken } from "~/areas/tasks/components/Task/Kubernetes/SnapshotTaken";
import { TaskStatusIcon } from "~/areas/tasks/components/Task/Kubernetes/TaskStatusIcon";
import SimpleExpander from "~/components/SimpleExpander/index";
import TimeFromNowLabel from "~/components/TimeLabels/TimeFromNowLabel";
import StringHelper from "~/utils/StringHelper/index";
export type KubernetesStepBaseProps = Pick<StepWithKubernetesAction, "isAtomicHelmStep" | "isResourceStatusEnabled"> & {
    stepActivity: Pick<ActivityElement, "Status" | "Ended" | "Started">;
    taskState?: TaskState;
    filter: KubernetesDetailsFilter;
    stepObjectStatuses: KubernetesObjectStatus[] | undefined;
    stepSummaries: KubernetesResourceManifestSummaryResource[] | undefined;
    stepManifestsQueryResult?: InlineQueryResult<KubernetesResourceManifestResource[] | undefined, null>;
};
type KubernetesStepStatusExpanderProps = Omit<KubernetesStepBaseProps, "stepObjectStatuses"> & {
    stepName: string;
    activeView: KubernetesDetailsStepView;
    showObjectDrawer: (kos: KubernetesObjectStatus) => void;
    stepResourceStatus: KubernetesStepStatus | undefined;
};
const styles = {
    stepNameContainer: css({
        lineHeight: 1.4,
        fontSize: fontSize["base"],
    }),
    stepName: css({
        fontWeight: fontWeight["600"],
        paddingLeft: space[4],
    }),
    snapshotTakenContainer: css({
        paddingLeft: space[16],
        paddingRight: space[16],
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    }),
    filterCounter: css({
        font: text.regular.default.xSmall,
        color: themeTokens.color.text.tertiary,
    }),
    tabContents: css({
        display: "contents",
    }),
    tabContentsHidden: css({
        display: "none",
    }),
};
const applyFilterToSummaries = (krms: KubernetesResourceManifestSummaryResource, filter: KubernetesDetailsFilter) => {
    let showItem = true;
    const checkActiveFilter = (filterValue: string | undefined, filterPredicate: (value: string) => boolean) => {
        //this filter is not active, don't do anything
        if (filterValue === undefined || StringHelper.isNullOrWhiteSpace(filterValue)) {
            return;
        }
        //if any active filter filters this out, run away!
        showItem &&= filterPredicate(filterValue);
    };
    checkActiveFilter(filter.targetName, (targetName) => targetName === krms.MachineName);
    checkActiveFilter(filter.namespace, (namespace) => namespace === (krms.KubernetesObjectNamespace || clusterScopedNamespaceFilter));
    checkActiveFilter(filter.name, (name) => krms.KubernetesObjectName.toLocaleLowerCase().includes(name.toLocaleLowerCase()));
    checkActiveFilter(filter.kind, (kind) => kind === krms.KubernetesResourceKind);
    checkActiveFilter(filter.status, (status) => status === (krms.ResourceStatus || unknownStatusFilter));
    return showItem;
};
const applyFilterToStatuses = (kos: KubernetesObjectStatus, filter: KubernetesDetailsFilter) => {
    let showItem = true;
    const checkActiveFilter = (filterValue: string | undefined, filterPredicate: (value: string) => boolean) => {
        //this filter is not active, don't do anything
        if (filterValue === undefined || StringHelper.isNullOrWhiteSpace(filterValue)) {
            return;
        }
        //if any active filter filters this out, run away!
        showItem &&= filterPredicate(filterValue);
    };
    checkActiveFilter(filter.targetName, (targetName) => targetName === kos.ClusterName);
    checkActiveFilter(filter.namespace, (namespace) => namespace === (kos.Namespace || clusterScopedNamespaceFilter));
    checkActiveFilter(filter.name, (name) => kos.Name.toLocaleLowerCase().includes(name.toLocaleLowerCase()));
    checkActiveFilter(filter.kind, (kind) => kind === kos.Kind);
    checkActiveFilter(filter.status, (status) => status === kos.Status);
    return showItem;
};
export const KubernetesStepExpander = (props: KubernetesStepStatusExpanderProps) => {
    const { activeView, showObjectDrawer, stepSummaries, stepResourceStatus, ...rest } = props;
    const lastUpdateMessage = React.useMemo(() => {
        if (props.stepActivity.Status === ActivityStatus.Canceled) {
            return <>This task was canceled prior to starting</>;
        }
        if (!props.stepResourceStatus) {
            return null;
        }
        if (props.stepActivity.Ended) {
            return <SnapshotTaken date={props.stepResourceStatus.LastUpdated}/>;
        }
        return (<>
                Last Updated: <TimeFromNowLabel time={props.stepResourceStatus.LastUpdated}/>
            </>);
    }, [props.stepActivity.Ended, props.stepActivity.Status, props.stepResourceStatus]);
    const filteredSummaries = useMemo(() => {
        if (!stepSummaries) {
            return;
        }
        //if there are no filters, just return the full array
        if (hasNoFilters(props.filter)) {
            return props.stepSummaries;
        }
        return props.stepSummaries?.filter((krms) => applyFilterToSummaries(krms, props.filter));
    }, [stepSummaries, props.filter, props.stepSummaries]);
    const filteredStatuses = useMemo(() => {
        if (!stepResourceStatus?.KubernetesObjects) {
            return;
        }
        //if there are no filters, just return the full array
        if (hasNoFilters(props.filter)) {
            return stepResourceStatus?.KubernetesObjects;
        }
        return stepResourceStatus?.KubernetesObjects.filter((kos) => applyFilterToStatuses(kos, props.filter));
    }, [props.filter, stepResourceStatus?.KubernetesObjects]);
    const manifestCounts = useMemo(() => ({ total: stepSummaries?.length || 0, filteredTotal: filteredSummaries?.length || 0 }), [filteredSummaries?.length, stepSummaries?.length]);
    const objectStatusCounts = useMemo(() => ({ total: stepResourceStatus?.KubernetesObjects.length || 0, filteredTotal: filteredStatuses?.length || 0 }), [filteredStatuses?.length, stepResourceStatus?.KubernetesObjects.length]);
    //the total counts change based on the active view
    const activeCounts = useMemo(() => {
        if (activeView === "Snapshot") {
            return objectStatusCounts;
        }
        else if (activeView === "Manifests") {
            return manifestCounts;
        }
        return { total: 0, filteredTotal: 0 };
    }, [activeView, manifestCounts, objectStatusCounts]);
    return (<SimpleExpander key={props.stepName} accessibleName={props.stepName} title={<div className={styles.stepNameContainer}>
                    <TaskStatusIcon status={props.stepActivity.Status}/> <span className={styles.stepName}>{props.stepName}</span>
                </div>} errorKey={props.stepName} containerKey={KubernetesStepsLayoutContainerKey} onDidExpand={(isExpanded) => logger.info("Expanded: {Expanded}", { Expanded: isExpanded })} isExpandedByDefault={true}>
            <div className={styles.snapshotTakenContainer}>
                <div aria-label="Last Updated">{lastUpdateMessage}</div>
                {activeCounts.total > activeCounts.filteredTotal && (<div aria-label="Filter Counter">
                        <span className={styles.filterCounter}>
                            {activeCounts.filteredTotal} of {activeCounts.total} objects match your filters
                        </span>
                    </div>)}
            </div>
            <div className={activeView === "Snapshot" ? styles.tabContents : styles.tabContentsHidden}>
                <KubernetesStepSnapshotStatus showObjectDrawer={showObjectDrawer} stepObjectStatuses={filteredStatuses} {...rest}/>
            </div>
            <div className={activeView === "Manifests" ? styles.tabContents : styles.tabContentsHidden}>
                <KubernetesStepAppliedManifests stepSummaries={filteredSummaries} {...rest}/>
            </div>
        </SimpleExpander>);
};
