import { css } from "@emotion/css";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React from "react";
import { And, ExcludedEnvironmentChip, ExcludedProjectChip, ExcludedTagChips, IncludedEnvironmentChip, IncludedProjectChip, IncludedTagChips } from "~/areas/tenants/Tenants/CurrentFilterSelection/CurrentFilterSelectionChips";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import type { TenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
interface FilteredByChipsProps {
    filterData: TenantFiltersData;
    filterState: TenantsFilterState;
}
export function FilteredByChips({ filterState, filterData }: FilteredByChipsProps) {
    const selectedFilters = getSelectedFilters(filterState);
    if (selectedFilters.length === 0) {
        return null;
    }
    return (<div className={styles.container}>
            <span>
                {selectedFilters.map((filter, index) => (<React.Fragment key={filter.type}>
                        {index === 0 ? null : <And />}
                        <SelectedChipGroup selectedFilter={filter} data={filterData}/>
                    </React.Fragment>))}
            </span>
        </div>);
}
function SelectedChipGroup({ selectedFilter, data }: {
    selectedFilter: SelectedFilter;
    data: TenantFiltersData;
}) {
    switch (selectedFilter.type) {
        case "project-include":
            return <IncludedProjectChip id={selectedFilter.value} projects={data.projects}/>;
        case "project-exclude":
            return <ExcludedProjectChip id={selectedFilter.value} projects={data.projects}/>;
        case "environment-include":
            return <IncludedEnvironmentChip id={selectedFilter.value} environments={data.environments}/>;
        case "environment-exclude":
            return <ExcludedEnvironmentChip id={selectedFilter.value} environments={data.environments}/>;
        case "tags-include":
            return <IncludedTagChips ids={selectedFilter.value} tags={data.tags}/>;
        case "tags-exclude":
            return <ExcludedTagChips ids={selectedFilter.value} tags={data.tags}/>;
        default:
            exhaustiveCheck(selectedFilter, "Unhandled filter type");
    }
}
type SingleValueType = "project-include" | "project-exclude" | "environment-include" | "environment-exclude";
type MultipleValuesType = "tags-include" | "tags-exclude";
type SingleValueFilter = {
    type: SingleValueType;
    value: string;
};
type MultipleValuesFilter = {
    type: MultipleValuesType;
    value: string[];
};
type SelectedFilter = SingleValueFilter | MultipleValuesFilter;
function getSelectedFilters(filterState: TenantsFilterState) {
    const selectedFilters: SelectedFilter[] = [];
    if (filterState.filterByProject) {
        selectedFilters.push({ type: "project-include", value: filterState.filterByProject });
    }
    if (filterState.filterByExcludedProject) {
        selectedFilters.push({ type: "project-exclude", value: filterState.filterByExcludedProject });
    }
    if (filterState.filterByEnvironment) {
        selectedFilters.push({ type: "environment-include", value: filterState.filterByEnvironment });
    }
    if (filterState.filterByExcludedEnvironment) {
        selectedFilters.push({ type: "environment-exclude", value: filterState.filterByExcludedEnvironment });
    }
    if (filterState.filterByTags.length > 0) {
        selectedFilters.push({ type: "tags-include", value: filterState.filterByTags });
    }
    if (filterState.filterByExcludedTags.length > 0) {
        selectedFilters.push({ type: "tags-exclude", value: filterState.filterByExcludedTags });
    }
    return selectedFilters;
}
const styles = {
    container: css({
        display: "flex",
        alignItems: "center",
    }),
};
