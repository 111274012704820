import { OctopusError } from "@octopusdeploy/octopus-server-client";
export function createErrorInfo(error: Error | OctopusError | null | undefined) {
    if (!error) {
        return undefined;
    }
    const isOctopusError = OctopusError.isOctopusError(error);
    return {
        message: isOctopusError ? error.ErrorMessage : error?.message,
        details: isOctopusError
            ? (error.Errors || [])
                .filter((x) => !!x)
                .map((s) => ({
                text: s,
            }))
            : [],
    };
}
