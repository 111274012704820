import type { KubernetesLiveResourceStatusCode } from "@octopusdeploy/octopus-server-client/src/resources/kubernetesLiveResourceStatusCode";
import { kubernetesliveResourceStatusCodes } from "@octopusdeploy/octopus-server-client/src/resources/kubernetesLiveResourceStatusCode";
import * as React from "react";
import { getLiveResourceStatusIcon } from "~/areas/projects/components/Observability/LiveStatusIcons";
import { ChipIcon, FilterTextChip, MissingChip } from "~/components/Chips";
import Lookup from "~/components/Lookup";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import type { FormFieldProps } from "~/components/form";
interface KubernetesStatusMultiSelectProps extends FormFieldProps<string[]> {
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}
interface StatusSelectItem {
    Id: KubernetesLiveResourceStatusCode;
    Name: string;
    Icon: React.ReactElement;
}
const KubernetesStatusTypedMultiSelect = MultiSelect<StatusSelectItem>();
const KubernetesStatusMultiSelect: React.FC<KubernetesStatusMultiSelectProps> = (props) => {
    const { onChange, ...otherProps } = props;
    const selectItems: StatusSelectItem[] = kubernetesliveResourceStatusCodes.map((i) => ({
        Id: i,
        Name: i,
        Icon: getLiveResourceStatusIcon(i),
    }));
    const chipRenderer = (r: StatusSelectItem | SelectItem, onRequestDelete: () => void) => (<Lookup lookupCollection={selectItems} lookupId={r.Id} getIdFromElement={(element: StatusSelectItem) => element.Id} render={(item: StatusSelectItem) => <FilterTextChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} filterText={item.Name} icon={item.Icon}/>} renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.CommunicationStyle}/>}/>);
    return <KubernetesStatusTypedMultiSelect items={selectItems} fieldName="status" renderChip={chipRenderer} onChange={(value) => onChange(value)} {...otherProps}/>;
};
KubernetesStatusMultiSelect.displayName = "KubernetesStatusMultiSelect"
export default KubernetesStatusMultiSelect;
