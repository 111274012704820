/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ScopeValues } from "@octopusdeploy/octopus-server-client";
import { sum, isEqual, compact } from "lodash";
import * as React from "react";
import type { VariableFilter } from "~/areas/variables/VariableFilter/VariableFilter";
import type { AllVariableMessages } from "~/areas/variables/VariableMessages/VariableMessages";
import AdvancedFilterLayout, { AdvancedFilterTextInput, AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout";
import type { FilterSection } from "~/components/AdvancedFilterLayout";
import { AdvancedTenantTagsSelector } from "~/components/AdvancedTenantSelector/AdvancedTenantSelector";
import type { DoBusyTask } from "~/components/DataBaseComponent/DataBaseComponent";
import FilterSearchBox from "~/components/FilterSearchBox/FilterSearchBox";
import { ChannelMultiSelect } from "~/components/MultiSelect/ChannelMultiSelect";
import { EnvironmentMultiSelect } from "~/components/MultiSelect/EnvironmentMultiSelect";
import { MachineMultiSelect } from "~/components/MultiSelect/MachineMultiSelect";
import ProcessMultiSelect from "~/components/MultiSelect/ProcessMultiSelect";
import { StepMultiSelect } from "~/components/MultiSelect/StepMultiSelect";
import { TargetTagMultiSelect } from "~/components/MultiSelect/TargetTagMultiSelect";
import WarningIcon from "~/components/WarningIcon";
import { useVariableEditorScoping } from "../VariableEditor/VariableEditorScopingContext";
import { isProjectScoped } from "../VariableEditor/types";
export interface VariableFilterLayoutProps<TVariableFilter extends VariableFilter> {
    filter: TVariableFilter;
    queryFilter: TVariableFilter;
    defaultFilter: TVariableFilter;
    availableScopes: ScopeValues;
    messages: AllVariableMessages;
    children?: React.ReactNode;
    extraFilters?: React.ReactNode;
    isTenanted: boolean; //only show tenant filter options it if there's tenant support
    alwaysShowCheckboxFilters?: boolean;
    hideAdvancedFilters?: boolean;
    doBusyTask: DoBusyTask;
    onFilterChanged(filter: TVariableFilter): void;
    renderContent(filterPanelIsVisible: boolean): React.ReactNode;
}
// typing for Layout is not quite correct (should be of type <TVariableFilter>), but if we move the class definition inside the function,
// we will be creating a new type every render, which will result in an infinite render loop since components from subsequent renders
// cannot be consolidated
class Layout<VariableFilter> extends AdvancedFilterLayout<VariableFilter> {
}
function VariableFilterLayout<TVariableFilter extends VariableFilter>(props: VariableFilterLayoutProps<TVariableFilter>) {
    const scoping = useVariableEditorScoping();
    return (<Layout filterSections={createFilterSections(props.filter, props.defaultFilter)} extendContentToEdges={true} filter={props.filter} queryFilter={props.queryFilter} defaultFilter={props.defaultFilter} onFilterReset={(filter: TVariableFilter) => {
            props.onFilterChanged(filter);
        }} renderContent={props.renderContent} additionalHeaderFilters={[<FilterSearchBox placeholder="By variable name" value={props.filter.name} onChange={(name) => onFilterChanged(props.filter, { name })}/>]}/>);
    function onFilterChanged(filter: TVariableFilter, partialFilter: Partial<VariableFilter>) {
        const variableFilter: TVariableFilter = { ...filter, ...partialFilter };
        props.onFilterChanged(variableFilter);
    }
    //TODO: revisit and remove casts for scope, string[]
    function createFilterSections(filter: TVariableFilter, defaultFilter: TVariableFilter): FilterSection[] {
        if (props.hideAdvancedFilters) {
            return [];
        }
        return compact([
            {
                render: (<div>
                        {renderErrorFilters(filter)}
                        <AdvancedFilterTextInput fieldName="value" value={filter.value} onChange={(value) => onFilterChanged(filter, { value })}/>
                        <AdvancedFilterTextInput fieldName={"description"} value={filter.description} onChange={(description) => onFilterChanged(filter, { description })}/>
                        {props.extraFilters}
                    </div>),
            },
            {
                sectionName: "Scope",
                isNotDefaultFilter: !isEqual(filter.scope.Environment, defaultFilter.scope.Environment) ||
                    !isEqual(filter.scope.Role, defaultFilter.scope.Role) ||
                    !isEqual(filter.scope.Machine, defaultFilter.scope.Machine) ||
                    !isEqual(filter.scope.Action, defaultFilter.scope.Action) ||
                    !isEqual(filter.scope.Channel, defaultFilter.scope.Channel) ||
                    !isEqual(filter.scope.ProcessOwner, defaultFilter.scope.ProcessOwner),
                render: (<div>
                        <EnvironmentMultiSelect accessibleName="environmentFilter" onChange={(Environment) => onFilterChanged(filter, { scope: { ...filter.scope, Environment } })} value={filter.scope.Environment ? [...(filter.scope.Environment as string[])] : []} items={props.availableScopes.Environments}/>

                        <TargetTagMultiSelect onChange={(Role) => onFilterChanged(filter, { scope: { ...filter.scope, Role } })} value={filter.scope.Role ? [...(filter.scope.Role as string[])] : []} items={props.availableScopes.Roles.map((r) => r.Id)}/>

                        <MachineMultiSelect onChange={(Machine) => onFilterChanged(filter, { scope: { ...filter.scope, Machine } })} value={filter.scope.Machine ? [...(filter.scope.Machine as string[])] : []} items={props.availableScopes.Machines}/>

                        {isProjectScoped(scoping) && (<ProcessMultiSelect items={props.availableScopes.Processes} value={filter.scope.ProcessOwner ? [...(filter.scope.ProcessOwner as string[])] : []} onChange={(processes) => onFilterChanged(filter, { scope: { ...filter.scope, ProcessOwner: processes } })}/>)}

                        {isProjectScoped(scoping) && (<StepMultiSelect onChange={(Action) => onFilterChanged(filter, { scope: { ...filter.scope, Action } })} value={filter.scope.Action ? [...(filter.scope.Action as string[])] : []} items={props.availableScopes.Actions}/>)}

                        {isProjectScoped(scoping) && (<ChannelMultiSelect onChange={(Channel) => onFilterChanged(filter, { scope: { ...filter.scope, Channel } })} value={filter.scope.Channel ? [...(filter.scope.Channel as string[])] : []} items={props.availableScopes.Channels}/>)}
                    </div>),
            },
            !props.isTenanted
                ? null
                : {
                    sectionName: "Tenant tag set",
                    // Ignore TenantTag scope values here (like the old portal)
                    // because you can select any tenant, and the selector component here loads them all
                    isNotDefaultFilter: !isEqual(filter.scope.TenantTag, defaultFilter.scope.TenantTag),
                    render: (<AdvancedTenantTagsSelector selectedTenantTags={filter.scope.TenantTag ? [...(filter.scope.TenantTag as string[])] : []} onChange={(TenantTag) => onFilterChanged(filter, { scope: { ...filter.scope, TenantTag } })} doBusyTask={props.doBusyTask} showPreviewButton={true}/>),
                },
        ]);
    }
    function renderErrorFilters(filter: TVariableFilter) {
        const groupMessages = props.messages.variableMessages;
        const emptyValuesCount = sum(groupMessages.map((gw) => gw.valuesMessages.filter((vw) => vw.hasEmptyValue).length));
        const variableSubstitutionSyntaxCount = sum(groupMessages.map((gw) => gw.valuesMessages.filter((vw) => vw.hasVariableSubstitutionSyntax).length));
        const duplicateNameCount = props.messages.duplicateVariableNames.length;
        const nonPrintableCharactersCountInNames = groupMessages.filter((gw) => gw.nameNonPrintableCharacter).length;
        const nonPrintableCharactersCountInValues = sum(groupMessages.map((gw) => gw.valuesMessages.filter((vw) => vw.valueNonPrintableCharacter).length));
        const nonPrintableCharactersCount = nonPrintableCharactersCountInNames + nonPrintableCharactersCountInValues;
        return (<div>
                {(emptyValuesCount > 0 || props.alwaysShowCheckboxFilters) && <AdvancedFilterCheckbox value={filter.filterEmptyValues} onChange={(filterEmptyValues) => onFilterChanged(filter, { filterEmptyValues })} label="Empty values"/>}

                {(duplicateNameCount > 0 || props.alwaysShowCheckboxFilters) && (<AdvancedFilterCheckbox value={filter.filterDuplicateNames} onChange={(filterDuplicateNames) => onFilterChanged(filter, { filterDuplicateNames })} label="Duplicate names" icon={<WarningIcon />}/>)}

                {(nonPrintableCharactersCount > 0 || props.alwaysShowCheckboxFilters) && (<AdvancedFilterCheckbox value={filter.filterNonPrintableCharacters} onChange={(filterNonPrintableCharacters) => onFilterChanged(filter, { filterNonPrintableCharacters })} label="Non-printable characters" icon={<WarningIcon />}/>)}

                {(variableSubstitutionSyntaxCount > 0 || props.alwaysShowCheckboxFilters) && (<AdvancedFilterCheckbox value={filter.filterVariableSubstitutionSyntax} onChange={(filterVariableSubstitutionSyntax) => onFilterChanged(filter, { filterVariableSubstitutionSyntax })} label="Using variable substitution syntax" icon={<WarningIcon />}/>)}
            </div>);
    }
}
export default VariableFilterLayout;
