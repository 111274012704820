/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { PrimaryPageAction, SimpleMenuItem } from "@octopusdeploy/design-system-components";
import { NavigationButton, NavigationButtonType, Pagination } from "@octopusdeploy/design-system-components";
import { logger } from "@octopusdeploy/logging";
import type { PerformanceConfigurationResource, ProjectResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { DashboardRenderMode, Permission, TenantedDeploymentMode } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { isEqual, omit } from "lodash";
import * as React from "react";
import { useMemo, useState } from "react";
import type { ActionEvent, AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, ProjectAnalyticView, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import type { RenderDashboardProps } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import type { DataCube } from "~/areas/projects/components/DashboardDataSource/DataCube";
import { DimensionTypes } from "~/areas/projects/components/DashboardDataSource/DataCube";
import TenantedProjectDashboardDataSource from "~/areas/projects/components/DashboardDataSource/TenantedProjectDashboardDataSource";
import UntenantedProjectDashboardDataSource from "~/areas/projects/components/DashboardDataSource/UntenantedProjectDashboardDataSource";
import { DeploymentsOverviewEnvironmentFilter, DeploymentsOverviewGroupingOptions, DeploymentsOverviewReleasesFilter, DeploymentsOverviewTenantFilter } from "~/areas/projects/components/DeploymentsOverview/DeploymentsOverviewAdvancedFilters";
import type { DeploymentOverviewFilters } from "~/areas/projects/components/DeploymentsOverview/hooks/useDeploymentsOverviewFilters";
import useDeploymentsOverviewFilters, { defaultDeploymentOverviewPageSize, deploymentOverviewPageSizes, isDeploymentOverviewPageSize } from "~/areas/projects/components/DeploymentsOverview/hooks/useDeploymentsOverviewFilters";
import type { SampleProjectTourContextProps } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import { SampleProjectTourContext } from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTour";
import SampleProjectTourStartTourOnMount from "~/areas/projects/components/ProjectLayout/SampleProjectTour/SampleProjectTourStartTourOnMount";
import { ProjectPageTitleAccessory } from "~/areas/projects/components/ProjectPageTitleAccessory";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context/withProjectContext";
import { repository } from "~/clientInstance";
import ActionList from "~/components/ActionList";
import { AdvancedFilters } from "~/components/AdvancedFilterLayout/AdvancedFilters";
import type { Errors } from "~/components/DataBaseComponent/index";
import { PageContent } from "~/components/PageContent/PageContent";
import { PageLoading } from "~/components/PageContent/PageLoading";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import SampleProcessButtonWithRedirect from "~/components/ProjectBasedActivation/SampleProcessButtonWithRedirect";
import { useRequiredContext } from "~/hooks/index";
import { RecentProjects } from "~/utils/RecentProjects/RecentProjects";
import type { DataBaseComponentState, DoBusyTask } from "../../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../../components/DataBaseComponent/DataBaseComponent";
import ProjectDashboard from "../ProjectDashboard";
import Onboarding from "./Onboarding";
interface OverviewState extends DataBaseComponentState {
    project: ProjectResource;
    tenants: TenantResource[];
    hasSteps: boolean;
    hasReleases: boolean;
    failedChecks: Array<{
        permission: Permission;
        isNotAllowed: boolean;
    }>;
    dashboardRenderMode: DashboardRenderMode;
    isDashboardLoaded: boolean;
}
interface DeploymentsOverviewPropsInternal extends WithProjectContextInjectedProps {
    sampleProjectTourContext: SampleProjectTourContextProps;
    dispatchAction: AnalyticActionDispatcher;
    filters: DeploymentOverviewFilters;
    setFilters: (newFilters: DeploymentOverviewFilters) => void;
}
const pageTitle = "Deployments";
class DeploymentsOverviewInternal extends DataBaseComponent<DeploymentsOverviewPropsInternal, OverviewState> {
    constructor(props: DeploymentsOverviewPropsInternal) {
        super(props);
        this.state = {
            hasSteps: false,
            hasReleases: false,
            project: null!,
            tenants: [],
            failedChecks: [],
            dashboardRenderMode: null!,
            isDashboardLoaded: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const tenants = isAllowed({ permission: Permission.TenantView, tenant: "*" }) ? await repository.Tenants.all() : [];
            const { model: project, projectContextRepository } = this.props.projectContext.state;
            const performanceConfiguration = await repository.PerformanceConfiguration.get();
            RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(project.Id);
            const requiredPermissions = [
                { permission: Permission.ProjectView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.ReleaseView, project: project.Id, tenant: "*", projectGroup: project.ProjectGroupId },
                { permission: Permission.EnvironmentView, wildcard: true },
            ];
            const failedChecks = requiredPermissions
                .map((check) => ({
                permission: check.permission,
                isNotAllowed: !isAllowed(check),
            }))
                .filter((check) => check.isNotAllowed);
            if (failedChecks.length > 0) {
                this.setState({
                    project,
                    failedChecks,
                });
                return;
            }
            let hasSteps = false;
            try {
                const projectStatus = await repository.Projects.getProjectStatus(project.Slug, project.SpaceId);
                hasSteps = projectStatus.HasSteps;
            }
            catch (exception) {
                logger.error(exception, "Failed to get status for {project}", { project });
            }
            let hasReleases = false;
            try {
                const releases = await repository.Projects.getReleases(project, { take: 1 });
                hasReleases = releases.TotalResults > 0;
            }
            catch (exception) {
                logger.error(exception, "Failed to get releases for {project}", { project });
            }
            this.setState({
                project,
                tenants,
                hasSteps,
                hasReleases,
                dashboardRenderMode: this.getDashboardRenderMode(project, performanceConfiguration),
            });
        });
    }
    render() {
        if (!this.state.project) {
            return <PageLoading loadingTitle={pageTitle}/>;
        }
        if (this.state.failedChecks.length > 0) {
            return (<PageContent busy={this.state.busy} errors={this.errors} header={{ title: pageTitle, contextSelector: <ProjectPageTitleAccessory /> }} callout={{ type: "information", title: "Permission required", content: `The ${this.state.failedChecks[0].permission} permission is required to view project overview details` }}>
                    {null}
                </PageContent>);
        }
        if (this.state.project && !this.state.hasSteps && !this.state.hasReleases) {
            return (<PageContent busy={this.state.busy} errors={this.errors} header={{ title: pageTitle, contextSelector: <ProjectPageTitleAccessory /> }}>
                    <Onboarding project={this.state.project} actionButtons={<PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*">
                                <ActionList alignStart={true} actions={[
                        <NavigationButton label="Create Process" href={links.deploymentProcessStepsPage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug }, { stepTemplates: true })} type={NavigationButtonType.Primary} onClick={() => {
                                const ev: ActionEvent = {
                                    action: Action.Add,
                                    resource: "Deployment Process",
                                };
                                this.props.dispatchAction("Define your Deployment Process", ev);
                            }}/>,
                        <SampleProcessButtonWithRedirect project={this.props.projectContext.state.model}/>,
                    ]}/>
                            </PermissionCheck>}/>
                    <ProjectAnalyticView resource="Project" projectId={this.state.project.Id}/>
                </PageContent>);
        }
        return (<>
                <DeploymentsOverviewPageContent project={this.state.project} hasSteps={this.state.hasSteps} dashboardRenderMode={this.state.dashboardRenderMode} setDashboardRenderMode={(value) => this.setDashboardRenderMode(value)} dashboardLoaded={this.state.isDashboardLoaded} busy={this.state.busy} errors={this.errors} render={this.renderDashboard} doBusyTask={this.doBusyTask} setIsDashboardLoaded={(value) => this.setState({ isDashboardLoaded: value })} filters={this.props.filters} onFiltersUpdated={this.filtersUpdated} tenants={this.state.tenants}/>
            </>);
    }
    private showLoadingDashboard(dataSource: RenderDashboardProps) {
        return (<ProjectDashboard spaceId={this.props.projectContext.state.model.SpaceId} cube={dataSource.cube!} filters={this.props.filters} allowDeployments={true} dashboardRenderMode={this.state.dashboardRenderMode} project={this.state.project}/>);
    }
    private localStorageKeyForVirtualDashboard(project: ProjectResource): string {
        return "virtualDashboard" + project.Id;
    }
    private getDashboardRenderMode(project: ProjectResource, performanceConfiguration: PerformanceConfigurationResource): DashboardRenderMode {
        const localRenderMode = localStorage.getItem(this.localStorageKeyForVirtualDashboard(project));
        if (localRenderMode !== null) {
            const typedRenderModeString = localRenderMode as keyof typeof DashboardRenderMode;
            return DashboardRenderMode[typedRenderModeString];
        }
        return performanceConfiguration.DefaultDashboardRenderMode;
    }
    private setDashboardRenderMode(value: DashboardRenderMode) {
        localStorage.setItem(this.localStorageKeyForVirtualDashboard(this.state.project), value.toString());
        this.setState({ dashboardRenderMode: value });
    }
    private filtersUpdated = (filters: DeploymentOverviewFilters) => {
        this.setState({ isDashboardLoaded: false });
        this.props.setFilters(filters);
    };
    private renderDashboard = (dataSource: RenderDashboardProps) => {
        if (!dataSource.cube) {
            return <></>;
        }
        return <SampleProjectTourStartTourOnMount>{this.showLoadingDashboard(dataSource)}</SampleProjectTourStartTourOnMount>;
    };
    static displayName = "DeploymentsOverviewInternal";
}
interface DeploymentsOverviewPageContent {
    project: ProjectResource;
    dashboardRenderMode: DashboardRenderMode;
    setDashboardRenderMode: (value: DashboardRenderMode) => void;
    hasSteps: boolean;
    busy: Promise<void> | undefined;
    doBusyTask: DoBusyTask;
    errors: Errors | undefined;
    render: (dataSource: RenderDashboardProps) => JSX.Element;
    dashboardLoaded: boolean;
    setIsDashboardLoaded: (value: boolean) => void;
    filters: DeploymentOverviewFilters;
    onFiltersUpdated: (filters: DeploymentOverviewFilters) => void;
    tenants: TenantResource[];
}
function DeploymentsOverviewPageContent({ busy, dashboardRenderMode, setDashboardRenderMode, doBusyTask, errors, filters, onFiltersUpdated, hasSteps, dashboardLoaded, setIsDashboardLoaded, project, render, tenants }: DeploymentsOverviewPageContent) {
    const [cube, setCube] = useState<DataCube | undefined>(undefined);
    const [totalItems, setTotalItems] = useState<number>(0);
    const dispatchAction = useProjectScopedAnalyticActionDispatch(project.Id);
    const defaultFilter: DeploymentOverviewFilters = useMemo(() => ({
        [DimensionTypes.Project]: {
            [project.Id]: true,
        },
        columnDimension: DimensionTypes.Environment,
        groupBy: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? DimensionTypes.Channel : DimensionTypes.None,
        groupByExtra: undefined,
        rowDimension: project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted ? DimensionTypes.Release : DimensionTypes.Tenant,
        page: 1,
        pageSize: filters.pageSize,
    }), [filters.pageSize, project.Id, project.TenantedDeploymentMode]);
    let primaryAction: PrimaryPageAction | undefined = undefined;
    const createReleaseIsAllowed = hasSteps || project.IsVersionControlled;
    if (createReleaseIsAllowed) {
        const createReleaseButton: PrimaryPageAction = {
            type: "navigate",
            label: "Create Release",
            path: links.createReleasePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug }),
            onClick: () => dispatchAction("Create a release", { resource: "Create Release", action: Action.Add }),
            hasPermissions: isAllowed({ permission: Permission.ReleaseCreate, project: project.Id, tenant: "*" }),
        };
        primaryAction = createReleaseButton;
    }
    const oppositeDashboardSetting = dashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? DashboardRenderMode.VirtualizeRowsAndColumns : DashboardRenderMode.VirtualizeColumns;
    const isAllowedToViewAudit = isAllowed({
        permission: Permission.EventView,
        wildcard: true,
    });
    const overflowActions: SimpleMenuItem[] = [
        {
            type: "button",
            label: dashboardRenderMode === DashboardRenderMode.VirtualizeColumns ? "Switch to fast rendering" : "Switch to full rendering",
            title: "Full rendering will render all rows, fast rendering will render only visible rows",
            onClick: () => setDashboardRenderMode(oppositeDashboardSetting),
        },
    ];
    if (isAllowedToViewAudit) {
        overflowActions.push({
            type: "internal-link",
            label: "Audit Trail",
            path: links.auditPage.generateUrl({ projects: [project.Id] }),
        });
    }
    const isUntenanted = project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted;
    const onCubeLoaded = (cube: DataCube, totalItems: number) => {
        setCube(cube);
        setTotalItems(totalItems);
        setIsDashboardLoaded(true);
    };
    const onTenantedCubeLoaded = (cube: DataCube, totalItems: number, filtersUsedToLoadCube: DeploymentOverviewFilters) => {
        // Requests with outdated parameters should be ignored, otherwise the loading bar will be hidden even though the latest request is ongoing
        const isQueryParamsUpToDate = isEqual(filtersUsedToLoadCube, filters);
        if (isQueryParamsUpToDate) {
            onCubeLoaded(cube, totalItems);
        }
    };
    const updateFilterAndResetPage = (newFilters: DeploymentOverviewFilters) => {
        onFiltersUpdated({ ...newFilters, page: 1 });
    };
    const onPageChange = (page: number) => {
        onFiltersUpdated({ ...filters, page });
    };
    const onPageSizeChange = (pageSize: number) => {
        const newPageSize = isDeploymentOverviewPageSize(pageSize) ? pageSize : defaultDeploymentOverviewPageSize;
        onFiltersUpdated({ ...filters, page: 1, pageSize: newPageSize });
    };
    // Tenanted dashboard endpoint doesn't support paging with grouping.
    const isPaged = isUntenanted || !filters.groupBy;
    return (<PageContent header={{ title: pageTitle, primaryAction, overflowActions, contextSelector: <ProjectPageTitleAccessory /> }} busy={dashboardLoaded ? false : busy} errors={errors} filters={cube
            ? {
                inputs: [
                    <DeploymentsOverviewReleasesFilter filters={filters} cube={cube} onFiltersUpdated={updateFilterAndResetPage} project={project}/>,
                    <DeploymentsOverviewGroupingOptions filters={filters} cube={cube} onFiltersUpdated={updateFilterAndResetPage}/>,
                ],
                advancedFilters: {
                    content: (<AdvancedFilters>
                                      <DeploymentsOverviewEnvironmentFilter filters={filters} cube={cube} onFiltersUpdated={updateFilterAndResetPage}/>
                                      <DeploymentsOverviewTenantFilter filters={filters} cube={cube} onFiltersUpdated={updateFilterAndResetPage} project={project} tenants={tenants} doBusyTask={doBusyTask}/>
                                  </AdvancedFilters>),
                    onResetFilter: () => onFiltersUpdated(defaultFilter),
                    hasUserSelectedValues: !isEqual(omit(filters, ["page", "pageSize"]), omit(defaultFilter, ["page", "pageSize"])),
                },
            }
            : undefined} pagination={cube && isPaged
            ? {
                ui: (<Pagination label={isUntenanted ? "Releases" : "Tenants"} totalResults={totalItems} itemsPerPageOptions={deploymentOverviewPageSizes} selectedItemsPerPage={filters.pageSize} currentPage={filters.page} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange}/>),
                placement: "topAndBottom",
            }
            : undefined}>
            {isUntenanted ? (<UntenantedProjectDashboardDataSource project={project} render={render} doBusyTask={doBusyTask} onCubeLoaded={onCubeLoaded} cube={cube}/>) : (<TenantedProjectDashboardDataSource project={project} filters={filters} render={render} doBusyTask={doBusyTask} onCubeLoaded={onTenantedCubeLoaded} cube={cube} isPaged={isPaged}/>)}
        </PageContent>);
}
export function DeploymentsOverview() {
    const projectContext = useProjectContext();
    const sampleProjectTourContext = useRequiredContext(SampleProjectTourContext);
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const [filters, setFilters] = useDeploymentsOverviewFilters(projectContext.state.model);
    return <DeploymentsOverviewInternal filters={filters} setFilters={setFilters} projectContext={projectContext} sampleProjectTourContext={sampleProjectTourContext} dispatchAction={dispatchAction}/>;
}
