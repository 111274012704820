import { NavigationButton, NavigationButtonType } from "@octopusdeploy/design-system-components";
import { EndpointRegistrationKeyValues, links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { AzureVm } from "~/components/Images/MachineSettings/AzureVm";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import AzureCategory from "./AzureCategoryDefinition";
import { InfoRegistrationCards } from "./RegistrationCards";
import WindowsCategory from "./WindowsCategoryDefinition";
import type { BuiltInEndpointRegistration } from "./endpointRegistry";
import { CommunicationStyle } from "./endpointRegistry";
import styles from "./styles.module.less";
const AzureVmEndpointDialogView: React.SFC<{}> = () => (<div>
        <h3>Installation Instructions</h3>
        <p>
            The <ExternalLink href="AzureVmExtensionArmDsc">Octopus Deploy DSC module</ExternalLink> can automatically download the latest Tentacle MSI, install it and register the agent with your Octopus Server.
        </p>
    </div>);
AzureVmEndpointDialogView.displayName = "AzureVmEndpointDialogView"
const AzureVmAdditionalActions = ({ spaceId }: {
    spaceId: string;
}) => (<NavigationButton key="deployment-targets" type={NavigationButtonType.Secondary} label="View Deployment Targets" href={links.deploymentTargetsPage.generateUrl({ spaceId }, { deploymentTargetTypes: [CommunicationStyle.TentacleActive, CommunicationStyle.TentaclePassive] })}/>);
export const azureVmRegistration: BuiltInEndpointRegistration = {
    key: EndpointRegistrationKeyValues.AzureVmExtension,
    displayOrder: 100,
    categories: [AzureCategory, WindowsCategory],
    name: "Azure VM",
    communicationStyle: CommunicationStyle.None,
    renderCard: InfoRegistrationCards.basic(() => <AzureVmEndpointDialogView />, ({ registration }) => ({
        logo: <AzureVm className={styles.centreThumbnail} title={registration.name}/>,
        registrationName: registration.name,
        description: "Easily connect an Azure VM to the Octopus Server with a VM Extension.",
    }), ({ spaceId }) => <AzureVmAdditionalActions spaceId={spaceId}/>, ({ onClick }) => onClick),
};
