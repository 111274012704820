import { css } from "@emotion/css";
import { PolaroidCameraIcon } from "@octopusdeploy/design-system-icons";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import DateFormatter from "~/utils/DateFormatter/index";
type SnapshotTakenProps = {
    date: string;
};
const containerStyles = css({
    color: themeTokens.color.text.secondary,
    font: text.regular.default.medium,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "> p": {
        margin: 0,
    },
});
const iconStyles = css({
    marginRight: space[8],
});
export const SnapshotTaken = (props: SnapshotTakenProps) => (<div className={containerStyles}>
        <span className={iconStyles}>
            <PolaroidCameraIcon size={20}/>
        </span>
        <p>
            Snapshot taken on <span title={DateFormatter.dateToLongFormat(props.date) || undefined}>{DateFormatter.dateToShortFormat(props.date)}</span>
        </p>
    </div>);
