import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren } from "react";
import React, { useMemo, useCallback, useState, useEffect, useRef, useContext, createContext } from "react";
type GroupAction = "Expand" | "Collapse";
type GroupActionHandler = (groupAction: GroupAction) => void;
type ExpandableCodeViewerGroupState = {
    registerGroupActionHandler: (instanceId: string, defaultIsExpanded: boolean, handler: GroupActionHandler) => void;
    deregisterGroupActionHandler: (instanceId: string) => void;
    onExpansionChange: (key: string, isExpanded: boolean) => void;
};
const ExpandableCodeViewerGroupContext = createContext<ExpandableCodeViewerGroupState | null>(null);
export const useExpandableCodeViewerGroup = (instanceId: string, isExpandedByDefault: boolean, onGroupAction: GroupActionHandler) => {
    const context = useContext(ExpandableCodeViewerGroupContext);
    useEffect(() => {
        if (!context) {
            return;
        }
        context.registerGroupActionHandler(instanceId, isExpandedByDefault, onGroupAction);
        return () => context.deregisterGroupActionHandler(instanceId);
    }, [context, instanceId, isExpandedByDefault, onGroupAction]);
    return context?.onExpansionChange;
};
const styles = {
    actionContainer: css({
        width: "100%",
        textAlign: "right",
    }),
    actionLink: css({
        font: text.regular.default.xSmall,
        color: themeTokens.color.text.secondary,
        marginLeft: space[24],
        cursor: "pointer",
        textTransform: "uppercase",
        "&:hover": {
            color: themeTokens.color.text.link.default,
        },
    }),
};
type ExpandableCodeViewerGroupProps = PropsWithChildren<{
    isExpandedByDefault?: boolean;
    showGroupActions: boolean;
}>;
export const ExpandableCodeViewerGroup = (props: ExpandableCodeViewerGroupProps) => {
    const registeredCodeViewers = useRef<Record<string, [
        boolean,
        GroupActionHandler
    ]>>({});
    const [hasAnyCollapsedCodeViewers, sethasAnyCollapsedCodeViewers] = useState<boolean>(false);
    const [hasAnyExpandedCodeViewers, setHasAnyExpandedCodeViewers] = useState<boolean>(false);
    const reevaluateActionConditions = () => {
        const allCodeViewerStates = Object.values(registeredCodeViewers.current).map(([isExpanded]) => isExpanded);
        sethasAnyCollapsedCodeViewers(allCodeViewerStates.length > 0 && allCodeViewerStates.some((expanded) => !expanded));
        setHasAnyExpandedCodeViewers(allCodeViewerStates.length > 0 && allCodeViewerStates.some((expanded) => expanded));
    };
    const executeActionHandlers = (action: GroupAction, isExpanded: boolean) => {
        for (const [key, [, handler]] of Object.entries(registeredCodeViewers.current)) {
            handler(action);
            registeredCodeViewers.current[key] = [isExpanded, handler];
        }
        reevaluateActionConditions();
    };
    const onExpansionChange = useCallback((instanceId: string, isExpanded: boolean) => {
        const [, handler] = registeredCodeViewers.current[instanceId];
        registeredCodeViewers.current[instanceId] = [isExpanded, handler];
        reevaluateActionConditions();
    }, []);
    const registerGroupActionHandler = useCallback((instanceId: string, isExpandedByDefault: boolean, handler: GroupActionHandler) => {
        registeredCodeViewers.current[instanceId] = [props.isExpandedByDefault || isExpandedByDefault, handler];
        //trigger the initial expansion/collapse but only if the group has a default
        if (props.isExpandedByDefault !== undefined) {
            handler(props.isExpandedByDefault ? "Expand" : "Collapse");
        }
        reevaluateActionConditions();
    }, [props.isExpandedByDefault]);
    const deregisterGroupActionHandler = useCallback((instanceId: string) => {
        //remove the registered handlers
        delete registeredCodeViewers.current[instanceId];
        reevaluateActionConditions();
    }, []);
    const state = useMemo(() => ({
        registerGroupActionHandler,
        deregisterGroupActionHandler,
        onExpansionChange,
    }), [deregisterGroupActionHandler, onExpansionChange, registerGroupActionHandler]);
    return (<div>
            {props.showGroupActions && (<div className={styles.actionContainer}>
                    {hasAnyCollapsedCodeViewers && (<a role="button" aria-label={"expand all"} className={styles.actionLink} onClick={() => executeActionHandlers("Expand", true)}>
                            Expand All
                        </a>)}
                    {hasAnyExpandedCodeViewers && (<a role="button" aria-label={"collapse all"} className={styles.actionLink} onClick={() => executeActionHandlers("Collapse", false)}>
                            Collapse All
                        </a>)}
                </div>)}
            <ExpandableCodeViewerGroupContext.Provider value={state}>{props.children}</ExpandableCodeViewerGroupContext.Provider>
        </div>);
};
