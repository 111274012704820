import { TargetIcon } from "@octopusdeploy/design-system-icons";
import type { EnvironmentResource, FeatureToggleEnvironmentResource, FeatureToggleResource, ProjectResource, TenantResource } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { useRef, useState } from "react";
import * as React from "react";
import EnvironmentOrMissingChip from "~/areas/projects/components/FeatureToggles/EnvironmentOrMissingChip";
import { ExcludedTenantList, SegmentList } from "~/areas/projects/components/FeatureToggles/FeatureToggleEnvironment";
import Chip from "~/components/Chips/Chip";
import { tenantChipListIncludingMissing } from "~/components/Chips/index";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import { useOctopusTheme } from "~/components/Theme/index";
import { DataTable, DataTableBody, DataTableRow, DataTableRowColumn } from "~/primitiveComponents/dataDisplay/DataTable";
import type { Origin } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import styles from "./style.module.less";
interface FeatureToggleTargetsButtonProps {
    project: ProjectResource;
    featureToggle: FeatureToggleResource;
    featureToggleEnvironment: FeatureToggleEnvironmentResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
}
export default ({ project, featureToggle, featureToggleEnvironment, environments, tenants }: FeatureToggleTargetsButtonProps) => {
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const spanRef = useRef<HTMLInputElement>(null);
    const popoverOrigin: Origin = { horizontal: "right", vertical: "center" };
    const theme = useOctopusTheme();
    // if environment has no targeting, then return empty
    if (featureToggleEnvironment.ToggleStrategy === "EntireEnvironment") {
        return <span></span>;
    }
    const bullseyeIcon = <TargetIcon size={24} color={theme.iconNeutral}/>;
    const showTargetsPopup = (event: React.MouseEvent) => {
        event.preventDefault();
        setPopoverOpen(true);
    };
    return (<div ref={spanRef} className={styles.featureToggleTargetingButton}>
            <IconButtonWithTooltip toolTipContent={"Show targeting details"} customIcon={bullseyeIcon} onClick={showTargetsPopup} accessibleName={"Show targeting details"}/>
            <Popover open={popoverOpen} anchorEl={spanRef.current} onClose={() => setPopoverOpen(false)} anchorOrigin={popoverOrigin}>
                <div className={styles.featureToggleTargetingPopoverContainer}>
                    <p>
                        The <InternalLink to={links.featureTogglePage.generateUrl({ spaceId: project.SpaceId, projectSlug: project.Slug, featureToggleSlug: featureToggle.Slug })}>{featureToggle.Name}</InternalLink> feature toggle targets the
                        following Tenants and/or Segments in <EnvironmentOrMissingChip environmentId={featureToggleEnvironment.DeploymentEnvironmentId} environments={environments}/>
                    </p>
                    <DataTable>
                        <DataTableBody>
                            {featureToggleEnvironment.TenantTargetingStrategy === "SpecificTenants" && (<DataTableRow>
                                    <DataTableRowColumn>Tenants</DataTableRowColumn>
                                    <DataTableRowColumn>
                                        {featureToggleEnvironment.RolloutPercentage === null ? null : <Chip backgroundColor={theme.experimentBackground}>{featureToggleEnvironment.RolloutPercentage}% of tenants</Chip>}
                                        {tenantChipListIncludingMissing(tenants, featureToggleEnvironment.TenantIds)}
                                        {ExcludedTenantList({ excludedTenants: tenants.filter((tenant) => featureToggleEnvironment.ExcludedTenantIds.includes(tenant.Id)) })}
                                    </DataTableRowColumn>
                                </DataTableRow>)}
                            {featureToggleEnvironment.Segments && featureToggleEnvironment.Segments.length > 0 && (<DataTableRow>
                                    <DataTableRowColumn>Segments</DataTableRowColumn>
                                    <DataTableRowColumn>{SegmentList({ segments: featureToggleEnvironment.Segments })}</DataTableRowColumn>
                                </DataTableRow>)}
                        </DataTableBody>
                    </DataTable>
                </div>
            </Popover>
        </div>);
};
