import { ActionButton, ActionButtonType, Callout } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { HasGitPersistenceSettings, HasRunbooksInGit, HasVariablesInGit } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import { Action, useProjectScopedAnalyticActionDispatch } from "~/analytics/Analytics";
import { useHiddenFeatures } from "~/areas/configuration/components/FeaturesLayout/useHiddenFeatures";
import { useProjectContext } from "~/areas/projects/context";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import { EnableProjectRunbooksDialog } from "./EnableProjectRunbooksDialog";
import MigrateProjectRunbooksWizard from "./MigrateProjectRunbooksDialog";
interface NewFeatureCalloutTitleProps {
    title: string;
}
const NewFeatureCalloutTitle: React.FC<NewFeatureCalloutTitleProps> = (props: NewFeatureCalloutTitleProps) => {
    return <>{props.title}</>;
};
NewFeatureCalloutTitle.displayName = "NewFeatureCalloutTitle"
interface MigrageProjectRunbooksToGitProps {
    project?: ProjectResource;
    hasNoRunbooks: boolean;
}
const MigrateProjectRunbooksBanner: React.FC<MigrageProjectRunbooksToGitProps> = (props: MigrageProjectRunbooksToGitProps) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const projectContext = useProjectContext();
    const project = projectContext.state.model;
    const dispatchAction = useProjectScopedAnalyticActionDispatch(projectContext.state.model.Id);
    const hiddenFeaturesEnabled = useHiddenFeatures();
    const migrationToggleEnabled = useOctopusFeatureToggle("git-runbooks-migration", false);
    // If this is a database project, or runbooks are already in Git, don't show the banner
    if (!HasGitPersistenceSettings(project.PersistenceSettings) || !HasVariablesInGit(project.PersistenceSettings) || HasRunbooksInGit(project.PersistenceSettings)) {
        return null;
    }
    // We only want to show the banner if the Git Runbooks toggle
    // is on and one of the following is true:
    //
    // - The migration toggle is enabled
    // - The hidden features are enabled, or
    // - The empty migration toggle is enabled and the project has no runbooks
    const bannerEnabled = isFeatureToggleEnabled("GitRunbooksFeatureToggle") && (migrationToggleEnabled || hiddenFeaturesEnabled);
    if (!bannerEnabled) {
        return null;
    }
    const openDialogWithAnalytics = () => {
        dispatchAction("Start Moving Runbooks to Git", { resource: "Runbooks", action: Action.Configure });
        setDialogOpen(true);
    };
    return (<Callout type={"new-feature"} title={<NewFeatureCalloutTitle title="Store Runbooks in Git"/>}>
            <p>You can now store your Runbooks in a Git repository alongside your deployment process and variables.</p>
            <ActionButton type={ActionButtonType.Secondary} label={props.hasNoRunbooks ? "Enable Config as Code Runbooks" : "Migrate Runbooks to Git"} onClick={() => openDialogWithAnalytics()}/>
            {props.hasNoRunbooks ? (<EnableProjectRunbooksDialog open={dialogOpen} close={() => setDialogOpen(false)} project={project}/>) : (<CustomDialog open={dialogOpen} close={() => setDialogOpen(false)} render={(props) => <MigrateProjectRunbooksWizard {...props}/>}/>)}
        </Callout>);
};
MigrateProjectRunbooksBanner.displayName = "MigrateProjectRunbooksBanner"
export default MigrateProjectRunbooksBanner;
