import { css, cx } from "@emotion/css";
import { fontSize, space } from "@octopusdeploy/design-system-tokens";
import React, { forwardRef, useMemo } from "react";
import { Skeleton } from "~/areas/projects/components/Projects/Skeleton";
import type { CodeComponent, CodeProps } from "~/components/Code/Code";
import { Code } from "~/components/Code/Code";
type CodeViewerWithLoadingProps = CodeProps & {
    isLoading: boolean;
};
const styles = {
    container: css({
        position: "relative",
    }),
    containerLoading: css({
        minHeight: space[80],
    }),
    loadingSkeletonContainer: css({
        position: "absolute",
        top: "4px", //aligns this with the top of the code viewer
        left: 0,
        width: "100%",
    }),
    loadingSkeletonItem: css({
        marginLeft: "50px",
        marginRight: space[8],
        height: `calc(${fontSize["medium"]} * 1.4)`, //same line height as the code lines
        display: "flex",
        alignItems: "center",
        "> div": {
            height: fontSize["medium"], //set the skeletons to be the same height as the text
        },
    }),
};
const skeletonRowLengths = ["25rem", "15rem", "40rem", "30rem"];
export const CodeViewerWithLoading = forwardRef<CodeComponent, CodeViewerWithLoadingProps>((props: CodeViewerWithLoadingProps, ref) => {
    const { isLoading, value, ...rest } = props;
    // the code editor control adds an extra row at the end, so do one less row in the manifest
    // this is there so that we get the row numbers in the editor for the skeleton rows to match up to
    const loadingManifest = useMemo(() => skeletonRowLengths.slice(0, skeletonRowLengths.length - 1).reduce((str) => `\n${str}`, ""), []);
    return (<div className={cx(styles.container, isLoading ? styles.containerLoading : undefined)}>
            <Code ref={ref} value={value || (isLoading ? loadingManifest : undefined)} {...rest}/>
            {isLoading && (<div className={styles.loadingSkeletonContainer}>
                    {skeletonRowLengths.map((width, i) => (<div key={i} className={cx(styles.loadingSkeletonItem, css({ width }))}>
                            <Skeleton shape={"Rounded"} borderRadius={"small"}/>
                        </div>))}
                </div>)}
        </div>);
});
