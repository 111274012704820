import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { useTrackEvent } from "@octopusdeploy/portal-analytics";
import { OctoLink } from "@octopusdeploy/utilities";
import React, { useState } from "react";
import CustomDialog from "~/components/Dialog/CustomDialog";
import { CustomExternalLinkDialogLayout, SmallDialogFrame } from "~/components/DialogLayout/Custom";
import Section from "~/components/Section";
interface Props {
    label: string;
}
export function InviteOctoIdUserDialogLink({ label }: Props) {
    const [showDialog, setShowDialog] = useState(false);
    const trackEvent = useTrackEvent();
    const showDialogHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowDialog(true);
    };
    const closeDialogHandler = () => {
        setShowDialog(false);
    };
    return (<>
            <CustomDialog open={showDialog} close={closeDialogHandler} render={() => (<CustomExternalLinkDialogLayout title="Invite user" externalLink={OctoLink.Create("ControlCenter")} externalLinkLabel="Go to Accounts" open={showDialog} close={closeDialogHandler} frame={SmallDialogFrame} dispatchAnalytics={() => trackEvent({
                eventName: "Invite User: Go To Control Center Button Clicked",
            })}>
                        <Section>Send email invites and access to your instance via Octopus.com</Section>
                    </CustomExternalLinkDialogLayout>)}/>
            <ActionButton onClick={showDialogHandler} label={label} type={ActionButtonType.Primary}/>
        </>);
}
