import type { Action } from "redux";
import { combineReducers } from "redux";
import { configurationArea } from "~/areas/configuration/reducers/configurationArea";
import infrastructureArea from "~/areas/infrastructure/reducers/infrastructureArea";
import drawerArea from "~/components/ContextualHelpLayout/reducers";
import dialogs from "~/components/Dialog/store";
import form from "~/components/FormPaperLayout/reducers";
import unhandledError from "~/components/UnhandledError/reducers";
import expanders from "~/components/form/Sections/reducers/expanders";
import { tenantsArea } from "../areas/tenants/tenantsArea";
import { notificationReducer } from "../components/Navbar/notifications.redux";
const rootReducer = combineReducers({
    infrastructureArea,
    tenantsArea,
    configurationArea,
    expanders,
    dialogs,
    notifications: notificationReducer,
    form,
    unhandledError,
    drawerArea,
});
const RESET_STATE_ACTION = "RESET_STATE";
export const resetState = (): Action => ({ type: RESET_STATE_ACTION });
const wrappedReducer: typeof rootReducer = (state, action) => {
    if (action.type === RESET_STATE_ACTION) {
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
};
export type GlobalState = ReturnType<typeof rootReducer>;
export default wrappedReducer;
export { wrappedReducer };
