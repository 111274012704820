import { css, cx } from "@emotion/css";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import { Collapse } from "@material-ui/core";
import { Button, Checkbox, Divider, NoSearchResults, Pagination, SearchInput, useIsLargerThanIpadResolution } from "@octopusdeploy/design-system-components";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { FilterIcon } from "@octopusdeploy/design-system-icons";
import { borderRadius, borderWidth, space, themeTokens } from "@octopusdeploy/design-system-tokens";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { QueryParamValuesSetter } from "@octopusdeploy/portal-routes";
import { useState } from "react";
import * as React from "react";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import { AddOrCloneTenant } from "~/areas/tenants/Tenants/AddOrCloneTenant";
import Onboarding from "~/areas/tenants/Tenants/Onboarding";
import type { TenantsFilterState } from "~/areas/tenants/Tenants/hooks/useTenantsFilterState";
import { useTenantsState } from "~/areas/tenants/Tenants/hooks/useTenantsState";
import TenantsDataTable from "~/areas/tenants/components/DataTable/TenantsDataTable";
import { FilterBuilder } from "~/areas/tenants/components/Filtering/FilterBuilder/FilterBuilder";
import type { FilterValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { createEnvironmentFilter, createProjectFilter, createTagSetFilters, getExcludedEnvironmentValue, getExcludedProjectValue, getExcludedTagSetValues, getIncludedEnvironmentValue, getIncludedProjectValue, getIncludedTagSetValues, } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import type { TenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import { useTenantFiltersData } from "~/areas/tenants/components/Filtering/hooks/useTenantFiltersData";
import { VerticalDivider } from "~/areas/tenants/components/HeaderBar/CollapsibleFilter";
import { repository } from "~/clientInstance";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ShareFilterButton from "~/components/ShareFilterButton/ShareFilterButton";
import { useDebouncedExternalState } from "~/hooks/useDebouncedExternalState";
import { FilteredByChips } from "./CurrentFilterSelection/CurrentFilterSelection";
interface TenantsPageProps {
    spaceId: string;
    queryParams: TenantsPageQueryParams;
    setQueryParams: QueryParamValuesSetter<TenantsPageQueryParams>;
}
export interface TenantsPageQueryParams {
    search: string;
    excludedSearch: string;
    project: string;
    excludedProject: string;
    environment: string;
    excludedEnvironment: string;
    tags: string; // comma separate values. TODO: Migrate this to an array type
    excludedTags: string; // comma separate values. TODO: Migrate this to an array type
    pageNumber: number | undefined;
    includeMissingVariablesStatus: boolean;
}
export function TenantsPage({ spaceId, queryParams, setQueryParams }: TenantsPageProps) {
    const dispatchAction = useAnalyticActionDispatch();
    const { doBusyTask, status: legacyDoBusyTaskStatus } = useLegacyDoBusyTask();
    const { tableState, pageSize, filterState, tenantsActions, dataKey } = useTenantsState(doBusyTask, queryParams, setQueryParams);
    const filterData = useTenantFiltersData(doBusyTask);
    const addOrCloneTenantAction: PrimaryPageAction = {
        type: "custom",
        key: "Add or clone tenant",
        content: <AddOrCloneTenant />,
        hasPermissions: isAllowed({ permission: Permission.TenantCreate }),
    };
    if (!tableState || !filterData) {
        return (<PageContent header={{ title: "Tenants", primaryAction: addOrCloneTenantAction }} errors={legacyDoBusyTaskStatus.errors} busy={legacyDoBusyTaskStatus.isInProgress}>
                {null}
            </PageContent>);
    }
    if (tableState.totalTenantCount === 0) {
        return <Onboarding />;
    }
    const { tenants, filteredTenantCount, totalTenantCount } = tableState;
    const paginationTotalCount = filteredTenantCount === null ? totalTenantCount : filteredTenantCount;
    async function onCsvDownloadRequested() {
        dispatchAction("Download CSV", { resource: "Tenant", action: Action.Download });
        await doBusyTask(async () => repository.Tenants.tenantsCsv(filterState));
    }
    const pagination = (<Pagination currentPage={filterState.pageNumber} itemsPerPageOptions={[30, 50, 100]} label="Tenants" onPageChange={(page) => tenantsActions.setPageNumber(page)} selectedItemsPerPage={pageSize} onPageSizeChange={(pageSize) => tenantsActions.setPageSize(pageSize)} totalResults={paginationTotalCount}/>);
    return (<PageContent header={{ title: "Tenants", primaryAction: addOrCloneTenantAction }} errors={legacyDoBusyTaskStatus.errors} busy={legacyDoBusyTaskStatus.isInProgress} 
    /**
     * The tenants page has a custom filter section that doesn't follow other page layouts.
     * We are just shoving the whole thing into the filter inputs array so it displays in the location on the page content
     * In order to position the pagination correctly, the pagination is rendered within this filter component as well
     */
    filters={{
            inputs: [<TenantsPageFilterSection filterData={filterData} filterState={filterState} onFilterChange={tenantsActions.setTenantsFilter} onCsvDownloadRequested={onCsvDownloadRequested} pagination={pagination}/>],
        }} pagination={paginationTotalCount >= 20
            ? {
                ui: pagination,
                placement: "bottom",
            }
            : undefined}>
            <div className={styles.borderedContainer}>
                {paginationTotalCount > 0 ? (<TenantsDataTable spaceId={spaceId} key={dataKey} tenants={tenants} includeMissingVariables={filterState.includeMissingVariablesStatus} doBusyTask={doBusyTask} busy={legacyDoBusyTaskStatus.isInProgress}/>) : (<NoSearchResults />)}
            </div>
        </PageContent>);
}
interface TenantPageFilterSectionProps {
    filterData: TenantFiltersData;
    filterState: TenantsFilterState;
    onFilterChange: (newTenantsFilter: TenantsFilterState) => void;
    onCsvDownloadRequested: () => Promise<void>;
    pagination: React.ReactNode;
}
function TenantsPageFilterSection({ filterData, filterState, onFilterChange, onCsvDownloadRequested, pagination }: TenantPageFilterSectionProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const dispatchAction = useAnalyticActionDispatch();
    const [expanded, setExpanded] = useState(false);
    const handleOnExpandCollapse = () => {
        setExpanded(!expanded);
        dispatchAction("Toggle Filters", { resource: "Filters", action: Action.View });
    };
    const onNameFilterChange = (name: string) => {
        onFilterChange({ ...filterState, filterByName: name });
    };
    const [debouncedNameFilter, setDebouncedNameFilter] = useDebouncedExternalState(filterState.filterByName ?? "", onNameFilterChange, 250);
    return (<section className={styles.filterSection} aria-label="current-filter-selection">
            <div className={cx(styles.filterControls, { [styles.filterControlsMobile]: !isLargerThanIpad })}>
                <div className={styles.filterControlsLeft}>
                    <div className={styles.searchInputContainer}>
                        <SearchInput placeholder={"Filter by tenant name..."} value={debouncedNameFilter} onChange={setDebouncedNameFilter} autoFocus={true} accessibleName="Filter by tenant name"/>
                    </div>
                    <Button label={`${expanded ? "Hide" : "Show"} advanced filters`} leftIcon={<FilterIcon size={20}/>} onClick={handleOnExpandCollapse} importance="quiet"/>
                    <FilteredByChips filterState={filterState} filterData={filterData}/>
                    <Divider orientation="vertical"/>
                    <TenantsActions onCsvDownloadRequested={onCsvDownloadRequested}/>
                </div>
                <div>{pagination}</div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                <TenantsFilterBuilder filterState={filterState} onFilterChange={onFilterChange} tenantFiltersData={filterData}/>
            </Collapse>
        </section>);
}
interface TenantsFilterBuilderProps {
    tenantFiltersData: TenantFiltersData;
    onFilterChange: (newTenantsFilter: TenantsFilterState) => void;
    filterState: TenantsFilterState;
}
function TenantsFilterBuilder({ onFilterChange, tenantFiltersData, filterState }: TenantsFilterBuilderProps) {
    const projectFilter = createProjectFilter(Array.from(tenantFiltersData.projects.values()), filterState.filterByProject, filterState.filterByExcludedProject);
    const environmentFilter = createEnvironmentFilter(Array.from(tenantFiltersData.environments.values()), filterState.filterByEnvironment, filterState.filterByExcludedEnvironment);
    const tagSetFilters = createTagSetFilters(Array.from(tenantFiltersData.tagSets.values()), filterState.filterByTags, filterState.filterByExcludedTags);
    const handleFilterChange = (newFilters: FilterValue[]) => {
        onFilterChange({
            ...filterState,
            filterByProject: getIncludedProjectValue(newFilters),
            filterByExcludedProject: getExcludedProjectValue(newFilters),
            filterByEnvironment: getIncludedEnvironmentValue(newFilters),
            filterByExcludedEnvironment: getExcludedEnvironmentValue(newFilters),
            filterByTags: getIncludedTagSetValues(newFilters),
            filterByExcludedTags: getExcludedTagSetValues(newFilters),
        });
    };
    const onIncludeMissingVariablesChanged = (include: boolean) => {
        onFilterChange({ ...filterState, includeMissingVariablesStatus: include });
    };
    return (<div className={styles.filterBuilder}>
            <IncludeMissingVariables include={filterState.includeMissingVariablesStatus} onChanged={onIncludeMissingVariablesChanged}/>
            <VerticalDivider />
            <FilterBuilder filters={[projectFilter, environmentFilter, ...tagSetFilters]} onChange={handleFilterChange}/>
        </div>);
}
interface IncludeMissingVariablesProps {
    include: boolean;
    onChanged: (value: boolean) => void;
}
export function IncludeMissingVariables({ include, onChanged }: IncludeMissingVariablesProps) {
    return (<div>
            <div className={styles.displayOptionsTitle}>Show</div>
            <Checkbox value={include} onChange={onChanged} label={"Missing variables warning"}/>
        </div>);
}
export function TenantsActions({ onCsvDownloadRequested }: {
    onCsvDownloadRequested: () => Promise<void>;
}) {
    return (<div className={styles.toolbarIconsContainer}>
            <ShareFilterButton />
            <DownloadCsvButton onCsvDownloadRequested={onCsvDownloadRequested}/>
        </div>);
}
export function DownloadCsvButton({ onCsvDownloadRequested }: {
    onCsvDownloadRequested: () => Promise<void>;
}) {
    return <IconButtonWithTooltip icon={"ArrowDownToSquareIcon"} onClick={onCsvDownloadRequested} toolTipContent={"Download CSV"} accessibleName={"SaveAlt"}/>;
}
const styles = {
    tenantsContainer: css({
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    }),
    filterSection: css({
        width: "100%",
    }),
    filterControls: css({
        display: "flex",
        justifyContent: "space-between",
        gap: space[8],
    }),
    filterControlsMobile: css({
        flexWrap: "wrap",
    }),
    filterControlsLeft: css({
        display: "flex",
        gap: space[16],
        flexWrap: "wrap",
    }),
    searchInputContainer: css({
        width: "19rem",
    }),
    filterBuilder: css({
        display: "flex",
        gap: "0.6rem",
        padding: "1rem",
    }),
    displayOptionsTitle: css({
        color: themeTokens.color.text.disabled,
    }),
    toolbarIconsContainer: css({
        display: "inline-flex",
        alignItems: "center",
        gap: space[24],
    }),
    borderedContainer: css({
        border: `${borderWidth[1]} solid ${themeTokens.color.border.primary}`,
        borderRadius: borderRadius.medium,
        overflow: "clip",
    }),
};
