import { css } from "@emotion/css";
import { text } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
import type { OctopusTheme } from "~/components/Theme";
interface OverviewCardTitleProps {
    link: LinkHref | undefined;
    theme: OctopusTheme;
    count: number | null;
    title: string;
}
export const OverviewCardTitle = ({ link, theme, count, title }: OverviewCardTitleProps) => {
    const titleContent = (<>
            {title} {count !== null && <span className={cardCountStyles}>({count.toLocaleString()})</span>}
        </>);
    return link ? (<InternalLink to={link} color={theme.whiteConstant}>
            {titleContent}
        </InternalLink>) : (titleContent);
};
const cardCountStyles = css({
    font: text.regular.default.medium,
});
