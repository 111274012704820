import { Callout } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ResourceCollection, EventCategoryResource, EventResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import { repository } from "~/clientInstance";
import AuditListRow from "~/components/AuditListRow/AuditListRow";
import List from "~/components/PagingList";
import { PermissionCheck } from "~/components/PermissionCheck";
import Note from "~/primitiveComponents/form/Note/Note";
import Section from "../Section";
class EventListingList extends List<EventResource> {
}
interface EventListingProps {
    data: ResourceCollection<EventResource>;
    eventCategories: EventCategoryResource[];
    regarding?: string[];
    noDataMessage?: string;
    onPageSelected?(skip: number, p: number): Promise<ResourceCollection<EventResource>>;
}
export const NotPermittedToViewEventsCallout: React.FC<{}> = () => (<Callout type={"information"} title={"Permission required"}>
        The {Permission.EventView} permission is required to view results here.
    </Callout>);
NotPermittedToViewEventsCallout.displayName = "NotPermittedToViewEventsCallout"
export default function EventListing(props: EventListingProps) {
    const [data, setData] = useState<ResourceCollection<EventResource> | null>(null);
    if (!props.regarding && !props.onPageSelected) {
        throw new Error("EventListing must have either `regarding` or `onPageSelected` props passed");
    }
    const initialData = data ? data : props.data;
    return (<div>
            <PermissionCheck permission={Permission.EventView} wildcard={true} alternate={<NotPermittedToViewEventsCallout />}>
                {initialData && initialData.Items && initialData.Items.length > 0 ? (<EventListingList initialData={initialData} onRow={(item: EventResource) => {
                const category = props.eventCategories.find((x) => x.Id === item.Category);
                return <AuditListRow event={item} categoryName={category ? category.Name : item.Category}/>;
            }} showPagingInNumberedStyle={true} onPageSelected={async (skip: number, p: number) => {
                const result = props.onPageSelected ? await props.onPageSelected(skip, p) : await repository.Events.list({ regarding: props.regarding, skip });
                setData(result);
            }} wrapRowsInListItem={false} // AuditListRow does custom styling on these rows.
        />) : (<Section>
                        <Note>{props.noDataMessage || "There's no data to show."}</Note>
                    </Section>)}
            </PermissionCheck>
        </div>);
}
