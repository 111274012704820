import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import { DebugMode } from "@octopusdeploy/octopus-server-client/src/resources/debugMode";
import * as React from "react";
import ExternalLink from "~/components/Navigation/ExternalLink";
import Note from "~/primitiveComponents/form/Note/Note";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import Summary from "../../../../../components/form/Sections/Summary";
interface DebugOptionsProps {
    debugMode: DebugMode;
    onChange(value: DebugMode): void;
}
const DebugOptions = (props: DebugOptionsProps) => {
    return (<ExpandableFormSection title="Debug Mode" errorKey="debug" help="Logs all variable templates and their resolved values for debugging." summary={buildSummary(props.debugMode)}>
            <div>
                <RadioButtonGroup value={props.debugMode} onChange={props.onChange} accessibleName="Debug Options">
                    <RadioButton label="None" value={DebugMode.None}/>
                    <RadioButton label="Log evaluated variables" value={DebugMode.Log}/>
                    {/*<RadioButton label="Debug" value={DebugMode.Debug} />*/}
                </RadioButtonGroup>
            </div>
            <Note>
                Debug logging contributes the <code>OctopusPrintVariables</code> and <code>OctopusPrintEvaluatedVariables</code> variables. See our <ExternalLink href="DebugVariableLogging">documentation</ExternalLink> for more information.
            </Note>
        </ExpandableFormSection>);
    function buildSummary(debugMode: DebugMode) {
        return debugMode === DebugMode.Log ? Summary.summary("Deploy with variable evaluation logs enabled") : Summary.default("Inactive");
    }
};
export default DebugOptions;
