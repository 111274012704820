/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { KnownErrorCodes } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { CloseButton } from "~/components/Button";
import ExternalLink from "~/components/Navigation/ExternalLink/ExternalLink";
import SmoothScroll from "~/components/SmoothScroll/SmoothScroll";
import Note from "~/primitiveComponents/form/Note/Note";
import { client } from "../../clientInstance";
import styles from "./style.module.less";
interface ErrorPanelProps {
    message: string;
    statusCode?: number;
    errors?: string[];
    parsedHelpLinks?: string[];
    helpText?: string;
    helpLink?: string;
    fullWidth?: boolean;
    canClose?: boolean;
    onErrorClose?: () => void;
    scrollToPanel?: boolean;
    clickableErrors?: {
        message: string;
        onClick: () => void;
    }[];
}
interface ErrorPanelState {
    showFullException: boolean;
}
class ErrorPanel extends React.Component<ErrorPanelProps, ErrorPanelState> {
    private static defaultProps: Partial<ErrorPanelProps> = {
        fullWidth: true,
        scrollToPanel: true,
    };
    panel: HTMLDivElement | null = undefined!;
    constructor(props: ErrorPanelProps) {
        super(props);
        this.state = {
            showFullException: false,
        };
    }
    componentDidMount() {
        if (this.props.scrollToPanel && this.panel) {
            SmoothScroll.scrollTo(this.panel);
        }
    }
    render() {
        if (this.props.statusCode === KnownErrorCodes.NetworkError) {
            // We are handling network errors globally via SystemMessagesBanner
            return null;
        }
        const serverInfo = client.tryGetServerInformation();
        return (<div ref={(ref) => (this.panel = ref)} className={styles.error} style={{
                display: this.props.fullWidth ? "block" : "inline-block",
                width: this.props.fullWidth ? "100%" : "auto",
            }}>
                <div className={styles.titleContainer}>
                    <span className={styles.title}>{this.props.message}</span>
                    <div>{this.props.canClose && <CloseButton size="1.5rem" onClose={this.props.onErrorClose}/>}</div>
                </div>
                {this.props.errors && this.props.errors.length > 0 && <ul>{this.props.errors.map((e, index) => this.detailFor(e, index))}</ul>}
                {this.props.clickableErrors && this.props.clickableErrors.length > 0 && (<div style={{ padding: "1rem 0rem" }}>
                        <span>Errors found in the following steps:</span>
                        <div>{this.props.clickableErrors.map((e, index) => this.renderClickableError(e.message, e.onClick, index))}</div>
                    </div>)}
                {this.props.helpText && (<div className={styles.note}>
                        <Note>{this.props.helpText}</Note>
                    </div>)}
                {this.props.helpLink && <ExternalLink href={this.props.helpLink}>More information...</ExternalLink>}
            </div>);
    }
    private detailFor(error: string, index: number) {
        const detailLinks = this.props.parsedHelpLinks;
        const detailLink = detailLinks && detailLinks.length > index && detailLinks[index];
        return (<li key={index}>
                {error}
                {detailLink && (<div className={styles.detailLink}>
                        <ExternalLink href={detailLink}>More information...</ExternalLink>
                    </div>)}
            </li>);
    }
    private renderClickableError(error: string, onClick: () => void, index: number) {
        return (<div key={`clickable-${index}`}>
                <a onClick={onClick} style={{ cursor: "pointer" }} role="button">
                    {error}
                </a>
            </div>);
    }
    static displayName = "ErrorPanel";
}
export default ErrorPanel;
