import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { dashboardRefreshIntervalInMs } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import type { RenderDashboardProps } from "~/areas/dashboard/DashboardOverview/DashboardOverviewPage";
import type { DataCube } from "~/areas/projects/components/DashboardDataSource/DataCube";
import ProgressionDataCube from "~/areas/projects/components/DashboardDataSource/ProgressionDataCube";
import { repository } from "~/clientInstance";
import type { DoBusyTask } from "~/components/DataBaseComponent/index";
import { useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { useRefreshLoop } from "~/hooks/useRefreshLoop";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
interface UntenantedProjectDashboardDataSourceProps {
    project: ProjectResource;
    render: (props: RenderDashboardProps) => JSX.Element;
    doBusyTask: DoBusyTask;
    cube: DataCube | undefined;
    onCubeLoaded: (cube: DataCube, totalItems: number) => void;
}
export default function UntenantedProjectDashboardDataSource({ doBusyTask, project, render, cube, onCubeLoaded }: UntenantedProjectDashboardDataSourceProps) {
    const dashboardRequest = useDoBusyTaskEffect(doBusyTask, async () => {
        const cube = await projectDashboardRefresh(project);
        onCubeLoaded(cube, cube.data.Releases.length);
    }, [project], { timeOperationOptions: timeOperationOptions.forRefresh() });
    useRefreshLoop(dashboardRequest, dashboardRefreshIntervalInMs);
    return render({ cube });
}
async function projectDashboardRefresh(project: ProjectResource): Promise<ProgressionDataCube> {
    const dataPromise = repository.Progression.getProgression(project);
    const allChannelsPromise = repository.Channels.allFromProject(project);
    const deploymentFreezesPromise = repository.DeploymentFreezes.activeByProject(project);
    const data = await dataPromise;
    const allChannels = await allChannelsPromise;
    const deploymentFreezes = await deploymentFreezesPromise;
    const progressionData = new ProgressionDataCube(data, project, deploymentFreezes.DeploymentFreezes);
    progressionData.addAdditionalChannels(allChannels);
    return progressionData;
}
