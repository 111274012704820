import { CommunicationStyle } from "@octopusdeploy/octopus-server-client";
import { EndpointRegistrationKeyValues } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { EndpointDialogCard } from "~/areas/infrastructure/components/MachineSettings/Endpoints/EndpointCard";
import { KubernetesAgentTargetConfigurationDialog } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentTargetConfigurationDialog";
import { KubernetesAgentWorkerConfigurationDialog } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/KubernetesAgentWorkerConfigurationDialog";
import { useKubernetesWorkerOutOfEarlyAccess } from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesAgent/useKubernetesWorkerOutOfEarlyAccess";
import kubernetesCategory from "~/areas/infrastructure/components/MachineSettings/Endpoints/KubernetesCategoryDefinition";
import type { MachineRegistration, RenderRegistrationCardProps } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import { EndpointSelectionScope } from "~/areas/infrastructure/components/MachineSettings/Endpoints/endpointRegistry";
import styles from "~/areas/infrastructure/components/MachineSettings/Endpoints/styles.module.less";
import { EarlyAccessChip, RecommendedChip } from "~/components/Chips";
import { KubernetesAgent } from "~/components/Images/MachineSettings/KubernetesAgent";
const kubernetesAgentEndpointRegistration: MachineRegistration = {
    key: EndpointRegistrationKeyValues.KubernetesTentacle,
    displayOrder: 10,
    communicationStyle: CommunicationStyle.KubernetesTentacle,
    name: "Kubernetes Agent",
    categories: [kubernetesCategory],
    discoverable: false,
    renderCard: (props) => <KubernetesAgentEndpointCard {...props}/>,
};
const KubernetesAgentEndpointCard = ({ registration, scope, onClick }: RenderRegistrationCardProps) => {
    const workerOutOfEarlyAccess = useKubernetesWorkerOutOfEarlyAccess();
    const outOfEarlyAccess = scope === EndpointSelectionScope.Worker ? workerOutOfEarlyAccess : true; /* The agent is now out of EAP */
    return (<EndpointDialogCard logo={<KubernetesAgent className={styles.centreThumbnail} title={registration.name}/>} registrationName={scope === EndpointSelectionScope.Worker ? "Kubernetes Worker" : registration.name} description={getDescription(scope)} chip={outOfEarlyAccess ? <RecommendedChip noMargin/> : <EarlyAccessChip noMargin/>} renderDialog={(renderProps) => {
            if (scope === EndpointSelectionScope.Worker) {
                return <KubernetesAgentWorkerConfigurationDialog open={renderProps.open} closeDialog={renderProps.closeDialog} openDialog={renderProps.openDialog}/>;
            }
            return <KubernetesAgentTargetConfigurationDialog open={renderProps.open} closeDialog={renderProps.closeDialog} openDialog={renderProps.openDialog}/>;
        }} onClick={onClick}/>);
};
function getDescription(scope: EndpointSelectionScope): string {
    if (scope === EndpointSelectionScope.Worker) {
        return "Install for fast and scalable work execution for a variety of deployment targets.";
    }
    return "Install an agent to execute deployments on your cluster. No extra workers needed.";
}
export { kubernetesAgentEndpointRegistration };
