/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { NavigationButton, NavigationButtonType, type PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { GitCredentialResource, PagingCollection, Repository } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import Onboarding from "~/areas/library/components/GitCredentials/Onboarding";
import MarkdownDescription from "~/components/MarkdownDescription/index";
import { PageContent } from "~/components/PageContent/PageContent";
import List from "~/components/PagingList/index";
import PermissionCheck, { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ListTitle from "~/primitiveComponents/dataDisplay/ListTitle/index";
class GitCredentialList extends List<GitCredentialResource> {
}
interface PlatformHubGitCredentialsLoaderData {
    gitCredentials: PagingCollection<GitCredentialResource>;
}
interface PlatformHubGitCredentialsPageProps {
    loaderData: PlatformHubGitCredentialsLoaderData;
}
export async function platformHubGitCredentialsLoader(repository: Repository): Promise<PlatformHubGitCredentialsLoaderData> {
    return { gitCredentials: await repository.PlatformHubRepository.getGitCredentials() };
}
function AddGitCredentialButton() {
    return (<PermissionCheck permission={Permission.PlatformHubEdit}>
            <NavigationButton type={NavigationButtonType.Primary} label="Add Git Credential" href={links.createPlatformHubGitCredentialPage.generateUrl()}/>
        </PermissionCheck>);
}
function BuildGitCredentialRow(gitCredential: GitCredentialResource) {
    return (<div>
            <ListTitle>{gitCredential.Name}</ListTitle>
            <MarkdownDescription markup={gitCredential.Description!}/>
        </div>);
}
function OnFilter(filter: string, gitCredential: GitCredentialResource) {
    if (!filter || filter.length === 0 || !gitCredential) {
        return true;
    }
    return gitCredential.Name.toLowerCase().includes(filter.toLowerCase());
}
export function PlatformHubGitCredentialsPage({ loaderData }: PlatformHubGitCredentialsPageProps) {
    const addGitCredentialPageAction: PrimaryPageAction = {
        type: "custom",
        key: "Add Git Credential",
        content: <AddGitCredentialButton />,
        hasPermissions: isAllowed({
            permission: Permission.PlatformHubEdit,
            wildcard: true,
        }),
    };
    return (loaderData && (<PageContent header={{ title: "Git Credentials", primaryAction: addGitCredentialPageAction }}>
                {loaderData.gitCredentials.Items && (<>
                        {loaderData.gitCredentials.TotalResults === 0 && <Onboarding actionButtons={[<AddGitCredentialButton />]}/>}
                        {loaderData.gitCredentials.TotalResults > 0 && (<GitCredentialList initialData={loaderData.gitCredentials} onRow={(item) => BuildGitCredentialRow(item)} onRowRedirectUrl={(item) => links.editPlatformHubGitCredentialPage.generateUrl({ gitCredentialId: item.Id })} onFilter={OnFilter} apiSearchParams={["name"]} filterSearchEnabled={true} filterHintText="Filter by name..."/>)}
                    </>)}
            </PageContent>));
}
