/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { Permission, VariableSetContentType } from "@octopusdeploy/octopus-server-client";
import type { LibraryVariableSetResource, ProjectResource, ScopeValues, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { compact, difference } from "lodash";
import * as React from "react";
import mergeScopeValues from "~/areas/variables/MergeScopeValues";
import type { AdditionalFilter, VariableWithSource } from "~/areas/variables/VariableDisplayer";
import FilterableVariableDisplayer from "~/areas/variables/VariableDisplayer/FilterableVariableDisplayer";
import VariableSetSectionHeading from "~/areas/variables/VariableSetSectionHeading/VariableDisplayerSectionHeading";
import VariableSetSelector from "~/areas/variables/VariableSetSelector/VariableSetSelector";
import { repository } from "~/clientInstance";
import Dialog from "~/components/Dialog/Dialog";
import type { DialogControls } from "~/components/Dialog/DialogTrigger";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import FormBaseComponent from "~/components/FormBaseComponent/FormBaseComponent";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent/FormBaseComponent";
import type { GroupedExpandableProps } from "~/components/GroupedExpandable";
import { default as GroupedExpandable } from "~/components/GroupedExpandable";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import ExpansionButtons from "~/components/form/Sections/ExpansionButtons";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import { convertVariableResourcesToVariablesWithSource } from "../../../../variables/convertVariableResourcesToVariablesWithSource";
import styles from "./style.module.less";
type Loadable<T> = T | "notloaded";
function isLoaded<T>(loadable: Loadable<T>): loadable is T {
    return loadable !== "notloaded";
}
type VariablesAndScopes = {
    variables: ReadonlyArray<VariableWithSource>;
    scopeValues: ScopeValues;
};
const libraryVariableSetsContainerKey = "library_variable_sets";
interface LibraryVariableSetWithVariables {
    libraryVariableSet: LibraryVariableSetResource;
    variableAndScopes: Loadable<VariablesAndScopes>;
}
type Busies = {
    [key: string]: Promise<void>;
};
interface LibraryVariableSetsState extends OptionalFormBaseComponentState<ReadonlyArray<LibraryVariableSetWithVariables>> {
    busies: Busies;
    variableSetNameFilter: string;
    filteredModel: ReadonlyArray<LibraryVariableSetWithVariables>;
    projectName: string;
}
interface LibraryVariableSetsProps {
    spaceId: string;
    projectSlug: string;
}
interface LibraryVariableSetsInternalProps extends LibraryVariableSetsProps {
    includeLibraryVariableDialogControls: DialogControls;
}
const sectionHeaderRowHeight = 48;
// Be careful when you change anything related to expanders here. They are implemented the way they are to make sure we
// can expand/collapse sections that user virtual scrolling.  A good test case is to have a variable set with
// enough variables so the take more than 1 screen.
class LibraryVariableSetsInternal extends FormBaseComponent<LibraryVariableSetsInternalProps & GroupedExpandableProps, LibraryVariableSetsState, ReadonlyArray<LibraryVariableSetWithVariables>> {
    constructor(props: LibraryVariableSetsInternalProps & GroupedExpandableProps) {
        super(props);
        this.state = {
            busies: {},
            variableSetNameFilter: "",
            filteredModel: [],
            projectName: "",
        };
    }
    async componentDidMount() {
        await this.doBusyTask(() => this.loadData(), { timeOperationOptions: timeOperationOptions.forInitialLoad() });
    }
    UNSAFE_componentWillReceiveProps(nextProps: GroupedExpandableProps) {
        if (nextProps === this.props) {
            return;
        }
        const changed = Object.keys(nextProps.expanders).filter((key) => nextProps.expanders[key] && !this.props.expanders[key]);
        if (changed.length === 0) {
            return;
        }
        //TODO: @Architecture - Re-write this in a way that we can wrap a DoBusyTask around the busies so the user gets feedback that it's being awaited.
        const promise = this.loadVariableSet(changed);
        const promises: Busies = changed.reduce((acc: Busies, key) => {
            acc[key] = promise;
            return acc;
        }, {});
        this.setState((state) => ({
            busies: {
                ...state!.busies,
                ...promises,
            },
        }));
    }
    render() {
        const additionalFilter: AdditionalFilter = {
            value: this.state.variableSetNameFilter,
            onValueChanged: this.handleFilterChanged,
            fieldName: "variable set name",
        };
        const includeLibraryVariableSetsPageAction: PrimaryPageAction | undefined = this.state.model
            ? {
                type: "button",
                label: "Include Variable Sets",
                hasPermissions: isAllowed({
                    permission: Permission.ProjectEdit,
                    wildcard: true,
                }),
                onClick: this.props.includeLibraryVariableDialogControls.openDialog,
            }
            : undefined;
        return (<PageContent busy={this.state.busy} errors={this.errors} header={{ title: "Variable Sets", primaryAction: includeLibraryVariableSetsPageAction }}>
                <Dialog open={this.props.includeLibraryVariableDialogControls.isOpen}>
                    <VariableSetSelector spaceId={this.props.spaceId} selectedVariableSetIds={this.state.model ? this.state.model.map((m) => m.libraryVariableSet.Id) : []} saveVariableSetsSelection={async (variableSetIds) => this.save(variableSetIds)}/>
                </Dialog>
                <ExpansionButtons expandAllOnMount={true} containerKey={libraryVariableSetsContainerKey}/>
                <FilterableVariableDisplayer availableScopes={this.getAvailableScopes()} variableSections={this.getVariables()} doBusyTask={this.doBusyTask} alwaysShowCheckboxFilters={true} shouldHideSectionContent={(sectionIndex) => {
                const variableSet = this.state.filteredModel[sectionIndex].libraryVariableSet;
                return !this.props.getExpanderValueForKey(variableSet.Id);
            }} additionalFilter={additionalFilter} sectionHeader={{
                sectionHeaderRowHeight,
                renderSectionHeader: (sectionIndex, cellAligner) => {
                    const variableSet = this.state.filteredModel[sectionIndex].libraryVariableSet;
                    return (<div className={styles.sectionHeader}>
                                    <VariableSetSectionHeading key={variableSet.Id} spaceId={variableSet.SpaceId} variableSetId={variableSet.Id} variableSetName={variableSet.Name} variableSetTab={"variables"} isExpanded={this.props.getExpanderValueForKey(variableSet.Id)!} busy={this.state.busies[variableSet.Id]} onExpandedChanged={(expanded) => this.props.onExpandedChanged(variableSet.Id, expanded)} onRemoveVariableSet={() => this.removeVariableSet(variableSet.Id)}/>
                                </div>);
                },
            }}/>
            </PageContent>);
    }
    private handleFilterChanged = (val: string) => {
        this.setState((state) => ({
            variableSetNameFilter: val,
            filteredModel: this.filterByName(state!.model!, val),
        }));
    };
    private async removeVariableSet(libraryVariableSetId: string) {
        const selectedVariableSetIds = this.state.model!.map((m) => m.libraryVariableSet.Id).filter((id) => id !== libraryVariableSetId);
        await this.save(selectedVariableSetIds);
        return Promise.resolve(true);
    }
    private async loadVariableSet(libraryVariableSetIds: string[]) {
        const toLoad = compact(libraryVariableSetIds.map((id) => {
            if (!this.state.model) {
                return null;
            }
            const current = this.state.model!.find((m) => m.libraryVariableSet.Id.toLowerCase() === id);
            if (!current) {
                return null;
            }
            return isLoaded(current!.variableAndScopes) ? null : current!.libraryVariableSet.VariableSetId;
        }));
        const variableSets = await repository.Variables.all({ ids: toLoad! });
        this.setState((prevState) => {
            const next = prevState!.model!.map((current) => {
                const variableSet = variableSets.find((vs: VariableSetResource) => vs.Id === current.libraryVariableSet.VariableSetId);
                if (variableSet) {
                    return {
                        libraryVariableSet: current.libraryVariableSet,
                        variableAndScopes: {
                            variables: convertVariableResourcesToVariablesWithSource(variableSet.Variables, {
                                spaceId: current.libraryVariableSet.SpaceId,
                                variableSetName: current.libraryVariableSet.Name,
                                variableSetId: current.libraryVariableSet.Id,
                            }),
                            scopeValues: variableSet.ScopeValues,
                        },
                    };
                }
                else {
                    return current;
                }
            });
            return {
                model: next,
                filteredModel: this.filterByName(next, this.state.variableSetNameFilter),
            };
        });
    }
    private filterByName(list: ReadonlyArray<LibraryVariableSetWithVariables>, filter: string): ReadonlyArray<LibraryVariableSetWithVariables> {
        return list.filter((set) => !filter || set.libraryVariableSet.Name.includes(filter));
    }
    private getAvailableScopes(): ScopeValues {
        const allScopeValues: ScopeValues[] = this.state.model ? this.state.model.filter((set) => isLoaded(set.variableAndScopes)).map((set) => (set.variableAndScopes as VariablesAndScopes).scopeValues) : [];
        return mergeScopeValues(allScopeValues);
    }
    private async loadData() {
        const project = await repository.Projects.get(this.props.projectSlug);
        const libraryVariableSets = await repository.LibraryVariableSets.all({ ids: project.IncludedLibraryVariableSetIds, contentType: VariableSetContentType.Variables });
        const next = libraryVariableSets.map((libraryVariableSet) => ({
            libraryVariableSet,
            variableAndScopes: "notloaded" as Loadable<VariablesAndScopes>,
        }));
        this.setState({
            model: next,
            filteredModel: this.filterByName(next, this.state.variableSetNameFilter),
            projectName: project.Name,
        }, () => this.props.registerAllExpanders(libraryVariableSets.map((v) => v.Id)));
    }
    private async save(selectedVariableSetIds: ReadonlyArray<string>) {
        // Should we be reloading the project here? or just using whatever was loaded previously
        const [project, allvariablesSet] = await Promise.all([repository.Projects.get(this.props.projectSlug), repository.LibraryVariableSets.all({ contentType: VariableSetContentType.Variables })]);
        const extraSets = difference(project.IncludedLibraryVariableSetIds, allvariablesSet.map((v) => v.Id));
        const updatedProject: ProjectResource = {
            ...project,
            IncludedLibraryVariableSetIds: [...selectedVariableSetIds, ...extraSets],
        };
        await repository.Projects.modify(updatedProject);
        await this.loadData();
    }
    private getVariables() {
        const variableSets = this.state.model ? this.state.filteredModel.map((set) => (isLoaded(set.variableAndScopes) ? [...set.variableAndScopes.variables] : [])) : [];
        return variableSets;
    }
    static displayName = "LibraryVariableSetsInternal";
}
const GroupedExpandableLibraryVariableSets = GroupedExpandable(libraryVariableSetsContainerKey, LibraryVariableSetsInternal);
function LibraryVariableSets({ spaceId, projectSlug }: LibraryVariableSetsProps) {
    const includeLibraryVariableDialogControls = useDialogTrigger();
    return <GroupedExpandableLibraryVariableSets spaceId={spaceId} projectSlug={projectSlug} includeLibraryVariableDialogControls={includeLibraryVariableDialogControls}/>;
}
export default LibraryVariableSets;
