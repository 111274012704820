import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import { QueryStateMode, optionalStringQueryParam, useQueryStringParam } from "@octopusdeploy/portal-routes";
import cn from "classnames";
import * as React from "react";
import type { AnalyticActionDispatcher } from "~/analytics/Analytics";
import { Action, useAnalyticActionDispatch } from "~/analytics/Analytics";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import PageDivider from "~/components/PageDivider";
import { Section } from "~/components/Section/Section";
import { useOctopusTheme } from "~/components/Theme";
import { orderBy } from "~/utils/orderBy";
import styles from "./EndpointSelector.module.less";
import type { CategorizedEndpointRegistration, CategorizedEndpointResult, CategoryDefinition, EndpointRegistration } from "./endpointRegistry";
import { EndpointSelectionScope } from "./endpointRegistry";
type EndpointSelectorProps = {
    spaceId: string;
    onSelect: (registration: EndpointRegistration, category: CategoryDefinition) => void;
    scope: EndpointSelectionScope;
    registrations: EndpointRegistration[];
    categories: CategoriesLookup;
    defaultCategory: string;
    heading: React.ReactNode;
};
export const EndpointSelector: React.FC<EndpointSelectorProps> = ({ spaceId, registrations, categories, defaultCategory, heading, scope, ...rest }) => {
    const [selectedCategory, setSelectedCategory] = useSelectedCategoryFromQueryString(categories, defaultCategory);
    const dispatchAction = useAnalyticActionDispatch();
    const categoryButtons: React.ReactNode[] = orderBy(Object.values(categories).map((x) => ({ label: x.category.category, displayOrder: x.category.displayOrder, action: () => setSelectedCategory(x.category) })), ["displayOrder", "label"]).map((x) => <ActiveItemButton label={x.label} onClick={x.action} key={x.label} active={selectedCategory && selectedCategory.category === x.label}/>);
    return (<React.Fragment>
            <Section>{heading}</Section>
            <CategoryButtons className={styles.paperActions}>{categoryButtons}</CategoryButtons>
            <Section bodyClassName={styles.container}>
                {selectedCategory && <EndpointCardGroupTitle className={styles.activeItemGroupHeading}>{selectedCategory.title}</EndpointCardGroupTitle>}
                {selectedCategory && !!selectedCategory.help && <EndpointCardGroupHelp>{selectedCategory.help}</EndpointCardGroupHelp>}
                {selectedCategory && (<EndpointCardGroup spaceId={spaceId} scope={scope} endpoints={categories[selectedCategory.category] && categories[selectedCategory.category].endpoints} onSelect={rest.onSelect} category={categories[selectedCategory.category] && categories[selectedCategory.category].category} dispatchAction={dispatchAction}/>)}
            </Section>
        </React.Fragment>);
};
EndpointSelector.displayName = "EndpointSelector"
interface EndpointCardGroupProps {
    spaceId: string;
    endpoints: CategorizedEndpointRegistration[];
    className?: string;
    category: CategoryDefinition;
    scope: EndpointSelectionScope;
    onSelect: (registration: CategorizedEndpointRegistration, category: CategoryDefinition) => void;
    dispatchAction: AnalyticActionDispatcher;
}
const EndpointCardGroup: React.FC<EndpointCardGroupProps> = ({ spaceId, endpoints = [], className, onSelect, category, scope, dispatchAction }) => {
    const orderedEndpoints = orderBy(endpoints, ["displayOrder", "name"]);
    return (<div className={cn(styles.cardGroup, className)}>
            {orderedEndpoints.map((endpoint) => (<EndpointCard key={endpoint.name} spaceId={spaceId} endpoint={endpoint} onSelect={onSelect} category={category} scope={scope} dispatchAction={dispatchAction}/>))}
        </div>);
};
EndpointCardGroup.displayName = "EndpointCardGroup"
export const EndpointCardGroupTitle: React.FC<{
    className?: string;
}> = ({ className, children }) => (<div className={cn(styles.groupHeading, className)}>
        <PageDivider>{children}</PageDivider>
    </div>);
EndpointCardGroupTitle.displayName = "EndpointCardGroupTitle"
export const EndpointCardGroupHelp: React.FC<{
    className?: string;
}> = ({ className, children }) => <div className={cn(styles.groupHelp, className)}>{children}</div>;
EndpointCardGroupHelp.displayName = "EndpointCardGroupHelp"
interface EndpointCardProps {
    spaceId: string;
    endpoint: CategorizedEndpointRegistration;
    className?: string;
    category: CategoryDefinition;
    scope: EndpointSelectionScope;
    onSelect: (registration: CategorizedEndpointRegistration, category: CategoryDefinition) => void;
    dispatchAction: AnalyticActionDispatcher;
}
const EndpointCard: React.FC<EndpointCardProps> = ({ spaceId, endpoint, onSelect, category, scope, dispatchAction }) => {
    const onClick = () => {
        if (scope === EndpointSelectionScope.DeploymentTarget) {
            dispatchAction("Add Deployment Target", {
                resource: "Deployment Target",
                action: Action.Add,
                data: {
                    target: endpoint.name,
                },
            });
        }
    };
    const card = endpoint.renderCard({
        spaceId,
        scope,
        registration: endpoint,
        category,
        onNavigate: () => onSelect(endpoint, category),
        onClick,
    });
    const clonedCard = React.cloneElement(card, { key: endpoint.name });
    if (endpoint.visibilityFeatureToggle === undefined) {
        return clonedCard;
    }
    else {
        return (<FeatureToggleVisibility key={endpoint.name} toggle={endpoint.visibilityFeatureToggle}>
                {clonedCard}
            </FeatureToggleVisibility>);
    }
};
EndpointCard.displayName = "EndpointCard"
type ActiveItemButtonProps = {
    active?: boolean;
    label: string;
    onClick?: () => void;
    icon?: {};
};
const ActiveItemButton: React.FC<ActiveItemButtonProps> = ({ onClick, label, active }) => {
    const theme = useOctopusTheme();
    const labelProps = active ? { color: theme.whiteConstant } : undefined;
    return <ActionButton type={ActionButtonType.Category} labelProps={labelProps} label={label} onClick={onClick} className={cn({ [styles.activeCategoryButton]: active })}/>;
};
ActiveItemButton.displayName = "ActiveItemButton"
const CategoryButtons: React.FC<{
    className?: string;
}> = ({ children, className }) => <div className={cn(styles.actions, className)}>{children}</div>;
CategoryButtons.displayName = "CategoryButtons"
export type CategoriesLookup = Record<string, CategorizedEndpointResult>;
const categoryParameter = optionalStringQueryParam("category");
function useSelectedCategoryFromQueryString(categories: CategoriesLookup, defaultCategory: string): [
    CategoryDefinition | undefined,
    (category: CategoryDefinition) => void
] {
    const defaultCategoryDefinition = categories[defaultCategory].category;
    const [value, setValue] = useQueryStringParam(categoryParameter, QueryStateMode.ReplaceHistory);
    const setCategoryDefinition = React.useCallback((category: CategoryDefinition) => setValue(category.category), [setValue]);
    const categoryFromUrl = categories[value ?? ""];
    return [categoryFromUrl?.category ?? defaultCategoryDefinition, setCategoryDefinition];
}
