import { css } from "@emotion/css";
import { borderRadius, fontWeight, lineHeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useInlineStatusQuery } from "@octopusdeploy/octopus-react-client";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import { TaskState } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { LiveStatusIcon } from "~/areas/projects/components/Observability/LiveStatusIcon";
import TaskStatusIcon from "~/areas/projects/components/TaskStatusIcon/index";
import InternalLink from "~/components/Navigation/InternalLink/index";
import DateFormatter from "~/utils/DateFormatter";
import { liveStatusSummaryText } from "./liveStatusCalculators";
interface LiveStatusSummaryBarProps {
    environmentId: string;
    project: ProjectResource;
    tenantId?: string;
}
export function LiveStatusSummaryBar(props: LiveStatusSummaryBarProps) {
    const liveStatus = useInlineStatusQuery(async (repo) => {
        return await repo.KubernetesLiveStatus.getRollupForApplicationInstance(props.project.Id, props.environmentId, props.tenantId);
    }, [props.project.Id, props.environmentId, props.tenantId], "Live Status Rollup", {
        refetchIntervalInMs: 1000,
    }).result;
    const latestDeployment = useInlineStatusQuery(async (repo) => {
        const deploymentsTimeline = await repo.Deployments.getDeploymentTimeline(props.project.Id, props.environmentId, props.tenantId);
        const latestForEnv = deploymentsTimeline.TimelineItems.Items.filter((d) => d.EnvironmentId === props.environmentId && d.TaskState !== TaskState.Queued);
        if (props.tenantId !== undefined) {
            const latestForTenant = latestForEnv.filter((d) => d.TenantId === props.tenantId);
            return latestForTenant[0];
        }
        else {
            const latestForTenant = latestForEnv.filter((d) => d.TenantId === null || d.TenantId === undefined);
            return latestForTenant[0];
        }
    }, [props.project, props.environmentId, props.tenantId], "Latest Deployment", {
        refetchIntervalInMs: 1000,
    }).result;
    function LiveStatusContent() {
        if (!liveStatus) {
            return <></>;
        }
        return (<div className={styles.iconStatusCard}>
                <div className={styles.iconStatusCardIcon}>
                    <LiveStatusIcon status={liveStatus.Status} size={48}/>
                </div>
                <div className={styles.iconStatusCardTitle}>{liveStatus.Status}</div>
                <div className={styles.iconStatusCardStatus}>Last updated {DateFormatter.momentAgo(liveStatus.LastUpdated.toString())}</div>
            </div>);
    }
    function LastDeploymentContent() {
        if (!latestDeployment) {
            return <></>;
        }
        const deploymentStatusText = !latestDeployment.Completed ? <span>Started {DateFormatter.momentAgo(latestDeployment.Created.toString())}</span> : <span>Completed {DateFormatter.momentAgo(latestDeployment.Completed?.toString() ?? "")}</span>;
        return (<InternalLink to={links.deploymentDetailsPage.generateUrl({ spaceId: props.project.SpaceId, deploymentId: latestDeployment.Id, projectSlug: props.project.Slug, releaseVersion: latestDeployment.Version })}>
                <div className={styles.iconStatusCard}>
                    <div className={styles.iconStatusCardIcon}>
                        <TaskStatusIcon iconOnly size={48} item={{ HasPendingInterruptions: latestDeployment.HasPendingInterruptions, HasWarningsOrErrors: latestDeployment.HasWarningsOrErrors, IsCompleted: latestDeployment.IsCompleted, State: latestDeployment.TaskState }}/>
                    </div>
                    <div className={styles.iconStatusCardTitle}>{latestDeployment.Version}</div>
                    <div className={styles.iconStatusCardStatus}>{deploymentStatusText}</div>
                </div>
            </InternalLink>);
    }
    function StatusSummaryContent() {
        if (!latestDeployment?.TaskState || !liveStatus?.Status) {
            return <></>;
        }
        return <span>{liveStatusSummaryText(latestDeployment.TaskState, liveStatus.Status)}</span>;
    }
    return (<div className={styles.summaryBar}>
            <LiveStatusCard title="Live Status">{LiveStatusContent()}</LiveStatusCard>
            <LiveStatusCard title="Last Deployment">{LastDeploymentContent()}</LiveStatusCard>
            <LiveStatusCard title="Status Summary">{StatusSummaryContent()}</LiveStatusCard>
        </div>);
}
interface LiveStatusCardProps {
    title: string;
    children: JSX.Element;
}
export function LiveStatusCard(props: LiveStatusCardProps) {
    return (<div className={styles.liveStatusCard}>
            <div className={styles.liveStatusCardTitle}>{props.title}</div>
            <div>{props.children}</div>
        </div>);
}
const styles = {
    summaryBar: css({
        background: themeTokens.color.background.secondary.default,
        display: "flex",
        flexDirection: "row",
    }),
    liveStatusCard: css({
        background: themeTokens.color.background.primary.default,
        flex: 1,
        height: "120px",
        borderRadius: borderRadius.large,
        margin: space[16],
        padding: space[16],
    }),
    liveStatusCardTitle: css({
        font: text.heading.xSmall,
        lineHeight: lineHeight.xSmall,
        color: themeTokens.color.text.secondary,
        fontWeight: fontWeight[700],
        textTransform: "uppercase",
        marginBottom: space[12],
    }),
    iconStatusCard: css({
        display: "grid",
        gridTemplateColumns: "60px auto",
        gridTemplateRows: "24px 24px",
    }),
    iconStatusCardIcon: css({
        gridRowStart: 1,
        gridRowEnd: 2,
    }),
    iconStatusCardTitle: css({
        gridRow: 1,
        gridColumn: 2,
        font: text.regular.bold.large,
        color: themeTokens.color.text.primary,
    }),
    iconStatusCardStatus: css({
        gridRow: 2,
        gridColumn: 2,
        font: text.regular.default.medium,
        color: themeTokens.color.text.secondary,
    }),
};
