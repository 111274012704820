import { RadioButton, RadioButtonGroup } from "@octopusdeploy/design-system-components";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import type { BusyState } from "~/components/BusyFromPromise/BusyFromPromise";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { SpaceMultiSelect } from "~/components/MultiSelect/SpaceMultiSelect";
interface ShareTemplateDialogProps {
    spaces: SpaceResource[];
    sharedSpaces: SharedSpaces;
    busy: BusyState;
    onSave: (sharedSpaces: SharedSpaces) => Promise<void>;
    errors: Error[];
}
export interface SharedSpaces {
    shareToAll: boolean;
    individualSpacesSharedTo: string[];
}
enum ShareTemplateSpacesType {
    ShareAll = "Share All",
    ShareIndividual = "Share Individual",
    NotShared = "Not Shared"
}
export function ShareTemplateDialog({ spaces, sharedSpaces, busy, onSave, errors }: ShareTemplateDialogProps) {
    const [selectedSpaces, setSelectedSpaces] = useState<string[]>(sharedSpaces.individualSpacesSharedTo);
    const [shareType, setShareType] = useState<ShareTemplateSpacesType>(getShareType(sharedSpaces));
    const [canSave, setCanSave] = useState<boolean>(false);
    function setCanSaveIfValid(type: ShareTemplateSpacesType, newSpaces: string[]) {
        if (type === ShareTemplateSpacesType.ShareIndividual) {
            setCanSave(sharedSpaces.individualSpacesSharedTo !== newSpaces);
        }
        else {
            setCanSave(true);
        }
    }
    return (<SaveDialogLayout errors={errors} title={"Sharing settings"} busy={busy} onSaveClick={async () => {
            const shareToAll = shareType === ShareTemplateSpacesType.ShareAll;
            const notShared = shareType === ShareTemplateSpacesType.NotShared;
            await onSave({ shareToAll, individualSpacesSharedTo: shareToAll || notShared ? [] : selectedSpaces });
            return true;
        }} saveButtonLabel={"Save"} saveButtonDisabled={shareType === ShareTemplateSpacesType.NotShared || !canSave}>
            <RadioButtonGroup accessibleName={"Space sharing type"} value={shareType} onChange={(type) => {
            setShareType(type);
            setCanSaveIfValid(type, selectedSpaces);
        }}>
                <RadioButton value={ShareTemplateSpacesType.ShareAll} label="Share to all current and future spaces"/>
                <RadioButton value={ShareTemplateSpacesType.ShareIndividual} label="Share to individual spaces"/>
            </RadioButtonGroup>
            {shareType === ShareTemplateSpacesType.ShareIndividual && (<>
                    <p>Select the spaces where this process template will be shared</p>
                    <SpaceMultiSelect items={spaces} onChange={(spaces) => {
                setSelectedSpaces(spaces);
                setCanSaveIfValid(shareType, spaces);
            }} value={selectedSpaces}/>
                </>)}
        </SaveDialogLayout>);
}
function getShareType(sharedSpaces: SharedSpaces): ShareTemplateSpacesType {
    if (sharedSpaces.shareToAll) {
        return ShareTemplateSpacesType.ShareAll;
    }
    if (sharedSpaces.individualSpacesSharedTo.length > 0) {
        return ShareTemplateSpacesType.ShareIndividual;
    }
    return ShareTemplateSpacesType.NotShared;
}
