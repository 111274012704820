import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import Card from "~/components/Card";
import type { DialogTriggerRenderProps } from "~/components/Dialog/DialogTrigger";
import { DialogTrigger } from "~/components/Dialog/DialogTrigger";
import OpenDialogButton from "~/components/Dialog/OpenDialogButton";
import styles from "./EndpointCard.module.less";
export interface EndpointCardDispatchProps {
    onNavigate?: () => void;
    onClick?: () => void;
}
export interface EndpointCardDetailProps {
    logo: React.ReactElement<{}>;
    registrationName: string;
    description: React.ReactNode;
    buttonLabel?: string;
}
export type EndpointCardProps = EndpointCardDispatchProps & EndpointCardDetailProps;
export const EndpointCard: React.SFC<EndpointCardProps> = (props) => (<Card logo={<div className={styles.logo}>{props.logo}</div>} className={styles.card} header={props.registrationName} content={<div className={styles.description}>{props.description}</div>} includeContentFade={false} /* Don't set true here, or you see the contentFade onHover */ onHoverView={<div className={styles.onHoverActions} onClick={props.onNavigate}>
                <div className={styles.onHoverActionsBackdrop}/>
                <ActionButton label={props.buttonLabel ? props.buttonLabel : "Add"} accessibleName={`Add ${props.registrationName}`} type={ActionButtonType.Primary} onClick={props.onNavigate}/>
            </div>} onClick={props.onClick}/>);
EndpointCard.displayName = "EndpointCard"
export interface EndpointCardDialogProps {
    open: boolean;
    closeDialog(): void;
    openDialog(): void;
}
interface EndpointDialogCardDetailProps {
    logo: React.ReactElement<{}>;
    registrationName: string;
    description: React.ReactNode;
    buttonLabel?: string;
    renderDialog: (props: EndpointCardDialogProps) => React.ReactNode;
    chip?: React.ReactNode;
    onClick?: () => void;
}
export const EndpointDialogCard = (props: EndpointDialogCardDetailProps) => {
    const isDialogOpenRef = React.useRef<boolean>(false);
    const conditionalOnClick = () => {
        if (isDialogOpenRef.current)
            return;
        if (props.onClick)
            props.onClick();
    };
    return (<Card logo={<div className={styles.logo}>{props.logo}</div>} className={styles.card} header={props.registrationName} content={<div>
                    <div className={styles.description}>{props.description}</div>
                    <div className={styles.chip}>{props.chip}</div>
                </div>} contentClassName={props.chip ? styles.variableHeightContent : undefined} includeContentFade={false} /* Don't set true here, or you see the contentFade onHover */ onHoverView={<div className={styles.onHoverActions}>
                    <div className={styles.onHoverActionsBackdrop}/>
                    <OpenDialogButton label={props.buttonLabel ? props.buttonLabel : "Add"} accessibleName={`Add ${props.registrationName}`} type={ActionButtonType.Primary} disabled={false} renderDialog={(renderProps) => props.renderDialog(renderProps)} onRender={(renderProps: DialogTriggerRenderProps) => {
                isDialogOpenRef.current = renderProps.open;
            }} onClick={conditionalOnClick}/>
                </div>}/>);
};
interface InformationalEndpointCardRenderProps {
    open: boolean;
    closeDialog(): void;
    openDialog(): void;
}
export type InformationalEndpointCardProps = EndpointCardDetailProps & {
    renderDialog: (props: InformationalEndpointCardRenderProps) => React.ReactElement<{}>;
    onClick?: () => void;
};
export const InformationalEndpointCard: React.SFC<InformationalEndpointCardProps> = ({ buttonLabel = "View Instructions", renderDialog, onClick, ...rest }) => (<DialogTrigger render={(renderProps) => {
        const conditionalOnClick = () => {
            if (renderProps.open)
                return;
            if (onClick)
                onClick();
        };
        return (<React.Fragment>
                    <EndpointCard {...rest} onNavigate={renderProps.openDialog} buttonLabel={buttonLabel} onClick={conditionalOnClick}/>
                    {renderDialog(renderProps)}
                </React.Fragment>);
    }}/>);
InformationalEndpointCard.displayName = "InformationalEndpointCard"
export type SimpleInformationalEndpointCardProps = EndpointCardDetailProps & {
    renderView: () => React.ReactElement<{}>;
};
export default EndpointCard;
