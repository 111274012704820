import { ActionButton } from "@octopusdeploy/design-system-components";
import type { ActionButtonType } from "@octopusdeploy/design-system-components";
import * as React from "react";
import Dialog from "~/components/Dialog/Dialog";
import type { PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import { isAllowed } from "../PermissionCheck/PermissionCheck";
import type { DialogTriggerRenderProps } from "./DialogTrigger";
import { DialogTrigger } from "./DialogTrigger";
import styles from "./style.module.less";
interface RenderDialogProps {
    open: boolean;
    closeDialog(): void;
    openDialog(): void;
}
interface OpenDialogButtonProps {
    label: string;
    accessibleName?: string;
    title?: string;
    disabled?: boolean;
    permission?: PermissionCheckProps;
    icon?: JSX.Element;
    iconPosition?: "left" | "right";
    type?: ActionButtonType;
    dialogWidth?: "600px" | "800px" | "1000px";
    renderDialog?: (props: RenderDialogProps) => React.ReactNode;
    onClick?: () => void; // Track analytics
    onRender?: (renderProps: DialogTriggerRenderProps) => void;
    children?: React.ReactNode;
}
const OpenDialogButton = (props: OpenDialogButtonProps) => {
    const getPermissionLabel = () => {
        if (props.permission === undefined) {
            return "No";
        }
        if (Array.isArray(props.permission.permission)) {
            return props.permission.permission.join(", ");
        }
        return props.permission.permission;
    };
    const disabledDueToPermission = !!props.permission ? !isAllowed(props.permission) : false;
    const permissionLabel = getPermissionLabel();
    const label = disabledDueToPermission ? `${permissionLabel} permission required` : props.label;
    return (<DialogTrigger render={({ open, openDialog, closeDialog }) => {
            if (props.onRender)
                props.onRender({ open, closeDialog, openDialog });
            const wrappedOpenDialog = () => {
                if (props.onClick)
                    props.onClick();
                openDialog();
            };
            return (<div className={styles.buttonDialogContainer}>
                        <ActionButton accessibleName={props.accessibleName} type={props.type} icon={props.icon} iconPosition={props.iconPosition} label={label} disabled={disabledDueToPermission || props.disabled} onClick={wrappedOpenDialog} title={props.title || props.label}/>
                        {props.renderDialog ? (props.renderDialog({ open, openDialog: wrappedOpenDialog, closeDialog })) : (<Dialog open={open} dialogWidth={props.dialogWidth}>
                                {props.children}
                            </Dialog>)}
                    </div>);
        }}/>);
};
export default OpenDialogButton;
