import { LegacySvgIcon } from "@octopusdeploy/design-system-components";
import cn from "classnames";
import * as React from "react";
import styles from "./styles.module.less";
export interface SvgImageProps {
    title: string;
    viewBox?: string;
    width?: string;
    height?: string;
    margin?: string;
    className?: string;
}
class SvgImage extends React.PureComponent<SvgImageProps> {
    render() {
        return (<div className={cn(styles.imageContainer, this.props.className)} style={{ width: this.props.width, height: this.props.height, margin: this.props.margin }}>
                <LegacySvgIcon className={styles.svgIcon} viewBox={this.props.viewBox} role="img" aria-labelledby="title">
                    <title id="title">{this.props.title}</title>
                    {this.props.children}
                </LegacySvgIcon>
            </div>);
    }
    static displayName = "SvgImage";
}
export default SvgImage;
