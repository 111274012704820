import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import dialogIdGenerator from "~/components/Dialog/dialogIdGenerator";
import { actions as dialogActions, selectors as dialogSelectors } from "./store";
export interface DialogTriggerRenderProps {
    open: boolean;
    closeDialog(): void;
    openDialog(): void;
}
export type DialogControls = {
    isOpen: boolean;
    closeDialog: () => void;
    openDialog: () => void;
};
export interface DialogTriggerProps {
    render: (props: DialogTriggerRenderProps) => React.ReactNode;
}
export function DialogTrigger(props: DialogTriggerProps) {
    const { closeDialog, openDialog, isOpen } = useDialogTrigger();
    return (<React.Fragment>
            {props.render({
            openDialog,
            closeDialog,
            open: isOpen,
        })}
        </React.Fragment>);
}
function useDialogId() {
    return React.useMemo(() => dialogIdGenerator.next(), []);
}
export function useDialogTrigger(): DialogControls {
    const dialogId = useDialogId();
    const isOpen = useSelector((state: GlobalState) => dialogSelectors.createDialogOpenSelector(dialogId)(state));
    const dispatch = useDispatch();
    const openDialog = React.useCallback(() => dispatch(dialogActions.open(dialogId)), [dispatch, dialogId]);
    const closeDialog = React.useCallback(() => dispatch(dialogActions.closeAll()), [dispatch]);
    return { isOpen, openDialog, closeDialog };
}
