/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import Modal from "@material-ui/core/Modal";
import * as React from "react";
import { useCallback } from "react";
import type { DialogLayoutDispatchProps } from "../DialogLayout/DialogLayout";
import ErrorContextProvider from "../ErrorContext/ErrorContext";
interface CustomDialogStateProps {
    open: boolean;
}
export interface RenderProps extends DialogLayoutDispatchProps, CustomDialogStateProps {
}
interface CustomDialogRenderProps extends RenderProps {
    render: (props: RenderProps) => React.ReactNode;
}
export interface CustomDialogProps extends CustomDialogRenderProps, DialogLayoutDispatchProps, CustomDialogStateProps {
    className?: string;
}
export const CustomDialog: React.FC<CustomDialogProps> = ({ open, render, close, ...rest }) => {
    const handleClose = useCallback(() => {
        if (close) {
            close();
        }
    }, [close]);
    const renderProps = { open, close: handleClose };
    return (<ErrorContextProvider>
            <Modal open={open} className={rest.className}>
                <div>{open && render(renderProps)}</div>
            </Modal>
        </ErrorContextProvider>);
};
CustomDialog.displayName = "CustomDialog"
export default React.memo(CustomDialog);
