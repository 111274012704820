import { css } from "@emotion/css";
import { space } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import React from "react";
import InternalLink from "~/components/Navigation/InternalLink";
export interface ResultsLinkProps {
    totalCount: number;
    renderedCount: number;
    link: LinkHref;
}
export const ResultsLink = ({ totalCount, renderedCount, link }: ResultsLinkProps) => {
    return renderedCount <= totalCount ? <InternalLink className={cardContentContainerResults} to={link} size={0.8125} weight={500}>{`${totalCount.toString()} Result${totalCount <= 1 ? "" : "s"}`}</InternalLink> : null;
};
const cardContentContainerResults = css({
    padding: `${space[8]} 0 ${space[16]} 0`,
    textAlign: "center",
    display: "block",
});
