import { Callout } from "@octopusdeploy/design-system-components";
import type { ValidateGitRefV2Response } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { VcsErrorPanel } from "~/areas/projects/components/VersionControl/VcsErrorPanel";
import { useProjectContext } from "~/areas/projects/context/index";
import ExternalLink from "~/components/Navigation/ExternalLink";
import SectionNote from "~/components/SectionNote/SectionNote";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
interface SettingsNoteSectionProps {
    isVersionControlled: boolean;
}
function SettingsNoteSectionInternal(props: SettingsNoteSectionProps & {
    gitError: ValidateGitRefV2Response | undefined;
}) {
    const gitRunbooksMigrationEnabled = useOctopusFeatureToggle("git-runbooks-migration", false);
    const gitRunbooksConversionMigrationEnabled = useOctopusFeatureToggle("git-runbooks-migrate-in-project-migration", true);
    // Hello future developer! If you're in here removing this new feature callout for Runbooks, be sure to update the "deployment process and variables" in the nonVcsSection below
    // to "deployment process, variables, and Runbooks". If the migration feature toggle is still around at that time, you'll need to make sure you switch between the two toggles
    // based on the toggle state.
    //
    // Chances are if we're removing the callout then we've already removed the feature toggle so that shouldn't be a problem, but you never know what the future will
    // hold!
    const runbooksCalloutNonVcsSection = (<Callout type="new-feature" title="Runbooks are now supported in version control">
            Converting this project to version control will now migrate your Runbooks to your Git repository alongside the deployment process and variables. Read the{" "}
            <ExternalLink href="ConfigAsCodeRunbooks">Config as Code Runbooks documentation</ExternalLink> for more details.
        </Callout>);
    const nonVcsSection = (<SectionNote>
            <div>
                Converting a project to version control allows you to store your deployment process and variables in a Git repository. Learn more about <ExternalLink href={"ConfigAsCodeEap"}>Config as Code.</ExternalLink>
            </div>
        </SectionNote>);
    const vcsErrorSection = <VcsErrorPanel content={<>{props.gitError?.Message}</>}/>;
    return (<>
            {props.isVersionControlled && props.gitError && vcsErrorSection}
            {!props.isVersionControlled && (gitRunbooksMigrationEnabled && gitRunbooksConversionMigrationEnabled ? runbooksCalloutNonVcsSection : nonVcsSection)}
        </>);
}
export function SettingsNoteSection(props: SettingsNoteSectionProps) {
    const projectContext = useProjectContext();
    return <SettingsNoteSectionInternal {...props} gitError={projectContext.state.gitRefValidationError}/>;
}
