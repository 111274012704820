/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Collapse } from "@material-ui/core";
import type { TransitionProps } from "@material-ui/core/transitions/transition";
import cn from "classnames";
import * as React from "react";
import type { ExpandableProps, ExpandableContainerProps } from "~/components/Expandable/Expandable";
import Expandable from "~/components/Expandable/Expandable";
import { OverflowMenu } from "~/components/OverflowMenu/OverflowMenu";
import type { MenuItem } from "~/components/OverflowMenu/OverflowMenu";
import type { FormSectionBaseProps } from "~/components/form/Sections/FormSectionBase";
import { FormSectionBase } from "~/components/form/Sections/FormSectionBase";
import { useTransitionComponentOverride } from "~/primitiveComponents/transitions/overrideTransitions";
import CardTitle from "./CardTitle";
import type { SummaryNode } from "./Summary";
import { getSummary } from "./Summary";
import styles from "./style.module.less";
export type { SummaryNode };
export enum CardFill {
    None,
    FillRight,
    FillAll
}
//todo: mattr: change all usages of this to use a new FormSection component that switches between this and NonExpandableFormSection
interface ExpandableFormSectionProps extends FormSectionBaseProps {
    summary: SummaryNode | React.ReactNode;
    title: string;
    chip?: React.ReactElement;
    help?: React.ReactNode; //todo: mattr: make mandatory
    expandable?: boolean; //todo: this prop will move to the new FormSection component
    focusOnExpandAll?: boolean;
    fillCardWidth?: CardFill;
    overflowMenuItems?: Array<MenuItem | MenuItem[]>;
    useCardTitleContainerStyleForSummary?: boolean;
    hasInitialFocus?: boolean;
    TransitionComponent: React.ComponentType<TransitionProps>;
    contextualHelp?: React.ReactNode;
}
type Props = ExpandableFormSectionProps & ExpandableProps & ExpandableContainerProps;
interface ExpandableFormSectionState {
    focus: boolean;
}
export class ExpandableFormSectionInternal extends FormSectionBase<Props, ExpandableFormSectionState> {
    constructor(props: Props) {
        super(props);
        this.state = { focus: this.props.hasInitialFocus || this.props.focusOnExpandAll || false };
    }
    // we can't just use actAsExpander on card Title because it eats the click
    // and we can't add links to the title in help text or our show more info link
    handleExpand = (e: any) => {
        if (e.target.tagName.toLowerCase() !== "a") {
            e.preventDefault();
            const selection = window.getSelection();
            const isSelectingText = selection ? selection.toString().length > 0 : false;
            if (isSelectingText) {
                // usability: Don't expand/collapse in cases where the user is just trying to select/copy text
                // from our expander's title, summary or help text.
                return;
            }
            this.props.onExpandedChanged(!this.props.isExpanded);
        }
    };
    componentDidUpdate(prevProps: Props) {
        const isExpanding = !prevProps.isExpanded && this.props.isExpanded;
        const expansionChanged = this.props.isExpanded !== prevProps.isExpanded;
        if (expansionChanged) {
            let nextState: ExpandableFormSectionState = this.state;
            //Expand All
            if (isExpanding && this.props.expandingAll && this.props.focusOnExpandAll) {
                nextState = { ...nextState, focus: true };
                //General expasion, manually triggered
            }
            else if (isExpanding && !this.props.expandingAll) {
                nextState = { ...nextState, focus: true };
            }
            this.setState(nextState);
        }
    }
    componentDidMount() {
        //Fallback if not used via expandable and isExpanded etc. isn't in the correct state
        if (!this.props.isExpanded && this.props.isExpandedByDefault) {
            this.props.onExpandedChanged(true);
        }
    }
    onBlur = () => {
        this.setState({ focus: false });
    };
    render() {
        const TransitionComponent = this.props.TransitionComponent;
        const { expandable = !this.props.children ? false : true } = this.props;
        const { forceMobileBehaviour } = this.props;
        const summary = getSummary(this.props.summary, { forceMobileBehaviour: Boolean(forceMobileBehaviour) });
        const subtitle = this.props.isExpanded ? this.help(this.props.help) : summary;
        const title = this.title(this.props.title, subtitle, this.props.chip, this.props.isExpanded ? this.props.contextualHelp : null);
        const bodyclasses = cn({
            [styles.cardMedia]: true,
            [styles.cardMediaForceMobile]: this.props.forceMobileBehaviour,
            [styles.cardMediaFillRight]: this.props.fillCardWidth === CardFill.FillRight || this.props.fillCardWidth === CardFill.FillAll,
            [styles.cardMediaFillAll]: this.props.fillCardWidth === CardFill.FillAll,
        });
        return (<div className={styles.formExpander} onBlur={this.onBlur}>
                {expandable ? (<>
                        <CardTitle title={title} onToggleExpand={this.handleExpand} accessibleName={this.props.title} isExpanded={this.props.isExpanded} forceMobileBehaviour={this.props.forceMobileBehaviour} useCardTitleContainerStyle={this.props.useCardTitleContainerStyleForSummary || !expandable}/>
                        <TransitionComponent in={this.props.isExpanded} unmountOnExit={true}>
                            {this.body(bodyclasses, true)}
                        </TransitionComponent>
                    </>) : (<>
                        <CardTitle title={title} forceMobileBehaviour={this.props.forceMobileBehaviour} useCardTitleContainerStyle={true}/>
                        {this.body(bodyclasses, true)}
                    </>)}
            </div>);
    }
    protected title(title: React.ReactNode, subtitle: React.ReactNode, chip?: React.ReactElement, contextualHelp?: React.ReactNode) {
        const sectionTitle = chip ? (<span className={styles.expandableFormSectionTitleWithChip}>
                {title} {chip}
            </span>) : (title);
        return (<>
                <span className={styles.cardTitle}>
                    {sectionTitle}
                    {contextualHelp}
                </span>
                {subtitle}
                {this.props.overflowMenuItems && (<div className={styles.overflowMenuActions}>
                        <OverflowMenu menuItems={this.props.overflowMenuItems}/>
                    </div>)}
            </>);
    }
    protected alterChild = (child: any): any => {
        //const focusOnExpand = this.props.expandingAll === false || this.props.focusOnExpandAll;
        if (this.state.focus && this.isFocusableControl(child)) {
            return React.cloneElement(child, {
                ...child.props,
                autoFocus: true,
            });
        }
        return child;
    };
    private isFocusableControl = (child: any) => {
        //TODO: We should check if we can find an alternate way to determine this, possibly by making this controlled via the consumer.
        return child.props && (child.props.onChange || child.props.setProperties) && !child.props.doNotAutoFocus;
    };
    static displayName = "ExpandableFormSectionInternal";
}
function CollapseWithAutoTimeout({ children, ...rest }: React.PropsWithChildren<TransitionProps>) {
    return (<Collapse {...rest} timeout={"auto"}>
            {children}
        </Collapse>);
}
function ExpandableFormSectionWithInjectedContext(props: Omit<Props, "TransitionComponent">) {
    const TransitionComponent = useTransitionComponentOverride() ?? CollapseWithAutoTimeout;
    return <ExpandableFormSectionInternal {...props} TransitionComponent={TransitionComponent}/>;
}
const ExpandableFormSection = Expandable(ExpandableFormSectionWithInjectedContext);
export { ExpandableFormSectionProps };
export default ExpandableFormSection;
