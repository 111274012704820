import type { EnvironmentResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { EnvironmentChip, MissingChip } from "~/components/Chips/index";
interface EnvironmentOrMissingChipProps {
    environmentId: string;
    environments: EnvironmentResource[];
}
export default ({ environmentId, environments }: EnvironmentOrMissingChipProps) => {
    const environment = environments.find((e) => e.Id === environmentId);
    if (!environment) {
        return <MissingChip lookupId={environmentId}/>;
    }
    return <EnvironmentChip environmentName={environment.Name}/>;
};
