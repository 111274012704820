import { css } from "@emotion/css";
import { ChevronDownIcon, ChevronRightIcon } from "@octopusdeploy/design-system-icons";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import cn from "classnames";
import type { PropsWithChildren, ReactNode, SyntheticEvent } from "react";
import React, { useState } from "react";
type ManifestNavigationDetailsProps = PropsWithChildren<{
    summary: ReactNode;
    summaryClassName?: string;
}>;
const summaryStyles = css({
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    gap: space[4],
    color: themeTokens.color.text.primary,
    font: text.regular.bold.medium,
    padding: space[8],
});
export const ManifestNavigationDetails = (props: ManifestNavigationDetailsProps) => {
    const [open, setOpen] = useState(true);
    const handleToggle = (event: SyntheticEvent<HTMLElement, Event>) => {
        if (event.currentTarget instanceof HTMLDetailsElement) {
            setOpen(event.currentTarget.open);
        }
    };
    return (<details open={open} onToggle={(e) => handleToggle(e)}>
            <summary className={cn(summaryStyles, props.summaryClassName)}>
                {open ? <ChevronDownIcon size={16}/> : <ChevronRightIcon size={16}/>}
                {props.summary}
            </summary>
            {props.children}
        </details>);
};
