import type { ChannelProgressionResource, OverviewReleaseBff, ProjectResource, ReleaseResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import AutoComplete from "~/components/AutoComplete/AutoComplete";
import { ChannelChip } from "../../../../components/Chips/index";
import styles from "./releaseFilter.module.less";
interface ReleaseFilterProps {
    releases: ReleaseResource[] | OverviewReleaseBff[];
    channelIndex: {
        [channelId: string]: ChannelProgressionResource;
    };
    project: ProjectResource;
    value: string;
    filterUpdated: (value: string) => void;
}
interface ReleaseFilterState {
    value: string;
}
export default class ReleaseFilter extends React.Component<ReleaseFilterProps, ReleaseFilterState> {
    private releases: ReleaseResource[] | OverviewReleaseBff[] = [];
    constructor(props: ReleaseFilterProps) {
        super(props);
        this.state = {
            value: "",
        };
    }
    async componentDidMount() {
        this.setState({
            value: await this.getValue(this.props.value),
        });
    }
    async componentDidUpdate(prevProps: ReleaseFilterProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: await this.getValue(this.props.value),
            });
        }
    }
    render() {
        return (<div className={styles.container}>
                <AutoComplete name="ReleaseFilter" value={this.state.value} onChange={this.props.filterUpdated} getOptions={this.getOptions} label="Filter by release" fieldName={"release"} placeholder="Enter release number"/>
            </div>);
    }
    private getOptions = async (searchText: string) => {
        const hasMultipleChannels = Object.keys(this.props.channelIndex).length > 1;
        if (!searchText) {
            this.releases = this.props.releases;
            return {
                items: this.props.releases.map((release) => this.getItem(release, hasMultipleChannels)),
                containsAllResults: true,
            };
        }
        const releases = await repository.Projects.getReleases(this.props.project, { searchByVersion: searchText, take: 5 });
        this.releases = releases.Items;
        return {
            items: releases.Items.map((release) => this.getItem(release, hasMultipleChannels)),
            containsAllResults: releases.TotalResults === releases.Items.length,
        };
    };
    private getItem(release: ReleaseResource | OverviewReleaseBff, hasMultipleChannels: boolean) {
        const channel = this.props.channelIndex[release.ChannelId];
        const display = (<span>
                {release.Version}
                {channel && hasMultipleChannels && <ChannelChip channelName={channel.Name}/>}
            </span>);
        return {
            Name: release.Version,
            Id: release.Id,
            display,
        };
    }
    private getValue = async (value: string) => {
        if (!value) {
            return Promise.resolve("");
        }
        let result = this.releases.find((release) => release.Id === value);
        if (!result) {
            result = await repository.Releases.get(value);
        }
        return Promise.resolve(result.Version);
    };
    static displayName = "ReleaseFilter";
}
