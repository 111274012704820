import type { AreaNavPanelExpandState, BottomSideNavBarItem, DesignSystemLinkHref, ShowLinkAsActive, TopSideNavBarItem } from "@octopusdeploy/design-system-components";
import { CogIcon, DeployIcon, InsightsIcon, PlatformHubIcon, TasksIcon } from "@octopusdeploy/design-system-icons";
import type { UserResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { LinkEvent, VerticalNavigationPageArea } from "@octopusdeploy/portal-routes";
import { nameForLink, links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import type { MouseEventHandler, ReactNode } from "react";
import React from "react";
import { repository, session } from "~/clientInstance";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { SpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { isSpaceNotFound, isSpecificSpaceContext } from "~/components/SpaceLoader/SpaceLoader";
import { UserAccountMenu } from "../Navbar/UserAccountMenu";
import { hasPermissionsInAnyScope } from "./hasPermissionInAnyScope";
export function getNavigationSideBarBottomItems(currentUser: UserResource, spaceContext: SpaceContext, showBlueprints: boolean, dispatchLinkClicked: (name: string, event: LinkEvent) => void): BottomSideNavBarItem[] {
    const spaceId = isSpecificSpaceContext(spaceContext) ? spaceContext.Id : null;
    // Temporary workaround
    const blueprints: BottomSideNavBarItem | null = showBlueprints
        ? createBottomSideBarLink(dispatchLinkClicked, spaceId, { accessibleName: "Platform Hub", icon: <PlatformHubIcon size={24}/>, href: links.processTemplatesPage.generateUrl(), showLinkAsActive: "if path partially matches" })
        : null;
    return compact([
        blueprints,
        hasConfigurationPermissions(spaceContext)
            ? createBottomSideBarLink(dispatchLinkClicked, spaceId, {
                icon: <CogIcon size={24}/>,
                href: links.configurationRootRedirect.generateUrl(),
                accessibleName: "Configuration",
                showLinkAsActive: "if path partially matches",
            })
            : null,
        { type: "custom", key: "user-menu", content: <UserAccountMenu currentUser={currentUser}/> },
    ]);
}
function hasTaskViewPermission(spaceContext: SpaceContext): boolean {
    if (!isSpaceNotFound(spaceContext) || spaceContext.isAlsoInSystemContext) {
        return session.currentPermissions?.hasPermissionInAnyScope(Permission.TaskView) ?? false;
    }
    return false;
}
export function getSideNavigationAreas(spaceContext: SpaceContext, verticalNavigationPageArea: VerticalNavigationPageArea, hasNavItems: boolean, areaNavPanelExpandState: AreaNavPanelExpandState, setAreaNavPanelExpandState: (expandState: AreaNavPanelExpandState) => void, areaNavPanelDisplayMode: "overlay" | "inline", dispatchLinkClicked: (name: string, event: LinkEvent) => void): TopSideNavBarItem[] {
    return compact([
        ...(isSpecificSpaceContext(spaceContext) ? getSpaceSpecificNavigationAreas(spaceContext.Id, verticalNavigationPageArea, hasNavItems, areaNavPanelExpandState, setAreaNavPanelExpandState, areaNavPanelDisplayMode, dispatchLinkClicked) : []),
        hasTaskViewPermission(spaceContext) ? createTopSideBarLink(dispatchLinkClicked, null, { label: "Tasks", icon: <TasksIcon size={24}/>, href: links.tasksPage.generateUrl(), showLinkAsActive: "if path partially matches" }) : null,
    ]);
}
function getSpaceSpecificNavigationAreas(spaceId: string, verticalNavigationPageArea: VerticalNavigationPageArea, hasNavItems: boolean, areaNavPanelExpandState: AreaNavPanelExpandState, setAreaNavPanelExpandState: (expandState: AreaNavPanelExpandState) => void, areaNavPanelDisplayMode: "overlay" | "inline", dispatchLinkClicked: (name: string, event: LinkEvent) => void): TopSideNavBarItem[] {
    return compact([
        createTopSideBarLink(dispatchLinkClicked, spaceId, {
            label: "Deploy",
            icon: <DeployIcon size={24}/>,
            href: links.projectsPage.generateUrl({ spaceId }),
            showLinkAsActive: verticalNavigationPageArea === "Projects" ? "always" : "never",
            onClick: (e: React.MouseEvent | undefined) => {
                // Q. How do we know when they're routing to a different top-level area?
                // If they're coming from Tasks or Configuration, and trying to open Projects,
                // we want to redirect them to the Dashboard.
                // We really only want to toggle the AreaNavPanel if they're navigating within
                // the same area.
                // HACK - We can hack this for now by asking if hasNavItems, because currently only
                // Projects has NavItems. This will break if/when we have another top-level area
                // menu with NavItems :(
                if (areaNavPanelDisplayMode === "inline" && hasNavItems) {
                    // If the user is clicking "Projects" because they can't see the AreaNavPanel, toggle
                    // the AreaNavPanel to let them peek the menu contents.
                    setAreaNavPanelExpandState(areaNavPanelExpandState.isExpanded ? { isExpanded: false } : { isExpanded: true });
                    e?.preventDefault();
                }
                // Else let this click bubble through to the regular route/link onClick.
            },
            onIsHoveredChanged: (isHovered: boolean, hoveredElement: HTMLElement) => {
                if (areaNavPanelDisplayMode === "overlay" && hasNavItems && isHovered) {
                    setAreaNavPanelExpandState({ isExpanded: true, reason: "hover", hoveredElement });
                }
            },
        }),
        hasPermissionsInAnyScope(spaceId, Permission.InsightsReportView)
            ? createTopSideBarLink(dispatchLinkClicked, spaceId, { label: "Insights", icon: <InsightsIcon size={24}/>, href: links.insightsRootRedirect.generateUrl({ spaceId }), showLinkAsActive: "if path partially matches" })
            : null,
    ]);
}
function hasConfigurationPermissions(spaceContext: SpaceContext) {
    if (!isSpaceNotFound(spaceContext) || spaceContext.isAlsoInSystemContext) {
        return (session.currentPermissions?.scopeToSpace(repository.spaceId).hasAnyPermissions() ||
            isAllowed({
                permission: [Permission.AdministerSystem, Permission.EventView, Permission.TeamView, Permission.UserView, Permission.UserRoleView, Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete],
                wildcard: true,
            }));
    }
    return false;
}
function createTopSideBarLink(dispatchLinkClicked: (name: string, event: LinkEvent) => void, spaceId: string | null, linkParams: {
    label: string;
    icon: ReactNode;
    href: DesignSystemLinkHref;
    showLinkAsActive: ShowLinkAsActive;
    accessibleName?: string;
    onClick?: MouseEventHandler<Element>;
    onIsHoveredChanged?: (isHovered: boolean, hoveredElement: HTMLElement) => void;
}): TopSideNavBarItem {
    const { onClick, ...otherLinkParams } = linkParams;
    const instrumentedOnClick = (ev: React.MouseEvent<Element, MouseEvent>) => {
        dispatchLinkClicked(nameForLink(), {
            linkLabel: linkParams.label,
            documentUrl: typeof linkParams.href === "string" ? linkParams.href : linkParams.href.resolveWithSpaceId(spaceId),
            linkLocation: "Main Navigation",
        });
        onClick?.(ev);
    };
    return {
        type: "link",
        ...otherLinkParams,
        onClick: instrumentedOnClick,
    };
}
function createBottomSideBarLink(dispatchLinkClicked: (name: string, event: LinkEvent) => void, spaceId: string | null, linkParams: {
    icon: ReactNode;
    href: DesignSystemLinkHref;
    showLinkAsActive: ShowLinkAsActive;
    accessibleName: string;
}): BottomSideNavBarItem {
    const onClick = (ev: React.MouseEvent<Element, MouseEvent>) => {
        dispatchLinkClicked(nameForLink(), {
            linkLabel: linkParams.accessibleName,
            documentUrl: typeof linkParams.href === "string" ? linkParams.href : linkParams.href.resolveWithSpaceId(spaceId),
            linkLocation: "Main Navigation",
        });
    };
    return {
        type: "link",
        ...linkParams,
        onClick,
    };
}
