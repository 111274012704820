import { css } from "@emotion/css";
import { Checkbox } from "@octopusdeploy/design-system-components";
// eslint-disable-next-line @octopusdeploy/custom-portal-rules/no-restricted-imports
import type { TagResource } from "@octopusdeploy/octopus-server-client";
import { noOp } from "@octopusdeploy/utilities";
import React, { useState } from "react";
import type { Filter, FilterResource, FilterValue, FilterRow, MultiSelectValue, SingleSelectValue } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { FilterMultiplicity, FilterOption, FilterType, getAvailableNames, getAvailableOptions, getPlaceholder, initializeFilterRows, isMultiSelectValue, isSingleSelectValue, isTagResourceArray, } from "~/areas/tenants/components/Filtering/FilterBuilder/filterBuilderTypes";
import { MissingChip } from "~/components/Chips/index";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip/index";
import { MultiSelect } from "~/components/MultiSelect/MultiSelect";
import Tag from "~/components/Tag/index";
import type { SelectItem } from "~/components/VirtualListWithKeyboard/SelectItem";
import { Select } from "~/components/form/index";
import type { DropdownMenuOption } from "~/primitiveComponents/form/Select/DropDownMenu";
type FilterDisplay = "compact" | "full";
interface FilterBuilderProps {
    filters: Filter[];
    onChange: (filterValues: FilterValue[]) => void;
    display?: FilterDisplay;
}
export function FilterBuilder({ filters, onChange, display = "full" }: FilterBuilderProps) {
    const [allFilterRows, setAllFilterRows] = useState<FilterRow[]>(initializeFilterRows(filters));
    const selectedFilterRows = allFilterRows.filter((row) => row.selected).sort((a, b) => a.order - b.order);
    const unselectedFilterRows = allFilterRows.filter((row) => !row.selected);
    const [order, setOrder] = useState(allFilterRows.length);
    const addFilterRow = (newFilter: FilterRow) => {
        updateFilterRows({ ...newFilter, selected: true, order });
        setOrder(order + 1);
    };
    const replaceFilterRow = (previousFilter: FilterRow, newFilter: FilterRow) => {
        onValueChange({ ...previousFilter, value: { ...previousFilter.value, value: undefined }, selected: false }, { ...newFilter, selected: true, order: previousFilter.order });
    };
    const removeFilterRow = (previousFilter: FilterRow) => {
        onValueChange({ ...previousFilter, value: { ...previousFilter.value, value: undefined }, selected: false });
    };
    const onSelectedFilterChange = (previousFilter?: FilterRow | undefined, newFilter?: FilterRow | undefined) => {
        if (previousFilter && newFilter) {
            replaceFilterRow(previousFilter, newFilter);
        }
        else if (previousFilter) {
            removeFilterRow(previousFilter);
        }
        else if (newFilter) {
            addFilterRow(newFilter);
        }
    };
    const updateFilterRows = (...updatedFilters: FilterRow[]) => {
        const newFilterValues = updatedFilters.reduce((filterValues, filter) => filterValues.map((f) => (f.name === filter.name && f.option === filter.option ? filter : f)), allFilterRows);
        setAllFilterRows(newFilterValues);
        return newFilterValues;
    };
    const onValueChange = (...updatedFilters: FilterRow[]) => {
        const newFilterValues = updateFilterRows(...updatedFilters);
        onChange(newFilterValues);
    };
    return (<div className={styles.filterRowsContainer}>
            {selectedFilterRows.map((filter, i) => (<SelectedFilter key={`${filter.name}-${filter.option}`} filter={filter} availableFilters={unselectedFilterRows} onSelectedFilterChange={onSelectedFilterChange} onValueChange={onValueChange} index={i} display={display}/>))}
            {unselectedFilterRows.some((row) => row.multiplicity === FilterMultiplicity.OncePerOption) ? (<UnselectedFilter availableFilters={unselectedFilterRows} onSelectedFilterChange={onSelectedFilterChange} index={selectedFilterRows.length} display={display}/>) : null}
        </div>);
}
interface SelectedFilterProps {
    filter: FilterRow;
    availableFilters: FilterRow[];
    onSelectedFilterChange: (previousFilter?: FilterRow | undefined, newFilter?: FilterRow | undefined) => void;
    onValueChange: (...updatedFilters: FilterRow[]) => void;
    index: number;
    display: FilterDisplay;
}
function SelectedFilter({ filter, availableFilters, onSelectedFilterChange, onValueChange, index, display }: SelectedFilterProps) {
    const onNameChange = (selectedName: string | undefined) => {
        if (selectedName === filter.name)
            return;
        const firstAvailableFilter = availableFilters.find((f) => f.name === selectedName);
        onSelectedFilterChange(filter, firstAvailableFilter);
    };
    const onOptionChange = (selectedOption: string | undefined) => {
        if (selectedOption === filter.option)
            return;
        const newFilter = availableFilters.find((f) => f.name === filter.name && f.option === selectedOption);
        onSelectedFilterChange(filter, newFilter);
    };
    const onFilterRemoved = () => {
        onSelectedFilterChange(filter, undefined);
    };
    const availableNames = getAvailableNames(availableFilters, filter);
    const availableOptions = getAvailableOptions(availableFilters, filter);
    return (<>
            <div className={styles.newGridRow}>{display === "full" ? <FilterByLabel rowNumber={index + 1}/> : null}</div>
            <NameSelect filter={filter} items={availableNames} onChange={onNameChange}/>
            <OptionSelect filter={filter} items={availableOptions} onChange={onOptionChange}/>
            <ValueSelect filter={filter} onChange={onValueChange}/>
            {filter.multiplicity === FilterMultiplicity.Always ? null : <RemoveFilterIcon onFilterRemoved={onFilterRemoved}/>}
        </>);
}
function FilterByLabel({ rowNumber }: {
    rowNumber: number;
}) {
    return <div className={styles.plainText}>{rowNumber}. Filter by</div>;
}
interface NameSelectProps {
    filter?: FilterRow;
    items: DropdownMenuOption[];
    onChange: (value: string | undefined) => void;
}
function NameSelect({ filter, items, onChange }: NameSelectProps) {
    if (filter && filter.multiplicity === FilterMultiplicity.Always) {
        return <div className={styles.plainText}>{filter.name}</div>;
    }
    return (<div>
            <Select value={filter?.name} onChange={onChange} items={items} allowClear={false} allowFilter={true} placeholder="Select a filter" sortItems={false}/>
        </div>);
}
interface OptionSelectProps {
    filter: FilterRow;
    items: DropdownMenuOption[];
    onChange: (value: string | undefined) => void;
}
function OptionSelect({ filter, items, onChange }: OptionSelectProps) {
    return (<div>
            <Select value={filter.option} onChange={onChange} items={items} allowClear={false}/>
        </div>);
}
interface FilterBuilderValueSelectProps {
    filter: FilterRow;
    onChange: (...updatedFilters: FilterRow[]) => void;
}
function ValueSelect({ filter, onChange }: FilterBuilderValueSelectProps) {
    const onSingleValueChange = (v: string | undefined) => onChange({ ...filter, value: { value: v, type: FilterType.SingleSelect } });
    const onMultiValueChange = (v: string[] | undefined) => onChange({ ...filter, value: { value: v, type: FilterType.MultiSelect } });
    if (isSingleSelectValue(filter.value)) {
        return (<div>
                <SingleValueSelect value={filter.value} items={filter.availableItems} onChange={onSingleValueChange} placeholder={getPlaceholder(filter.name)} allowClear={filter.multiplicity === FilterMultiplicity.Always}/>
            </div>);
    }
    if (isMultiSelectValue(filter.value)) {
        return (<div>
                <MultiValueSelect value={filter.value} items={filter.availableItems} onChange={onMultiValueChange}/>
            </div>);
    }
    return null;
}
interface SingleValueSelectProps {
    value: SingleSelectValue;
    items: FilterResource[];
    onChange: (value: string | undefined) => void;
    placeholder: string;
    allowClear: boolean;
}
function SingleValueSelect({ value, items, onChange, placeholder, allowClear }: SingleValueSelectProps) {
    return <Select value={value.value} onChange={onChange} items={items.map((i) => ({ value: i.Id, text: i.Name }))} allowFilter={true} allowClear={allowClear} placeholder={placeholder}/>;
}
interface MultiValueSelectProps {
    value: MultiSelectValue;
    items: FilterResource[];
    onChange: (value: string[] | undefined) => void;
}
function MultiValueSelect({ value, items, onChange }: MultiValueSelectProps) {
    if (isTagResourceArray(items)) {
        return (<TagMultiSelect value={value.value ?? []} onChange={onChange} items={items} placeholder="Select tags" renderChip={(item: TagResource | SelectItem, onRequestDelete: () => void): JSX.Element => {
                const tag = items.find((t: TagResource) => t.Id === item.Id);
                if (tag) {
                    return <Tag onRequestDelete={onRequestDelete} description={tag.Description} tagName={tag.Name} tagColor={tag.Color} deleteButtonAccessibleName={`Delete ${tag.Name}`}/>;
                }
                else {
                    return <MissingChip lookupId={item.Id}/>;
                }
            }} renderItem={(tag) => ({
                primaryText: (<div className={styles.checkboxRowContainer}>
                            <Checkbox value={value.value?.includes(tag.Id) ?? false} onChange={noOp}/>
                            {tag.Name}
                        </div>),
            })} fieldName={"tags"} disableFilterSelectedItems={true} disableCloseOnSelected={true}/>);
    }
    return null;
}
interface RemoveFilterIconProps {
    onFilterRemoved: () => void;
}
function RemoveFilterIcon({ onFilterRemoved }: RemoveFilterIconProps) {
    return (<div className={styles.iconMargin}>
            <IconButtonWithTooltip toolTipContent="Remove filter" onClick={onFilterRemoved} accessibleName={"Cancel"} icon={"XmarkIcon"}/>
        </div>);
}
interface UnselectedFilterProps {
    availableFilters: FilterRow[];
    onSelectedFilterChange: (previousFilter?: FilterRow | undefined, newFilter?: FilterRow | undefined) => void;
    index: number;
    display: FilterDisplay;
}
function UnselectedFilter({ availableFilters, onSelectedFilterChange, index, display }: UnselectedFilterProps) {
    if (availableFilters.length === 0) {
        return null;
    }
    const onNameChange = (selectedName: string | undefined) => {
        const firstAvailableFilter = availableFilters.find((f) => f.name === selectedName);
        onSelectedFilterChange(undefined, firstAvailableFilter);
    };
    const availableNames = getAvailableNames(availableFilters);
    const placeholderOption = FilterOption.Is;
    const placeholderValue = "Select a filter to see values...";
    return (<>
            <div className={styles.newGridRow}>{display === "full" ? <FilterByLabel rowNumber={index + 1}/> : null}</div>
            <NameSelect filter={undefined} items={availableNames} onChange={onNameChange}/>
            <Select value={undefined} onChange={noOp} items={[]} placeholder={placeholderOption} disabled={true}/>
            <Select value={undefined} onChange={noOp} items={[]} placeholder={placeholderValue} disabled={true}/>
        </>);
}
const TagMultiSelect = MultiSelect<TagResource>();
const styles = {
    filterRowsContainer: css({
        width: "100%",
        display: "grid",
        gridTemplateColumns: "min-content minmax(auto, 15rem) minmax(auto, 6rem) minmax(8rem, 30rem) minmax(auto, 1.5rem)",
        gridColumnGap: "1rem",
        gridTemplateRows: "auto",
        alignItems: "end",
        height: "min-content",
    }),
    plainText: css({
        whiteSpace: "nowrap",
        marginBottom: "1.5rem",
    }),
    newGridRow: css({
        gridColumnStart: "1",
    }),
    iconMargin: css({
        marginBottom: "1rem",
    }),
    checkboxRowContainer: css({
        display: "inline-flex",
        alignItems: "center",
    }),
};
