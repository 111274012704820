import { pageId } from "@octopusdeploy/portal-routes";
export default () => {
    function workerMachineLinks() {
        return {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.Worker", name: "Worker" }),
            settings: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerSettings", name: "Worker Settings" }),
            connection: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerConnectivity", name: "Worker Connectivity" }),
            events: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerEvents", name: "Worker Events" }),
        };
    }
    const deploymentTargetLinks = () => ({
        root: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTarget", name: "Deployment Target" }),
        settings: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetSettings", name: "Deployment Target Settings" }),
        deployments: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetDeployments", name: "Deployment Target Deployments" }),
        runbookRuns: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetRunbookRuns", name: "Deployment Target Runbook Runs" }),
        connection: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetConnectivity", name: "Deployment Target Connectivity" }),
        events: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetEvents", name: "Deployment Target Events" }),
    });
    return {
        root: pageId({
            area: "Infrastructure",
            id: "Infrastructure",
            name: "Infrastructure",
        }),
        overview: pageId({
            area: "Infrastructure",
            id: "Infrastructure.Overview",
            name: "Overview",
        }),
        environments: {
            root: pageId({
                area: "Infrastructure",
                id: "Infrastructure.Environments",
                name: "Environments",
            }),
            create: pageId({
                area: "Infrastructure",
                id: "Infrastructure.EnvironmentsNew",
                name: "Add New Environment",
            }),
            filtered: pageId({ area: "Infrastructure", id: "Infrastructure.EnvironmentsFiltered", name: "Filtered Environments" }),
        },
        environment: pageId({ area: "Infrastructure", id: "Infrastructure.Environment", name: "Environment" }),
        machines: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargets", name: "Deployment Targets" }),
            select: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetsSelect", name: "Add New Deployment Target Selection" }),
            create: pageId({
                area: "Infrastructure (Windows, Linux, Mac, Azure, Listening Tentacle, Polling Tentacle, SSH Connection, Offline Package Drop, Azure Web App, Azure Cloud Service, Service Fabric Cluster, Kubernetes (K8s) Cluster, Cloud Region, Step Package)",
                id: "Infrastructure.DeploymentTargetsNew",
                name: "Add New Deployment Target",
            }),
            filtered: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetsFiltered", name: "Filtered Deployment Targets" }),
            discover: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetsDiscover", name: "Discover Deployment Target" }),
        },
        machine: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTarget", name: "Deployment Target" }),
            settings: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetSettings", name: "Deployment Target Settings" }),
            deployments: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetDeployments", name: "Deployment Target Deployments" }),
            runbookRuns: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetRunbookRuns", name: "Deployment Target Runbook Runs" }),
            connection: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetConnectivity", name: "Deployment Target Connectivity" }),
            events: pageId({ area: "Infrastructure", id: "Infrastructure.DeploymentTargetEvents", name: "Deployment Target Events" }),
        },
        deploymentTarget: {
            ...deploymentTargetLinks(),
        },
        proxies: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.Proxies", name: "Proxies" }),
            create: pageId({ area: "Infrastructure", id: "Infrastructure.ProxiesNew", name: "Add New Proxy" }),
        },
        proxy: pageId({ area: "Infrastructure", id: "Infrastructure.Proxy", name: "Proxy" }),
        machinePolicies: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.MachinePolicies", name: "Machine Policies" }),
            create: pageId({
                area: "Infrastructure",
                id: "Infrastructure.MachinePoliciesNew",
                name: "Add New Machine Policy",
            }),
        },
        machinePolicy: pageId({ area: "Infrastructure", id: "Infrastructure.MachinePolicy", name: "Machine Policy" }),
        accounts: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.Accounts", name: "Accounts" }),
            create: pageId({ area: "Infrastructure", id: "Infrastructure.AccountsNew", name: "Add New Account" }),
        },
        account: pageId({ area: "Infrastructure", id: "Infrastructure.Account", name: "Account" }),
        workerMachines: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.Workers", name: "Workers" }),
            select: pageId({ area: "Infrastructure", id: "Infrastructure.WorkersSelect", name: "Add New Worker Selection" }),
            create: pageId({
                area: "Infrastructure (Windows, Linux, Mac, Listening Tentacle, Polling Tentacle, SSH Connection)",
                id: "Infrastructure.WorkersNew",
                name: "Add New Worker",
            }),
            filtered: pageId({ area: "Infrastructure", id: "Infrastructure.WorkersFiltered", name: "Filtered Workers" }),
            discover: pageId({ area: "Infrastructure", id: "Infrastructure.WorkersDiscover", name: "Discover Worker" }),
        },
        workerMachine: {
            ...workerMachineLinks(),
        },
        workerPools: {
            root: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerPools", name: "Worker Pools" }),
            create: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerPoolNew", name: "Add New Worker Pool" }),
            filtered: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerPoolsFiltered", name: "Filtered Worker Pools" }),
        },
        workerPool: pageId({ area: "Infrastructure", id: "Infrastructure.WorkerPool", name: "Worker Pool" }),
    };
};
