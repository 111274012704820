/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { css, cx } from "@emotion/css";
import type { PrimaryPageAction } from "@octopusdeploy/design-system-components";
import { EmptyStateDefaultIllustration } from "@octopusdeploy/design-system-components";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { ChannelResource, CreateReleaseActionResource, DeploymentActionPackageResource, DeploymentProcessResource, EnvironmentResource, EventCategoryResource, EventGroupResource, MachineFilterResource, ProjectResource, Repository, ResourceCollection, ScopedDeploymentActionResource, TenantResource, TriggerResource, TriggerScheduleResource, } from "@octopusdeploy/octopus-server-client";
import { Permission, TenantedDeploymentMode, TriggerActionCategory, TriggerActionType, TriggerFilterType, triggerIdIs } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import type * as H from "history";
import * as _ from "lodash";
import { flatten, isEqual } from "lodash";
import * as React from "react";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router";
import { GitTriggersFeatureToggleVisibility } from "~/areas/projects/components/Triggers/useIsGitTriggersEnabled";
import { repository } from "~/clientInstance";
import { AdvancedFilters } from "~/components/AdvancedFilterLayout/AdvancedFilters";
import { AdvancedFilterCheckbox } from "~/components/AdvancedFilterLayout/index";
import { ChannelChip, matchesToChips } from "~/components/Chips";
import type { DataBaseComponentState } from "~/components/DataBaseComponent";
import { DataBaseComponent } from "~/components/DataBaseComponent";
import { EmptyPage } from "~/components/EmptyPage/EmptyPage";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FilterSearchBox from "~/components/FilterSearchBox/index";
import InternalLink from "~/components/Navigation/InternalLink/InternalLink";
import { NoResults } from "~/components/NoResults/NoResults";
import { OverflowMenu, OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import type { PageContentProps } from "~/components/PageContent/PageContent";
import { PageContent } from "~/components/PageContent/PageContent";
import PagingDataTable from "~/components/PagingDataTable";
import List from "~/components/PagingList";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import type { ProjectPageLoaderContext } from "~/routing/pageRegistrations/ProjectPageRegistration";
import { timeOperationOptions } from "~/utils/OperationTimer/timeOperation";
import ScheduledTriggerDescriptionHelper from "~/utils/ScheduledTriggerDescriptionHelper";
import { AddDeploymentTriggerButton } from "./AddDeploymentTriggerButton";
import { AddProjectTriggerDialogButton } from "./AddProjectTriggerDialog";
import { FeedTriggerDescription, TriggerNameAndLink } from "./Feed/FeedTriggerDescriptionHelper";
import { GitTriggerDescription } from "./Git/GitTriggerDescription";
import ScheduledTrigger from "./Scheduled/ScheduledTrigger";
import SideBar from "./SideBar";
import Trigger from "./Trigger";
import TriggerDescription from "./TriggerDescription";
import { TriggerType } from "./TriggerType";
import TriggersOnboarding from "./TriggersOnboarding";
interface DeploymentTriggersState extends DataBaseComponentState {
    triggersResponse: ResourceCollection<TriggerResource>;
    project: ProjectResource;
    open: boolean;
    anchor?: any;
    filter: DeploymentTriggersFilter;
    isSearching: boolean;
}
interface DeploymentTriggersFilter {
    searchText: string;
    showDeploymentTargetTriggers: boolean;
    showScheduledTriggers: boolean;
    showBuiltInRepositoryTriggers: boolean;
    showExternalFeedTriggers: boolean;
    showGitTriggers: boolean;
}
interface DeploymentTriggersPageLoaderData {
    project: ProjectResource;
    triggersResponse: ResourceCollection<TriggerResource>;
    environments: EnvironmentResource[];
    categories: EventCategoryResource[];
    groups: EventGroupResource[];
    builtInFeedPackageActions: DeploymentActionPackageResource[];
    deploymentProcess: DeploymentProcessResource;
    channels: ChannelResource[];
    tenants: TenantResource[];
}
function shouldShowAutomaticReleaseCreation(project: ProjectResource, builtInFeedPackageActions: DeploymentActionPackageResource[]) {
    return builtInFeedPackageActions.length > 0 || project.AutoCreateRelease;
}
function isBuiltInPackageRepositoryInUse(builtInFeedPackageActions: DeploymentActionPackageResource[]) {
    return builtInFeedPackageActions.length > 0;
}
export async function deploymentTriggersPageLoader(repository: Repository, context: ProjectPageLoaderContext): Promise<DeploymentTriggersPageLoaderData> {
    const projectContext = await context.projectContext;
    const { model: project, projectContextRepository } = projectContext.state;
    const { Enabled: isMultiTenancyEnabled } = await repository.Tenants.status();
    // We grab the deployment process for the default branch only as that is all that external feed
    // and built-in package triggers support right now. This will need to be updated if/when it is extended.
    // prettier-ignore
    const deploymentProcessPromise = isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })
        ? projectContextRepository.DeploymentProcesses.getForDefaultBranch()
        : Promise.resolve<DeploymentProcessResource>(null!);
    // prettier-ignore
    const channelsPromise = isAllowed({ permission: Permission.ProcessView, project: project.Id, tenant: "*" })
        ? repository.Projects.getChannels(project)
        : Promise.resolve(null);
    const builtInFeedPromise = repository.Feeds.getBuiltIn();
    const environmentsPromise = repository.Environments.all();
    const categoriesPromise = repository.Events.categories({ appliesTo: "Machine" });
    const groupsPromise = repository.Events.groups({ appliesTo: "Machine" });
    const triggersPromise = repository.Projects.getTriggers(project, 0, 30, undefined, TriggerActionCategory.Deployment);
    // prettier-ignore
    const tenantsPromise = isAllowed({ permission: Permission.TenantView, tenant: "*" }) && isMultiTenancyEnabled && project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted
        ? repository.Tenants.all({ projectId: project.Id })
        : Promise.resolve([]);
    const deploymentProcess = await deploymentProcessPromise;
    const channels = await channelsPromise;
    const builtInFeed = await builtInFeedPromise;
    const actions = deploymentProcess && flatten(deploymentProcess.Steps.map((step) => step.Actions)).filter((a) => !a.IsDisabled);
    const builtInFeedPackageActions = actions &&
        // start with the steps
        _.chain(deploymentProcess.Steps)
            // Get the step actionPackages
            .flatMap((step) => step.Actions)
            // Filter by built-in feed id
            // Convert them to deployment-action-packages
            .flatMap((action) => _.chain(action.Packages)
            .filter((pkg) => pkg.FeedId === builtInFeed.Id)
            .map((pkg) => ({ DeploymentAction: action.Name, PackageReference: pkg.Name! }))
            .value())
            .value();
    return {
        triggersResponse: await triggersPromise,
        environments: await environmentsPromise,
        categories: await categoriesPromise,
        deploymentProcess,
        groups: await groupsPromise,
        project,
        builtInFeedPackageActions,
        channels: channels ? channels.Items : [],
        tenants: await tenantsPromise,
    };
}
type DeploymentTriggersInternalProps = {
    history: H.History;
} & DeploymentTriggersPageProps;
class DeploymentTriggersInternal extends DataBaseComponent<DeploymentTriggersInternalProps, DeploymentTriggersState> {
    constructor(props: DeploymentTriggersInternalProps) {
        super(props);
        this.state = {
            triggersResponse: props.loaderData.triggersResponse,
            open: false,
            filter: createDefaultFilter(),
            isSearching: false,
            project: props.loaderData.project,
        };
    }
    filterTriggers = (_: string, trigger: TriggerResource) => {
        const filter = this.state.filter;
        const deploymentTargetTriggerFilterTypes = [TriggerFilterType.MachineFilter];
        const scheduledTriggerFilterTypes = [TriggerFilterType.OnceDailySchedule, TriggerFilterType.ContinuousDailySchedule, TriggerFilterType.DaysPerMonthSchedule, TriggerFilterType.CronExpressionSchedule];
        return ((filter.searchText === "" || (filter.searchText !== "" && trigger.Name.toLowerCase().includes(filter.searchText.toLowerCase()))) &&
            (filter.showDeploymentTargetTriggers || (!filter.showDeploymentTargetTriggers && !deploymentTargetTriggerFilterTypes.includes(trigger.Filter.FilterType))) &&
            (filter.showScheduledTriggers || (!filter.showScheduledTriggers && !scheduledTriggerFilterTypes.includes(trigger.Filter.FilterType))) &&
            (filter.showBuiltInRepositoryTriggers || (!filter.showBuiltInRepositoryTriggers && !triggerIdIs(trigger, "built-in-feed"))) &&
            (filter.showExternalFeedTriggers || (!filter.showExternalFeedTriggers && trigger.Filter.FilterType !== TriggerFilterType.FeedFilter)) &&
            (filter.showGitTriggers || (!filter.showGitTriggers && trigger.Filter.FilterType !== TriggerFilterType.GitFilter)));
    };
    render() {
        const { builtInFeedPackageActions, channels, deploymentProcess } = this.props.loaderData;
        const content = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") ? (<MediaQuery maxWidth={600}>
                {(small) => (<MediaQuery minWidth={901}>
                        {(large) => (<PagingDataTable<TriggerResource> tableClassName={small ? "" : emotionStyles.tableCellHeightHack} headerColumns={this.buildDataTableColumnHeaders(getBreakpoint(small, large))} initialData={this.state.triggersResponse} additionalRequestParams={new Map([["triggerActionCategory", [TriggerActionCategory.Deployment]]])} onRow={(item: any) => this.buildDataTableColumns(item, getBreakpoint(small, large))} onFilter={this.filterTriggers} onEmpty={() => (<EmptyPage image={<div className={emotionStyles.emptyStateIllustration}>
                                                <EmptyStateDefaultIllustration />
                                            </div>} title={"You have no triggers available yet"} description={"Add a trigger to get started"}/>)}></PagingDataTable>)}
                    </MediaQuery>)}
            </MediaQuery>) : (<List<TriggerResource> initialData={this.state.triggersResponse} additionalRequestParams={new Map([["triggerActionCategory", [TriggerActionCategory.Deployment]]])} onRow={(item: any) => this.buildTriggerRow(item)} onRowRedirectUrl={(trigger) => getTriggerRedirectUrl(trigger, this.props.projectSlug)} onFilter={this.filterTriggers} empty={<NoResults />}/>);
        const showTriggerOnboarding = this.state.triggersResponse?.TotalResults === 0;
        let primaryAction: PrimaryPageAction | undefined = undefined;
        let primaryButton: React.ReactNode | undefined = undefined;
        const addTriggerButton = <AddDeploymentTriggerButton spaceId={this.props.spaceId} projectSlug={this.props.projectSlug}/>;
        const addTriggerPageAction: PrimaryPageAction = {
            type: "custom",
            key: "Add Trigger",
            content: addTriggerButton,
        };
        const addReleaseTriggerButton = <AddProjectTriggerDialogButton project={this.state.project}/>;
        const addReleaseTriggerPageAction: PrimaryPageAction = {
            type: "custom",
            key: "Add Trigger",
            content: addReleaseTriggerButton,
        };
        primaryButton = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") ? addReleaseTriggerButton : addTriggerButton;
        primaryAction = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") ? addReleaseTriggerPageAction : addTriggerPageAction;
        const sidebar = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") || !isAllowed({ permission: Permission.ProcessView, project: this.state.project.Id, tenant: "*" }) ? undefined : (<SideBar project={this.state.project} builtInPackageRepositoryInUse={isBuiltInPackageRepositoryInUse(builtInFeedPackageActions)} actionPackages={this.props.loaderData.builtInFeedPackageActions} showAutomaticReleaseCreation={shouldShowAutomaticReleaseCreation(this.state.project, builtInFeedPackageActions)} channels={channels} deploymentProcess={deploymentProcess} onProjectUpdated={(p) => this.onProjectUpdated(p)}/>);
        const shouldUseFullWidth = isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle");
        if (showTriggerOnboarding) {
            return (<PageContent busy={this.state.busy} errors={this.errors} overrideFullWidth={shouldUseFullWidth} header={{ title: deploymentTriggersPageTitle }} sidebar={sidebar}>
                    <TriggersOnboarding actionButtons={primaryButton}/>
                </PageContent>);
        }
        return (<PageContent busy={this.state.busy} errors={this.errors} overrideFullWidth={shouldUseFullWidth} header={{ title: deploymentTriggersPageTitle, primaryAction }} filters={{
                inputs: [
                    <FilterSearchBox placeholder="Filter by name..." value={this.state.filter.searchText} autoFocus={true} onChange={(searchText) => this.setFilterState({ searchText }, async () => {
                            await this.onFilterChange();
                        })}/>,
                ],
                advancedFilters: {
                    hasUserSelectedValues: !isEqual(createDefaultFilter(), this.state.filter),
                    onResetFilter: () => {
                        this.setState({ filter: createDefaultFilter() }, async () => {
                            await this.onFilterChange();
                            const location = { ...this.props.history, search: null! as any };
                            this.props.history.replace(location);
                        });
                    },
                    content: (<AdvancedFilters>
                                <p>
                                    <strong>Show trigger types:</strong>
                                </p>
                                {isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") && (<AdvancedFilterCheckbox label="External feed" value={this.state.filter.showExternalFeedTriggers} onChange={(x) => this.setFilterState({ showExternalFeedTriggers: x }, async () => {
                                await this.onFilterChange();
                            })}/>)}
                                <GitTriggersFeatureToggleVisibility>
                                    <AdvancedFilterCheckbox label="Git repository" value={this.state.filter.showGitTriggers} onChange={(x) => this.setFilterState({ showGitTriggers: x }, async () => {
                            await this.onFilterChange();
                        })}/>
                                </GitTriggersFeatureToggleVisibility>
                                <AdvancedFilterCheckbox label="Scheduled" value={this.state.filter.showScheduledTriggers} onChange={(x) => this.setFilterState({ showScheduledTriggers: x }, async () => {
                            await this.onFilterChange();
                        })}/>
                                {isFeatureToggleEnabled("ExternalReleaseTriggerFeatureToggle") && (<AdvancedFilterCheckbox label="Built-in package repository" value={this.state.filter.showBuiltInRepositoryTriggers} onChange={(x) => this.setFilterState({ showBuiltInRepositoryTriggers: x }, async () => {
                                await this.onFilterChange();
                            })}/>)}
                                <AdvancedFilterCheckbox label="Deployment target" value={this.state.filter.showDeploymentTargetTriggers} onChange={(x) => this.setFilterState({ showDeploymentTargetTriggers: x }, async () => {
                            await this.onFilterChange();
                        })}/>
                            </AdvancedFilters>),
                },
            }} sidebar={sidebar} callout={this.getInvalidConfigurationCallout()}>
                {content}
            </PageContent>);
    }
    async reloadTriggers() {
        await this.doBusyTask(async () => {
            this.setState({
                triggersResponse: await repository.Projects.getTriggers(this.state.project, 0, 30, undefined, TriggerActionCategory.Deployment, undefined, this.state.filter.searchText),
            });
        });
    }
    private getInvalidConfigurationCallout(): PageContentProps["callout"] {
        const { deploymentProcess } = this.props.loaderData;
        if (deploymentProcess && this.state.project.AutoCreateRelease) {
            if (!this.state.project.ReleaseCreationStrategy || !this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage) {
                return {
                    type: "warning",
                    title: "Invalid Configuration",
                    content: "This project is configured to use Automatic Release Creation, but the step is missing. Please adjust or disable the Automatic Release Creation configuration.",
                };
            }
            else {
                const action = flatten(deploymentProcess.Steps.map((step) => step.Actions)).filter((a) => a.Name === this.state.project.ReleaseCreationStrategy.ReleaseCreationPackage.DeploymentAction);
                if (action && action.length > 0 && action[0].IsDisabled) {
                    return {
                        type: "warning",
                        title: "Invalid Configuration",
                        content: (<>
                                Step <InternalLink to={links.deploymentProcessStepsPage.generateUrl({ spaceId: this.state.project.SpaceId, projectSlug: this.state.project.Slug }, { actionId: action[0].Id })}>{action[0].Name}</InternalLink> is
                                currently used for Automatic Release Creation, but it has been disabled.
                                <br />
                                Please re-enable the step, disable Automatic Release Creation, or choose a different step.
                            </>),
                    };
                }
            }
        }
        return undefined;
    }
    private async onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.reloadTriggers();
            this.setState({ isSearching: false });
        });
    }
    private setFilterState<K extends keyof DeploymentTriggersFilter>(state: Pick<DeploymentTriggersFilter, K>, callback?: () => void) {
        this.setState((prev) => ({
            filter: { ...(prev!.filter as object), ...(state as object) },
        }), callback);
    }
    private buildTriggerRow(trigger: TriggerResource) {
        const { environments, categories, groups, channels } = this.props.loaderData;
        const overflowMenuItems: any = this.getOverflowMenuItems(trigger);
        if (trigger.Filter.FilterType === TriggerFilterType.MachineFilter) {
            return <Trigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} environments={environments} categories={categories} groups={groups}/>;
        }
        return <ScheduledTrigger key={trigger.Id} trigger={trigger} menuItems={overflowMenuItems} runbooks={[]} environments={environments} channels={channels}/>;
    }
    private getOverflowMenuItems(trigger: TriggerResource) {
        const menuItems = [];
        const triggerEditPermission = { permission: Permission.TriggerEdit, project: this.state.project.Id };
        // Can't disable ARC, only delete
        if (!triggerIdIs(trigger, "built-in-feed")) {
            menuItems.push(OverflowMenuItems.item(trigger.IsDisabled ? "Enable" : "Disable", () => (trigger.IsDisabled ? this.enable(trigger) : this.disable(trigger)), triggerEditPermission));
        }
        return menuItems;
    }
    private async enable(trigger: TriggerResource) {
        trigger.IsDisabled = false;
        await this.saveTrigger(trigger);
    }
    private async disable(trigger: TriggerResource) {
        trigger.IsDisabled = true;
        await this.saveTrigger(trigger);
    }
    private async saveTrigger(trigger: TriggerResource) {
        const isSuccess = await this.doBusyTask(async () => {
            await repository.ProjectTriggers.modify(trigger);
            const triggersResponse = await repository.Projects.getTriggers(this.state.project, 0, 30, undefined, TriggerActionCategory.Deployment);
            this.setState({
                triggersResponse,
            });
        });
        if (!isSuccess) {
            await this.doBusyTask(async () => {
                this.setState({
                    triggersResponse: await repository.Projects.getTriggers(this.state.project, 0, 30, undefined, TriggerActionCategory.Deployment),
                });
            }, { preserveCurrentErrors: true, timeOperationOptions: timeOperationOptions.forInitialLoad() });
        }
    }
    private onProjectUpdated(project: ProjectResource) {
        this.setState({
            project,
        });
    }
    private buildDataTableColumnHeaders(screenSize: "sm" | "md" | "lg") {
        const columnHeaders = ["Name"];
        if (screenSize !== "sm") {
            columnHeaders.push("Type");
            columnHeaders.push("Channel");
        }
        if (screenSize === "lg") {
            columnHeaders.push("Trigger action");
        }
        if (screenSize !== "sm") {
            columnHeaders.push("");
        }
        return columnHeaders;
    }
    private buildDataTableColumns(trigger: TriggerResource, screenSize: "sm" | "md" | "lg") {
        const columns = [<TriggerNameAndLink trigger={trigger} projectSlug={this.props.projectSlug}/>];
        if (screenSize !== "sm") {
            columns.push(<TriggerType trigger={trigger}/>);
            const channelChip = this.channelChip(trigger);
            if (channelChip) {
                columns.push(channelChip);
            }
            else {
                columns.push(<></>);
            }
        }
        if (screenSize === "lg") {
            columns.push(this.buildTriggerDescription(trigger));
        }
        if (screenSize !== "sm") {
            columns.push(this.getOverflowMenu(trigger));
        }
        return columns.map((column) => this.wrapWithStyles(column, trigger));
    }
    private wrapWithStyles(children: React.ReactNode, trigger: TriggerResource) {
        let cellStyle = emotionStyles.verticallyCenter;
        if (trigger.IsDisabled) {
            cellStyle = cx(cellStyle, emotionStyles.disabled);
        }
        return <div className={cellStyle}>{children}</div>;
    }
    private channelChip(trigger: TriggerResource): React.ReactElement | undefined {
        const { channels } = this.props.loaderData;
        const channelId = (trigger.Action as CreateReleaseActionResource).ChannelId;
        if (!channelId || !channels)
            return;
        return channels.find((c) => c.Id === channelId) ? (matchesToChips(channels, [channelId], (channel) => <ChannelChip labelColor={trigger.IsDisabled ? themeTokens.color.text.disabled : themeTokens.color.text.primary} channelName={channel.Name} key={channel.Id} noMargin={true}/>)[0]) : (<ChannelChip labelColor={trigger.IsDisabled ? themeTokens.color.text.disabled : themeTokens.color.text.primary} channelName={channelId} noMargin={true}/>);
    }
    private buildTriggerDescription(trigger: TriggerResource) {
        const { builtInFeedPackageActions, deploymentProcess, environments, categories, groups, channels } = this.props.loaderData;
        if (trigger.Filter.FilterType === TriggerFilterType.FeedFilter || trigger.Filter.FilterType === TriggerFilterType.ArcFeedFilter) {
            return <FeedTriggerDescription trigger={trigger} builtInFeedPackageActions={builtInFeedPackageActions} projectSlug={this.props.projectSlug} deploymentProcess={deploymentProcess}/>;
        }
        else if (trigger.Filter.FilterType === TriggerFilterType.GitFilter) {
            return <GitTriggerDescription trigger={trigger} project={this.state.project} deploymentProcess={deploymentProcess}/>;
        }
        if (trigger.Filter.FilterType === TriggerFilterType.MachineFilter) {
            const filter = trigger.Filter as MachineFilterResource;
            const description = new TriggerDescription(filter, environments, categories, groups);
            return description.buildDescription();
        }
        const scheduleDescription = ScheduledTriggerDescriptionHelper.getScheduleDescription(trigger.Filter as TriggerScheduleResource);
        const actionDescription = ScheduledTriggerDescriptionHelper.getActionDescription(trigger.Action as ScopedDeploymentActionResource, environments, [], channels);
        return (<span>
                {actionDescription}
                {scheduleDescription}.
            </span>);
    }
    private getOverflowMenu(trigger: TriggerResource) {
        return <OverflowMenu menuItems={this.getOverflowMenuItems(trigger)}/>;
    }
    static displayName = "DeploymentTriggersInternal";
}
function createDefaultFilter(): DeploymentTriggersFilter {
    return {
        searchText: "",
        showDeploymentTargetTriggers: true,
        showScheduledTriggers: true,
        showBuiltInRepositoryTriggers: true,
        showExternalFeedTriggers: true,
        showGitTriggers: true,
    };
}
type DeploymentTriggersPageProps = {
    spaceId: string;
    projectSlug: string;
    loaderData: DeploymentTriggersPageLoaderData;
};
export const DeploymentTriggersPage: React.FC<DeploymentTriggersPageProps> = (props) => {
    const history = useHistory();
    return <DeploymentTriggersInternal history={history} {...props}/>;
};
DeploymentTriggersPage.displayName = "DeploymentTriggersPage"
function getTriggerRedirectUrl(trigger: TriggerResource, projectSlug: string) {
    if (trigger.Action.ActionType === TriggerActionType.AutoDeploy) {
        return links.editDeploymentTriggerPage.generateUrl({ spaceId: trigger.SpaceId, projectSlug, triggerId: trigger.Id });
    }
    return links.editDeploymentScheduledTriggerPage.generateUrl({ spaceId: trigger.SpaceId, projectSlug, triggerId: trigger.Id });
}
const getBreakpoint = (small: boolean, large: boolean) => {
    if (small)
        return "sm";
    if (large)
        return "lg";
    return "md";
};
export const deploymentTriggersPageTitle = "Triggers";
const emotionStyles = {
    // This is required so we can set the height of a cell to 100% so we can center the content
    tableCellHeightHack: css({ height: "1px" }),
    verticallyCenter: css({ height: "100%", display: "flex", alignItems: "center" }),
    disabled: css({ color: themeTokens.color.text.disabled }),
    emptyStateIllustration: css({ height: 300 }),
};
