import { css } from "@emotion/css";
import { Switch } from "@octopusdeploy/design-system-components";
import { text, lineHeight, themeTokens, fontSize } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { LiveStatusConnectionIcon } from "~/areas/projects/components/Observability/LiveStatusConnectionIcon";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
export type ProjectsDashboardLiveStatusSwitchProps = {
    value: boolean;
    onValueChange: (newValue: boolean) => void;
    accessibleName: string;
};
const ContainerStyle = css({
    color: themeTokens.color.text.secondary,
    font: text.regular.default.xSmall,
    lineHeight: lineHeight.xSmall,
    fontSize: fontSize.medium,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    textAlign: "center",
});
export function ProjectsDashboardLiveStatusSwitch(props: ProjectsDashboardLiveStatusSwitchProps) {
    const { value, onValueChange, accessibleName } = props;
    const isKubernetesLiveStatusEnabled = isFeatureToggleEnabled("KubernetesLiveObjectStatusFeatureToggle");
    if (!isKubernetesLiveStatusEnabled) {
        return <></>;
    }
    return (<span className={ContainerStyle}>
            <Switch key="show-live-status" value={value} onChange={onValueChange} accessibleName={accessibleName}/>
            <LiveStatusConnectionIcon />
            {value ? "Hide live status" : "Show live status"}
        </span>);
}
