/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import cn from "classnames";
import * as React from "react";
import styles from "./style.module.less";
export interface FormSectionBaseProps {
    error?: string;
    forceMobileBehaviour?: boolean;
}
export abstract class FormSectionBase<TProps extends FormSectionBaseProps, TState = {}> extends React.Component<TProps, TState> {
    protected alterChild: (child: any) => void = undefined!;
    renderChild = (child: any) => {
        if (!child) {
            return child;
        }
        const children: any = child.props && child.props.children ? { children: typeof child.props.children !== "function" ? this.renderChildren(child.props.children) : child.props.children } : null;
        if (this.alterChild) {
            child = this.alterChild(child);
        }
        const props = children ? { ...child.props, ...children } : child.props;
        return React.isValidElement(child) ? React.cloneElement(child, props) : child;
    };
    renderChildren = (children: any) => {
        return React.Children.map(children, this.renderChild);
    };
    abstract render(): any;
    protected title(title: React.ReactNode, subtitle: React.ReactNode) {
        return (<div className={styles.cardTitleContainer}>
                <span className={styles.cardTitle}>{title}</span>
                {subtitle}
            </div>);
    }
    protected help(help: React.ReactNode) {
        return <span className={cn(styles.cardSubTitle, this.props.forceMobileBehaviour && styles.cardSubTitleForceMobile)}>{this.renderChild(help || <div />)}</span>;
    }
    protected body(className: string, expandable: boolean) {
        return <div className={className}>{this.renderChildren(this.props.children)}</div>;
    }
    static displayName = "FormSectionBase";
}
