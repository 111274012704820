import type { EnvironmentResource, GetProjectTemplatesWithValuesBffResponse, ProjectResource, GetScopedCommonTemplatesWithValuesBffResponse, ScopedCommonTemplateTenantValue } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import _, { cloneDeep } from "lodash";
import React, { useState } from "react";
import type { ProjectTenantVariablesFilterState } from "~/areas/projects/components/Variables/TenantVariables/FiltersBar";
import type { TemplatesWarnings } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesNavigationTabs";
import { useCommonTemplatesFilterState } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesFilterState";
import { usePageState } from "~/areas/tenants/components/Paging/usePageState";
import { repository } from "~/clientInstance";
import { type DoBusyTask, useDoBusyTaskEffect } from "~/components/DataBaseComponent/index";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner/index";
import { generateGuid } from "../../../Process/generation";
import type { CommonTemplatesValueKey } from "../useCommonTemplatesValueChangeTracker";
import { extractCommonTemplateValueIds } from "./useScopedCommonTemplatesScopeChangeTracker";
interface Actions {
    setPageState: (newPageNumber: number, newPageSize: number) => void;
    setFilter: (newFilter: ProjectTenantVariablesFilterState) => void;
    resetFilter: (newFilter: Partial<ProjectTenantVariablesFilterState>) => void;
    onSave: () => Promise<void>;
    addRow: (setId: string, templateId: string, tenantId: string) => string | undefined;
    deleteRow: (rowKey: CommonTemplatesValueKey, value: ScopedCommonTemplateTenantValue) => void;
}
export interface ScopedCommonTemplatesState {
    data: GetScopedCommonTemplatesWithValuesBffResponse | null;
    environments: Map<string, EnvironmentResource>;
    warnings: TemplatesWarnings;
    pageSize: number;
    filterState: ProjectTenantVariablesFilterState;
    actions: Actions;
}
const pagingSelectionKey = "octoProjectTenantVariablesPagingSelection";
export function useScopedCommonTemplatesState(project: ProjectResource, doBusyTask: DoBusyTask): ScopedCommonTemplatesState {
    const [data, setData] = useState<GetScopedCommonTemplatesWithValuesBffResponse | null>(null);
    const [environments, setEnvironments] = useState<Map<string, EnvironmentResource>>(new Map());
    const [warnings, setWarnings] = useState<TemplatesWarnings>({ projectTemplates: false, commonTemplates: false });
    const [pageState, setPageState] = usePageState(pagingSelectionKey);
    const [filterState, setFilterState] = useCommonTemplatesFilterState();
    const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);
    const raceConditionerRef: Readonly<React.MutableRefObject<RequestRaceConditioner>> = React.useRef(new RequestRaceConditioner());
    const actions: Actions = {
        setPageState: (newPageNumber, newPageSize) => {
            setFilterState({ ...filterState, pageNumber: newPageNumber });
            setPageState(filterState.pageNumber, newPageSize);
        },
        setFilter: (newFilter) => setFilterState({ ...newFilter, pageNumber: 1 }),
        resetFilter: (newFilter) => setFilterState({ ...defaultFilter, ...newFilter }),
        onSave: async () => {
            await doBusyTask(async () => {
                await raceConditionerRef.current.avoidStaleResponsesForRequest(repository.Projects.getScopedCommonTemplatesWithValues(project.Id, (filterState.pageNumber - 1) * pageState.pageSize, pageState.pageSize, filterState), (response) => {
                    setData(response);
                });
            });
        },
        addRow: (setId: string, templateId: string, tenantId: string) => {
            if (data === null) {
                return;
            }
            const variableSet = data.VariableSets.find((v) => v.LibraryVariableSet.Id === setId);
            if (variableSet === undefined) {
                return;
            }
            const template = variableSet.Templates.find((t) => t.Template.Id === templateId);
            if (template === undefined) {
                return;
            }
            const guid = generateGuid();
            const index = _.findLastIndex(template.Values, (v) => v.TenantId === tenantId);
            template.Values.splice(index + 1, 0, {
                TemplateId: template.Template.Id,
                TenantId: tenantId,
                TenantName: "",
                TenantLogoLink: "",
                Value: undefined,
                ValueStatus: "missing",
                Id: guid,
                Scope: {
                    EnvironmentIds: [],
                },
                TenantIsDisabled: false, //what happens if tenant is disabled?
            });
            setData(cloneDeep(data));
            return guid;
        },
        deleteRow: (rowKey: CommonTemplatesValueKey, value: ScopedCommonTemplateTenantValue) => {
            const { setId, templateId, guid } = extractCommonTemplateValueIds(rowKey);
            if (data === null) {
                return;
            }
            const variableSet = data.VariableSets.find((v) => v.LibraryVariableSet.Id === setId);
            if (variableSet === undefined) {
                return;
            }
            const template = variableSet.Templates.find((t) => t.Template.Id === templateId);
            if (template === undefined) {
                return;
            }
            const rowCount = template.Values.length;
            if (rowCount > 1) {
                template.Values = template.Values.filter((v) => v.Id !== guid);
            }
            else {
                value.Value = undefined;
            }
            setData(cloneDeep(data));
        },
    };
    useDoBusyTaskEffect(doBusyTask, async () => {
        const loadCommonTemplates = () => repository.Projects.getScopedCommonTemplatesWithValues(project.Id, (filterState.pageNumber - 1) * pageState.pageSize, pageState.pageSize, filterState);
        const updateDataState = (commonResponse: GetScopedCommonTemplatesWithValuesBffResponse | null, projectResponse: GetProjectTemplatesWithValuesBffResponse | null, environments: Map<string, EnvironmentResource>) => {
            setData(commonResponse);
            setEnvironments(environments);
            setWarnings((previous) => ({ commonTemplates: commonResponse?.AnyMissingValues ?? previous.commonTemplates, projectTemplates: projectResponse?.AnyMissingValues ?? previous.projectTemplates }));
        };
        const loadEnvironments = async (): Promise<Map<string, EnvironmentResource>> => {
            if (!hasPermission(Permission.EnvironmentView)) {
                return new Map();
            }
            const all = await repository.Environments.all();
            return all.reduce((lookup, env) => lookup.set(env.Id, env), new Map<string, EnvironmentResource>());
        };
        if (isInitialPageLoad) {
            const [commonResponse, projectResponse, environmentsResponse] = await Promise.all([
                loadCommonTemplates(),
                repository.Projects.getProjectTemplatesWithValues(project.Id, (filterState.pageNumber - 1) * pageState.pageSize, pageState.pageSize, filterState),
                loadEnvironments(),
            ]);
            updateDataState(commonResponse, projectResponse, environmentsResponse);
            setIsInitialPageLoad(false);
        }
        else {
            const promises = Promise.all([loadCommonTemplates(), loadEnvironments()]);
            await raceConditionerRef.current.avoidStaleResponsesForRequest(promises, ([response, environments]) => updateDataState(response, null, environments));
        }
    }, [project, filterState, pageState.pageSize]);
    return { data, environments, warnings, pageSize: pageState.pageSize, filterState, actions };
}
const defaultFilter: ProjectTenantVariablesFilterState = {
    filterByName: undefined,
    filterByEnvironment: undefined,
    filterByExcludedEnvironment: undefined,
    filterByTenant: undefined,
    filterByExcludedTenant: undefined,
    filterByTags: [],
    filterByExcludedTags: [],
    filterByValueStatus: undefined,
    filterByTemplateId: undefined,
    filterByLibraryVariableSetId: undefined,
    pageNumber: 1,
};
