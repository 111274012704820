import { Callout } from "@octopusdeploy/design-system-components";
import * as React from "react";
import DeleteDialogLayout from "~/components/Dialog/DeleteDialogLayout";
interface StopShareTemplateDialogProps {
    onSave: (sharedSpaces: SharedSpaces) => Promise<void>;
    onClose: () => void;
}
export interface SharedSpaces {
    shareToAll: boolean;
    individualSpacesSharedTo: string[];
}
export function StopShareTemplateDialog({ onSave, onClose }: StopShareTemplateDialogProps) {
    return (<DeleteDialogLayout title={"Stop sharing this template"} onDeleteClick={async () => {
            await onSave({ shareToAll: false, individualSpacesSharedTo: [] });
            return true;
        }} deleteButtonLabel={"Stop sharing"} deleteButtonBusyLabel={"Updating..."} onClose={onClose} renderContent={() => (<Callout type={"warning"} title={"This could affect deployment processes in spaces using this template."}>
                    Existing releases can still be run using this template, but it can't be included in new releases or deployment processes.
                </Callout>)}/>);
}
