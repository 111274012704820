import type { PropertyValueResource } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
export function useCommonTemplatesValueChangeTracker(): CommonTemplatesValueChangeTracker {
    const [changes, setChanges] = useState<Map<CommonTemplatesValueKey, PropertyValueResource | undefined>>(new Map());
    return {
        has: (key: CommonTemplatesValueKey) => changes.has(key),
        get: (key: CommonTemplatesValueKey) => {
            if (changes.has(key)) {
                return changes.get(key);
            }
            throw new Error(`Key "${key}" not found in changed project tenant variables`);
        },
        set: (key: CommonTemplatesValueKey, value: PropertyValueResource | undefined) => {
            const copy = new Map(changes);
            copy.set(key, value);
            setChanges(copy);
        },
        remove: (key: CommonTemplatesValueKey) => {
            const copy = new Map(changes);
            copy.delete(key);
            setChanges(copy);
        },
        reset: () => setChanges(new Map()),
        values: () => Array.from(changes.entries()).map(([key, value]) => {
            const { setId, templateId, tenantId, guid: id } = extractCommonTemplateValueIds(key);
            return {
                id,
                setId,
                templateId,
                tenantId,
                value,
            };
        }),
        hasAny: () => changes.size > 0,
        changedTenants: () => {
            const tenantIds: Set<string> = new Set();
            for (const variableKey of changes.keys()) {
                const { tenantId } = extractCommonTemplateValueIds(variableKey);
                tenantIds.add(tenantId);
            }
            return tenantIds;
        },
    };
}
export interface CommonTemplatesValueChangeTracker {
    has: (key: CommonTemplatesValueKey) => boolean;
    get: (key: CommonTemplatesValueKey) => PropertyValueResource | undefined; // To satisfy type check. Notice that PropertyValueResource can be undefined
    set: (key: CommonTemplatesValueKey, value: PropertyValueResource | undefined) => void;
    remove: (key: CommonTemplatesValueKey) => void;
    reset: () => void;
    values: () => ChangedCommonTemplatesValue[];
    hasAny: () => boolean;
    changedTenants: () => Set<string>;
}
export interface ChangedCommonTemplatesValue {
    id: string;
    setId: string;
    templateId: string;
    tenantId: string;
    value: PropertyValueResource | undefined;
}
export type CommonTemplatesValueKey = `${string};${string};${string};${string}`;
export function createCommonTemplatesValueKey(setId: string, templateId: string, tenantId: string, guid: string): CommonTemplatesValueKey {
    return `${setId};${templateId};${tenantId};${guid}`;
}
function extractCommonTemplateValueIds(key: CommonTemplatesValueKey): {
    setId: string;
    templateId: string;
    tenantId: string;
    guid: string;
} {
    const extracted = key.split(`;`);
    return {
        setId: extracted[0],
        templateId: extracted[1],
        tenantId: extracted[2],
        guid: extracted[3],
    };
}
