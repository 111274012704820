import { css, cx } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { PropsWithChildren } from "react";
import React from "react";
import { CustomDialog } from "~/components/Dialog/CustomDialog";
import CustomTransparentDialogFrame from "~/components/OnboardingDialog/CustomTransparentDialogFrame";
import type { HelpPanelImage, HelpPanelSize } from "~/components/OnboardingDialog/HelpPanel";
import { HelpPanel, HelpPanelToggle } from "~/components/OnboardingDialog/HelpPanel";
import useLocalStorage from "~/hooks/useLocalStorage";
export interface OnboardingDialogProps extends OnboardingDialogFrameProps {
    fullScreen?: boolean;
    open: boolean;
    close: (isCancel?: boolean) => void;
}
const OnboardingDialog = ({ children, open, close, ...frameProps }: PropsWithChildren<OnboardingDialogProps>) => (<CustomDialog open={open} close={close} render={() => (<OnboardingDialogFrame {...frameProps} onCloseDialog={close}>
                {children}
            </OnboardingDialogFrame>)}/>);
interface OnboardingDialogFrameProps {
    name: string;
    fullScreen?: boolean;
    contentPanelHeight?: 500 | 600;
    helpPanelContent?: React.ReactNode;
    helpPanelImage?: HelpPanelImage;
    helpPanelShowToggle?: boolean;
    helpPanelSize?: HelpPanelSize;
    helpPanelTheme?: "light" | "dark";
    onToggleHelp?: (open: boolean) => void;
    onCloseDialog?: () => void;
    // Do not use for new components
    useBackwardsCompatiblePadding?: boolean;
}
export const OnboardingDialogFrame = (props: PropsWithChildren<OnboardingDialogFrameProps>) => {
    const { children, helpPanelContent, helpPanelImage, fullScreen, onToggleHelp, onCloseDialog } = props;
    const shouldShowHelpPanelLocalStorageKey = `onboardingDialog.shouldShowHelpPanel.${props.name}`;
    const [showHelpPanel, setShowHelpPanel] = useLocalStorage(shouldShowHelpPanelLocalStorageKey, true);
    const helpPanelShowToggle = props.helpPanelShowToggle ?? true;
    const helpPanelSize = props.helpPanelSize ?? "third";
    const helpPanelTheme = props.helpPanelTheme ?? "dark";
    const contentPanelHeight = props.contentPanelHeight ?? 500;
    const useBackwardsCompatiblePadding = props.useBackwardsCompatiblePadding ?? false;
    const toggleHelpPanel = (show: boolean) => {
        onToggleHelp?.(show);
        setShowHelpPanel(show);
    };
    const closeDialog = () => {
        onCloseDialog?.();
    };
    const wrapperClassName = cx(styles.contentPanel, styles.contentPanelPadding(useBackwardsCompatiblePadding), styles.contentPanelHeight(contentPanelHeight), styles.contentPanelWidth(helpPanelSize));
    return !fullScreen ? (<CustomTransparentDialogFrame>
            <div className={wrapperClassName} style={showHelpPanel ? { borderRadius: "4px 0 0 4px" } : {}}>
                {children}
                <HelpPanelToggle open={showHelpPanel} showToggle={helpPanelShowToggle} onClick={() => toggleHelpPanel(true)}/>
            </div>

            <HelpPanel content={helpPanelContent} image={helpPanelImage} size={helpPanelSize} onClose={() => toggleHelpPanel(false)} showToggle={helpPanelShowToggle} open={showHelpPanel} theme={helpPanelTheme}/>
        </CustomTransparentDialogFrame>) : (<>{children}</>);
};
export default OnboardingDialog;
const styles = {
    contentPanel: css({
        display: "flex",
        flexDirection: "row",
        position: "relative",
        width: "100%",
        height: "100%",
        background: themeTokens.color.background.primary.default,
        borderRadius: "4px",
        margin: "auto",
        transition: "transform 1s",
        transform: "translateX(0)",
        zIndex: "10",
        boxShadow: themeTokens.shadow.large,
    }),
    contentPanelPadding: (useBackwardsCompatiblePadding: boolean) => css({ padding: useBackwardsCompatiblePadding ? `${space[12]} ${space[16]} ${space[32]} ${space[16]}` : 0 }),
    contentPanelHeight: (size: 500 | 600) => css({ minHeight: size }),
    contentPanelWidth: (size: HelpPanelSize) => {
        switch (size) {
            case "half":
                return css({
                    "@media (min-width: 600px)": { width: "75%" },
                    "@media (min-width: 700px)": { width: "65%" },
                    "@media (min-width: 800px)": { width: "55%" },
                    "@media (min-width: 900px)": { width: "50%" },
                });
            case "third":
                return css({
                    "@media (min-width: 600px)": { width: "90%" },
                    "@media (min-width: 700px)": { width: "80%" },
                    "@media (min-width: 800px)": { width: "70%" },
                    "@media (min-width: 900px)": { width: "60%" },
                });
        }
    },
};
