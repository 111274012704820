import { css, cx } from "@emotion/css";
import { LockOnIcon } from "@octopusdeploy/design-system-icons";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { PropertyValueResource, WorkerPoolResource } from "@octopusdeploy/octopus-server-client";
import { VariableType } from "@octopusdeploy/octopus-server-client";
import { isResourceBoundValue } from "@octopusdeploy/step-runtime-inputs";
import { noOp } from "@octopusdeploy/utilities";
import * as React from "react";
import type { LoadedSourceItems } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import MissingVariablesIcon from "~/areas/tenants/MissingVariablesIcon/MissingVariablesIcon";
import { isAccountType } from "~/areas/variables/isAccountType";
import { MissingChip } from "~/components/Chips/index";
import ReadonlyText from "~/components/ReadonlyText/index";
import convertPropertyValueResourceToString from "~/components/convertPropertyValueResourceToString";
import { ObfuscatedPlaceholder } from "~/components/form/Sensitive/Sensitive";
import { Text } from "~/components/form/index";
import { WorkerPoolIcon } from "~/primitiveComponents/dataDisplay/Icon/index";
interface ReadonlyInlineVariableValueProps {
    accounts: {
        [accountId: string]: string;
    };
    certificates: {
        [certificateId: string]: string;
    };
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    value: PropertyValueResource | undefined;
    defaultValue: PropertyValueResource | undefined;
    variableType: VariableType;
    hasEditPermissions: boolean;
    sourceItems?: LoadedSourceItems;
}
export default ({ value, variableType, defaultValue, accounts, certificates, workerPools, hasEditPermissions, sourceItems }: ReadonlyInlineVariableValueProps) => {
    const variableValue = convertPropertyValueResourceToString(value);
    const variableDefaultValue = convertPropertyValueResourceToString(defaultValue);
    if (isAccountType(variableType)) {
        return <AccountValue value={variableValue} defaultValue={variableDefaultValue} hasEditPermissions={hasEditPermissions} accounts={accounts} sourceItems={sourceItems}/>;
    }
    if (variableType === VariableType.Sensitive) {
        return <SensitiveValue value={variableValue} defaultValue={variableDefaultValue} hasEditPermissions={hasEditPermissions}/>;
    }
    if (variableType === VariableType.String) {
        return <StringValue value={variableValue} defaultValue={variableDefaultValue} hasEditPermissions={hasEditPermissions}/>;
    }
    if (variableType === VariableType.Certificate) {
        return <CertificateValue value={variableValue} defaultValue={variableDefaultValue} hasEditPermissions={hasEditPermissions} certificates={certificates} sourceItems={sourceItems}/>;
    }
    if (variableType === VariableType.WorkerPool) {
        return <WorkerPoolValue value={variableValue} defaultValue={variableDefaultValue} hasEditPermissions={hasEditPermissions} workerPools={workerPools} sourceItems={sourceItems}/>;
    }
    return null;
};
interface AccountValueProps {
    value: string;
    defaultValue: string;
    hasEditPermissions: boolean;
    accounts: {
        [accountId: string]: string;
    };
    sourceItems?: LoadedSourceItems;
}
function AccountValue({ value, defaultValue, hasEditPermissions, accounts, sourceItems }: AccountValueProps) {
    const [displayValue, isDefaultValue] = value ? [value, false] : [defaultValue, true];
    const styleClasses = isDefaultValue ? [styles.container, styles.defaultValue] : [styles.container];
    if (isResourceBoundValue(displayValue)) {
        return (<div className={cx(styleClasses)}>
                <ValueField text={displayValue} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
            </div>);
    }
    if (!displayValue) {
        return (<div className={cx(styleClasses)}>
                <ValueField text={displayValue} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
            </div>);
    }
    const getAccountName = (id: string) => accounts[id] || (sourceItems && sourceItems.accounts.items.find((c) => c.Id === id))?.Name;
    const accountName = getAccountName(displayValue);
    if (!accountName) {
        return <MissingChip lookupId={displayValue}/>;
    }
    return (<div className={cx(styleClasses)}>
            <ValueField text={accountName} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
        </div>);
}
interface CertificateValueProps {
    value: string;
    defaultValue: string;
    hasEditPermissions: boolean;
    certificates: {
        [certificateId: string]: string;
    };
    sourceItems?: LoadedSourceItems;
}
function CertificateValue({ value, defaultValue, hasEditPermissions, certificates, sourceItems }: CertificateValueProps) {
    const [displayValue, isDefaultValue] = value ? [value, false] : [defaultValue, true];
    const styleClasses = isDefaultValue ? [styles.container, styles.defaultValue] : [styles.container];
    if (isResourceBoundValue(displayValue)) {
        return (<div className={cx(styleClasses)}>
                <ValueField text={displayValue} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
            </div>);
    }
    if (!displayValue) {
        return (<div className={cx(styleClasses)}>
                <ValueField text={displayValue} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
            </div>);
    }
    const getCertificateName = (id: string) => certificates[id] || (sourceItems && sourceItems.certificates.loadedItems.find((c) => c.Id === id))?.Name;
    const certificateName = getCertificateName(displayValue);
    if (!certificateName) {
        return <MissingChip lookupId={displayValue}/>;
    }
    return (<div className={cx(styleClasses)}>
            <LockOnIcon size={24} color={themeTokens.color.icon.secondary}/>
            <ValueField text={certificateName} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
        </div>);
}
interface WorkerPoolValueProps {
    value: string;
    defaultValue: string;
    hasEditPermissions: boolean;
    workerPools: {
        [workerPoolId: string]: WorkerPoolResource;
    };
    sourceItems?: LoadedSourceItems;
}
function WorkerPoolValue({ value, defaultValue, hasEditPermissions, workerPools, sourceItems }: WorkerPoolValueProps) {
    const [displayValue, isDefaultValue] = value ? [value, false] : [defaultValue, true];
    const styleClasses = isDefaultValue ? [styles.container, styles.defaultValue] : [styles.container];
    if (isResourceBoundValue(displayValue)) {
        return (<div className={cx(styleClasses)}>
                <ValueField text={displayValue} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
            </div>);
    }
    if (!displayValue) {
        return (<div className={cx(styleClasses)}>
                <ValueField text={displayValue} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
            </div>);
    }
    const getWorkerPool = (id: string) => workerPools[id] || (sourceItems && sourceItems.workerPools.loadedItems.find((w) => w.Id === id));
    const workerPool = getWorkerPool(displayValue);
    if (!workerPool) {
        return <MissingChip lookupId={displayValue}/>;
    }
    return (<div className={cx(styleClasses)}>
            <span style={{ color: themeTokens.color.icon.secondary }}>
                <WorkerPoolIcon color={"inherit"}/>
            </span>
            <ValueField text={workerPool.Name} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
        </div>);
}
interface SensitiveValueProps {
    value: string;
    defaultValue: string;
    hasEditPermissions: boolean;
}
function SensitiveValue({ value, defaultValue, hasEditPermissions }: SensitiveValueProps) {
    const [displayValue, isDefaultValue] = value ? [ObfuscatedPlaceholder, false] : [defaultValue ? ObfuscatedPlaceholder : "", true];
    const styleClasses = isDefaultValue ? [styles.container, styles.defaultValue] : [styles.container];
    return (<div className={cx(styleClasses)}>
            <ValueField text={displayValue} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
        </div>);
}
interface StringValueProps {
    value: string;
    defaultValue: string;
    hasEditPermissions: boolean;
}
function StringValue({ value, defaultValue, hasEditPermissions }: StringValueProps) {
    const [displayValue, isDefaultValue] = value ? [value, false] : [defaultValue, true];
    const styleClasses = isDefaultValue ? [styles.container, styles.defaultValue] : [styles.container];
    return (<div className={cx(styleClasses)}>
            <ValueField text={displayValue} isDefaultValue={isDefaultValue} hasEditPermissions={hasEditPermissions}/>
        </div>);
}
interface ValueFieldProps {
    text: string;
    isDefaultValue: boolean;
    hasEditPermissions: boolean;
}
function ValueField({ text, isDefaultValue, hasEditPermissions }: ValueFieldProps) {
    if (isDefaultValue && !text) {
        return (<div className={styles.missingValueContainer}>
                <MissingVariablesIcon show={true}/>
                {hasEditPermissions ? <Text value={""} placeholder={"Missing value"} onChange={noOp} hideUnderline={true} monoSpacedFont={true}/> : <ReadonlyText text={"Missing value"} className={styles.missingValue} monoSpacedFont={true}/>}
            </div>);
    }
    const [value, placeholder] = isDefaultValue ? ["", text] : [text, ""];
    return hasEditPermissions ? <Text value={value} placeholder={placeholder} onChange={noOp} hideUnderline={true} showValueAsTitleAttribute={true} monoSpacedFont={true}/> : <ReadonlyText text={text} monoSpacedFont={true}/>;
}
const styles = {
    container: css({
        flexGrow: "1",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: space[6],
    }),
    defaultValue: css({
        color: themeTokens.color.icon.secondary,
    }),
    missingValue: css({
        "&&": { color: themeTokens.color.icon.secondary },
    }),
    missingValueContainer: css({
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: space[8],
    }),
};
