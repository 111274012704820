/* eslint-disable @octopusdeploy/custom-portal-rules/no-restricted-imports */
import { Grid, Dialog } from "@material-ui/core";
import { ActionButton, ActionButtonType } from "@octopusdeploy/design-system-components";
import type { ProjectResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { CommitMessageWithDetails } from "~/areas/projects/components/VersionControl/CommitMessageWithDetails";
import type { Errors } from "~/components/DataBaseComponent";
import { DialogLayout } from "~/components/DialogLayout/DialogLayout";
import KeyboardHandler, { Key } from "~/components/KeyboardHandler";
import { useOctopusFeatureToggle } from "~/hooks/useOctopusFeatureToggle";
import type { CommitSummaryAndDetailsProps } from "../../VersionControl/CommitDialog";
import { CommitSummaryAndDetails } from "../../VersionControl/CommitDialog";
import styles from "./style.module.less";
interface CommitDialogProps {
    open: boolean;
    defaultSummary: string;
    commitMessage: CommitMessageWithDetails;
    setCommitMessage: (commitMessage: CommitMessageWithDetails) => void;
    errors?: Errors;
    project: ProjectResource;
    onCancel?: () => void;
    onClose: () => void;
    onNext: () => Promise<boolean | undefined>;
}
const CommitMessageStep: React.FC<CommitSummaryAndDetailsProps> = (props) => {
    return <CommitSummaryAndDetails {...props}/>;
};
CommitMessageStep.displayName = "CommitMessageStep"
// TODO: Frontend and UX review - Once our functional equivalents are in place for DataBaseComponent, let's
// review this CommitDialog and see if we can get busy indicators showing here also.
const CommitDialog: React.FC<CommitDialogProps> = ({ open, commitMessage, defaultSummary, setCommitMessage, errors, project, onCancel, onClose, onNext }) => {
    const commitChanges = async () => {
        onClose();
        await onNext();
    };
    const close = () => {
        if (onCancel) {
            onCancel();
        }
        onClose();
    };
    const actions = [<ActionButton key="Cancel" label="Cancel" title="Cancel" onClick={close}/>, <ActionButton key="Commit" label="Commit" title="Commit" onClick={commitChanges} type={ActionButtonType.Save} disabled={!commitMessage}/>];
    const onEnter = (event: KeyboardEvent): boolean => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target: any = event.target ? event.target : event.srcElement;
        const tagName = target.tagName;
        if (tagName.toUpperCase() === "INPUT") {
            commitChanges();
            return true;
        }
        return false;
    };
    const onCtrlEnter = (event: KeyboardEvent) => {
        commitChanges();
        return true;
    };
    const keyboardRegistrations = [
        { key: Key.Enter, onKeyPressed: onEnter },
        { key: Key.CtrlEnter, onKeyPressed: onCtrlEnter },
    ];
    const gitRunbooksMigrationEnabled = useOctopusFeatureToggle("git-runbooks-migration", false);
    const gitRunbooksConversionMigrationEnabled = useOctopusFeatureToggle("git-runbooks-migrate-in-project-migration", true);
    const conversionNote = gitRunbooksMigrationEnabled && gitRunbooksConversionMigrationEnabled ? (<p>
                You’re about to commit the <b>{project.Name}</b> project deployment settings, process, non-sensitive variables, and Runbooks to the default branch of your repository.
            </p>) : (<p>
                You’re about to commit the <b>{project.Name}</b> project deployment settings, process and non-sensitive variables to the default branch of your repository.
            </p>);
    return (<Dialog open={open} fullWidth>
            <KeyboardHandler registrations={keyboardRegistrations}>
                <DialogLayout actions={actions} title={"Convert project to Version Control"} closeDialog={close}>
                    {open && (<Grid container direction="column" className={styles.commitDialog}>
                            <p>
                                <b>This process can’t be reversed.</b>
                            </p>
                            {conversionNote}
                            <CommitMessageStep commitMessage={commitMessage} defaultSummary={defaultSummary} onCommitMessageChanged={(value: CommitMessageWithDetails) => setCommitMessage(value)}/>
                        </Grid>)}
                </DialogLayout>
            </KeyboardHandler>
        </Dialog>);
};
CommitDialog.displayName = "CommitDialog"
export default CommitDialog;
