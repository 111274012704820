/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMutation } from "@octopusdeploy/octopus-react-client";
import type { GitCredentialResource, Repository } from "@octopusdeploy/octopus-server-client";
import { GitCredentialAuthenticationType, Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import { GitCredentialPopover } from "~/areas/library/components/GitCredentials/GitCredentialPage";
import Markdown from "~/components/Markdown";
import { useSpaceAwareNavigation } from "~/components/Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { OverflowMenuItems } from "~/components/OverflowMenu/OverflowMenu";
import { OverflowMenuConverterVNext } from "~/components/OverflowMenu/OverflowMenuConverterVNext";
import { PageContent } from "~/components/PageContent/PageContent";
import { Text, ExpandableFormSection, Summary, required, MarkdownEditor, Sensitive, Note } from "~/components/form";
import { Form, useForm } from "~/components/form/Form/Form";
interface PlatformHubGitCredentialPageProps {
    loaderData: PlatformHubGitCredentialLoaderData;
    isCreate: boolean;
}
interface PlatformHubGitCredentialLoaderData {
    gitCredential: GitCredentialResource;
}
function getEmptyModel(): GitCredentialResource {
    return {
        Id: null!,
        SpaceId: "",
        Name: "",
        Description: "",
        Details: {
            Type: GitCredentialAuthenticationType.UsernamePassword,
            Username: null!,
            Password: null!,
        },
        Links: null!,
    };
}
export async function platformHubGitCredentialLoader(repository: Repository, gitCredentialId: string | null): Promise<PlatformHubGitCredentialLoaderData> {
    if (gitCredentialId) {
        return { gitCredential: await repository.PlatformHubRepository.getGitCredentialById(gitCredentialId) };
    }
    else {
        return { gitCredential: getEmptyModel() };
    }
}
export function PlatformHubGitCredentialPage({ loaderData, isCreate }: PlatformHubGitCredentialPageProps) {
    const { navigate } = useSpaceAwareNavigation();
    const { model, setModel, formProps, createSaveAction, getFieldError, isSubmitting } = useForm({
        initialModel: loaderData.gitCredential,
        formName: "Save Platform Hub Git Credential",
        onSubmit: async (repository: Repository, model) => handleSaveAction(repository, model),
        afterSubmit: async (repository, updatedModel) => {
            if (isCreate)
                return navigate(links.editPlatformHubGitCredentialPage.generateUrl({ gitCredentialId: updatedModel.Id }));
        },
        submitPermission: { permission: Permission.PlatformHubEdit },
    });
    const title = isCreate ? "Create Git Credential" : model.Name;
    const descriptionSummary = () => (model.Description ? Summary.summary(<Markdown markup={model.Description}/>) : Summary.placeholder("No credential description provided"));
    const credentialSummary = () => Summary.summary("Username and password");
    const handleSaveAction = async (repository: Repository, gitCredentialResource: GitCredentialResource) => {
        if (isCreate)
            return await repository.PlatformHubRepository.createGitCredential(gitCredentialResource);
        return await repository.PlatformHubRepository.modifyGitCredential(gitCredentialResource);
    };
    const { execute: deleteGitCredential } = useMutation({
        name: "deleteGitCredential",
        action: async (repository: Repository, gitCredentialResource: GitCredentialResource) => await repository.PlatformHubRepository.deleteGitCredential(gitCredentialResource),
        afterComplete: async () => navigate(links.platformHubGitCredentialsPage.generateUrl()),
    });
    const overflowActions = isCreate
        ? OverflowMenuConverterVNext.convertAll([])
        : OverflowMenuConverterVNext.convertAll([OverflowMenuItems.deleteItemDefault("Git credential", () => deleteGitCredential(model), { permission: Permission.PlatformHubEdit })]);
    return (<PageContent header={{
            primaryAction: createSaveAction({}),
            title,
            breadcrumbs: [{ label: "Git Credentials", pageUrl: links.platformHubGitCredentialsPage.generateUrl() }],
            overflowActions: overflowActions.menuItems,
        }}>
            {overflowActions.dialogs}
            <Form {...formProps} expandAllOnMount={isCreate}>
                <ExpandableFormSection errorKey="Name" title="Name" focusOnExpandAll summary={model.Name ? Summary.summary(model.Name) : Summary.placeholder("Enter a name for your Git credential")} help="Enter a name for your Git credential">
                    <Text value={model.Name} onChange={(Name) => setModel({ ...model, Name })} label="Git credential name" validate={required("Enter a Git credential name")} error={getFieldError("Name")} autoFocus/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="description" title="Description" summary={descriptionSummary()} help="Enter a description for your Git credential">
                    <MarkdownEditor value={model.Description} label="Git credential description" onChange={(Description) => setModel({ ...model, Description })}/>
                </ExpandableFormSection>
                <ExpandableFormSection errorKey="details" title="Credentials" summary={credentialSummary()} help="Enter your Git credential details">
                    <div>
                        We recommend using a personal access token with limited privilege <GitCredentialPopover />
                    </div>
                    <Text key="Username" value={model.Details.Username} onChange={(Username) => setModel({ ...model, Details: { ...model.Details, Username } })} label="Username" error={getFieldError("Username")} validate={required("Enter authentication details.")} disabled={isSubmitting}/>
                    <Sensitive key="Password" value={model.Details.Password} onChange={(Password) => setModel({ ...model, Details: { ...model.Details, Password } })} label="Personal Access Token or Password" error={getFieldError("Password")} disabled={isSubmitting}/>
                    <Note>
                        GitLab and GitHub <b>require</b> token-based authentication (excludes Github Enterprise Server).
                    </Note>
                </ExpandableFormSection>
            </Form>
        </PageContent>);
}
