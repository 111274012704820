/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Callout } from "@octopusdeploy/design-system-components";
import type { ActionsUpdateProcessResource, ActionTemplateResource, ActionTemplateUsageResource, ActionUpdateResultResource, ProjectSummaryResource, Repository } from "@octopusdeploy/octopus-server-client";
import { ActionUpdateOutcome } from "@octopusdeploy/octopus-server-client";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import { debounce, groupBy, orderBy, reduce } from "lodash";
import * as React from "react";
import { repository } from "~/clientInstance";
import ConfirmationDialog from "~/components/Dialog/ConfirmationDialog";
import DialogOpener from "~/components/Dialog/DialogOpener";
import type { OptionalFormBaseComponentState } from "~/components/FormBaseComponent";
import FormBaseComponent from "~/components/FormBaseComponent";
import InternalRedirect from "~/components/Navigation/InternalRedirect/InternalRedirect";
import { PageContent } from "~/components/PageContent/PageContent";
import { Section } from "~/components/Section/Section";
import type { ActionTemplateUsageVersionControlledFilter } from "./ActionTemplateUsageTable";
import { ActionTemplateUsageTable } from "./ActionTemplateUsageTable";
import MergeConflictResolutionDialog from "./MergeConflictResolutionDialog";
interface ActionTemplateUsagePageState extends OptionalFormBaseComponentState<ActionTemplateResource> {
    redirectTo: LinkHref;
    usages: ActionTemplateUsageResource[];
    pendingUpdates: number;
    showSaveAs: boolean;
    mergeDetails: {
        usages: ActionTemplateUsageResource[];
        mergeResults: ActionUpdateResultResource[];
    };
    showConfirmation: boolean;
    vcsUsagesExist: boolean;
}
interface ActionTemplateUsagePageProps {
    spaceId: string;
    templateId: string;
    loaderData: ActionTemplateUsagePageLoaderData;
    onActionTemplateUpdated: () => void;
}
export async function actionTemplateUsagePageLoader(repository: Repository, templateId: string): Promise<ActionTemplateUsagePageLoaderData> {
    const actionTemplate = await repository.ActionTemplates.get(templateId);
    const actionTemplateUsages = orderBy(await repository.ActionTemplates.getUsage(actionTemplate), [(x) => x.ProjectName, (x) => x.StepName]);
    const vcsProjects = repository.Projects.summariesVersionControlled();
    const projects = repository.Projects.summaries();
    return {
        actionTemplate,
        actionTemplateUsages,
        vcsProjects: await vcsProjects,
        projects: await projects,
    };
}
export const actionTemplateUsagePageTitle = "Usage";
export interface ActionTemplateUsagePageLoaderData {
    actionTemplate: ActionTemplateResource;
    actionTemplateUsages: ActionTemplateUsageResource[];
    vcsProjects: ProjectSummaryResource[];
    projects: ProjectSummaryResource[];
}
export class ActionTemplateUsagePage extends FormBaseComponent<ActionTemplateUsagePageProps, ActionTemplateUsagePageState, ActionTemplateResource> {
    constructor(props: ActionTemplateUsagePageProps) {
        super(props);
        const { actionTemplate, actionTemplateUsages } = props.loaderData;
        this.state = {
            redirectTo: null!,
            usages: actionTemplateUsages,
            pendingUpdates: actionTemplateUsages.filter((u) => `${u.Version}` !== `${actionTemplate.Version}`).length,
            showSaveAs: false,
            mergeDetails: null!,
            showConfirmation: false,
            vcsUsagesExist: actionTemplateUsages.some((u) => u.Branch),
            model: actionTemplate,
        };
    }
    private getUsages = async (template: Partial<ActionTemplateResource>, args?: unknown) => orderBy(await repository.ActionTemplates.getUsage(template, args), [(x) => x.ProjectName, (x) => x.StepName]);
    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={false}/>;
        }
        const filter = debounce(async (f: ActionTemplateUsageVersionControlledFilter) => {
            if (this.state?.model) {
                const usages = await this.getUsages(this.state?.model, f);
                this.setState({ ...this.state, ...{ usages } });
            }
        }, 800);
        return (<PageContent header={{ title: actionTemplateUsagePageTitle }} busy={this.state.busy} errors={this.errors}>
                <div>
                    <DialogOpener open={!!this.state.mergeDetails} onClose={() => this.load()} wideDialog={true}>
                        {this.state.mergeDetails && <MergeConflictResolutionDialog usages={this.state.mergeDetails.usages} mergeResults={this.state.mergeDetails.mergeResults} actionTemplate={this.state.model!}/>}
                    </DialogOpener>
                    <Section>
                        <p>
                            Current version: <b>{this.state.model!.Version}</b>
                        </p>
                        {this.state.pendingUpdates > 0 && (<Callout title={"Updates available"} type={"information"}>
                                {this.state.pendingUpdates} step{this.state.pendingUpdates === 1 ? "" : "s"} may be using an old version of this template. Consider updating to get the latest changes.
                            </Callout>)}
                        <p>
                            {this.state.usages.length > 0 ? (<span>This template is in use by the following projects:</span>) : (<span>
                                    This template is not used by any projects.{" "}
                                    {this.props.loaderData.vcsProjects.length ? "Note: For version controlled projects, step template usages are only shown for branches that have releases created from them." : ""}
                                </span>)}
                        </p>
                    </Section>
                    <ConfirmationDialog title="Update all usages" continueButtonLabel="Update all" open={this.state.showConfirmation} onClose={() => this.setState({ showConfirmation: false })} onContinueClick={async () => this.handleUpdateAll(this.state.usages)}>
                        <p>Are you sure that you want to update all usages of this template?</p>
                    </ConfirmationDialog>
                    <ActionTemplateUsageTable spaceId={this.state.model!.SpaceId} templateVersion={this.state.model!.Version} usages={this.state.usages} onUpdateAction={this.handleUpdateAction} onUpdateAll={this.confirmUpdateAll} onFilterUsages={filter} showVcsUsages={this.state.vcsUsagesExist} projects={this.props.loaderData.projects} vcsProjects={this.props.loaderData.vcsProjects}/>
                </div>
            </PageContent>);
    }
    private async getExistingTemplate(): Promise<ActionTemplateResource> {
        return repository.ActionTemplates.get(this.props.templateId);
    }
    private handleUpdateAction = async (usage: ActionTemplateUsageResource) => {
        const updates: ActionsUpdateProcessResource[] = [{ ProcessId: usage.ProcessId, ProcessType: usage.ProcessType, ProjectId: usage.ProjectId, ActionIds: [usage.ActionId] }];
        return this.updateActions(this.state.model!, updates, [usage]);
    };
    private confirmUpdateAll = async () => {
        this.setState({ showConfirmation: true });
    };
    private handleUpdateAll = async (usages: ActionTemplateUsageResource[]) => {
        this.setState({ showConfirmation: false });
        const actionTemplateVersion = this.state.model!.Version.toString();
        const usagesToUpdate = usages.filter((usage) => usage.Version !== actionTemplateVersion && !usage.Branch);
        const usagesByProcessId = groupBy(usagesToUpdate, (x) => x.ProcessId);
        const initialUpdateValue: ActionsUpdateProcessResource = null!;
        const updates = Object.keys(usagesByProcessId).reduce((prev: ActionsUpdateProcessResource[], processId) => {
            return [
                ...prev,
                reduce(usagesByProcessId[processId], (update, usage) => {
                    return !update ? { ProcessType: usage.ProcessType, ProcessId: usage.ProcessId, ProjectId: usage.ProjectId, ActionIds: [usage.ActionId] } : { ...update, ActionIds: [...update.ActionIds, usage.ActionId] };
                }, initialUpdateValue),
            ];
        }, []);
        return this.bulkUpdateActions(this.state.model!, updates, usagesToUpdate);
    };
    private updateActions = async (actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], usagesToUpdate: ActionTemplateUsageResource[]) => {
        await this.doBusyTask(async () => {
            try {
                await repository.ActionTemplates.updateActions(actionTemplate, actionsToUpdate);
                await this.load();
            }
            catch (error) {
                if (error.StatusCode !== 400) {
                    throw error;
                }
                this.resolveMergeConflicts(usagesToUpdate, error.Details);
            }
        });
    };
    private bulkUpdateActions = async (actionTemplate: Partial<ActionTemplateResource>, actionsToUpdate: ActionsUpdateProcessResource[], usagesToUpdate: ActionTemplateUsageResource[]) => {
        await this.doBusyTask(async () => {
            const response = await repository.ActionTemplates.bulkUpdateActions(actionTemplate, actionsToUpdate);
            if (response.Outcome === ActionUpdateOutcome.Success && response.TaskId !== undefined) {
                this.setState({ redirectTo: links.taskPage.generateUrl({ taskId: response.TaskId }) });
                return;
            }
            if (response.Outcome !== ActionUpdateOutcome.Success) {
                this.resolveMergeConflicts(usagesToUpdate, response.Results);
            }
        });
    };
    private resolveMergeConflicts(usagesToUpdate: ActionTemplateUsageResource[], mergeResults: ActionUpdateResultResource[]) {
        this.setState({
            mergeDetails: { usages: usagesToUpdate, mergeResults },
        });
    }
    private load = async () => {
        await this.doBusyTask(async () => {
            const model = this.state.model ?? (await this.getExistingTemplate());
            const usages = await this.getUsages(model, {});
            const pendingUpdates = usages.filter((u) => `${u.Version}` !== `${model.Version}`).length;
            this.props.onActionTemplateUpdated();
            this.setState({ ...this.state, usages, pendingUpdates, model, mergeDetails: null! });
        });
    };
    static displayName = "ActionTemplateUsagePage";
}
