import { ExclamationCircleIcon, GhostIcon, HourglassIcon, PauseIcon } from "@octopusdeploy/design-system-icons";
import type { KubernetesLiveApplicationStatusCode } from "@octopusdeploy/octopus-server-client/dist/src/resources/kubernetesLiveApplicationInstanceStatusCode";
import type { KubernetesLiveResourceStatusCode } from "@octopusdeploy/octopus-server-client/src/resources/kubernetesLiveResourceStatusCode";
import * as React from "react";
import { DegradedIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/DegradedIcon";
import { HealthyIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/HealthyIcon";
import { InSyncIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/InSyncIcon";
import { OutOfSyncIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/OutOfSyncIcon";
import { ProgressingIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/ProgressingIcon";
import { UnknownIcon } from "~/areas/projects/components/Observability/LiveStatusIcons/UnknownIcon";
const iconSize = 20;
export function getLiveResourceStatusIcon(status: KubernetesLiveResourceStatusCode): React.ReactElement {
    switch (status) {
        case "Degraded":
            return <DegradedIcon />;
        case "Healthy":
            return <HealthyIcon />;
        case "InSync":
            return <InSyncIcon />;
        case "OutOfSync":
            return <OutOfSyncIcon />;
        case "Progressing":
            return <ProgressingIcon />;
        case "Unknown":
            return <UnknownIcon />;
        case "Missing":
            return <GhostIcon size={iconSize}/>;
        case "Suspended":
            return <PauseIcon size={iconSize}/>;
        default:
            return <UnknownIcon />;
    }
}
export function getLiveMachineStatusIcon(status: KubernetesLiveApplicationStatusCode) {
    switch (status) {
        case "Healthy":
            return <HealthyIcon />;
        case "Progressing":
            return <ProgressingIcon />;
        case "OutOfSync":
            return <OutOfSyncIcon />;
        case "Missing":
            return <GhostIcon size={iconSize}/>;
        case "Degraded":
            return <DegradedIcon />;
        case "Waiting":
            return <HourglassIcon size={iconSize}/>;
        case "Unavailable":
            return <ExclamationCircleIcon size={iconSize}/>;
        case "Unknown":
        case "NotSupported":
        default:
            return <UnknownIcon />;
    }
}
