import { TaskState } from "@octopusdeploy/octopus-server-client";
const failedTaskStates = [TaskState.Failed, TaskState.Canceled, TaskState.TimedOut];
const progressingTaskStates = [TaskState.Executing, TaskState.Cancelling];
export function liveStatusSummaryText(deploymentStatus: TaskState, liveStatus: string): string {
    if (failedTaskStates.includes(deploymentStatus)) {
        return "Overall live status is unavailable because your last deployment failed.";
    }
    if (progressingTaskStates.includes(deploymentStatus)) {
        return "Overall live status will be available once the deployment completes.";
    }
    switch (liveStatus) {
        case "OutOfSync":
            return "The objects on your cluster no longer match what you last deployed.";
        case "Progressing":
            return "Objects in your application are currently in a progressing state.";
        case "Degraded":
            return "Your objects experienced errors after the deployment completed.";
        case "Missing":
            return "Objects in your application are currently in a missing state.";
        case "Healthy":
            return "The objects in your cluster match what was specified in the last deployment.";
        case "Unknown":
        default:
            return "We\u2019re having trouble getting live status updates for this application.";
    }
}
