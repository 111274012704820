/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PageAction, PrimaryPageAction } from "@octopusdeploy/design-system-components";
import type { DeploymentResource, EnvironmentResource, LifecycleProgressionResource, ProjectResource, ReleaseResource, ResourcesById, TaskResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import * as React from "react";
import type * as Analytics from "~/analytics/Analytics";
import { ProjectAnalyticView, useProjectScopedAnalyticTrackedActionDispatch } from "~/analytics/Analytics";
import { DeployButton } from "~/areas/projects/components/Releases/DeployButton/DeployButton";
import { useProjectContext } from "~/areas/projects/context/ProjectContext";
import { PageContent } from "~/components/PageContent/PageContent";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";
import { repository } from "../../../../../clientInstance";
import type { DataBaseComponentState } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import { DataBaseComponent } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import Task from "../../../../tasks/components/Task/Task";
import { DeploymentTimelineDrawer } from "./DeploymentTimelineDrawer";
interface DeploymentDetailsState extends DataBaseComponentState {
    deployment?: DeploymentResource;
    release?: ReleaseResource;
    progression?: LifecycleProgressionResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
    timelineVisible: boolean;
}
interface DeploymentDetailsPageInternalProps extends DeploymentDetailsPageProps {
    trackAction: Analytics.AnalyticTrackedActionDispatcher;
    project: ProjectResource;
}
class DeploymentDetailsPageInternal extends DataBaseComponent<DeploymentDetailsPageInternalProps, DeploymentDetailsState> {
    constructor(props: DeploymentDetailsPageInternalProps) {
        super(props);
        this.state = {
            timelineVisible: false,
        };
    }
    async componentDidMount() {
        await this.doBusyTask(async () => {
            const deployment = await repository.Deployments.get(this.props.deploymentId);
            const release = await repository.Releases.get(deployment.ReleaseId);
            this.setState({ deployment, release });
        });
    }
    toggleDrawer = () => {
        this.setState({ timelineVisible: !this.state.timelineVisible });
    };
    getAdditionPageActions = (): PageAction[] => {
        const showTimelineAction: PageAction = {
            buttonType: "secondary",
            label: "Timeline",
            type: "button",
            accessibleName: "Timeline",
            onClick: this.toggleDrawer,
        };
        return [showTimelineAction];
    };
    getPrimaryPageAction = (task: TaskResource<any>): PrimaryPageAction | undefined => {
        const successful = task.FinishedSuccessfully ?? false;
        if (successful && this.state.progression && this.state.progression.NextDeployments && this.state.progression.NextDeployments.length > 0) {
            return {
                type: "custom",
                key: "Deploy",
                content: (<DeployButton spaceId={this.props.project.SpaceId} releaseVersion={this.state.release!.Version} projectSlug={this.props.project.Slug} projectId={this.props.project.Id} nextDeployments={this.state.progression.NextDeployments} environmentsById={this.state.environmentsById!} tenantedDeploymentMode={this.props.project.TenantedDeploymentMode}/>),
            };
        }
        if (task.IsCompleted) {
            const tryAgainLink = links.createDeploymentPage.generateUrl({ spaceId: this.props.project.SpaceId, projectSlug: this.props.project.Slug, releaseVersion: this.state.release!.Version }, { previousDeploymentId: this.state.deployment!.Id });
            return {
                type: "navigate",
                label: !successful ? "Try again..." : "Re-deploy...",
                path: tryAgainLink,
                hasPermissions: isAllowed({ permission: Permission.DeploymentCreate, project: this.state.deployment!.ProjectId, environment: this.state.deployment!.EnvironmentId, tenant: "*" }),
            };
        }
    };
    additionalRefresh = async (task: TaskResource<any>) => {
        if (task.FinishedSuccessfully && !this.state.progression) {
            const progression = repository.Releases.progression(this.state.release!);
            const environmentsById = repository.Environments.allById();
            this.setState({ progression: await progression, environmentsById: await environmentsById });
        }
    };
    render() {
        const deployment = this.state.deployment;
        if (deployment) {
            return (<>
                    <ProjectAnalyticView name="View a Deployment" resource="Deploy Release" projectId={deployment.ProjectId}/>
                    <Task taskId={deployment.TaskId} delayRender={() => false} projectId={this.props.project.Id} environmentId={deployment.EnvironmentId} tenantId={deployment.TenantId ?? undefined} getPrimaryPageAction={this.getPrimaryPageAction} getAdditionalPageActions={this.getAdditionPageActions} additionalRefresh={this.additionalRefresh}/>
                    <DeploymentTimelineDrawer deploymentName={this.props.project.Name} environmentId={deployment.EnvironmentId} projectId={this.props.project.Id} tenantId={deployment.TenantId ?? undefined} spaceId={this.props.project.SpaceId} isOpen={this.state.timelineVisible} onClose={this.toggleDrawer}/>
                </>);
        }
        return (<PageContent busy={this.state.busy} header={{ title: "Task" }} errors={this.errors}>
                {null}
            </PageContent>);
    }
    static displayName = "DeploymentDetailsPageInternal";
}
interface DeploymentDetailsPageProps {
    deploymentId: string;
}
export function DeploymentDetailsPage(props: DeploymentDetailsPageProps) {
    const projectContext = useProjectContext();
    const trackAction = useProjectScopedAnalyticTrackedActionDispatch(projectContext.state.model.Id);
    return <DeploymentDetailsPageInternal {...props} project={projectContext.state.model} trackAction={trackAction}/>;
}
