import { Level2OuterPageLayout } from "@octopusdeploy/design-system-components";
import { Permission } from "@octopusdeploy/octopus-server-client";
import { links } from "@octopusdeploy/portal-routes";
import { compact } from "lodash";
import * as React from "react";
import { NoPermissionsPage } from "~/components/NoPermissionsPage/NoPermissionsPage";
import { hasPermission } from "~/components/PermissionCheck/PermissionCheck";
import { useCreatePageSideNavLink } from "~/hooks/useCreatePageSideNavLink";
export function PlatformHubLayout({ children }: React.PropsWithChildren<{}>) {
    const createPageSideNavLink = useCreatePageSideNavLink();
    if (!hasPermission(Permission.PlatformHubView)) {
        return <NoPermissionsPage />;
    }
    const navItems = compact([
        createPageSideNavLink("Templates", links.processTemplatesPage.generateUrl(), true),
        createPageSideNavLink("Version Control Settings", links.platformHubConnectionPage.generateUrl(), true),
        "divider",
        createPageSideNavLink("Accounts", links.platformHubAccountsPage.generateUrl(), false),
        createPageSideNavLink("Git Credentials", links.platformHubGitCredentialsPage.generateUrl(), false),
    ]);
    return (<main>
            <Level2OuterPageLayout navItems={navItems} header={{ title: "Platform Hub" }}>
                {children}
            </Level2OuterPageLayout>
        </main>);
}
