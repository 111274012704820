import { useState } from "react";
export function useScopedCommonTemplatesScopeChangeTracker(): ScopedCommonTemplatesScopeChangeTracker {
    const [changes, setChanges] = useState<Map<ScopedCommonTemplatesScopeKey, string[] | undefined>>(new Map());
    return {
        has: (key: ScopedCommonTemplatesScopeKey) => changes.has(key),
        get: (key: ScopedCommonTemplatesScopeKey) => {
            if (changes.has(key)) {
                return changes.get(key);
            }
            throw new Error(`Key "${key}" not found in changed project tenant variables in scoped common templates value scope tracker.`);
        },
        set: (key: ScopedCommonTemplatesScopeKey, value: string[] | undefined) => {
            const copy = new Map(changes);
            copy.set(key, value);
            setChanges(copy);
        },
        remove: (key: ScopedCommonTemplatesScopeKey) => {
            const copy = new Map(changes);
            copy.delete(key);
            setChanges(copy);
        },
        reset: () => setChanges(new Map()),
        values: () => Array.from(changes.entries()).map(([key, value]) => {
            const { setId, templateId, tenantId, guid } = extractCommonTemplateValueIds(key);
            return {
                setId,
                templateId,
                tenantId,
                guid,
                environmentIds: value || undefined,
            };
        }),
        hasAny: () => changes.size > 0,
        changedTenants: () => {
            const tenantIds: Set<string> = new Set();
            for (const variableKey of changes.keys()) {
                const { tenantId } = extractCommonTemplateValueIds(variableKey);
                tenantIds.add(tenantId);
            }
            return tenantIds;
        },
    };
}
export interface ScopedCommonTemplatesScopeChangeTracker {
    has: (key: ScopedCommonTemplatesScopeKey) => boolean;
    get: (key: ScopedCommonTemplatesScopeKey) => string[] | undefined;
    set: (key: ScopedCommonTemplatesScopeKey, environmentIds: string[] | undefined) => void;
    remove: (key: ScopedCommonTemplatesScopeKey) => void;
    reset: () => void;
    values: () => ChangedCommonTemplatesScope[];
    hasAny: () => boolean;
    changedTenants: () => Set<string>;
}
export interface ChangedCommonTemplatesScope {
    setId: string;
    templateId: string;
    tenantId: string;
    guid: string;
    environmentIds: string[] | undefined;
}
export type ScopedCommonTemplatesScopeKey = `${string};${string};${string};${string}`;
export function createScopedCommonTemplatesScopeKey(setId: string, templateId: string, tenantId: string, guid: string): ScopedCommonTemplatesScopeKey {
    return `${setId};${templateId};${tenantId};${guid}`;
}
export function extractCommonTemplateValueIds(key: ScopedCommonTemplatesScopeKey): {
    setId: string;
    templateId: string;
    tenantId: string;
    guid: string;
} {
    const extracted = key.split(`;`);
    return {
        setId: extracted[0],
        templateId: extracted[1],
        tenantId: extracted[2],
        guid: extracted[3],
    };
}
