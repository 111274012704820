import type { PathToInput, ObjectRuntimeInputs } from "@octopusdeploy/step-runtime-inputs";
import { createInputValueAccessorForDeploymentTarget, getPathToInput } from "@octopusdeploy/step-runtime-inputs";
import type { MultilineTextComponent } from "@octopusdeploy/step-ui";
import React from "react";
import { Note } from "~/components/StepPackageEditor/Inputs/Note/Note";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
interface DeploymentTargetMultilineTextProps<StepInputs> {
    configuredStepUIProps: MultilineTextComponent;
    inputs: ObjectRuntimeInputs<StepInputs>;
    setInputs(inputs: ObjectRuntimeInputs<StepInputs>): void;
    getFieldError: (path: PathToInput) => string;
}
export function DeploymentTargetMultilineText<StepInputs>(props: DeploymentTargetMultilineTextProps<StepInputs>) {
    const { input, label, note, rows } = props.configuredStepUIProps;
    const inputAccessor = createInputValueAccessorForDeploymentTarget<StepInputs, string>(input);
    const inputPath = getPathToInput(input);
    const inputValue = inputAccessor.getInputValue(props.inputs);
    return (<>
            <DebounceText value={inputValue} onChange={(newValue) => {
            const updatedInputs = inputAccessor.changeInputValue(props.inputs, newValue);
            props.setInputs(updatedInputs);
        }} label={label} minRows={rows} error={props.getFieldError(inputPath)}/>
            <Note note={note}/>
        </>);
}
