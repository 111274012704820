import type { GetProjectsAffectedByChangeBffResponse, ProjectResource, ModifyTenantCommonVariablesCommand, TenantVariableResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { useState } from "react";
import AffectedProjectsNotificationDialog from "~/areas/projects/components/Variables/TenantVariables/AffectedProjectsNotificationDialog";
import CommonTemplatesInlineContent from "~/areas/projects/components/Variables/TenantVariables/CommonTemplatesInlineContent";
import ProjectTenantVariablesPageContent, { type TenantVariablesPageSaveAction } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesPageContent";
import ScopedCommonTemplatesInlineContent from "~/areas/projects/components/Variables/TenantVariables/ScopedTenantVariables/ScopedCommonTemplatesInlineContent";
import type { ScopedCommonTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/ScopedTenantVariables/useScopedCommonTemplatesState";
import { useScopedCommonTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/ScopedTenantVariables/useScopedCommonTemplatesState";
import type { CommonTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesState";
import { useCommonTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesState";
import type { ChangedCommonTemplatesValue, CommonTemplatesValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesValueChangeTracker";
import { useCommonTemplatesValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useCommonTemplatesValueChangeTracker";
import { useTenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import { useTenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import { repository } from "~/clientInstance";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import Dialog from "~/components/Dialog/Dialog";
import { useDialogTrigger } from "~/components/Dialog/DialogTrigger";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
import type { ChangedCommonTemplatesScope, ScopedCommonTemplatesScopeChangeTracker } from "./ScopedTenantVariables/useScopedCommonTemplatesScopeChangeTracker";
import { useScopedCommonTemplatesScopeChangeTracker } from "./ScopedTenantVariables/useScopedCommonTemplatesScopeChangeTracker";
interface CommonTemplatesPageProps {
    project: ProjectResource;
}
function ScopedCommonTemplatesPage({ project }: CommonTemplatesPageProps) {
    const { status: legacyStatus, doBusyTask } = useLegacyDoBusyTask();
    const contentState = useCommonTemplatesState(project, doBusyTask);
    const scopedContentState = useScopedCommonTemplatesState(project, doBusyTask);
    const filterData = useTenantVariableFiltersData(project.Id, doBusyTask);
    const variableResourceOptions = useTenantVariableResourceOptions(doBusyTask);
    const valueChangeTracker = useCommonTemplatesValueChangeTracker();
    const scopeChangeTracker = useScopedCommonTemplatesScopeChangeTracker();
    const { isOpen, openDialog } = useDialogTrigger();
    const [affectedProjects, setAffectedProjects] = useState<GetProjectsAffectedByChangeBffResponse | null>(null);
    const saveAction: TenantVariablesPageSaveAction = {
        onClick: async () => {
            await doBusyTask(async () => {
                const response = await getAffectedProjects(project, valueChangeTracker, scopeChangeTracker);
                setAffectedProjects(response);
                if (response && response.TotalCount > 0) {
                    openDialog();
                }
                else {
                    await saveScoped(valueChangeTracker, scopeChangeTracker, contentState, scopedContentState);
                }
            });
        },
        disabled: !valueChangeTracker.hasAny() && !scopeChangeTracker.hasAny(),
    };
    const saveByConfirmation = async () => await doBusyTask(async () => {
        await saveScoped(valueChangeTracker, scopeChangeTracker, contentState, scopedContentState);
        setAffectedProjects(null);
    });
    const onConfirmationCancelled = () => setAffectedProjects(null);
    return (<ProjectTenantVariablesPageContent legacyStatus={legacyStatus} project={project} doBusyTask={doBusyTask} saveAction={saveAction} warnings={contentState.warnings} page={"common"}>
            {project ? (<ScopedCommonTemplatesInlineContent project={project} doBusyTask={doBusyTask} contentState={scopedContentState} filterData={filterData} variableResourceOptions={variableResourceOptions} valueChangeTracker={valueChangeTracker} scopeChangeTracker={scopeChangeTracker}/>) : null}
            <Dialog open={isOpen}>
                <AffectedProjectsNotificationDialog affectedProjects={affectedProjects} onSave={saveByConfirmation} onCancel={onConfirmationCancelled}></AffectedProjectsNotificationDialog>
            </Dialog>
        </ProjectTenantVariablesPageContent>);
}
function UnscopedCommonTemplatesPage({ project }: CommonTemplatesPageProps) {
    const { status: legacyStatus, doBusyTask } = useLegacyDoBusyTask();
    const contentState = useCommonTemplatesState(project, doBusyTask);
    const filterData = useTenantVariableFiltersData(project.Id, doBusyTask);
    const variableResourceOptions = useTenantVariableResourceOptions(doBusyTask);
    const valueChangeTracker = useCommonTemplatesValueChangeTracker();
    const scopeChangeTracker = useScopedCommonTemplatesScopeChangeTracker();
    const { isOpen, openDialog } = useDialogTrigger();
    const [affectedProjects, setAffectedProjects] = useState<GetProjectsAffectedByChangeBffResponse | null>(null);
    const saveAction: TenantVariablesPageSaveAction = {
        onClick: async () => {
            await doBusyTask(async () => {
                const response = await getAffectedProjects(project, valueChangeTracker, scopeChangeTracker);
                setAffectedProjects(response);
                if (response && response.TotalCount > 0) {
                    openDialog();
                }
                else {
                    await save(valueChangeTracker, contentState);
                }
            });
        },
        disabled: !valueChangeTracker.hasAny() && !scopeChangeTracker.hasAny(),
    };
    const saveByConfirmation = async () => await doBusyTask(async () => {
        await save(valueChangeTracker, contentState);
        setAffectedProjects(null);
    });
    const onConfirmationCancelled = () => setAffectedProjects(null);
    return (<ProjectTenantVariablesPageContent legacyStatus={legacyStatus} project={project} doBusyTask={doBusyTask} saveAction={saveAction} warnings={contentState.warnings} page={"common"}>
            {project ? <CommonTemplatesInlineContent project={project} doBusyTask={doBusyTask} contentState={contentState} filterData={filterData} variableResourceOptions={variableResourceOptions} valueChangeTracker={valueChangeTracker}/> : null}
            <Dialog open={isOpen}>
                <AffectedProjectsNotificationDialog affectedProjects={affectedProjects} onSave={saveByConfirmation} onCancel={onConfirmationCancelled}></AffectedProjectsNotificationDialog>
            </Dialog>
        </ProjectTenantVariablesPageContent>);
}
export default function CommonTemplatesPage({ project }: CommonTemplatesPageProps) {
    return isFeatureToggleEnabled("CommonVariableScopingFeatureToggle") ? <ScopedCommonTemplatesPage project={project}/> : <UnscopedCommonTemplatesPage project={project}/>;
}
async function getAffectedProjects(project: ProjectResource, valueTracker: CommonTemplatesValueChangeTracker, scopeTracker: ScopedCommonTemplatesScopeChangeTracker) {
    const changedSetsForValue = valueTracker.values().map((v) => ({ SetId: v.setId, TenantId: v.tenantId }));
    const changedSetsForScope = scopeTracker.values().map((v) => ({ SetId: v.setId, TenantId: v.tenantId }));
    const changedSets = [...changedSetsForValue, ...changedSetsForScope];
    return await repository.Projects.getProjectsAffectedByCommonTemplateChange(project.Id, changedSets);
}
async function save(valueTracker: CommonTemplatesValueChangeTracker, contentState: CommonTemplatesState) {
    await saveChangedValues(valueTracker);
    await contentState.actions.onSave();
    valueTracker.reset();
}
async function saveScoped(valueTracker: CommonTemplatesValueChangeTracker, scopeTracker: ScopedCommonTemplatesScopeChangeTracker, contentState: CommonTemplatesState, scopedContentState: ScopedCommonTemplatesState) {
    await saveCommonTemplates(valueTracker, scopeTracker);
    await scopedContentState.actions.onSave();
    valueTracker.reset();
    scopeTracker.reset();
}
async function saveCommonTemplates(valueTracker: CommonTemplatesValueChangeTracker, scopeTracker: ScopedCommonTemplatesScopeChangeTracker) {
    const changedTenants = new Set([...valueTracker.changedTenants(), ...scopeTracker.changedTenants()]);
    const tenantCommonVariablesPromises = Array.from(changedTenants).map((id) => repository.Tenants.getCommonVariablesByTenantId(id));
    const tenantCommonVariables = await Promise.all(tenantCommonVariablesPromises);
    const tenantVariablesLookup: {
        [tenantId: string]: ModifyTenantCommonVariablesCommand;
    } = {};
    for (const tenantCommonVariable of tenantCommonVariables) {
        const { TenantId, CommonVariables } = tenantCommonVariable;
        const variables = CommonVariables.map((v) => ({ Id: v.Id, LibraryVariableSetId: v.LibraryVariableSetId, TemplateId: v.TemplateId, Template: v.Template, Value: v.Value, Scope: v.Scope }));
        tenantVariablesLookup[tenantCommonVariable.TenantId] = { TenantId: TenantId, Variables: variables };
    }
    const modifiedTenantVariables = modifyCommonTenantVariables(tenantVariablesLookup, valueTracker.values(), scopeTracker.values());
    const savingPromises = modifiedTenantVariables.map((variables) => repository.Tenants.setCommonVariablesByTenantId(variables.TenantId, variables));
    await Promise.all(savingPromises);
}
async function saveChangedValues(valueTracker: CommonTemplatesValueChangeTracker) {
    const changedTenants = valueTracker.changedTenants();
    const tenantVariablesPromises = Array.from(changedTenants).map((id) => repository.Tenants.getVariablesByTenantId(id));
    const tenantVariables = await Promise.all(tenantVariablesPromises);
    const tenantVariablesLookup: {
        [tenantId: string]: TenantVariableResource;
    } = {};
    for (const tenantVariable of tenantVariables) {
        tenantVariablesLookup[tenantVariable.TenantId] = tenantVariable;
    }
    const modifiedTenantVariables = modifyTenantVariables(tenantVariablesLookup, valueTracker.values());
    const savingPromises = modifiedTenantVariables.map((variables) => repository.Tenants.setVariablesByTenantId(variables.TenantId, variables));
    await Promise.all(savingPromises);
}
function modifyTenantVariables(tenantVariables: {
    [tenantId: string]: TenantVariableResource;
}, valueChanges: ChangedCommonTemplatesValue[]): TenantVariableResource[] {
    const modified = new Map<string, TenantVariableResource>();
    for (const { setId, templateId, tenantId, value } of valueChanges) {
        const variables = tenantVariables[tenantId];
        variables.LibraryVariables[setId].Variables[templateId] = value ?? "";
        modified.set(tenantId, variables);
    }
    return Array.from(modified.values());
}
function modifyCommonTenantVariables(tenantVariables: {
    [tenantId: string]: ModifyTenantCommonVariablesCommand;
}, valueChanges: ChangedCommonTemplatesValue[], scopeChanges: ChangedCommonTemplatesScope[]): ModifyTenantCommonVariablesCommand[] {
    const modified = new Map<string, ModifyTenantCommonVariablesCommand>();
    for (const { id, setId, templateId, tenantId, value } of valueChanges) {
        const variables = tenantVariables[tenantId];
        const variable = variables.Variables.find((v) => v.Id === id);
        if (!variable) {
            if (!value)
                continue;
            const scope = scopeChanges.find((s) => s.guid === id);
            variables.Variables.push({
                Id: id,
                LibraryVariableSetId: setId,
                TemplateId: templateId,
                Value: value,
                Scope: { EnvironmentIds: scope?.environmentIds ?? [] },
            });
            modified.set(tenantId, variables);
            continue;
        }
        if (!value) {
            variables.Variables.splice(variables.Variables.indexOf(variable), 1);
            modified.set(tenantId, variables);
            continue;
        }
        variable.Value = value;
        modified.set(tenantId, variables);
    }
    for (const { guid, setId, templateId, tenantId, environmentIds } of scopeChanges) {
        const variables = tenantVariables[tenantId];
        const variable = variables.Variables.find((v) => v.Id === guid);
        if (!variable) {
            const value = valueChanges.find((s) => s.id === guid);
            variables.Variables.push({
                Id: guid,
                LibraryVariableSetId: setId,
                TemplateId: templateId,
                Value: value?.value ?? "",
                Scope: { EnvironmentIds: environmentIds ?? [] },
            });
            modified.set(tenantId, variables);
            continue;
        }
        variable.Scope.EnvironmentIds = environmentIds ?? [];
        modified.set(tenantId, variables);
    }
    return Array.from(modified.values());
}
