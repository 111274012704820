import * as React from "react";
import DebounceValue from "~/components/DebounceValue/DebounceValue";
import type { TextProps } from "~/primitiveComponents/form/Text/Text";
import Text from "~/primitiveComponents/form/Text/Text";
interface AdvancedFilterTextInputProps {
    fieldName: string;
    value: string;
    onChange(value: string): void;
    disabled?: boolean;
}
const DebouncedText = DebounceValue<TextProps & {
    fieldName?: string;
}, string>(Text);
export const AdvancedFilterTextInput: React.SFC<AdvancedFilterTextInputProps> = (props: AdvancedFilterTextInputProps) => {
    return <DebouncedText disabled={props.disabled} fieldName={props.fieldName} onChange={props.onChange} value={props.value}/>;
};
AdvancedFilterTextInput.displayName = "AdvancedFilterTextInput"
