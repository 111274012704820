/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { ActionButton } from "@octopusdeploy/design-system-components";
import { noOp } from "@octopusdeploy/utilities";
import * as _ from "lodash";
import * as React from "react";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import type { Item } from "../../primitiveComponents/form/Select/Select";
import { BoundSelect } from "../../primitiveComponents/form/Select/Select";
import type { TextInput } from "../../primitiveComponents/form/Text/Text";
import { DebounceText } from "../../primitiveComponents/form/Text/Text";
import type { BoundFieldProps } from "../Actions/pluginRegistry";
import type { AutoCompleteSearchResults } from "../AutoComplete";
import { RemoveItemsList } from "../RemoveItemsList/RemoveItemsList";
import { VariableLookupAutoComplete } from "../form/VariableLookupAutoComplete";
import { VariableLookupText } from "../form/VariableLookupText";
import styles from "./style.module.less";
function buildErrorStyle(theme: OctopusTheme) {
    return { bottom: "0px", color: theme.danger };
}
export interface KeyValueOption {
    key: string;
    keyError?: string;
    value: string;
    valueError?: string;
    option: string;
    optionError?: string;
    option2: string;
    option2Error?: string;
}
class KeyValueOptionRemoveItemsList extends RemoveItemsList<KeyValueOption> {
}
export interface KeyValueOptionEditListProps {
    items: () => KeyValueOption[];
    name: string;
    verb?: string;
    keyLabel: string;
    keyHintText?: string;
    keyMultiline?: boolean;
    keyRowsMax?: number;
    valueLabel?: string;
    valueValues?: Item[];
    valueMultiline?: boolean;
    valueRowsMax?: number;
    valueReset?: string;
    valueHintText?: string;
    optionLabel?: string;
    optionValues?: Item[];
    optionReset?: string;
    optionHintText?: string;
    optionMultiline?: boolean;
    optionRowsMax?: number;
    option2Label?: string;
    option2Values?: Item[];
    option2Reset?: string;
    option2HintText?: string;
    option2Multiline?: boolean;
    option2RowsMax?: number;
    reverseLayout?: boolean;
    hideBindOnKey?: boolean;
    onChange: (items: KeyValueOption[]) => void;
    onAdd?: (items: KeyValueOption[]) => void;
    getOptions?: (searchText: string) => Promise<AutoCompleteSearchResults>;
    getValueOptions?: (searchText: string) => Promise<AutoCompleteSearchResults>;
    getOptionOptions?: (searchText: string) => Promise<AutoCompleteSearchResults>;
    getOption2Options?: (searchText: string) => Promise<AutoCompleteSearchResults>;
    addToTop?: boolean;
}
export interface KeyValueOptionEditListState {
    source: KeyValueOption[];
}
/*
 * The extended key value list supports:
 * - Select boxes
 * - Suggestion boxes
 * - Errors on items
 * - On add callback
 * - More inputs (between 2 and 4)
 * - Adding new items to the top of the list
 * @param item
 * @param idx
 * @param data
 * @param theme
 */
export class ExtendedKeyValueEditList extends React.PureComponent<KeyValueOptionEditListProps & BoundFieldProps, KeyValueOptionEditListState> {
    adding = false;
    constructor(props: KeyValueOptionEditListProps & BoundFieldProps) {
        super(props);
        this.state = {
            source: props.items(),
        };
    }
    handleRemoveRow = (item: any) => {
        const data = this.state.source;
        data.splice(data.indexOf(item), 1);
        this.invokeOnChange(data);
    };
    handleRowRef = (idx: number) => (input: TextInput | null) => {
        if (input && this.adding && idx === 0) {
            input.focus();
            this.adding = false;
        }
    };
    handleKeyChange = (idx: number) => (val: any) => {
        const data = this.state.source;
        data[idx].key = val;
        this.invokeOnChange(data);
    };
    handleValueChange = (idx: number) => (val: any) => {
        const data = this.state.source;
        data[idx].value = val;
        this.invokeOnChange(data);
    };
    handleOptionChange = (idx: number) => (val: any) => {
        const data = this.state.source;
        data[idx].option = val;
        this.invokeOnChange(data);
    };
    handleOption2Change = (idx: number) => (val: any) => {
        const data = this.state.source;
        data[idx].option2 = val;
        this.invokeOnChange(data);
    };
    invokeOnChange = (data: KeyValueOption[]) => {
        this.props.onChange([...data]);
    };
    invokeOnAdd = () => {
        if (this.props.onAdd) {
            this.props.onAdd([...this.state.source]);
        }
    };
    handleAddClick = () => {
        this.adding = true;
        if (this.props.addToTop) {
            this.setState((prev) => ({
                ...prev,
                source: [
                    {
                        key: "",
                        keyError: null!,
                        value: "",
                        valueError: null!,
                        option: "",
                        optionError: null!,
                        option2: "",
                        option2Error: null!,
                    },
                    ...prev.source,
                ],
            }), this.invokeOnAdd);
        }
        else {
            this.setState((prev) => ({
                ...prev,
                source: [
                    ...prev.source,
                    {
                        key: "",
                        keyError: null!,
                        value: "",
                        valueError: null!,
                        option: "",
                        optionError: null!,
                        option2: "",
                        option2Error: null!,
                    },
                ],
            }), this.invokeOnAdd);
        }
    };
    renderRow = (item: any, idx: number, theme: OctopusTheme) => {
        let fields = [
            <div className={styles.textControl} key="key">
                {this.props.hideBindOnKey ? (<DebounceText textInputRef={this.props.reverseLayout ? noOp : this.handleRowRef(idx)} value={item.key} onChange={this.handleKeyChange(idx)} label={this.props.keyLabel} placeholder={this.props.keyHintText}/>) : this.props.getOptions ? (<VariableLookupAutoComplete name={this.props.keyLabel} value={item.key} label={this.props.keyLabel} placeholder={this.props.keyHintText} getOptions={this.props.getOptions} onChange={this.handleKeyChange(idx)} allowAnyTextValue={true}/>) : (<VariableLookupText multiline={this.props.keyMultiline} maxRows={this.props.keyRowsMax} localNames={this.props.localNames} textInputRef={this.props.reverseLayout ? noOp : this.handleRowRef(idx)} value={item.key} onChange={this.handleKeyChange(idx)} label={this.props.keyLabel} placeholder={this.props.keyHintText}/>)}
            </div>,
            this.errorText(theme, item.keyError),
        ];
        if (this.props.valueLabel) {
            fields.push(<div className={styles.textControl} key="value">
                    {this.props.valueValues ? (<BoundSelect variableLookup={{ localNames: this.props.localNames }} label={this.props.valueLabel} onChange={this.handleValueChange(idx)} value={item.value} items={this.props.valueValues} resetValue={this.props.valueReset} boundRows={1}/>) : this.props.getValueOptions ? (<VariableLookupAutoComplete name={this.props.valueLabel} value={item.value} placeholder={this.props.valueHintText} label={this.props.valueLabel} getOptions={this.props.getValueOptions} onChange={this.handleValueChange(idx)} allowAnyTextValue={true}/>) : (<VariableLookupText multiline={this.props.valueMultiline} maxRows={this.props.valueRowsMax} localNames={this.props.localNames} key="value" textInputRef={this.props.reverseLayout ? this.handleRowRef(idx) : noOp} value={item.value} onChange={this.handleValueChange(idx)} label={this.props.valueLabel} placeholder={this.props.valueHintText}/>)}
                </div>, this.errorText(theme, item.valueError));
        }
        if (this.props.optionLabel) {
            fields.push(<div className={styles.textControl} key="option">
                    {this.props.optionValues ? (<BoundSelect variableLookup={{ localNames: this.props.localNames }} label={this.props.optionLabel} onChange={this.handleOptionChange(idx)} value={item.option} items={this.props.optionValues} resetValue={this.props.optionReset} boundRows={1}/>) : this.props.getOptionOptions ? (<VariableLookupAutoComplete name={this.props.optionLabel} value={item.option} placeholder={this.props.optionHintText} label={this.props.optionLabel} getOptions={this.props.getOptionOptions} onChange={this.handleOptionChange(idx)} allowAnyTextValue={true}/>) : (<VariableLookupText multiline={this.props.optionMultiline} maxRows={this.props.optionRowsMax} localNames={this.props.localNames} key="option" textInputRef={this.props.reverseLayout ? this.handleRowRef(idx) : noOp} value={item.option} onChange={this.handleOptionChange(idx)} label={this.props.optionLabel} placeholder={this.props.optionHintText}/>)}
                </div>, this.errorText(theme, item.optionError));
        }
        if (this.props.option2Label) {
            fields.push(<div className={styles.textControl} key="option2">
                    {this.props.option2Values ? (<BoundSelect variableLookup={{ localNames: this.props.localNames }} label={this.props.option2Label} onChange={this.handleOption2Change(idx)} value={item.option2} items={this.props.option2Values} resetValue={this.props.option2Reset} boundRows={1}/>) : this.props.getOption2Options ? (<VariableLookupAutoComplete name={this.props.option2Label} value={item.option2} placeholder={this.props.option2HintText} label={this.props.option2Label} getOptions={this.props.getOption2Options} onChange={this.handleOption2Change(idx)} allowAnyTextValue={true}/>) : (<VariableLookupText multiline={this.props.option2Multiline} maxRows={this.props.option2RowsMax} localNames={this.props.localNames} key="option2" textInputRef={this.props.reverseLayout ? this.handleRowRef(idx) : noOp} value={item.option2} onChange={this.handleOption2Change(idx)} label={this.props.option2Label} placeholder={this.props.option2HintText}/>)}
                </div>, this.errorText(theme, item.option2Error));
        }
        if (this.props.reverseLayout) {
            fields = fields.reverse();
        }
        return (<div key={"KVOI-" + idx} className={styles.threeItemContainer}>
                {fields}
            </div>);
    };
    render() {
        return withTheme((theme) => {
            const renderRow = (item: any, idx: number) => this.renderRow(item, idx, theme);
            const actionButton = <ActionButton key="Add" label={`${this.props.verb || "Add"} ${this.props.name}`} onClick={this.handleAddClick}/>;
            return <KeyValueOptionRemoveItemsList listActions={[actionButton]} data={this.state.source} onRemoveRow={this.handleRemoveRow} onRow={renderRow} clearButtonToolTip={`Remove ${this.props.name}`}/>;
        });
    }
    private errorText(theme: OctopusTheme, error: string | undefined) {
        if (error) {
            return <div style={{ ...styles.error, ...buildErrorStyle(theme) }}>{error}</div>;
        }
    }
    static displayName = "ExtendedKeyValueEditList";
}
export type StringKeyValueOptionEditListProps = Omit<KeyValueOptionEditListProps, "items" | "onChange"> & {
    items: string;
    onChange(items: string): void;
};
function stringToKeyValueOptions(value: string): KeyValueOption[] {
    if (value === null || value === undefined) {
        return [];
    }
    const arrayValue = JSON.parse(value) as KeyValueOption[];
    /*
        Swapping between UI elements can lead to objects (i.e. {"key": "value"} being saved
        and arrays (i.e [{"key":"value"}]) being expected. So make sure we actually received
        and array.
     */
    return _.isArray(arrayValue) ? arrayValue : ([] as KeyValueOption[]);
}
const StringExtendedKeyValueEditList: React.SFC<StringKeyValueOptionEditListProps & BoundFieldProps> = ({ items, onChange, ...rest }) => (<ExtendedKeyValueEditList items={() => stringToKeyValueOptions(items)} onChange={(values) => onChange(JSON.stringify(values))} {...rest}/>);
StringExtendedKeyValueEditList.displayName = "StringExtendedKeyValueEditList"
export default StringExtendedKeyValueEditList;
