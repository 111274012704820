import type { ProjectResource, TenantProjectVariablesResource, TenantVariableResource } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import ProjectTemplatesInlineContent from "~/areas/projects/components/Variables/TenantVariables/ProjectTemplatesInlineContent";
import type { TenantVariablesPageSaveAction } from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesPageContent";
import ProjectTenantVariablesPageContent from "~/areas/projects/components/Variables/TenantVariables/ProjectTenantVariablesPageContent";
import { useProjectTemplatesState } from "~/areas/projects/components/Variables/TenantVariables/useProjectTemplatesState";
import type { ChangedProjectTemplateVariableValue, ProjectTenantVariableValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import { useProjectTenantVariableValueChangeTracker } from "~/areas/projects/components/Variables/TenantVariables/useProjectTenantVariableValueChangeTracker";
import { useTenantVariableFiltersData } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableFiltersData";
import { useTenantVariableResourceOptions } from "~/areas/projects/components/Variables/TenantVariables/useTenantVariableResourceOptions";
import { repository } from "~/clientInstance";
import { type DoBusyTask } from "~/components/DataBaseComponent/index";
import { useLegacyDoBusyTask } from "~/components/DataBaseComponent/useLegacyDoBusyTask";
import { isFeatureToggleEnabled } from "~/components/FeatureToggle/New/FeatureToggleContext";
interface ProjectTemplatesPageInternalProps {
    project: ProjectResource;
}
export default function ProjectTemplatesPageInternal({ project }: ProjectTemplatesPageInternalProps) {
    const { status: legacyStatus, doBusyTask } = useLegacyDoBusyTask();
    const contentState = useProjectTemplatesState(project, doBusyTask);
    const filterData = useTenantVariableFiltersData(project.Id, doBusyTask);
    const variableResourceOptions = useTenantVariableResourceOptions(doBusyTask);
    const valueChangeTracker = useProjectTenantVariableValueChangeTracker();
    const saveAction = useProjectTenantVariableSaveAction(valueChangeTracker, doBusyTask, contentState.actions.onSaveProjectTemplates);
    return (<ProjectTenantVariablesPageContent legacyStatus={legacyStatus} project={project} doBusyTask={doBusyTask} saveAction={saveAction} page={"project"} warnings={contentState.warnings}>
            {project ? <ProjectTemplatesInlineContent project={project} doBusyTask={doBusyTask} contentState={contentState} filterData={filterData} variableResourceOptions={variableResourceOptions} variableValueTracker={valueChangeTracker}/> : null}
        </ProjectTenantVariablesPageContent>);
}
export function useProjectTenantVariableSaveAction(valueChangeTracker: ProjectTenantVariableValueChangeTracker, doBusyTask: DoBusyTask, onSuccess: () => Promise<void>): TenantVariablesPageSaveAction {
    const action: TenantVariablesPageSaveAction = {
        onClick: async () => {
            if (isFeatureToggleEnabled("CommonVariableScopingFeatureToggle")) {
                await saveScopedProjectTemplateVariableValues(doBusyTask, valueChangeTracker);
            }
            else {
                await saveProjectTemplateVariableValues(doBusyTask, valueChangeTracker);
            }
            await onSuccess();
            valueChangeTracker.reset();
        },
        disabled: !valueChangeTracker.hasAny(),
    };
    return action;
}
async function saveProjectTemplateVariableValues(doBusyTask: DoBusyTask, valueTracker: ProjectTenantVariableValueChangeTracker) {
    return doBusyTask(async () => {
        const changedTenants = valueTracker.changedTenants();
        const tenantVariablesPromises = Array.from(changedTenants).map((id) => repository.Tenants.getVariablesByTenantId(id));
        const tenantVariables = await Promise.all(tenantVariablesPromises);
        const tenantVariablesLookup: {
            [tenantId: string]: TenantVariableResource;
        } = {};
        for (const tenantVariable of tenantVariables) {
            tenantVariablesLookup[tenantVariable.TenantId] = tenantVariable;
        }
        const modifiedTenantVariables = modifyTenantVariables(tenantVariablesLookup, valueTracker.values());
        const savingPromises = modifiedTenantVariables.map((variables) => repository.Tenants.setVariablesByTenantId(variables.TenantId, variables));
        await Promise.all(savingPromises);
    });
}
async function saveScopedProjectTemplateVariableValues(doBusyTask: DoBusyTask, valueTracker: ProjectTenantVariableValueChangeTracker) {
    return doBusyTask(async () => {
        const changedTenants = valueTracker.changedTenants();
        const tenantVariablesPromises = Array.from(changedTenants).map((id) => repository.Tenants.getProjectVariablesByTenantId(id));
        const tenantVariablesResponses = await Promise.all(tenantVariablesPromises);
        const tenantVariablesLookup: {
            [tenantId: string]: TenantProjectVariablesResource;
        } = {};
        for (const response of tenantVariablesResponses) {
            const { TenantId, ProjectVariables } = response;
            tenantVariablesLookup[TenantId] = { TenantId, Variables: ProjectVariables };
        }
        const modifiedTenantVariables = modifyScopedTenantVariables(tenantVariablesLookup, valueTracker.values());
        const savingPromises = modifiedTenantVariables.map((variables) => repository.Tenants.setProjectVariablesByTenantId(variables.TenantId, variables));
        await Promise.all(savingPromises);
    });
}
function modifyTenantVariables(tenantVariables: {
    [tenantId: string]: TenantVariableResource;
}, changes: ChangedProjectTemplateVariableValue[]): TenantVariableResource[] {
    const modified = new Map<string, TenantVariableResource>();
    for (const { projectId, templateId, tenantId, environmentId, value } of changes) {
        const variables = tenantVariables[tenantId];
        variables.ProjectVariables[projectId].Variables[environmentId][templateId] = value ?? "";
        modified.set(tenantId, variables);
    }
    return Array.from(modified.values());
}
function modifyScopedTenantVariables(tenantVariables: {
    [tenantId: string]: TenantProjectVariablesResource;
}, changes: ChangedProjectTemplateVariableValue[]): TenantProjectVariablesResource[] {
    const modified = new Map<string, TenantProjectVariablesResource>();
    for (const { projectId, templateId, tenantId, environmentId, value } of changes) {
        const payload = tenantVariables[tenantId];
        const variable = payload.Variables.find((v) => v.ProjectId === projectId && v.TemplateId === templateId && v.Scope.EnvironmentIds.includes(environmentId));
        if (!variable) {
            payload.Variables.push({
                Id: "",
                ProjectId: projectId,
                TemplateId: templateId,
                Value: value ?? "",
                Template: undefined,
                Scope: { EnvironmentIds: [environmentId] },
            });
            modified.set(tenantId, payload);
            continue;
        }
        variable.Value = value ?? "";
        modified.set(tenantId, payload);
    }
    return Array.from(modified.values());
}
